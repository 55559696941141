import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";

function TopBrandCards({ brands }) {
  const history = useHistory();
  const [current, setcurrent] = useState(0);

  const gotoPage = (page) => {
    history.push(page);
  };
  console.log(brands);
  let brand = brands[current];
  if (!brand)
    return (
      <div className="flex items-center justify-center w-full h-full">
        No Top Products Present
      </div>
    );
  else
    return (
      <div className="flex-1 bg-panel_bg_secondary flex  relative">
        <div className="px-4 py-2 w-full h-full absolute  rounded-md">
          <div className="flex gap-x-5 py-3 border-b border-dashed border-black border-opacity-60">
            <div className=" flex gap-2 ">
              {brands?.map((brand, index) => (
                <div
                  key={"brandcard" + index}
                  className={
                    index === current
                      ? "h-28 w-28 rounded-md bg-blue-300 p-1"
                      : "h-28 w-28"
                  }
                >
                  <img
                    src={brand.imageUrl}
                    alt=""
                    onClick={() => setcurrent(index)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="mt-5 flex justify-between ">
            <div className="">
              <div className="flex items-center gap-x-3 font-medium text-blue-800">
                <div className="">
                  <p className="text-lg ">{brand.name}</p>
                </div>
                <div className="flex gap-x-2 items-center">
                  <img
                    src={require("../images/icons/rating.png").default}
                    alt=""
                    className=""
                  />
                  <p>{brand?.rating || 3}</p>
                </div>
              </div>
            </div>
            <button
              className="bg-white py-2 px-8"
              onClick={() => gotoPage("/home/viewBrand/" + brand.id)}
            >
              View
            </button>
          </div>
        </div>
      </div>
    );
}

export default TopBrandCards;
