import React from "react";
import { useState } from "react";
import ReviewsList from "./ReviewsList";
import urls from "../utils/urls";
import axios from "axios";
import { useEffect } from "react";
import { Cookie } from "phosphor-react";
function BrandReviews({ brandId }) {
  const [nodata, setnoData] = useState(false);
  const [loading, setloading] = useState(false);
  const [stats, setStats] = useState([]);

  useEffect(() => {
    let revs = localStorage.getItem("revs" + brandId);
    if (revs) setStats(JSON.parse(revs));
  }, []);

  const fetchStats = async () => {
    setloading(true);
    console.log("fetching from server all users");
    const response = await axios(urls.admin + "getBrandReviews/" + brandId, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
        Authorization: urls.adminAuth,
      },
    });

    if (response.status === 200) {
      let res = response.data;
      setnoData(res.length === 0);
      setStats(res);
      if (res.length !== 0) {
        localStorage.setItem("revs" + brandId, JSON.stringify(res));
      }
      setloading(false);
    }
  };

  if (loading) {
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <i className="animate-pulse">loading...</i>
      </div>
    );
  } else if (stats.length !== 0) {
    return (
      <div className="bg-white rounded-md shadow-md p-2">
        <p className="my-3 text-2xl">{stats.length} Reviews</p>
        <ReviewsList stats={stats} />
      </div>
    );
  } else if (nodata) {
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <i className="animate-pulse">No Brand Reviews Found</i>
      </div>
    );
  } else
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <button
          className="border p-2 shadow-md border-blue-500"
          onClick={fetchStats}
        >
          Reveal Brand Reviews Found
        </button>
      </div>
    );
}

export default BrandReviews;
