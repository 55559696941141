import { storage } from "./firebaseconfig";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AWN from "awesome-notifications";
import { Rating } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import Resizer from "react-image-file-resizer";
import ProductFlicks from "./components/ProductFlicks";
import ProductReviewers from "./components/ProductReviewers";
import ProductReviews from "./components/ProductReviews";
import urls from "./utils/urls";
import axios from "axios";
import AffiliateLinkUpdate from "./components/AffiliateLinkUpdate";
import SKUupdate from "./components/SKUupdate";
import UpdateBrandofProduct from "./components/UpdateBrandofProduct";
import UpdateCatAndSubCat from "./components/UpdateCatAndSubCat";
import CustomRating from "./helpers/Rating";
import DeleteProductSection from "./components/DeleteProductSection";

function ViewProduct() {
  const { productid } = useParams();
  const [product, setproduct] = useState(null);
  const [loading, setloading] = useState(true);

  let nameRef = useRef();
  let descRef = useRef();

  const router = useHistory();

  const fetchUser = async () => {
    setloading(true);
    console.log("fetching from server all users");
    const response = await axios(urls.admin + "getProduct/" + productid, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
        Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
      },
    });

    if (response.status === 200) {
      let res = response.data;
      setproduct(res);
      setloading(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, [productid]);

  const resizeImage = (imagefile, size = 200) =>
    new Promise((resolve, reject) => {
      try {
        console.log("image resizing");
        Resizer.imageFileResizer(
          imagefile,
          size,
          size,
          "JPEG",
          80,
          0,
          (uri) => {
            fetch(uri).then((res) => {
              resolve(res.blob());
            });
          },
          "base64",
          30,
          30
        );
      } catch (err) {
        console.log(err);
        reject(false);
      }
    });

  console.log(product);

  const uploadImageTask = (imageFile, fileName) =>
    new Promise((resolve, reject) => {
      console.log("uploading image");
      var storageRef = storage.ref();
      storageRef
        .child(`product/${fileName}.jpeg`)
        .put(imageFile)
        .then((snapshot) => {
          snapshot.ref
            .getDownloadURL()
            .then(function (downloadURL) {
              return resolve(downloadURL);
            })
            .catch((err) => {
              return reject(false);
            });
        });
    });

  const uploadImage = async (e) => {
    var imageFile = e.target.files.item(0) ?? null;
    if (!imageFile) return new AWN().warning("no image file selected");
    imageFile = await resizeImage(imageFile, 250);
    if (!imageFile) return new AWN().warning("Bad image format");
    const imageUrl = await uploadImageTask(imageFile, productid);
    if (!imageUrl) {
      return new AWN().alert("something went wrong on uploading");
    }

    updateProd({ imageUrl: imageUrl });
  };

  const handletop = async () => {
    new AWN().async(
      axios(
        "https://userspecificapi-zscu3untuq-el.a.run.app/updateTopProduct/" +
          productid,
        {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "asdfwp234-0835234-34535FHD568-a26463213234234-llksdfsdlfwSLDF",
          },
        }
      ),
      async (response) => {
        if (response.status === 200) {
          let data = response.data;
          let message = "operation not succeded";
          console.log(data);
          if (data.success) {
            message = data.top
              ? "Added in Top Product List"
              : "Removed from top users";
            new AWN().success(message);
            setproduct({ ...product, top: data.top });
            return;
          }
          new AWN().warning(message);
          return;
        }
      },
      (err) => {
        console.log(err);
        new AWN().warning(err.toString());
        return;
      }
    );
  };
  const updateProd = async (data) => {
    new AWN().async(
      axios(urls.admin + "updateProduct/" + productid, {
        method: "PUT", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: urls.adminAuth,
        },
        data: JSON.stringify(data),
      }),
      async (response) => {
        console.log(response);
        if (response.status === 200) {
          let message = "operation not succeded";
          if (response.data.acknowledged) {
            new AWN().success("operation successful");
            setproduct({ ...product, ...data });
            return;
          }
          new AWN().warning(message);
          return;
        }
      },
      (err) => {
        console.log(err);
        new AWN().warning(err.toString());
        return;
      }
    );
  };

  const publish = () => {
    new AWN().async(
      fetch(urls.admin + "publishProduct/" + productid, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }),
      async (response) => {
        if (response.status === 200) {
          let data = await response.json();
          let message = "operation not succeded";
          console.log(data);
          if (data.success) {
            message = data.status ? "published" : "pending";
            new AWN().success(message);
            setproduct({ ...product, status: data.status });
            //set in the list also

            let prodList = sessionStorage.getItem("products");
            prodList = JSON.parse(prodList);
            let index = prodList.findIndex((r) => r.id === productid);
            if (index !== -1) {
              prodList[index].status = data.status;
              sessionStorage.setItem("products", JSON.stringify(prodList));
            }
            //set in the list also
            return;
          }
          new AWN().warning(message);
          return;
        }
      },
      (err) => {
        console.log(err);
        new AWN().warning(err.toString());
        return;
      }
    );
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      "& > * + *": {
        marginTop: theme.spacing(1),
      },
    },
  }));

  const inputfield =
    "appearance-none outline-none border-b text-nav_active_bg font-medium text-sm tracking-wide pr-10 w-full h-10";
  if (loading)
    return (
      <div className="h-96 flex items-center justify-center">
        <i className="fas fa fa-spinner animate-spin"></i>
      </div>
    );
  else
    return (
      <div className="bg-panel_bg_secondary  py-10 px-6 font-Manrope">
        <div className="flex items-center justify-between shadow-md">
          <button
            onClick={() => {
              localStorage.removeItem("reviews" + productid);
              localStorage.removeItem("flicks" + productid);
              router.replace("/home/assets/product");
            }}
            className=""
          >
            <span className="fas fa-arrow-left px-3"></span>
            View Product - {product?.name}
          </button>
        </div>
        <div className="py-10 grid grid-cols-2 gap-5 ">
          <div className="row-span-3 space-y-10">
            <div className="bg-white shadow-md">
              <div className="w-full border-b border-dashed bg-white">
                {!product?.coverImage ? null : (
                  <div className="">
                    <img
                      src={product?.coverImage}
                      alt=""
                      className="w-full h-56 object-cover object-left-top "
                    />
                  </div>
                )}
                <div className="p-5 flex items-center justify-between ">
                  <div className="">
                    <div className="flex items-center gap-x-3">
                      <img
                        src={product?.imageUrl}
                        alt=""
                        className="h-40 w-40 rounded-full object-cover border transform duration-100 hover:scale-150"
                      />
                      <div className="">
                        <label for="uploadProdimage">
                          <div className="cursor-pointer">
                            <span className="fas fa fa-pencil text-xs "></span>
                            <span className="text-xs"> Upload Image</span>
                          </div>
                        </label>
                        <input
                          type="file"
                          id="uploadProdimage"
                          className="hidden"
                          onChange={uploadImage}
                        />
                      </div>
                      <p className="font-semibold text-xl">{product?.name}</p>
                    </div>
                  </div>

                  <div className="">
                    {product?.rating && (
                      <div className={useStyles.root}>
                        <Rating
                          name="half-rating"
                          defaultValue={product?.rating}
                          precision={0.5}
                          readOnly
                        />
                      </div>
                    )}
                    <p className="text-right text-sm">
                      <span className="text-blue-500">#13</span>
                      <span className="text-black text-opacity-60">out of</span>
                      <span className="underline text-blue-500">200</span>
                    </p>
                  </div>
                </div>
                <div className="flex justify-center pb-5">
                  <button
                    onClick={handletop}
                    className="border-r px-7 h-10 appearance-none outline-none bg-tab_passive_bg hover:bg-gray-200 text-nav_active_bg"
                  >
                    Make Top Product
                  </button>
                  <button
                    className={
                      product?.status
                        ? "bg-green-500 px-5 text-white"
                        : "px-5 text-white bg-yellow-500"
                    }
                    onClick={publish}
                  >
                    {product?.status ? "Put on hold" : "Publish Now"}
                  </button>
                </div>
              </div>
              <div className=" ">
                <div className="">
                  <div className="py-5  grid grid-flow-row grid-cols-2 px-5 gap-x-5">
                    <div className="grid col-span-2 grid-cols-2 text-black gap-5">
                      {Object.entries(product?.attributes ?? {})?.map(
                        ([key, value]) => (
                          <div key={key} className="">
                            <p className="">
                              {key} - ({value})
                            </p>
                            <CustomRating rating={value} />
                          </div>
                        )
                      )}
                    </div>
                    <div className="py-2">
                      <p className="">Name</p>
                      <div className="relative">
                        <i className="absolute fas fa fa-pencil right-1 pt-1"></i>

                        <input
                          type="text"
                          className={inputfield}
                          placeholder="Name"
                          ref={nameRef}
                          defaultValue={product?.name}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="py-2">
                      <p className="">Category ID</p>
                      <input
                        type="text"
                        className={inputfield}
                        placeholder="Category ID"
                        defaultValue={product?.catId}
                        disabled
                      />
                    </div>
                    <div className="py-2">
                      <p className="">Product ID</p>
                      <input
                        type="text"
                        className={inputfield}
                        placeholder="Product ID"
                        defaultValue={product?.id}
                        disabled
                      />
                    </div>

                    <div className="py-2">
                      <p className="">Description</p>
                      <div className="relative ">
                        <i className="absolute fas fa fa-pencil right-1 pt-1"></i>

                        <textarea
                          type="text"
                          className={inputfield + " h-44"}
                          ref={descRef}
                          placeholder="Description"
                          defaultValue={product?.description}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="py-2">
                      <p className="">Status</p>

                      {product?.status ? "Published" : "Pending"}
                    </div>

                    <UpdateBrandofProduct
                      brandId={product?.parent}
                      updateFun={updateProd}
                    />

                    <UpdateCatAndSubCat
                      subcategory={product.subcategory}
                      updateFun={updateProd}
                    />
                    <AffiliateLinkUpdate
                      link={product.affiliateLink}
                      updateFun={updateProd}
                    />
                    <SKUupdate sku={product.sku} updateFun={updateProd} />
                    <div className="py-2">
                      <p className="">Date of origin</p>
                      <div className="relative ">
                        <p className="">
                          {new Date(product?.createdAt).toDateString()}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 grid-flow-col gap-x-5 mx-5 pb-5">
                    <div className="bg-blue-500 h-32 w-full rounded">
                      <div className="flex items-center justify-between px-5 py-3">
                        <p className="text-white">Total Views on Review:</p>
                        <img
                          src={require("./images/icons/allarrow.png").default}
                          alt=""
                          className=""
                        />
                      </div>
                      <div className="px-5 py-3">
                        <p className="text-4xl text-white font-medium">300</p>
                      </div>
                    </div>
                    <div className="bg-green-400 h-32 w-full rounded">
                      <div className="flex items-center justify-between px-5 py-3">
                        <p className="text-white">Total Views on Experience:</p>
                        <img
                          src={require("./images/icons/allarrow.png").default}
                          alt=""
                          className=""
                        />
                      </div>
                      <div className="px-5 py-3">
                        <p className="text-4xl text-white font-medium">300</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" w-full space-y-10">
            <div className="bg-white shadow-md p-2 rounded">
              <p className="">Information</p>
              <ol className="">
                {product.affiliateLink ? null : (
                  <li className="bg-red-300 shadow-sm rounded p-2 m-1">
                    Please update Affiliate Link
                  </li>
                )}
                {product.sku ? null : (
                  <li className="bg-red-300 shadow-sm rounded p-2 m-1">
                    Please update SKU
                  </li>
                )}
                {product.imageUrl ? null : (
                  <li className="bg-red-300 shadow-sm rounded p-2 m-1">
                    Please update Image
                  </li>
                )}
                {product.parent ? null : (
                  <li className="bg-red-300 shadow-sm rounded p-2 m-1">
                    Please update brand Parent
                  </li>
                )}
              </ol>
            </div>
            <div className="grid grid-cols-2 grid-flow-row gap-10">
              <div className="bg-white p-10 shadow-md">
                <p className="text-xl text-center "> Total Reviews:</p>
                <p className="text-3xl text-center text-blue-700 font-medium">
                  {product?.reviews ?? "0"}
                </p>
              </div>
              <div className="bg-white p-10 shadow-md">
                <p className="text-xl text-center ">Total Flicks:</p>
                <p className="text-3xl text-center text-blue-700 font-medium">
                  {product?.flicks ?? "0"}
                </p>
              </div>
              <div className="bg-white p-10 shadow-md">
                <p className="text-xl text-center ">Interested in Buying:</p>
                <p className="text-3xl text-center text-blue-700 font-medium">
                  {product?.interested ?? "0"}
                </p>
              </div>
              <div className="bg-white shadow-md p-10">
                <p className="text-xl text-center ">Buy now Clicks:</p>
                <p className="text-3xl text-center text-blue-700 font-medium">
                  {product?.buynowclicks ?? "0"}
                </p>
              </div>
            </div>
          </div>
          <DeleteProductSection productId={productid} />
          <ProductFlicks productId={productid} />
          <ProductReviewers productId={productid} />
          <ProductReviews productId={productid} />
        </div>
      </div>
    );
}

export default ViewProduct;
