import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

function ServerStatus() {
  const [shortServer, setShortServer] = useState(true);
  const [editorServer, setEditorServer] = useState(true);
  useEffect(() => {
    axios
      .get("http://34.131.171.176:5000")
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          sessionStorage.setItem("videoShortServer", true);
          console.log(response.data);
          setShortServer(true);
        } else {
          sessionStorage.setItem("videoShortServer", false);
          setShortServer(false);
        }
      })
      .then((e) => {
        sessionStorage.setItem("videoEditorServer", false);
        setShortServer(false);
      });
    axios
      .get("https://34.131.171.176")
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          sessionStorage.setItem("videoEditorServer", true);
          setEditorServer(true);
        } else {
          sessionStorage.setItem("videoEditorServer", false);
          setEditorServer(false);
        }
      })
      .catch((e) => {
        sessionStorage.setItem("videoEditorServer", false);
        setEditorServer(false);
      });
  }, []);
  console.log(
    editorServer,
    shortServer,
    "===--------COMING FROM SERVER STATUS----------------"
  );

  return (
    <div className="grid lg:grid-cols-2 lg:grid-flow-col gap-5 mt-5">
      <div
        className={
          "h-48 w-full rounded grid grid-cols-2 grid-flow-col" +
          (editorServer ? " bg-green-500" : " bg-red-500")
        }
      >
        <div className=" flex flex-col justify-between px-5 py-3">
          <p className="text-white">Video Editor Server</p>
          <p className="text-5xl text-white font-medium">
            {editorServer ? "Running" : "Down"}
          </p>
          <p className="text-xs text-white font-medium">Status</p>
        </div>
      </div>
      <div
        className={
          "h-48 w-full rounded grid grid-cols-2 grid-flow-col" +
          (shortServer ? " bg-green-500" : " bg-red-500")
        }
      >
        <div className=" flex flex-col justify-between px-5 py-3">
          <p className="text-white">Video shorts Server</p>
          <div className="flex items-end gap-x-3  py-3">
            <p className="text-5xl text-white font-medium">
              {shortServer ? "Running" : "Down"}
            </p>
          </div>
          <p className="text-white">Status</p>
        </div>
      </div>
    </div>
  );
}

export default ServerStatus;
