import React from "react";
import { firebasefb } from "./firebaseconfig";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import DataCard from "./utils/DataCard";
import Goback from "./utils/Goback";

function CoinHistory() {
  const router = useHistory();
  const { userid } = useParams();
  const [coinsleft, setcoinsleft] = useState(0);
  const [coinsearned, setcoinsearned] = useState(0);
  const [coinsspend, setcoinsspend] = useState(0);
  const [coins, setcoins] = useState([]);

  const calculate = (coins) => {
    var plus = 0;
    var neg = 0;
    coins.forEach((c) => {
      if (c.coins > 0) plus += c.coins;
      else neg -= c.coins;
    });
    setcoinsearned(plus);
    setcoinsspend(neg);
    setcoinsleft(plus + neg);
  };

  useEffect(() => {
    firebasefb
      .collection("userActivity")
      .doc(userid)
      .get()
      .then((res) => {
        setcoins(res.data().coins);
        calculate(res.data().coins);
      });
  }, [userid]);
  return (
    <div className="bg-background min-h-screen">
      <div className="bg-white h-24 w-full">
        <div className="bg-white h-24">
          <h1 className="font-medium text-3xl flex flex-grow-0 pt-5 pl-8 ">
            <Goback />
            <span className="pt-2"> &nbsp; Coin History</span>
          </h1>
        </div>
      </div>

      <div className=" bg-white flex  justify-between items-end ml-20 mr-20 mt-10 h-auto  shadow-lg">
        <div className="">
          <p className="text-3xl font-medium p-4">Coins earned</p>
        </div>
        <div className="pr-4">
          <p className="text-md font-Regular text-sidetext text-right ">
            Coins
          </p>

          <p className="text-3xl font-medium flex flex-grow-0 ">
            {coinsearned}
          </p>
        </div>
      </div>
      <div className=" bg-white flex  justify-between items-end ml-20 mr-20 mt-4 h-auto  shadow-lg">
        <div className="">
          <p className="text-3xl font-medium p-4">Coins spend</p>
        </div>
        <div className="pr-4">
          <p className="text-md font-Regular text-sidetext text-right ">
            Coins
          </p>

          <p className="text-3xl font-medium flex flex-grow-0 ">{coinsspend}</p>
        </div>
      </div>
      <div className=" bg-white flex  justify-between items-end ml-20 mr-20 mt-4 h-auto  shadow-lg">
        <div className="">
          <p className="text-3xl font-medium p-4">Coins left</p>
        </div>
        <div className="pr-4">
          <p className="text-md font-Regular text-sidetext text-right ">
            Coins
          </p>

          <p className="text-3xl font-medium flex flex-grow-0 ">{coinsleft}</p>
        </div>
      </div>
      <div className="bg-white m-20">
        <table className="table-fixed text-black w-full h-full overflow-scroll">
          <thead className="text-left">
            <tr className="h-12 border-b">
              <th className=" w-20 pl-10 font-medium ">Serial No.</th>
              <th className="w-20 pl-5 font-medium ">Coins</th>
              <th className="  font-medium ">Message</th>
            </tr>
          </thead>
          <tbody>
            {coins.map((c, index) => (
              <tr className="h-20 text-lg font-regular text-filter hover:bg-gray-50 border-b cursor-pointer">
                <td className=" w-12  pl-10 ">{index}</td>
                <td className="w-20  pl-5">
                  {c.coins}
                  <span className="text-pink-500 font-bold">C</span>
                </td>
                <td className="  ">{c.message}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="h-2"></div>
    </div>
  );
}

export default CoinHistory;
