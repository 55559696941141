import AWN from "awesome-notifications";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { data } from "./utils/Res";
import CustomDataGrid from "./utils/CustomDataGrid";
import { useStateValue } from "./StateProvider";
import urls from "./utils/urls";
import axios from "axios";
import ExportCollection from "./components/ExportCollection";
import UPloadProductsTSV from "./components/UploadProductsTSV";

function Products() {
  const [products, setproducts] = useState([]);
  const [{ countAdmin }, dispatch] = useStateValue();

  const [searchproduct, setsearchproduct] = useState([]);
  const [searchmode, setsearchmode] = useState(false);
  const history = useHistory();
  const [loading, setloading] = useState(true);

  let queryRef = useRef();

  const fetchUsers = async (page = 0, refresh = false) => {
    if (page === 0 && !refresh) {
      let chart = sessionStorage.getItem("products");
      if (chart) {
        chart = JSON.parse(chart);
        setproducts(chart);
        setloading(false);
        return;
      }
    }
    console.log("fetching from server all users");
    const response = await fetch(urls.admin + "getProducts/" + page, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });

    if (response.status === 200) {
      let res = await response.json();
      if (page !== 0) {
        let eres = sessionStorage.getItem("products");
        eres = JSON.parse(eres);
        res = eres.concat(res);
      }
      sessionStorage.setItem("products", JSON.stringify(res));
      setproducts(res);
      setloading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const searchNow = async (e) => {
    e.preventDefault();
    setsearchmode(true);
    let query = queryRef.current.value;
    query = query.trim();
    console.log(query);
    let response;
    if (!query.includes(" ") && query.startsWith("pro")) {
      response = await axios(urls.admin + "getProduct/" + query, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
        },
      });
      if (response.status === 200) {
        let res = response.data;
        console.log(res);
        setsearchproduct([res]);
      }
    } else {
      response = await axios(urls.admin + "searchProducts", {
        method: "PUT", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
        },
        data: JSON.stringify({ query: query }),
      });
      if (response.status === 200) {
        if (response.data.err) {
          new AWN().warning(response.data.err);
          return;
        }
        let res = response.data;
        setsearchproduct(res);
      }
    }
  };

  const columns = [
    { field: "name", headerName: "name", width: 190 },
    {
      field: "createdAt",
      headerName: "Date",
      width: 210,
      renderCell: (row) => new Date(row.createdAt).toDateString(),
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (row) => (
        <span className={row?.status ? data.greenButton : data.redButton}>
          {row?.status ? "published" : "pending"}
        </span>
      ),
    },
    { field: "id", headerName: "Product Id", width: 150 },
    { field: "rating", headerName: "Rating ", width: 120 },
    { field: "flicks", headerName: "Flicks ", width: 120 },
    { field: "reviews", headerName: "Reviews ", width: 120 },
  ];

  const actionNode = (row) => (
    <div className=" bg-white shadow-sm z-50 w-48 absolute border right-24 rounded-md -mt-8">
      <div className="h-5 w-5  bg-white shadow-sm rounded-full border flex items-center justify-center right-0 absolute -m-2 cursor-pointer">
        <img
          src={require("./images/icons/Close.svg").default}
          alt=""
          className="transform "
        />
      </div>
      <div className="w-full">
        <div
          className="h-10 w-full font-medium flex items-center px-4 bg-white hover:bg-purple-100 cursor-pointer rounded select-none"
          onClick={() => history.push(`/home/viewProduct/${row.id}`)}
        >
          View
        </div>
      </div>
    </div>
  );

  const refreshPage = async () => {
    await fetchUsers(0, true);
    new AWN().success("page refreshed.");
  };

  const loadMore = () => {
    fetchUsers(Math.floor(products.length / 50));
  };

  const uploadCsv = async (selectedFile) => {
    if (!selectedFile) {
      new AWN().warning("not JSON file selected");
      return;
    }
    let ext = selectedFile.name.split(".").pop();
    if (ext !== "json") {
      new AWN().warning("this is not json file");
      return;
    }
    let formData = new FormData();
    formData.append("file", selectedFile);

    new AWN().async(
      axios.post(urls.admin + "insertProducts", formData, {
        headers: {
          Authorization: urls.adminAuth,
        },
      }),
      function (response) {
        if (response.data.success)
          new AWN().success("File uploaded successfully.");
        else new AWN().alert(response.data.message);
      },
      function (error) {
        new AWN().alert("Error uploading file.");
      }
    );
  };

  if (loading)
    return (
      <div className="h-screen flex items-center justify-center">
        <span className="fas fa-spinner animate-spin fa-3x"></span>
      </div>
    );
  return (
    <div className="font-Manrope py-10">
      <div className="flex justify-between items-center px-10">
        <p className="text-3xl font-medium">Total Products:</p>
        <p className="text-3xl font-semibold "> {countAdmin.products || 0}</p>
      </div>
      <div className="flex gap-1 px-10 justify-end">
        <ExportCollection collection="products" />
        <Link to="/home/mergeProduct">
          <button className="ml-3 w-40 py-1 px-3 text-sm h-10 bg-yellow-400 rounded text-white">
            Merge Product
          </button>
        </Link>
        <button
          className="ml-3 w-44 py-2 px-3 bg-blue-400 rounded text-white"
          onClick={() => history.push("/home/createProduct")}
        >
          Create New Product
        </button>

        <button
          className="ml-3 py-2 px-8 bg-purple-400 rounded text-white"
          onClick={refreshPage}
        >
          <i className="fas fa fa-refresh"></i>
        </button>
      </div>
      <div className="flex w-full px-10 my-6">
        <form
          action=""
          className="flex items-center relative w-full"
          onSubmit={(e) => searchNow(e)}
        >
          {searchmode ? (
            <i
              className="fas fa-times-circle fa-lg p-1 cursor-pointer absolute right-3"
              onClick={() => {
                setsearchmode(false);
              }}
            ></i>
          ) : null}
          <input
            type="text"
            ref={queryRef}
            className="pr-8 py-2 px-3 w-full appearance-none outline-none border focus:border-pink-500"
            placeholder="Search by Name, Category, products"
          />
        </form>
      </div>

      <div className="pb-20 px-10 w-full">
        <div className="w-full  bg-white border shadow-lg rounded-md overflow-x-auto">
          {!searchmode ? (
            <CustomDataGrid
              key="normal"
              rows={products}
              columns={columns}
              actionNode={actionNode}
              loadMore={loadMore}
            />
          ) : (
            <CustomDataGrid
              key="search"
              rows={searchproduct}
              columns={columns}
              actionNode={actionNode}
              loadMore={loadMore}
            />
          )}
        </div>
      </div>
      <div className="h-2"></div>
    </div>
  );
}

export default Products;
