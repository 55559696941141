import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";

function ReviewersTopCards({ reviewers }) {
  const [current, setcurrent] = useState(0);

  let history = useHistory();
  let reviewer = reviewers[current];

  if (!reviewer)
    return (
      <div className="flex items-center justify-center w-full h-full">
        No Top Revierws Present
      </div>
    );
  else
    return (
      <div className="flex-1 bg-panel_bg_secondary flex  relative">
        <div className="px-4 py-1 w-full h-full absolute  rounded-md">
          <div className="flex gap-x-5 py-1 border-b border-dashed border-black border-opacity-60">
            <div className="flex gap-2">
              {reviewers?.map((reviewer, index) => (
                <div
                  className={
                    index === current
                      ? " w-28 rounded-md bg-blue-300 p-1"
                      : " w-28"
                  }
                >
                  <img
                    src={reviewer.imageUrl}
                    className="w-28 h-28  object-cover rounded-md"
                    alt=""
                    onClick={() => setcurrent(index)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="mt-5 flex justify-between ">
            <div className="">
              <div className="flex items-center gap-x-3 font-medium text-blue-800">
                <div className="">
                  <p className="text-lg ">{reviewer.name}</p>
                </div>
                <div className="flex gap-x-2 items-center">
                  <img
                    src={require("../images/icons/rating.png").default}
                    alt=""
                    className=""
                  />
                  <p>{reviewer?.rating || 3}</p>
                </div>
              </div>
              <div className="">
                <p className="text-blue-800">
                  Reviews: {reviewer.reviews ?? 0}
                </p>
              </div>
            </div>
            <div className="flex items-end flex-col">
              <div className="divide-x-2 border rounded-md mt-5">
                <button
                  className="bg-white py-2 px-8"
                  onClick={() => history.push("/home/users/" + reviewer.id)}
                >
                  View
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default ReviewersTopCards;
