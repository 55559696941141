import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useStateValue } from "../StateProvider";

function Goback() {
  const router = useHistory();
  return (
    <div className="">
      <div className="p-2">
        <button
          onClick={() => {
            router.goBack();
          }}
          className=""
        >
          <span className="fas fa-arrow-left"></span>
        </button>
      </div>
    </div>
  );
}

export default Goback;
