import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";

function IdeasFromCreators() {
  const [current, setcurrent] = useState(0);
  const [flicks, setFlicks] = useState([]);
  let history = useHistory();

  const fetchTopFLicks = async () => {
    let chart = sessionStorage.getItem("topFlicks");
    if (chart) {
      chart = JSON.parse(chart);
      setFlicks(chart.data);
      return;
    }
    console.log("fetching from server top users");
    const response = await axios(
      "https://userspecificapi-zscu3untuq-el.a.run.app/getStory/user_admin",
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "asdfwp234-0835234-34535FHD568-a26463213234234-llksdfsdlfwSLDF",
        },
      }
    );

    if (response.status === 200) {
      let res = response.data;
      sessionStorage.setItem("topFlicks", JSON.stringify(res));
      setFlicks(res.data);
    }
  };

  useEffect(() => {
    fetchTopFLicks();
  }, []);

  const playFlicks = async (url) => {
    const AWN = (await import("awesome-notifications")).default;

    new AWN().modal(`<video width="320" height="240" controls>
        <source src=${url} type="video/mp4">
        
      Your browser does not support the video tag.
      </video>`);
    return;
  };
  if (flicks.length === 0)
    return (
      <div className="flex items-center justify-center w-full h-full">
        No Ideas from creators found
      </div>
    );
  else
    return (
      <div className="flex-1 bg-panel_bg_secondary flex  relative">
        <div className="px-4 py-1 w-full h-full absolute  rounded-md">
          <div className="flex gap-x-5 py-1 border-b border-dashed border-black border-opacity-60 overflow-auto">
            <div className="flex gap-2">
              {flicks?.map((flicks, index) => (
                <div
                  className={
                    index === current
                      ? " w-28 rounded-md bg-blue-300 p-1"
                      : " w-28"
                  }
                >
                  <img
                    src={flicks.thumbnail}
                    className="w-28 h-28  object-cover rounded-md"
                    alt=""
                    onClick={() => setcurrent(index)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="mt-5 flex justify-between ">
            <div className="flex items-end flex-col">
              <div className="divide-x-2 border rounded-md mt-5">
                <button
                  className="bg-purple-500 text-white py-2 px-8 "
                  onClick={() => playFlicks(flicks[current].reviewUrl)}
                >
                  Play
                </button>
                <button
                  className="bg-green-500 text-white py-2 px-8"
                  onClick={() =>
                    history.push(
                      "/home/flicks/ViewFlicks/" + flicks[current].id
                    )
                  }
                >
                  View
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default IdeasFromCreators;
