export const initialState = {
  user: JSON.parse(localStorage.getItem("user")),
  notification: [],
  currentItem: null,
  countAdmin: null,
  userData: [],
  reviewCache: [],
  brandCache: [],
  productCache: [],
  hashtags: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };
    case "SET_COUNT_ADMIN":
      return {
        ...state,
        countAdmin: action.countAdmin,
      };
    case "SET_ITEM":
      return {
        ...state,
        currentItem: action.item,
      };
    case "DELETE_ITEM":
      return {
        ...state,
        currentItem: null,
      };
    case "DELETE_USER":
      localStorage.clear();
      return {
        ...state,
        user: null,
      };
    case "DELETE_COUNT_ADMIN":
      return {
        ...state,
        countAdmin: null,
      };
    case "ADD_NOTIFICATION":
      return {
        ...state,
        notification: [...state.notification, action.notification],
      };
    case "EMPTY_NOTIFICATION":
      return {
        ...state,
        notification: [],
      };
    case "SET_USERDATA":
      return {
        ...state,
        userData: [...state.userData, ...action.userData],
      };
    case "SET_BRAND":
      return {
        ...state,
        brandCache: action.brands,
      };
    case "SET_PRODUCT":
      return {
        ...state,
        productCache: action.products,
      };
    case "SET_REVIEW":
      return {
        ...state,
        reviewCache: action.reviews,
      };
    case "SET_HASHTAGS":
      return {
        ...state,
        hashtags: action.hashtags,
      };
    default:
      return state;
  }
};

export default reducer;
