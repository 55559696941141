import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { firebasefb } from "./firebaseconfig";
import Goback from "./utils/Goback";

function ViewCategory() {
  const { catid } = useParams();
  const [category, setcategory] = useState(null);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    console.log(catid);
    firebasefb
      .collection("category")
      .where("id", "==", catid)
      .get()
      .then((res) => {
        setcategory(res.docs[0].data());
        setloading(false);
      });
  }, [catid]);

  console.log(category);

  if (loading)
    return (
      <div className="h-screen flex justify-center items-center">
        <span className="fas fa-spinner animate-spin fa-3x">{}</span>
      </div>
    );
  else
    return (
      <div className="bg-background min-h-screen">
        <div className="bg-white h-24">
          <h1 className="font-medium text-3xl flex flex-grow-0 pt-5 pl-8 ">
            <Goback />
            <span className="pt-2"> &nbsp; View Category</span>
          </h1>
        </div>
        <div className="m-16">
          <div className=" items-center justify-center flex  ">
            <div className="h-96 w-96 bg-white items-center justify-center flex   shadow-md">
              <img src={category.imageUrl} className="h-80 w-80 " alt="" />
            </div>
          </div>
        </div>
        <div className="m-16">
          <div className="items-center justify-center flex flex-col">
            <p className="text-black font-medium text-3xl tracking-wide">
              {category.name}
            </p>
            <p className="text-black  text-sm tracking-wide">{category.id}</p>
          </div>
        </div>
        <div className="m-20">
          {category.images?.map((e, index) => (
            <div className="bg-white mt-4 ">
              <ul className="list-disc">
                <li
                  key={index}
                  className="flex  items-center rounded shadow-md p-1 "
                >
                  <img
                    src={e.icon}
                    alt=""
                    className={"h-32 w-32 rounded mr-2 "}
                  />
                  <div className="">
                    <p className="text-lg mx-1 my-2 capitalize font-semibold">
                      {e.name}
                    </p>
                    <p className="">
                      {Object.entries(e?.attributes || {})?.map(
                        (att, index) => (
                          <span
                            key={"att" + index}
                            className="bg-indigo-300 text-gray-600 py-1 px-2 rounded-lg m-1"
                          >
                            {att[0]}
                          </span>
                        )
                      )}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          ))}
        </div>
        <div className="h-2"></div>
      </div>
    );
}

export default ViewCategory;
