import React from "react";
import { useState } from "react";
import urls from "../utils/urls";

function UserFlicksStats({ userid }) {
  const fetchStats = async () => {
    setloading(true);
    console.log("fetching from server all users");
    const response = await fetch(urls.admin + "getFlickStats/" + userid, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });
    console.log(response.status);
    if (response.status === 200) {
      let res = await response.json();
      setStats(res);
      setnoData(res.length === 0);
      console.log(res);
      setloading(false);
    }
  };

  const [loading, setloading] = useState(false);
  const [nodata, setnoData] = useState(false);
  const [stats, setStats] = useState([]);

  if (loading) {
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <i className="animate-pulse">loading...</i>
      </div>
    );
  } else if (stats.length !== 0) {
    return (
      <div className=" bg-pink-400 rounded shadow-md text-white ">
        <p className="m-3 text-2xl">Flicks Stats</p>
        <div className="text-white p-3 flex-1">
          <p className="text-xs">
            <span className="text-5xl px-3">{stats[0].reviews}</span>Reviews
          </p>
          <p className="">
            <span className="text-5xl px-3">{stats[0].views}</span>Views
          </p>
          <p className="">
            <span className="text-5xl px-3">{stats[0].hearts}</span>Hearts
          </p>
        </div>
      </div>
    );
  } else if (nodata) {
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <i className="animate-pulse">No Flicks Stat Found</i>
      </div>
    );
  } else
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <button
          className="border p-2 shadow-md border-blue-500"
          onClick={fetchStats}
        >
          Reveal Flicks Stats
        </button>
      </div>
    );
}

export default UserFlicksStats;
