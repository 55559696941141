import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { firebasefb } from "./firebaseconfig";
import { data } from "./utils/Res";
import { DataGrid } from "@material-ui/data-grid";
import { useStateValue } from "./StateProvider";

function Redemption() {
  const [redeem, setredeem] = useState([]);
  const [loading, setloading] = useState(true);
  const [{ currentItem }, dispatch] = useStateValue();
  const router = useHistory();

  useEffect(() => {
    firebasefb
      .collection("redemption")
      .get()
      .then((res) => {
        var a = [];
        res.docs.forEach((redeems) => {
          let data = redeems.data();
          a.push({ ...data, id: redeems.id });
        });

        setredeem(a);
        setloading(false);
      });
  }, []);

  const columns = [
    {
      field: "redeemDate",
      headerName: "Date",
      width: 250,
      renderCell: ({ row }) => <span className="">{Date(row.redeemDate)}</span>,
    },
    {
      field: "Via",
      headerName: "Via",
      width: 150,
      renderCell: ({ row }) => <span className="">{row.via}</span>,
    },

    {
      field: "upiId",
      headerName: "Upi Id",
      width: 150,
    },

    {
      field: "title",
      headerName: "Name",
      width: 190,
      renderCell: ({ row }) => <div className="">{row.title}</div>,
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 320,
      // eslint-disable-next-line react/display-name
      renderCell: ({ row }) => (
        <div>
          <button
            onClick={() => {
              dispatch({ type: "SET_ITEM", item: row });
              router.push("/home/viewRedeemDetail/" + row.id);
            }}
            className={data.greenButton}
          >
            View
          </button>
        </div>
      ),
    },
  ];

  if (loading)
    return (
      <div className="h-screen flex items-center justify-center">
        <span className="fas fa-spinner animate-spin"></span>
      </div>
    );
  else
    return (
      <div className="bg-background min-h-screen w-full ">
        <div className=" bg-white flex  justify-between items-end  h-auto  ">
          <div className="">
            <p className="text-3xl font-medium p-4">Total Redeems</p>
          </div>
          <div className="pr-4">
            <p className="text-md font-Regular text-sidetext text-right ">
              Redeems
            </p>

            <p className="text-3xl font-medium flex flex-grow-0 text-right">
              {redeem.length}
            </p>
          </div>
        </div>
        <div className="h-96">
          <DataGrid rows={redeem} columns={columns} pageSize={20} />
        </div>
      </div>
    );
}

export default Redemption;
