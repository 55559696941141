import React from "react";
import { firebasefb } from "./firebaseconfig";
function Test() {
  var running = false;

  const movecoin = async () => {
    if (running) return;
    running = true;

    console.log("starting");

    let brands = await firebasefb
      .collection("product")
      .where("parent", ">", "")
      .get()
      .then((res) => res.docs.map((r) => r.data()));

    let progress = 0;
    let count = 0;
    let indicator = "=>";
    let joblength = brands.length;

    let parents = [];

    for (const brand of brands) {
      count += 1;

      let parentName = parents.find((p) => p.id === brand.parent);

      if (!parentName) {
        console.log(brand.parent);

        let b = await firebasefb
          .doc("brand/" + brand.parent)
          .get()
          .then((res) => res.data());

        if (!b) continue;
        parentName = b.name;
        parents.push(b);
      } else {
        parentName = parentName.name;
      }

      console.log(parentName);

      await firebasefb.doc("product/" + brand.id).update({
        parentName: parentName,
      });

      progress = Math.round((count / joblength) * 100);
      indicator += progress % 10 === 0 ? "=>" : "";

      console.log(indicator + progress + " % ");
    }

    console.log("completed job");

    running = false;
  };

  const practice = async () => {
    let products = [];

    products = await firebasefb
      .collection("review")
      .orderBy("attributes", "desc")
      .get()
      .then((res) => res.docs.map((r) => r.data()));

    let csv = JSON.stringify(products, (key, value) => {
      if (typeof value === "string") {
        if (value.includes("#")) return value.replaceAll("#", "");
      }
      return value;
    });

    let dn = document.createElement("a");
    dn.href = "data:text/plain;charset=utf-8," + encodeURI(csv);
    dn.target = "_blank";
    dn.download = "reviewsWithAttr.json";
    dn.click();
  };
  //371 2807
  return (
    <div id="buttons">
      <button className="bg-red-500 m-2" onClick={() => practice()}>
        practice this
      </button>
      <button
        className="bg-red-500 m-2"
        onClick={() => console.log("not working")}
      >
        Move Coin history
      </button>
    </div>
  );
}

export default Test;
