import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AWN from "awesome-notifications";

import Rating from "@material-ui/lab/Rating";
import { makeStyles } from "@material-ui/core/styles";

import BrandProducts from "./components/BrandProducts";
import BrandReviewers from "./components/BrandReviewers";
import BrandReviews from "./components/BrandReviews";
import UpdateCatAndSubCat from "./components/UpdateCatAndSubCat";
import BrandFlicks from "./components/BrandFlicks";
import DeleteProductSection from "./components/DeleteProductSection";
import urls from "./utils/urls";
import axios from "axios";

function ViewBrands() {
  const { brandid } = useParams();
  const [brand, setbrand] = useState({});

  let router = useHistory();

  const fetchUser = async () => {
    console.log("fetching from server all users");
    const response = await fetch(urls.admin + "getBrand/" + brandid, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });

    if (response.status === 200) {
      let res = await response.json();
      setbrand(res);
    }
  };

  useEffect(() => {
    fetchUser();
  }, [brandid]);

  const handletop = async () => {
    new AWN().async(
      axios(
        "https://userspecificapi-zscu3untuq-el.a.run.app/updateTopBrand/" +
          brandid,
        {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "asdfwp234-0835234-34535FHD568-a26463213234234-llksdfsdlfwSLDF",
          },
        }
      ),
      (response) => {
        if (response.status === 200) {
          let data = response.data;
          let message = "operation not succeded";
          console.log(data);
          if (data.success) {
            message = data.top
              ? "Added in Top Brand List"
              : "Removed from top brands";

            let topBrands = sessionStorage.getItem("topBrands");
            topBrands = JSON.parse(topBrands);
            if (topBrands && topBrands.data) {
              if (data.top) {
                topBrands.data = topBrands.data.push(brand);
              } else {
                topBrands.data = topBrands.data.filter((r) => r.id !== brandid);
              }

              sessionStorage.setItem("topBrands", JSON.stringify(topBrands));
            }
            new AWN().success(message);
            setbrand({ ...brand, top: data.top });
            return;
          }
          new AWN().warning(message);
          return;
        }
      },
      (err) => {
        console.log(err);
        new AWN().warning(err.toString());
        return;
      }
    );
  };
  const updateBrand = async (data) => {
    delete data.attributes;
    new AWN().async(
      axios(urls.admin + "updateBrand/" + brandid, {
        method: "PUT", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: urls.adminAuth,
        },
        data: JSON.stringify(data),
      }),
      async (response) => {
        console.log(response);
        if (response.status === 200) {
          let message = "operation not succeded";
          if (response.data.acknowledged) {
            new AWN().success("operation successful");
            setbrand({ ...brand, ...data });
            return;
          }
          new AWN().warning(message);
          return;
        }
      },
      (err) => {
        console.log(err);
        new AWN().warning(err.toString());
        return;
      }
    );
  };

  const publish = () => {
    new AWN().async(
      axios(urls.admin + "publishBrand/" + brandid, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: urls.adminAuth,
        },
      }),
      async (response) => {
        if (response.status === 200) {
          let data = response.data;
          console.log(data);
          if (data.success) {
            setbrand({ ...brand, status: data.status });
            return;
          }
          new AWN().warning(response.data.err);
          return;
        }
      },
      (err) => {
        console.log(err);
        new AWN().warning(err.toString());
        return;
      }
    );
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      "& > * + *": {
        marginTop: theme.spacing(1),
      },
    },
  }));

  console.log(brand);

  const inputfield =
    "appearance-none outline-none border-b text-nav_active_bg font-medium text-sm tracking-wide pr-10 w-full h-10";

  return (
    <div className="bg-panel_bg_secondary min-h-screen py-10 px-6 font-Manrope ">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-x-3">
          <button
            onClick={() => {
              sessionStorage.removeItem("revs" + brandid);
              sessionStorage.removeItem("flicks" + brandid);
              router.replace("/home/assets/brand");
            }}
            className=""
          >
            <span className="fas fa-arrow-left"></span>
          </button>
          <p className="mx-3 text-3xl">{brand?.name}</p>
        </div>
      </div>
      <div className="py-10 grid grid-cols-2 gap-5">
        <div className="row-span-3 space-y-10">
          <div className="bg-white">
            <div className="w-full border-b border-dashed bg-white">
              <div className="">
                <img
                  src={brand.coverImage ?? null}
                  alt=""
                  className="w-full h-56 object-cover object-left-top "
                />
              </div>
              <div className="p-5 flex items-center justify-between ">
                <div className="">
                  <div className="flex items-center gap-x-3">
                    <img
                      src={brand.imageUrl}
                      alt=""
                      className="h-14 w-14 rounded-full object-cover border"
                    />
                    <p className="font-semibold text-xl">{brand?.name}</p>
                  </div>
                </div>

                <div className="">
                  {brand?.rating && (
                    <div className={useStyles.root}>
                      <Rating
                        name="half-rating"
                        defaultValue={brand.rating}
                        precision={0.5}
                        readOnly
                      />
                    </div>
                  )}
                  <p className="text-right text-sm">
                    <span className="text-blue-500">#13</span>
                    <span className="text-black text-opacity-60">out of</span>
                    <span className="underline text-blue-500">200</span>
                  </p>
                </div>
              </div>
              <div className="flex justify-center pb-5">
                <button
                  className="border-r px-7 h-10 appearance-none outline-none bg-yellow-200 hover:bg-yellow-500 text-nav_active_bg"
                  onClick={handletop}
                >
                  {brand?.top ? "Remove From Top" : "Make Top brand"}
                </button>
                <button className="border-r px-7 h-10 appearance-none outline-none bg-tab_passive_bg hover:bg-gray-200 text-nav_active_bg">
                  Push Notification
                </button>
                <button
                  className={
                    brand?.status
                      ? "bg-green-500 px-5 text-white"
                      : "px-5 text-white bg-yellow-500"
                  }
                  onClick={publish}
                >
                  {brand?.status ? "Put on hold" : "Publish Now"}
                </button>
              </div>
            </div>
            <div className=" ">
              <div className="">
                <div className="border-b  px-5 flex justify-between items-center">
                  <p className="border-b border-nav_active_bg w-20 py-3">
                    &nbsp; &nbsp; Details
                  </p>
                </div>
                <div className="py-5 grid grid-flow-row grid-cols-2 px-5 gap-x-5">
                  <div className="py-2">
                    <p className="">Name</p>
                    <p className="">{brand?.name}</p>
                  </div>
                  <UpdateCatAndSubCat
                    subcategory={brand.subcategory}
                    updateFun={updateBrand}
                  />

                  <div className="py-2">
                    <p className="">Brand ID</p>
                    <div className="relative ">
                      <input
                        type="text"
                        className={inputfield}
                        placeholder="Brand ID"
                        defaultValue={brand?.id}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="py-2">
                    <p className="">Category</p>
                    <div className="relative ">
                      <input
                        type="text"
                        className={inputfield}
                        placeholder="Category"
                        defaultValue={brand?.catId}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="py-2">
                    <p className="">Description</p>
                    <textarea className="h-32 w-full text-sm">
                      {brand?.description}
                    </textarea>
                  </div>
                  <div className="py-2">
                    <p className="">Tags</p>
                    <div className="relative ">
                      <input
                        type="text"
                        className={inputfield}
                        placeholder="Tags"
                        defaultValue={brand?.tag}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="py-2">
                    <p className="">Added by</p>
                    <div className="relative ">
                      <input
                        type="text"
                        className={inputfield}
                        placeholder="Added by"
                        defaultValue={brand?.addedBy?.name}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="py-2">
                    <p className="">Status</p>
                    {brand.status ? "Publihshed" : "Pending"}
                  </div>
                  <div className="py-2">
                    <p className="">Subscribers</p>
                    <div className="relative ">
                      <input
                        type="text"
                        className={inputfield}
                        placeholder="Subscribers"
                        defaultValue={brand?.subscribes}
                        editMode
                      />
                    </div>
                  </div>
                  <div className="py-2">
                    <p className="">Uploaded by</p>

                    <input
                      type="text"
                      className={inputfield}
                      placeholder="Uploaded by"
                      defaultValue={brand?.addedBy?.id}
                      editMode
                    />
                  </div>

                  <div className="py-2">
                    <p className="">Onboarding date</p>
                    <p className="">
                      {new Date(brand?.dateAdded).toDateString()}
                    </p>
                  </div>
                  <div className="py-2">
                    <p className="">MyRevue hire</p>
                    <input
                      type="text"
                      className={inputfield}
                      placeholder="MyRevue hire"
                      defaultValue={brand?.followers}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" w-full space-y-10">
          <div className="bg-white py-10 grid grid-cols-3 gap-5 items-center justify-around">
            <div className="">
              <p className="text-xl text-center "> Total Reviews:</p>
              <p className="text-3xl text-center text-blue-700 font-medium">
                {brand?.reviews ?? "0"}
              </p>
            </div>
            <div className="">
              <p className="text-xl text-center ">Total Flicks:</p>
              <p className="text-3xl text-center text-blue-700 font-medium">
                {brand?.flicks ?? "0"}
              </p>
            </div>
            <div className="">
              <p className="text-xl text-center ">Total Subscibers: </p>
              <p className="text-3xl text-center text-blue-700 font-medium">
                {brand?.subscribes ?? "0"}
              </p>
            </div>
          </div>
        </div>
        {/* this area is grid */}
        <DeleteProductSection productId={brandid} type="brand" />
        <BrandProducts brandId={brandid} />
        <BrandReviewers brandId={brandid} />
        <BrandReviews brandId={brandid} />
        <BrandFlicks brandId={brandid} />
      </div>
    </div>
  );
}

export default ViewBrands;
