import React from "react";

function PopUp({children}) {
  return (
    <div className="h-screen w-full fixed top-0 bg-black z-10  left-0 bg-opacity-80 flex justify-center items-center">
      <div className=" min-width-half bg-white bg-opacity-100  p-3 text-left ">
        {children}
      </div>
    </div>
  );
}

export default PopUp;
