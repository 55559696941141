export const data = {
  redButton: " p-2 m-1 text-white bg-red-400 rounded shadow hover:bg-red-800",
  greenButton:
    " p-2 m-1 text-white bg-green-400 rounded  shadow hover:bg-green-800",
  yellowButton:
    " p-2 m-1 text-white bg-yellow-400 rounded shadow hover:bg-yellow-800",
  pinkButton:
    " p-2 m-1 text-white bg-pink-400 rounded shadow hover:bg-pink-800",
  purpleButton:
    " p-2 m-1 text-white bg-purple-500 rounded shadow hover:bg-purple-400",
  playButton: " p-2 m-1 text-white bg-blue-400 rounded shadow ",
  reviewStatusPending:
    " text-sm p-2 m-1 text-white bg-yellow-400 rounded shadow ",
  reviewStatusPublsihed:
    " text-sm p-2 m-1 text-white bg-green-400 rounded shadow ",
  textInput: "border p-2 h-12 appearance-none  focus:outline-none w-84",
  textInputStyle:
    "h-12 px-5 w-full text-black appearance-none outline-none border  focus:border-puple-600 border-purple-500 ",
  textInputStyleDark:
    "h-12 w-full px-5 bg-back text-sm tracking-wide appearance-none focus:outline-none outline-none border border-opacity-10 focus:border-opacity-50",
  updateInputfield:
    "h-14 px-5 w-full appearance-none outline-none border border-box bg-pink-100 focus:border-pink-700 m-1",
  activeTab:
    " px-3 py-2 flex-1 text-xl bg-indigo-300 border-b-1 border-indigo-800 cursor-pointer",
  passiveTab:
    "px-3 py-2 flex-1 text-xl text-gray-500 bg-gray-300 cursor-pointer",
};
