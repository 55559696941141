import React, { useRef, useState } from "react";
import Goback from "./utils/Goback";
import { data } from "./utils/Res";
import AWN from "awesome-notifications";
import { firebasefb, auth } from "./firebaseconfig";
import { nanoid } from "nanoid";

function CreateBrandAdmin() {
  const [loading, setloading] = useState(false);

  let messageref = useRef();
  let headref = useRef();

  const addnewnot = async () => {
    if (headref.current?.value?.length < 10)
      return new AWN().warning("add email");
    if (messageref.current.value.length < 5)
      return new AWN().warning("add brand id");

    setloading(true);

    var message = {
      email: headref.current.value,
      brandId: messageref.current.value?.trim(),
      pass: nanoid(12),
      role: "brandAdmin",
    };

    if (!message.brandId) {
      new AWN().warning("please enter brandId ");
    }

    const brand = await firebasefb
      .doc("brand/" + message.brandId)
      .get()
      .then((rs) => rs.data());

    message.name = brand.name;
    message.imageUrl = brand.imageUrl;

    console.log("everything is okay right here");
    auth
      .createUserWithEmailAndPassword(message.email, message.pass)
      .then((res) => {
        console.log("everything is okay right here 2");

        firebasefb
          .collection("brandAdmin")
          .doc(message.email)
          .set(message)
          .then((res) => {
            console.log("everything is okay right here 3");

            new AWN().success("success");
            setloading(false);
          })
          .catch((e) => {
            new AWN().alert(e);
            console.log(e);
            setloading(false);
          });
      })
      .catch((e) => {
        alert(e);
        setloading(false);
      });
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="bg-white h-24 mb-10">
        <h1 className="font-medium text-3xl flex flex-grow-0 pt-5 pl-8 ">
          <Goback />
          <span className="pt-2">&nbsp; Create Brand Admin</span>
        </h1>
      </div>
      <div className="">
        <div className="bg-gray-100 m-10 grid grid-cols-2">
          <div className="m-5">
            <p className="font-medium text-lg pb-2">Email</p>
            <input
              type="text"
              ref={headref}
              placeholder="email of the brand admin"
              className={data.textInputStyle}
            />
          </div>
          <div className="m-5">
            <p className="font-medium text-lg pb-2">brand ID</p>
            <input
              type="text"
              ref={messageref}
              placeholder="id of the brand"
              className={data.textInputStyle}
            />
          </div>
        </div>
      </div>
      <div className="text-right mx-10 px-5">
        <button
          className="w-48 h-14 bg-pink-500 hover:bg-pink-700 text-white font-medium tracking-wide"
          onClick={() => addnewnot()}
        >
          Create
        </button>
      </div>
      {loading ? (
        <div className="fixed top-0 w-full h-screen bg-black bg-opacity-40 flex justify-center items-center">
          <p className="text-white text-4xl">
            <i className="fas fa-spinner animate-spin  px-4"></i>
            Creating Brand Admin for you
          </p>
        </div>
      ) : null}
    </div>
  );
}

export default CreateBrandAdmin;
