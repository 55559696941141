import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./Login";
import "./awn.css";
import Home from "./Home";
import GuardedRoute from "./components/GuardedRoute";
import { useStateValue } from "./StateProvider";
import Test from "./Test";
import imageResizer from "./components/ImageResizer";
import { useEffect } from "react";
function App() {
  useEffect(() => {
    let token = localStorage.getItem("user");
    dispatch({ type: "SET_USER", user: token ? JSON.parse(token) : null });
  }, []);

  //prodImageurl
  const [{ user }, dispatch] = useStateValue();
  // console.log(user);
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/test" component={Test} />
        <Route path="/resizer" component={imageResizer} />
        {user?.role === "admin" && (
          <GuardedRoute auth={user} path="/home" component={Home} />
        )}

        <Route path="/" component={Login} />
      </Switch>
    </Router>
  );
}

export default App;
