import { firebasefb } from "../firebaseconfig";
import { useState, useEffect } from "react";
import { data } from "../utils/Res";
import firebase from "firebase";
import CustomDataGrid from "../utils/CustomDataGrid";

import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import AWN from "awesome-notifications";
import axios from "axios";

function WebReviews() {
  const router = useHistory();
  const [reviews, setreviews] = useState([]);
  const [loading, setloading] = useState(false);
  let { path, url } = useRouteMatch();
  const location = useLocation();
  const [last, setlast] = useState(null);
  const [lastpost, setlastpost] = useState(false);

  // console.log(path);
  const active =
    " font-medium bg-tab_active_bg text-tab_active_color  py-3 w-full text-center";
  const passive =
    " bg-tab_passive_bg text-tab_passive_color  py-3  w-full text-center border-r border-black border-opacity-5";

  useEffect(() => {
    setloading(true);
    firebasefb
      .collection("schedulerTasks")
      .get()
      .then((res) => {
        var a = [];
        res.docs.forEach((r) => {
          var temp = r.data();
          let rev = temp.review;
          rev.processed = temp.processed;
          rev.id = temp.id;
          a.push(rev);
        });
        setlast(res.docs[res.docs.length - 1]);
        setreviews(a);
        setloading(false);
      });
  }, []);

  const playReview = async (url) => {
    const AWN = (await import("awesome-notifications")).default;

    new AWN().modal(`<video width="320" height="240" controls>
        <source src=${url} type="video/mp4">
        
      Your browser does not support the video tag.
      </video>`);
    return;
  };

  const rejectReview = async (review) => {
    var user = await firebasefb
      .collection("user")
      .doc(review.uploadedBy.id)
      .get();
    if (!user) return alert("no user found something went wrong try again");
    if (!user.token) {
      alert(
        "user have no message token associated, so notification can be sent"
      );
    } else {
      const token = user.token;

      const message = {
        notification: {
          title: "uploaded review rejected",
          body: `review named ${review.name} rejected due to `,
        },
      };
      const params = JSON.stringify({ message: message, token: token });
      const headers = {
        "content-type": "application/json",
      };
      var response = await axios.post(
        "https://restapi-zscu3untuq-em.a.run.app/notification",
        params,
        { headers: headers }
      );
      console.log(response);
      if (response.status === 200 && response.data.success) {
        new AWN().success("notification sent");
      } else {
        new AWN().alert("something went wrong  try again");
        return;
      }
    }
    await firebasefb
      .collection("review")
      .doc(review.id)
      .update({ rejected: true })
      .then(() => {
        new AWN().success("review rejected");
      });

    await firebasefb
      .collection("userAcitivity")
      .doc(review.uploadedBy.id)
      .update({
        activity: firebase.firestore.FieldValue.arrayUnion({
          imageUrl: review.thumbnail,
          time: firebase.firestore.Timestamp.now(),
          to: review.id,
          by: "admin",
          type: "rejected",
        }),
      });
  };

  const loadmore = () => {
    if (!lastpost) {
      firebasefb
        .collection("review")
        .startAfter(last)
        .limit(20)
        .get()
        .then((res) => {
          var a = [];
          res.docs.forEach((doc) => {
            a.push(doc.data());
          });
          setreviews([...reviews, ...a]);
          var b = res.docs.pop();
          while (!b) {
            b = res.docs.pop();
          }
          setlast(b);
          a.length === 0 ? setlastpost(true) : setlastpost(false);
        });
    }
  };

  const columns = [
    { field: "name", headerName: "Title", width: 190 },
    {
      field: "name",
      headerName: "Uploaded By",
      width: 190,
      renderCell: (row) => row.uploadedBy.name,
    },
    {
      field: "status",
      headerName: "Status",
      width: 190,
      renderCell: (row) =>
        row.rejected ? (
          <p className=" text-red-500 font-bold">Rejected</p>
        ) : row.status ? (
          <p className=" text-green-500 font-bold">Published</p>
        ) : (
          <p className=" text-yellow-500 font-bold">Pending</p>
        ),
    },
    {
      field: "phone",
      headerName: "Join Date",
      renderCell: (row) => {
        return <div className="">{row.dateAdded.toDate().toDateString()}</div>;
      },
    },

    // { field: "description", headerName: "Description", width: 250 },

    { field: "views", headerName: "Views", width: 100 },
    { field: "parentName", headerName: "Parent", width: 200 },
  ];

  const actionNode = (row, close) => {
    return (
      <div className=" bg-white shadow-sm z-50 w-48 absolute border right-24 rounded-md -mt-8">
        <button
          onClick={() => {
            if (close) close(null);
          }}
          className="h-5 w-5  bg-white shadow-sm rounded-full border flex items-center justify-center right-0 absolute -m-2 cursor-pointer"
        >
          <i className="fas fa-close"></i>
        </button>
        <div className="w-full">
          <div
            className="h-10 w-full font-medium flex items-center px-4 bg-white hover:bg-purple-100 cursor-pointer rounded select-none"
            onClick={() => router.push(`/home/reviews/viewWebReview/${row.id}`)}
          >
            View
          </div>
          <div
            className="h-10 w-full font-medium flex items-center px-4 bg-white hover:bg-purple-100 cursor-pointer rounded"
            onClick={() => {
              navigator.clipboard.writeText("app.myrevue.com/" + row.id);
              new AWN().info("copied to clipboard");
            }}
          >
            Copy Link
          </div>
          <div
            className="h-10 w-full font-medium flex items-center px-4 bg-white hover:bg-purple-100 cursor-pointer rounded text-green-500"
            onClick={() => router.push(`/home/reviews/Viewreview/${row.id}`)}
          >
            Update
          </div>
          <div
            className="h-10 w-full font-medium flex items-center px-4 bg-white hover:bg-purple-100 cursor-pointer rounded text-blue-500"
            onClick={() => playReview(row.reviewUrl)}
          >
            Play Media
          </div>
          <div
            className="h-10 w-full font-medium flex items-center px-4 bg-white hover:bg-purple-100 cursor-pointer rounded text-red-500"
            onClick={() => rejectReview(row)}
          >
            Reject
          </div>
          <div
            className="h-10 w-full font-medium flex items-center px-4 bg-white hover:bg-purple-100 cursor-pointer rounded text-red-500"
            onClick={() => {
              if (close) close(null);
            }}
          >
            Close
          </div>
        </div>
      </div>
    );
  };

  return loading ? (
    <div className="h-screen flex items-center justify-center">
      <span className="fas fa-spinner animate-spin"></span>
    </div>
  ) : (
    <div className=" min-h-screen bg-panel_bg w-full font-Manrope">
      <div className="flex justify-between items-center">
        <p className="text-3xl font-medium">Total Reviews:</p>
        <p className="text-3xl font-semibold ">{reviews.length ?? 0}</p>
      </div>

      <div className="">
        <div className="py-4 flex justify-evenly ">
          <Link
            to={`${url}/allReviews`}
            className={
              location.pathname.includes("allReviews") ||
              location.pathname === url
                ? active
                : passive
            }
          >
            All Reviews
          </Link>
          <Link
            to={`${url}/pending`}
            className={location.pathname.includes("pending") ? active : passive}
          >
            Pending
          </Link>
          <Link
            to={`${url}/published`}
            className={
              location.pathname.includes("published") ? active : passive
            }
          >
            Published
          </Link>
          <Link
            to={`${url}/rejected`}
            className={
              location.pathname.includes("rejected") ? active : passive
            }
          >
            Rejected
          </Link>
        </div>
      </div>
      <div className="pb-20 w-full">
        <div className="w-full  bg-white border shadow-lg rounded-md overflow-x-auto">
          <Switch>
            <Route exact path={path}>
              <CustomDataGrid
                rows={reviews}
                columns={columns}
                actionNode={actionNode}
                loadMore={loadmore}
              />
            </Route>

            <Route path={`${path}/allReviews`}>
              <CustomDataGrid
                rows={reviews}
                columns={columns}
                actionNode={actionNode}
                loadMore={loadmore}
              />
            </Route>
            <Route path={`${path}/pending`}>
              <CustomDataGrid
                rows={reviews.filter((a) => !a?.status)}
                columns={columns}
                actionNode={actionNode}
              />
            </Route>
            <Route path={`${path}/published`}>
              <CustomDataGrid
                rows={reviews.filter((a) => a?.status)}
                columns={columns}
                actionNode={actionNode}
              />
            </Route>
            <Route path={`${path}/rejected`}>
              <CustomDataGrid
                rows={reviews.filter((a) => a?.rejected)}
                columns={columns}
                actionNode={actionNode}
              />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default WebReviews;
