import AWN from "awesome-notifications";
import axios from "axios";
import React from "react";
import urls from "../utils/urls";
import { useHistory } from "react-router-dom";
import { useState } from "react";

function DeleteProductSection({ productId, type = "product" }) {
  const [show, setShow] = useState(false);

  const router = useHistory();

  const deleteProduct = async () => {
    if (type === "brand")
      new AWN().confirm(
        "Are you sure want to delete this product.",
        async () => {
          new AWN().async(
            axios(urls.admin + "deleteBrand/" + productId, {
              method: "GET", // *GET, POST, PUT, DELETE, etc.
              headers: {
                "Content-Type": "application/json",
                Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
              },
            }),
            (response) => {
              if (response.status === 200) {
                if (response.data.success) {
                  new AWN().success("Brand Deleted");
                  let prods = sessionStorage.getItem("brands");
                  if (prods) {
                    prods = JSON.parse(prods);
                    prods = prods.filter((r) => r.id !== productId);
                    sessionStorage.setItem("brands", JSON.stringify(prods));
                  }
                  router.goBack();
                } else {
                  new AWN().warning(response.data.message);
                }
              }
            },
            (error) => {
              new AWN().warning(error.message);
            }
          );
        },
        () => {
          new AWN().info("Deletion cancelled");
        }
      );
    if (type === "product")
      new AWN().confirm(
        "Are you sure want to delete this product.",
        async () => {
          new AWN().async(
            axios(urls.admin + "deleteProduct/" + productId, {
              method: "GET", // *GET, POST, PUT, DELETE, etc.
              headers: {
                "Content-Type": "application/json",
                Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
              },
            }),
            (response) => {
              if (response.status === 200) {
                if (response.data.success) {
                  new AWN().success("Product Deleted");
                  let prods = sessionStorage.getItem("products");
                  if (prods) {
                    prods = JSON.parse(prods);
                    prods = prods.filter((r) => r.id !== productId);
                    sessionStorage.setItem("products", JSON.stringify(prods));
                  }
                  router.goBack();
                } else {
                  new AWN().warning(response.data.message);
                }
              }
            },
            (error) => {
              new AWN().warning(error.message);
            }
          );
        },
        () => {
          new AWN().info("Deletion cancelled");
        }
      );
  };

  if (show)
    return (
      <div className="flex flex-col bg-red-100 border rounded m-1 p-3">
        <div className="flex justify-between">
          <p className="flex-1 ">Delete this product</p>
          <button
            className="bg-red-500 p-2 text-white rounded shadow-md"
            onClick={deleteProduct}
          >
            Delete This {type}
          </button>
        </div>
      </div>
    );
  else
    return (
      <div className="p-3 bg-red-100 border border-red-500 flex items-center justify-center">
        <button
          className="bg-red-500 text-white p-2 rounded-sm"
          onClick={() => setShow(true)}
        >
          Reveal Danger Section
        </button>
      </div>
    );
}

export default DeleteProductSection;
