import { data } from "./Res";
import { firebasefb } from "../firebaseconfig";
import firebase from "firebase";
import AWN from "awesome-notifications";
import { useHistory } from "react-router-dom";
import { useStateValue } from "../StateProvider";
import moment from "moment";

function ReviewItem({ review, index }) {
    const [{ user }] = useStateValue();
    const router = useHistory();

    const playReview = async () => {
        new AWN().modal(`<video width="320" height="240" controls>
        <source src=${review.reviewUrl} type="video/mp4">
        
      Your browser does not support the video tag.
      </video>`);
        return;
    };

    const deleteItem = (review) => {
        new AWN().confirm(
            `are you sure wanna delete this "${review.name}"`,
            () => {
                firebasefb
                    .collection("deleteItem")
                    .add({
                        type: "video",
                        ref: review.reviewUrl,
                        id: review.id,
                    })
                    .then(() => {
                        firebasefb
                            .collection("user")
                            .doc(review.uploadedBy.id)
                            .update({
                                reviews:
                                    firebase.firestore.FieldValue.increment(-1),
                            });
                        firebasefb
                            .collection("review")
                            .doc(review.id)
                            .delete()
                            .then((e) => {
                                new AWN().success("deleted successfully");
                            })
                            .catch((e) => {
                                new AWN().error("something gone wrong.");
                            });
                    })
                    .catch((e) => {
                        new AWN().error("something gone wrong");
                    });
            },
            () => {
                new AWN().info("you pressed on cancel");
            }
        );
    };

    return (
        <tr className="border-b border-white border-opacity-20">
            <td className="pl-16">{index + 1}</td>
            <td className="pl-10">{review.name}</td>
            <td className="text-center">{Math.round(review.rating * 2) / 2}</td>
            <td className="text-center">{review?.status === true ? "Published" : "Pending"}</td>
            <td className="text-center">{moment(review?.dateAdded?.toDate().toDateString()).format('DD/MM/YYYY')}</td>
            <td className="text-center">{review.views}</td>

            <td className="">
                {user.role === "admin" ? (
                    <div className="">
                        <button
                            onClick={() =>
                                router.push(
                                    `/Viewreview/${review.id}`
                                )
                            }
                            className={data.greenButton}
                        >
                            View
                        </button>
                        <button
                            className={data.playButton}
                            onClick={() => playReview()}
                        >
                            Play
                        </button>

                        <button
                            onClick={() =>
                                router.push(`/home/updateReview/${review.id}`)
                            }
                            className={data.yellowButton}
                        >
                            update
                        </button>
                        <button
                            className={data.redButton}
                            onClick={() => deleteItem(review)}
                        >
                            Delete
                        </button>
                    </div>
                ) : (
                    <div>
                        <button
                            onClick={() =>
                                router.push(`/brand/Viewreview/${review.id}`)
                            }
                            className={data.greenButton}
                        >
                            View
                        </button>
                        <button
                            className={data.playButton}
                            onClick={() => playReview()}
                        >
                            Play
                        </button>
                    </div>
                )}
            </td>
        </tr>
    );
}

export default ReviewItem;
