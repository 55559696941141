import AWN from "awesome-notifications";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { firebasefb } from "../firebaseconfig";
import CustomRating from "../helpers/Rating";

function UpdateCatAndSubCat({ subcategory, updateFun }) {
  const [editMode, setEditMode] = useState(false);
  const [category, setcategory] = useState(null);
  const [selected, setselected] = useState(null);
  const [subcat, setsubcat] = useState(null);

  const checkFun = () => {
    if (!selected.id) {
      new AWN().warning("no category selected");
      return;
    }
    if (!subcat) {
      new AWN().warning("no subcategory selected");
      return;
    }

    let attr = selected?.images.find((r) => r.name === subcat);
    if (!attr) {
      new AWN().warning("please selected subcategory found");
      return;
    }
    attr = attr.attributes;
    console.log(attr);

    updateFun({ catId: selected.id, subcategory: subcat, attributes: attr });
    setEditMode(false);
  };

  const loadSub = async () => {
    if (!category) {
      firebasefb
        .collection("category")
        .get()
        .then((res) => {
          var a = [];
          res.docs.forEach((e) => {
            a.push(e.data());
          });
          setcategory(a);
          setEditMode(true);
        });
    }
    setEditMode(true);
  };

  return (
    <div className="py-2">
      <div className="flex">
        <p className="">subcategory</p>
        {editMode ? (
          <div className="">
            <i
              onClick={() => setEditMode(false)}
              className="cursor-pointer mx-3 fas fa fa-close text-red-500 right-1 pt-1"
            ></i>
            <i
              onClick={checkFun}
              className="cursor-pointer mx-3 fas fa fa-check text-green-500 right-1 pt-1"
            ></i>
          </div>
        ) : (
          <i
            onClick={loadSub}
            className="cursor-pointer mx-3 fas fa fa-pencil right-1 pt-1"
          ></i>
        )}
      </div>
      {editMode ? (
        <div className="flex flex-col h-36 border rounded">
          <p className="text-xs">select category*</p>
          <select
            className="h-12 m-2"
            onChange={(e) =>
              setselected(category.find((r) => r.id === e.target.value))
            }
            name="category"
            id=""
          >
            <option value="">---select---</option>
            {category?.map((cat) => (
              <option value={cat.id} key={cat.id}>
                {cat.name}
              </option>
            ))}
          </select>
          <p className="text-xs">select category*</p>
          <select
            className="h-12 m-2"
            onChange={(e) => setsubcat(e.target.value)}
            name="category"
            id=""
          >
            <option value="">---select---</option>
            {selected?.images?.map((sub) => (
              <option value={sub.name} key={sub.name}>
                {sub.name}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <p className="text-purple-500 text-sm">
          {subcategory ?? "Not present"}
        </p>
      )}
    </div>
  );
}

export default UpdateCatAndSubCat;
