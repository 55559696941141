import React, { useState } from "react";
import { firebasefb } from "./firebaseconfig";
import { data } from "./utils/Res";

const Testing = () => {
  // const update = async () => {
  //   console.log("updation started");
  //   const allData = await firebasefb
  //     .collection("brand")
  //     .get()
  //     .then((res) =>
  //       res.docs.map((da) => ({ id: da.id, timestamp: da.joinDate }))
  //     )
  //     .catch((e) => []);

  //   console.log(allData.length, "this is all i can get from database");
  // };

  const [temp, settemp] = useState([]);

  const countCollec = async () => {
    const count = await firebasefb
      .collection("review")
      .where("type", "==", "experience")
      .get()
      .then((res) => res.docs.map((e) => e.data()));

    console.log(count.length + " this is length");

    let a = [];
    for (let review of count) {
      let exist = a.filter((e) => e.user === review.uploadedBy.id);
      if (exist.length !== 0) {
        let index = a.findIndex((e) => e.user === review.uploadedBy.id);
        a[index].exp.push(review);
      } else {
        a.push({
          user: review.uploadedBy.id,
          exp: [review],
        });
      }
      console.log("added " + review.id);
    }
    settemp(a);
    console.log(a);
  };

  const countExpCat = async () => {
    console.log(temp);
    let a = temp;
    a.forEach((user) => {
      let expcat = new Set();
      for (let exp of user.exp) {
        expcat.add(exp.catId);
      }
      let index = a.findIndex((e) => e.user === user.user);
      a[index].expCat = expcat;
    });

    console.log(a);
    settemp(a);
  };

  let leftover = [];

  const uploadExpCat = async () => {
    console.log("starting execution");
    let cats = await firebasefb
      .collection("category")
      .get()
      .then((res) => res.docs.map((r) => r.data()));

    let products = await firebasefb
      .collection("product")
      .get()
      .then((res) => res.docs.map((e) => e.data()));
    console.log(products.length);

    products = products.filter((pr) => !pr.attributes);

    console.log(products.length, products[0]);

    return;
    let count = 1;
    for (let prs of products) {
      let attr = cats.filter((c) => c.id === prs.catId);
      if (attr.length === 0) {
        console.log("category not found");
        return;
      }
      attr = attr[0];

      attr = attr.images.filter((im) => im.name === prs.subcategory);
      if (attr.length === 0) {
        console.log("subcategory not found", prs.subcategory, prs.id);
        continue;
      }
      attr = attr[0];

      attr = attr.attributes;

      if (!attr) {
        console.log("attributes not found", prs.subcategory, prs.id);
        continue;
      }
      console.log(count);
      count += 1;

      firebasefb
        .doc("product/" + prs.id)
        .update({
          attributes: attr,
        })
        .then(() => {
          console.log("updated with -> ", attr, "this is id -> " + prs.id);
        })
        .catch((e) => {
          console.log(e);
          leftover.push(prs.id);
        });
    }
  };
  return (
    <div>
      <button onClick={() => uploadExpCat()} className={data.greenButton}>
        Update Brand
      </button>
    </div>
  );
};

export default Testing;
