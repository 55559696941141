import React from "react";
import { useRef } from "react";
import { data } from "./Res";

function ArrayInputer({ arr, placeholder, setarr }) {
    const textform = useRef();

    const updatearr = (e) => {
        e.preventDefault();
        const text = textform.current.value;
        if (text.length < 2) return;
        if (arr.includes(text)) {
            return alert("already exist");
        } else {
            setarr([...arr, text]);
            textform.current.value = "";
        }
    };
    return (
        <div className="">
            <div className="text-black pt-2">
                <form onSubmit={(e) => updatearr(e)}>
                    <div className="w-full flex flex-grow-0">
                        <input
                            type="text"
                            ref={textform}
                            placeholder="Enter Sub-Category Name"
                            className="bg-box h-14 w-4/6 pl-4 focus:outline-none "
                        />
                        <button
                            type="submit"
                            className="w-2/6 bg-pink-500 hover:bg-pink-700 h-14 text-white text-center  font-medium tracking-wide"
                        >
                            Add
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ArrayInputer;
