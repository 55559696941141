import { DataGrid } from "@material-ui/data-grid";
import AWN from "awesome-notifications";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { firebasefb } from "./firebaseconfig";
import { data } from "./utils/Res";
function Coupan() {
  const [brands, setbrands] = useState([]);
  const history = useHistory();
  const [loading, setloading] = useState(true);
  const [searchResult, setsearchResult] = useState([]);
  const [query, setquery] = useState("");

  useEffect(() => {
    firebasefb
      .collection("rewardCoupan")
      .get()
      .then((res) => {
        var a = [];
        res.docs.forEach((d) => {
          a.push({ ...d.data(), cid: d.id });
        });
        setbrands(a);
        setloading(false);
      });
  }, []);

  const deleteNow = async (row) => {
    let notifier = new AWN();
    let onOk = () => {
      firebasefb
        .collection("rewardCoupan")
        .doc(row.id)
        .delete()
        .then((res) => {
          firebasefb.collection("bin").add(row);
          new AWN().success("successfully Deleted");
          return;
        })
        .catch((e) => {
          new AWN().error("something went wrong");
        });
    };
    let onCancel = () => {
      notifier.info("You pressed Cancel");
    };
    notifier.confirm("Are you sure?", onOk, onCancel, {
      labels: {
        confirm: "Dangerous action",
      },
    });
  };

  const columns = [
    { field: "title", headerName: "name", width: 190 },
    { field: "id", headerName: "coupon Id", width: 180 },

    {
      field: "actions",
      headerName: "Actions",
      width: 320,
      // eslint-disable-next-line react/display-name
      renderCell: ({ row }) => (
        <div>
          <button
            className={data.greenButton}
            onClick={() => history.push(`/home/viewcoupan/${row.id}`)}
          >
            View
          </button>
          <button
            className={data.redButton}
            onClick={() => history.push(`/home/updatecoupan/${row.cid}`)}
          >
            Update
          </button>
          <button className={data.redButton} onClick={() => deleteNow(row)}>
            Delete
          </button>
        </div>
      ),
    },
  ];

  if (loading)
    return (
      <div className="h-screen flex items-center justify-center">
        <span className="fas fa-spinner animate-spin fa-3x"></span>
      </div>
    );
  else
    return (
      <div>
        <div className=" bg-white flex  justify-between items-end m-10 h-auto  shadow-lg">
          <div className="">
            <p className="text-3xl font-medium p-4">Total Coupons</p>
          </div>
          <div className="pr-4">
            <p className="text-md font-Regular text-sidetext text-right ">
              Coupons
            </p>

            <p className="text-3xl font-medium flex flex-grow-0 text-right">
              {brands.length}
            </p>
          </div>
        </div>

        <div className="bg-white m-10">
          <div className="flex h-14 mt-8 items-center flex-wrap">
            <div className="flex-grow flex items-center">
              <i className="fas fa-search p-2 text-gray-700 mt-2"></i>
              <input
                className="flex-grow h-7 outline-none text-gray-700"
                type="text"
                placeholder="Search by Name, coupon, Brands"
                onChange={(e) => {
                  setquery(e.target.value);
                  setsearchResult(
                    brands.filter((row) => row.name.includes(e.target.value))
                  );
                }}
              />
            </div>
            <div className="duration-200">
              <button
                onClick={() => {
                  history.push("/home/addNewcoupan");
                }}
                className=" bg-green-500 hover:bg-green-700 w-64 h-14  focus:outline-none text-white"
              >
                <span className="text-center">
                  <i class="fas fa-plus "></i> Create New coupon
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className=" bg-white m-10 rounded">
          {query.length === 0 ? (
            <DataGrid
              autoHeight={true}
              rows={brands}
              columns={columns}
              pageSize={20}
            />
          ) : (
            <DataGrid
              autoHeight={true}
              rows={searchResult}
              columns={columns}
              pageSize={20}
            />
          )}
        </div>
        <div className="h-2"></div>
      </div>
    );
}

export default Coupan;
