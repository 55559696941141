import React from "react";
import { useState } from "react";
import FlicksList from "./FlicksList";
import urls from "../utils/urls";
import { useEffect } from "react";
function BrandFlicks({ brandId }) {
  useEffect(() => {
    let flicks = sessionStorage.getItem("flicks" + brandId);
    if (flicks) {
      flicks = JSON.parse(flicks);
      setStats(flicks);
    }
  }, []);

  const fetchStats = async () => {
    setloading(true);
    console.log("fetching from server all users");
    const response = await fetch(urls.admin + "getBrandFlicks/" + brandId, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });

    if (response.status === 200) {
      let res = await response.json();
      setnoData(res.length === 0);
      setStats(res);
      if (res.length !== 0)
        sessionStorage.setItem("flicks" + brandId, JSON.stringify(res));
      setloading(false);
    }
  };

  const [nodata, setnoData] = useState(false);
  const [loading, setloading] = useState(false);
  const [stats, setStats] = useState([]);

  if (loading) {
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <i className="animate-pulse">loading...</i>
      </div>
    );
  } else if (stats.length !== 0) {
    return (
      <div className="bg-white rounded-md shadow-md p-2">
        <p className="my-3 text-2xl">{stats.length} Flicks</p>
        <FlicksList stats={stats} />
      </div>
    );
  } else if (nodata) {
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <i className="animate-pulse">No Brand Flicks Found</i>
      </div>
    );
  } else
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <button
          className="border p-2 shadow-md border-blue-500"
          onClick={fetchStats}
        >
          Reveal Brand Flicks Found
        </button>
      </div>
    );
}

export default BrandFlicks;
