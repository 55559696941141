import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { firebasefb } from "./firebaseconfig";
import Goback from "./utils/Goback";

function ViewRequest() {
  const { requestid } = useParams();
  const [request, setrequest] = useState(null);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    firebasefb
      .collection("requests")
      .doc(requestid)
      .get()
      .then((res) => {
        setrequest(res.data());
        setloading(false);
      });
  }, []);

  if (loading)
    return (
      <div className="h-screen flex justify-center items-center">
        <span className="fas fa-spinner animate-spin"></span>
      </div>
    );
  else
    return (
      <div className="bg-background min-h-screen">
        <div className="bg-white h-24">
          <h1 className="font-medium text-3xl flex flex-grow-0 pt-5 pl-8 ">
            <Goback />
            <span className="pt-2"> &nbsp; View request</span>
          </h1>
        </div>
        <div className="bg-white m-20 ">
          <div className="p-10 pb-2">
            <p className="font-medium text-lg pb-2">Fulfilled</p>
            <select
              className="h-14 px-5 w-full bg-box appearance-none outline-none border border-box focus:border-purple-700"
              name="fulfilled"
              id=""
            >
              <option value="default">--Select--</option>
              <option value="No">No</option>
              <option value="yes">Yes</option>
            </select>
          </div>
          <div className="pl-10 pr-10 pb-2">
            <p className="font-medium text-lg pb-2">Type</p>
            <input
              type="text"
              disabled
              defaultValue={request.type}
              className="h-14 px-5 w-full bg-box appearance-none outline-none border border-box focus:border-purple-700"
            />
          </div>
          <div className="pl-10 pr-10 pb-2">
            <p className="font-medium text-lg pb-2">Name</p>
            <input
              type="text"
              disabled
              defaultValue={request.name}
              className="h-14 px-5 w-full bg-box appearance-none outline-none border border-box focus:border-purple-700"
            />
          </div>
          <div className="pl-10 pr-10 pb-2">
            <p className="font-medium text-lg pb-2">Category</p>
            <input
              type="text"
              disabled
              defaultValue={request.category}
              className="h-14 px-5 w-full bg-box appearance-none outline-none border border-box focus:border-purple-700"
            />
          </div>
          <div className="pl-10 pr-10 pb-10">
            <p className="font-medium text-lg pb-2">Description</p>
            <input
              type="text"
              disabled
              defaultValue={request.desc}
              className="h-auto p-4 w-full bg-box appearance-none outline-none border border-box focus:border-purple-700"
            />
          </div>
        </div>
        <div className="h-2"></div>
      </div>
    );
}

export default ViewRequest;
