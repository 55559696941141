import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import Category from "../Category";
import { firebasefb } from "../firebaseconfig";

function AddhashTags() {
  const [categories, setcategory] = useState([]);
  const [selectCat, setCat] = useState(null);
  const [selectSubcat, setselectSubcat] = useState(null);

  let tagref = useRef();

  useEffect(() => {
    if (categories.length !== 0) return;
    firebasefb
      .collection("category")
      .get()
      .then((cats) => {
        let a = cats.docs.map((cat) => cat.data());
        setcategory(a);
      });
  }, []);

  console.log(selectCat);
  console.log(selectSubcat);

  return (
    <div className="py-3 px-1">
      <p className="text-3xl"> Add New hashTag</p>
      <div className="flex  flex-col p-2 gap-5">
        <div className="">
          <p className="text-xs">Category</p>
          <select
            name=""
            id=""
            onChange={(e) => setCat(e.target.value)}
            className="w-full  h-12 "
          >
            <option value="default">default</option>
            {categories.map((cat) => (
              <option key={cat.id} value={cat.id}>
                {cat.name}
              </option>
            ))}
          </select>
        </div>
        <div className="">
          <p className="text-xs">Sub category</p>
          <select
            name=""
            id=""
            className="w-full  h-12 "
            onChange={(e) => setselectSubcat(e.target.value)}
          >
            <option value="default">default</option>

            {categories
              .find((r) => r.id === selectCat)
              ?.images?.map((cat) => (
                <option key={cat.name} value={cat.name}>
                  {cat.name}
                </option>
              ))}
          </select>
        </div>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            let tag = tagref.current.value;
            if (tag.length <= 3) return;
            await firebasefb.collection("hashtags").doc(tag).set({
              category: selectCat,
              subcategory: selectSubcat,
              hash: tag,
              dateAdded: Date.now(),
              addedBy: "admin",
            });
            tagref.current.value = "";
          }}
        >
          <input
            type="text"
            className="w-full  h-12 px-2"
            placeholder="write hashtag here without #"
            ref={tagref}
          />
        </form>
      </div>
    </div>
  );
}

export default AddhashTags;
