import React, { useState } from "react";
import urls from "../utils/urls";
function UserBrandReviewed({ userid }) {
  const fetchStats = async () => {
    setloading(true);
    console.log("fetching from server all users");
    const response = await fetch(
      urls.admin + "getBrandReviewedByUser/" + userid,
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }
    );

    if (response.status === 200) {
      let res = await response.json();
      setnoData(res.length === 0);
      setStats(res);
      setloading(false);
    }
  };

  const [nodata, setnoData] = useState(false);
  const [loading, setloading] = useState(false);
  const [stats, setStats] = useState([]);

  if (loading) {
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <i className="animate-pulse">loading...</i>
      </div>
    );
  } else if (stats.length !== 0) {
    return (
      <div className="bg-purple-600 text-white rounded-md shadow-md p-2 col-span-2 ">
        <p className="my-3 text-2xl">Brand Reviewed</p>
        <div className="h-96 overflow-y-scroll">
          <table className="table-fixed">
            <thead>
              <tr>
                <th>Sl No.</th>
                <th>image</th>
                <th>Name</th>
                <th>Reviews</th>
                <th>Views</th>
                <th>Rating (Avg)</th>
              </tr>
            </thead>
            <tbody>
              {stats.map((stat, index) => (
                <tr key={stat.id} className="h-14">
                  <td>{index + 1}.</td>
                  <td>
                    <img
                      src={stat.imageUrl}
                      className="h-12 w-12  rounded-full"
                      alt=""
                    />
                  </td>
                  <td className="pl-3">{stat.name}</td>
                  <td className="pl-3">{stat.reviews}</td>
                  <td className="pl-3">{stat.views}</td>
                  <td className="pl-3">{stat.rating.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  } else if (nodata) {
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <i className="animate-pulse">No Brand Reviewed Found</i>
      </div>
    );
  } else
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <button
          className="border p-2 shadow-md border-blue-500"
          onClick={fetchStats}
        >
          Reveal Brand Reviewed
        </button>
      </div>
    );
}

export default UserBrandReviewed;
