import axios from "axios";
import React, { useState } from "react";
import urls from "../utils/urls";

function ProductReviewers({ productId }) {
  const fetchStats = async () => {
    setloading(true);
    console.log("fetching from server all users");
    const response = await axios(
      urls.admin + "getProductReviewers/" + productId,
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
        },
      }
    );
    console.log(response.data);
    if (response.status === 200) {
      let res = response.data;
      setnoData(res.length === 0);
      setStats(res);
      setloading(false);
    }
  };

  const [nodata, setnoData] = useState(false);
  const [loading, setloading] = useState(false);
  const [stats, setStats] = useState([]);

  if (loading) {
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <i className="animate-pulse">loading...</i>
      </div>
    );
  } else if (stats.length !== 0) {
    return (
      <div className="bg-white  rounded-md shadow-md p-2">
        <p className="my-3 text-2xl">{stats.length} product Reviewers</p>
        <div className="h-96 overflow-y-scroll">
          <table className="table-fixed">
            <tbody>
              {stats.map((stat) => (
                <tr key={stat.id + "sdfsdf"} className="h-14">
                  <td className="">
                    <img
                      src={stat.imageUrl}
                      className="h-12 w-12  rounded-full"
                      alt=""
                    />
                  </td>
                  <td className="pl-3">{stat.name}</td>
                  <td>{stat.reviews} Reviews</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  } else if (nodata) {
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <i className="animate-pulse">No product Reviewers found</i>
      </div>
    );
  } else
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <button
          className="border p-2 shadow-md border-blue-500"
          onClick={fetchStats}
        >
          Reveal product Reviewers
        </button>
      </div>
    );
}

export default ProductReviewers;
