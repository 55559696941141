import React from "react";
import { useState } from "react";
import urls from "../utils/urls";
function UserBrandInterested({ userid }) {
  const fetchStats = async () => {
    setloading(true);
    console.log("fetching from server all users");
    const response = await fetch(urls.admin + "getSubscribedBrand/" + userid, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: urls.adminAuth,
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });

    if (response.status === 200) {
      let res = await response.json();
      setnoData(res.length === 0);
      setStats(res);
      setloading(false);
    }
  };

  const [nodata, setnoData] = useState(false);
  const [loading, setloading] = useState(false);
  const [stats, setStats] = useState([]);

  if (loading) {
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <i className="animate-pulse">loading...</i>
      </div>
    );
  } else if (stats.length !== 0) {
    return (
      <div className="bg-purple-600 text-white rounded-md shadow-md p-2 col-span-2">
        <p className="my-3 text-2xl">Brand Subscribed</p>
        <div className="h-96 overflow-y-scroll">
          <table className="table-fixed">
            <tbody>
              {stats.map((stat) => (
                <tr key={stat.id} className="h-14">
                  <td>
                    <img
                      src={stat.imageUrl}
                      className="h-12 w-12  rounded-full"
                      alt=""
                    />
                  </td>
                  <td className="pl-3">{stat.name}</td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  } else if (nodata) {
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <i className="animate-pulse">No Brand Interested Stat Found</i>
      </div>
    );
  } else
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <button
          className="border p-2 shadow-md border-blue-500"
          onClick={fetchStats}
        >
          Reveal Brand Subscribed
        </button>
      </div>
    );
}

export default UserBrandInterested;
