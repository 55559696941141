import AWN from "awesome-notifications";
import axios from "axios";
import { nanoid } from "nanoid";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { storage } from "../firebaseconfig";

function EditAppAds() {
  const [ads, setAds] = useState([]);
  const [newAds, setnewAds] = useState(null);
  const [progress, setProgress] = useState(0);

  const fetchAppAds = async () => {
    let chart = sessionStorage.getItem("appAds");
    if (chart) {
      chart = JSON.parse(chart);
      setAds(chart);
      return;
    }
    console.log("fetching from server top users");
    const response = await axios(
      "https://userspecificapi-zscu3untuq-el.a.run.app/getAppAds",
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "asdfwp234-0835234-34535FHD568-a26463213234234-llksdfsdlfwSLDF",
        },
      }
    );

    if (response.status === 200) {
      let res = response.data;
      sessionStorage.setItem("appAds", JSON.stringify(res.data));
      setAds(res.data);
    }
  };

  const addNewAdsNow = async () => {
    if (!newAds.thumbnail) {
      new AWN().warning("no thumbnail selected");
      return;
    }
    if (!newAds.videoUrl) {
      new AWN().warning("no video selected");
      return;
    }
    const response = await axios(
      "https://userspecificapi-zscu3untuq-el.a.run.app/addAppAds",
      {
        method: "PUT", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "asdfwp234-0835234-34535FHD568-a26463213234234-llksdfsdlfwSLDF",
        },
        data: JSON.stringify({ ...newAds, status: true }),
      }
    );

    if (response.status === 200) {
      if (response.data.success) {
        let res = response.data;
        let ad = { ...newAds, _id: res.data.insertedId };
        setAds([...ads, ad]);
        sessionStorage.setItem("appAds", JSON.stringify([...ads, ad]));
        new AWN().success("succesFully added ads");
        setnewAds(null);
      } else {
        new AWN().warning(response.data.message);
      }
    }
  };
  const deleteAd = async (id) => {
    const response = await axios(
      "https://userspecificapi-zscu3untuq-el.a.run.app/deleteAppAd/" + id,
      {
        method: "DELETE", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "asdfwp234-0835234-34535FHD568-a26463213234234-llksdfsdlfwSLDF",
        },
      }
    );

    if (response.status === 200) {
      if (response.data.success) {
        let newsetofAds = ads.filter((r) => r._id !== id);
        setAds(newsetofAds);
        sessionStorage.setItem("appAds", JSON.stringify(newsetofAds));
        new AWN().success("succesFully deleted ad");
      } else {
        new AWN().warning(response.data.message);
      }
    }
  };

  console.log(ads);

  useEffect(() => {
    fetchAppAds();
  }, []);

  const playFlicks = async (url) => {
    const AWN = (await import("awesome-notifications")).default;

    new AWN().modal(`<video width="320" height="240" controls>
          <source src=${url} type="video/mp4">
          
        Your browser does not support the video tag.
        </video>`);
    return;
  };

  const thumbnailUpload = (e) => {
    var image = e.target.files[0];
    console.log(image?.name);
    const extension = image?.name.split(".").pop();
    if (!image || !["jpg", "jpeg", "png"].includes(extension)) {
      new AWN().alert("no image file selected", {
        position: "bottom-right",
      });
      return;
    }
    var storageRef = storage.ref();
    const id = nanoid(3);
    storageRef
      .child(`appAds/${id}.${extension}`)
      .put(image)
      .then((snapshot) => {
        snapshot.ref
          .getDownloadURL()
          .then(function (downloadURL) {
            setnewAds({ ...newAds, thumbnail: downloadURL });
          })
          .catch((err) => {
            new AWN().alert(err.message, {
              position: "bottom-right",
            });
          });
      });
  };
  const uploadVideo = (e) => {
    var image = e.target.files[0];
    const extension = image?.name.split(".").pop();

    if (!image || extension !== "mp4") {
      new AWN().alert("no review file selected", {
        position: "bottom-right",
      });
      return;
    }
    var storageRef = storage.ref();
    const id = nanoid(5);

    var uploadTask = storageRef.child(`appAds//${id}.mp4`).put(image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        setProgress(progress);
      },
      (error) => {
        // Handle unsuccessful uploads
        alert(error);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          setnewAds({ ...newAds, videoUrl: downloadURL });
        });
      }
    );
  };

  return (
    <div className="p-3 bg-background min-h-full">
      <div className="flex items-center justify-start text-3xl bg-white p-2 my-2">
        <Link to="/home">
          <i className="fas fa fa-arrow-left"></i>
        </Link>
        <p className="mx-3">Edit App Story</p>
      </div>
      <div className="grid grid-cols-6 gap-3">
        <div className="grid p-2 grid-cols-5 grid-flow-row col-span-4 gap-3 bg-white rounded-md shadow-md">
          {ads?.map((r, index) => (
            <div
              className="p-2 shadow-md rounded bg-background "
              key={index + "sadflhsd"}
            >
              <img src={r.thumbnail} alt="" />
              <div className="text-center py-2">
                <button
                  className="mx-3 text-green-500"
                  onClick={() => playFlicks(r.videoUrl)}
                >
                  <i className="fas fa fa-play"></i>
                </button>
                <button
                  onClick={() => deleteAd(r._id)}
                  className="mx-3 text-red-500"
                >
                  <i className="fas fa fa-trash"></i>
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="bg-white shadow-md rounded col-span-2 p-2">
          <p className="text-xl">Add Story Here</p>
          {progress > 0 && progress < 100 ? (
            <div className="">Progress : {progress}%</div>
          ) : null}
          <div className="flex gap-3 items-center justify-center">
            <label htmlFor="adsThumbnail">
              {newAds?.thumbnail ? (
                <img src={newAds.thumbnail} className="w-44" alt="" />
              ) : (
                <div className="rounded h-44 w-44 bg-background text-center flex items-center justify-center cursor-pointer">
                  <p className="text-xs">thumbnail</p>
                </div>
              )}
            </label>
            <input
              type="file"
              id="adsThumbnail"
              onChange={(e) => thumbnailUpload(e)}
              className="hidden"
            />
            <label htmlFor="adsVideo">
              {newAds?.videoUrl ? (
                <video controls src={newAds.videoUrl} className="w-44" alt="" />
              ) : (
                <div className="rounded h-44 w-44 bg-background text-center flex items-center justify-center cursor-pointer">
                  <p className="text-xs">Video</p>
                </div>
              )}
            </label>
            <input
              type="file"
              id="adsVideo"
              onChange={(e) => uploadVideo(e)}
              className="hidden"
            />
          </div>
          <div className="text-center p-3">
            {newAds != null && (
              <button
                onClick={addNewAdsNow}
                className="bg-green-500 rounded-md shadow-md py-2 px-3 text-white mx-3"
              >
                Add
              </button>
            )}
            <button
              onClick={() => setnewAds(null)}
              className="bg-red-500 rounded-md shadow-md py-2 px-3 text-white mx-3"
            >
              Discard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditAppAds;
