import React from "react";
import { Link } from "react-router-dom";

function ReviewsList({ stats = [] }) {
  const playReview = async (url) => {
    const AWN = (await import("awesome-notifications")).default;

    new AWN().modal(`<video width="320" height="240" controls>
        <source src=${url} type="video/mp4">
        
      Your browser does not support the video tag.
      </video>`);
    return;
  };

  return (
    <div className="h-96 overflow-y-scroll">
      <table className="table-fixed">
        <thead>
          <tr>
            <th className="w-1/8">Status</th>
            <th className="w-2/8">Name</th>
            <th className="w-1/8">Play</th>
            <th className="w-1/8">Views</th>
            <th className="w-1/8">Rating</th>
            <th className="w-1/8">Date</th>
            <th className="w-1/8">Action</th>
          </tr>
        </thead>
        <tbody>
          {stats.map((stat) => (
            <tr key={stat.id} className="h-14">
              <td>
                {stat.status ? (
                  <button className="bg-green-400 text-white rounded-sm shadow-md p-2">
                    Published
                  </button>
                ) : (
                  <button className="bg-red-400 text-white rounded-sm shadow-md p-2">
                    Pending
                  </button>
                )}
              </td>
              <td className="pl-3">{stat.name}</td>
              <td className="pl-3">
                <button
                  onClick={() => playReview(stat.reviewUrl)}
                  className="p-1 bg-yellow-500 text-white rounded shadow-sm"
                >
                  Play
                </button>
              </td>
              <td className="pl-3 text-center">
                {stat.views}
                <i className="fas fa fa-eye mx-2 text-blue-500"></i>
              </td>
              <td className="pl-3 text-center">
                {stat.rating}
                <i className="fas fa fa-star mx-2 text-yellow-500"></i>
              </td>
              <td className="pl-3">
                {new Date(stat.dateAdded).toDateString()}
              </td>
              <td className="text-center">
                <Link to={"/home/reviews/Viewreview/" + stat.id}>
                  <i className="fas fa fa-folder-open"></i>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ReviewsList;
