import userEvent from "@testing-library/user-event";
import React, { useRef } from "react";
import Goback from "./utils/Goback";
import { data } from "./utils/Res";
import firebase from "firebase";
import { firebasefb } from "./firebaseconfig";
import { nanoid } from "nanoid";
import AWN from "awesome-notifications";
import { useHistory } from "react-router";

function CreateUser() {
    const history = useHistory();
    let nameref = useRef();
    let phoneref = useRef();
    let emailref = useRef();
    let gender = useRef();

    const createUserNow = async () => {
        if (nameref.current.value.length < 3)
            return alert("name should contain more than 3 letters");

        var id = `user_${nanoid(8)}`;
        var filterName = nameref.current.value.toLowerCase().split(" ");
        var user = {
            id: id,
            tag: id,
            name: nameref.current.value,
            phone: phoneref.current.value
                ? "+91" + phoneref.current.value
                : null,
            email: emailref.current.value ?? null,
            gender: gender.current.value,
            filter: filterName,
            about: "",
            joinDate: firebase.firestore.Timestamp.now(),
        };
        if (phoneref.current.value.length < 10) {
            exist = await firebasefb
                .collection("user")
                .where("email", "==", user.email)
                .get();
            if (exist.docs.length !== 0) {
                return new AWN().warning("user already exist with same email");
            }
        } else {
            var exist = await firebasefb
                .collection("user")
                .where("phone", "==", user.phone)
                .get();
            if (exist.docs.length !== 0) {
                return new AWN().warning(
                    "user already exist with same phone number"
                );
            }
        }

        firebasefb
            .collection("user")
            .doc(user.id)
            .set(user)
            .then(async (res) => {
                await firebasefb
                    .collection("userActivity")
                    .doc(user.id)
                    .set({ activity: [], coins: [] });
                new AWN().success("added successfully");
                history.goBack();
            })
            .catch((e) => {
                new AWN().warning("added successfully");
            });
    };
    return (
        <div className="bg-panel_bg min-h-screen font-Manrope">
            <div className="border-b border-dashed h-24">
                <h1 className=" text-3xl flex flex-grow-0 pt-5 pl-8 ">
                    <Goback />
                    <span className="pt-2"> &nbsp; Create New User</span>
                </h1>
            </div>
            <div className="grid grid-cols-2">
                <div className="px-10 py-3">
                    <p className=" text-lg font-medium">
                        Name <span className="text-red-500">*</span>
                    </p>
                    <input
                        type="text"
                        ref={nameref}
                        placeholder="john doe"
                        className={data.textInputStyle}
                    />
                </div>
                <div className="px-10 py-3">
                    <p className=" text-lg font-medium">
                        email <span className="text-red-500">*</span>
                    </p>
                    <input
                        type="text"
                        ref={emailref}
                        placeholder="email "
                        className={data.textInputStyle}
                    />
                </div>
                <div className="px-10 py-3">
                    <p className="  text-lg font-medium">
                        phone <span className="text-red-500">*</span>
                    </p>
                    <input
                        type="text"
                        ref={phoneref}
                        placeholder="phone number"
                        className={data.textInputStyle}
                    />
                </div>
                <div className="px-10 py-3    ">
                    <p className="  text-lg font-medium">
                        Gender <span className="text-red-500">*</span>
                    </p>
                    <select
                        type="text"
                        placeholder="gender"
                        ref={gender}
                        className={data.textInputStyle}
                    >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                </div>
            </div>
            <div className="p-10 text-right ">
                <button
                    className={data.pinkButton}
                    onClick={() => createUserNow()}
                >
                    Create
                </button>
            </div>
        </div>
    );
}

export default CreateUser;
