import React from "react";

function CustomRating({ rating }) {
  return Array(5)
    .fill()
    .map((_, index) => {
      const rate = rating ?? 3;

      if (index < rate) return <i className="fas fa-star text-pink-600"></i>;
      else return <i className="fas fa-star text-grey-600"></i>;
    });
}

export default CustomRating;
