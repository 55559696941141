import moment from "moment";
import React, { useRef, useState } from "react";
import Goback from "./utils/Goback";
import { data } from "./utils/Res";
import AWN from "awesome-notifications";
import { firebasefb } from "./firebaseconfig";

function CreateNewNotification() {
    const [not, setnot] = useState({});

    let messageref = useRef();
    let headref = useRef();
    let timeref = useRef();

    const addnewnot = () => {
        if (headref.current?.value?.length < 10)
            return new AWN().warning("add more words in headline");
        if (messageref.current.value.length < 10)
            return new AWN().warning("add more words in message");

        if (timeref.current.value.length === "")
            return new AWN().warning("add time when this will be published");
        if (!not.type) return new AWN().warning("select type");

        const message = {
            header: headref.current.value,
            message: messageref.current.value,
            time: timeref.current.value,
            type: not.type,
            multiple: true,
        };

        firebasefb
            .collection("notifications")
            .add(message)
            .then((res) => {
                new AWN().success("success");
            })
            .catch((e) => {
                new AWN().alert(e);
            });
    };

    return (
        <div className="bg-gray-100 min-h-screen">
            <div className="bg-white h-24 mb-10">
                <h1 className="font-medium text-3xl flex flex-grow-0 pt-5 pl-8 ">
                    <Goback />
                    <span className="pt-2">&nbsp; Create New Notification</span>
                </h1>
            </div>
            <div className="">
                <div className="bg-gray-100 m-10 grid grid-cols-2">
                    <div className="m-5">
                        <p className="font-medium text-lg pb-2">HeadLine</p>
                        <input
                            type="text"
                            ref={headref}
                            placeholder="Notification HeadLine"
                            className={data.textInputStyle}
                        />
                    </div>
                    <div className="m-5">
                        <p className="font-medium text-lg pb-2">Message</p>
                        <input
                            type="text"
                            ref={messageref}
                            placeholder="Notification Message"
                            className={data.textInputStyle}
                        />
                    </div>

                    <div className="m-5">
                        <p className="font-medium text-lg pb-2">Time</p>
                        <input
                            type="datetime-local"
                            ref={timeref}
                            placeholder="Enter Category Name"
                            min={
                                moment(new Date())
                                    .format("YYYY-MM-DD")
                                    .toString() + "T00:00"
                            }
                            className={data.textInputStyle}
                        />
                    </div>
                    <div className="m-5">
                        <p className="font-medium text-lg pb-2">type</p>
                        <select
                            onChange={(e) => {
                                setnot({ ...not, type: e.target.value });
                            }}
                            name="status"
                            id=""
                            className={data.textInputStyle}
                        >
                            <option value="draft">Draft</option>
                            <option value="schedule">Schedule</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="text-right mx-10 px-5">
                <button
                    className="w-48 h-14 bg-pink-500 hover:bg-pink-700 text-white font-medium tracking-wide"
                    onClick={() => addnewnot()}
                >
                    Create
                </button>
            </div>
        </div>
    );
}

export default CreateNewNotification;
