import React, { useEffect, useState } from "react";
import { useStateValue } from "./StateProvider";
import { firebasefb } from "./firebaseconfig";
import { useParams } from "react-router-dom";

function ViewRedeemDetail() {
  const { coupanId } = useParams();

  const [redeem, setredeem] = useState({});
  const [{ currentItem }, dispatch] = useStateValue();

  console.log(redeem);
  useEffect(() => {
    if (currentItem) {
      setredeem(currentItem);
      return;
    }
    firebasefb
      .doc("redemption/" + coupanId)
      .get()
      .then((res) => setredeem(res.data()));
  }, []);

  return (
    <div>
      {Object.keys(redeem).map((ke, index) => (
        <div className="flex m-2" key={index}>
          <p className="w-96 py-2 bg-purple-400 px-4">{ke}</p>
          <p className="px-2">{redeem[ke]?.toString()}</p>
        </div>
      ))}
    </div>
  );
}

export default ViewRedeemDetail;
