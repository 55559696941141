import {
  Link,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import WebReviews from "./components/WebReviews";
import Experience from "./Experience";
import Reviews from "./Reviews";
import Flicks from "./utils/Flicks";

function Videoes() {
  let { path, url } = useRouteMatch();
  const location = useLocation();
  const active =
    " font-medium bg-tab_active_bg text-tab_active_color  py-3 w-full text-center";
  const passive =
    " bg-tab_passive_bg text-tab_passive_color  py-3  w-full text-center";

  return (
    <div className="bg-panel_bg min-h-screen w-full py-6 font-Manrope ">
      <div className="flex rounded-md mx-10 font-medium tracking-wide text-xl">
        <Link
          to={`${url}/experience`}
          className={
            location.pathname.includes("experience") ? active : passive
          }
        >
          Experience
        </Link>
        <Link
          to={`${url}/reviews`}
          className={location.pathname.includes("reviews") ? active : passive}
        >
          Reviews
        </Link>
        <Link
          to={`${url}/flicks`}
          className={location.pathname.includes("flicks") ? active : passive}
        >
          Flicks
        </Link>
        <Link
          to={`${url}/webReviews`}
          className={
            location.pathname.includes("webReviews") ? active : passive
          }
        >
          Web Reviews
        </Link>
      </div>

      <div className="m-10">
        <div className="    rounded h-screen w-full">
          <Switch>
            <Route path={`${path}/experience`} component={Experience} />
            <Route path={`${path}/reviews`} component={Reviews} />
            <Route path={`${path}/flicks`} component={Flicks} />
            <Route path={`${path}/webReviews`} component={WebReviews} />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default Videoes;
