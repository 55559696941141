import { firebasefb } from "./firebaseconfig";
import { useState, useEffect } from "react";
import { data } from "./utils/Res";
import firebase from "firebase";
import CustomDataGrid from "./utils/CustomDataGrid";
import { useStateValue } from "./StateProvider";

import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import AWN from "awesome-notifications";
import axios from "axios";

function Experience() {
  const router = useHistory();
  const [{ countAdmin }, dispatch] = useStateValue();

  const [reviews, setreviews] = useState([]);
  const [loading, setloading] = useState(false);
  let { path, url } = useRouteMatch();
  const location = useLocation();
  const [last, setlast] = useState(null);
  const [lastpost, setlastpost] = useState(false);

  // console.log(path);
  const active =
    " font-medium bg-tab_active_bg text-tab_active_color  py-3 w-full text-center";
  const passive =
    " bg-tab_passive_bg text-tab_passive_color  py-3  w-full text-center border-r border-black border-opacity-5";

  useEffect(() => {
    setloading(true);
    firebasefb
      .collection("experience")
      .orderBy("dateAdded")
      .limit(50)
      .get()
      .then((res) => {
        var a = [];
        res.docs.forEach((r) => {
          var temp = r.data();
          a.push(temp);
        });
        setlast(res.docs[res.docs.length - 1]);
        setreviews(a);
        setloading(false);
      });
  }, []);

  const playReview = async (url) => {
    const AWN = (await import("awesome-notifications")).default;

    new AWN().modal(`<video width="320" height="240" controls>
        <source src=${url} type="video/mp4">
        
      Your browser does not support the video tag.
      </video>`);
    return;
  };

  const rejectReview = async (review) => {
    var user = await firebasefb
      .collection("user")
      .doc(review.uploadedBy.id)
      .get();
    if (!user) return alert("no user found something went wrong try again");
    if (!user.token) {
      alert(
        "user have no message token associated, so notification can be sent"
      );
    } else {
      const token = user.token;

      const message = {
        notification: {
          title: "uploaded review rejected",
          body: `review named ${review.name} rejected due to `,
        },
      };
      const params = JSON.stringify({ message: message, token: token });
      const headers = {
        "content-type": "application/json",
      };
      var response = await axios.post(
        "https://restapi-zscu3untuq-em.a.run.app/notification",
        params,
        { headers: headers }
      );
      // console.log(response);
      if (response.status === 200 && response.data.success) {
        new AWN().success("notification sent");
      } else {
        new AWN().alert("something went wrong  try again");
        return;
      }
    }
    await firebasefb
      .collection("review")
      .doc(review.id)
      .update({ rejected: true })
      .then(() => {
        new AWN().success("review rejected");
      });

    await firebasefb
      .collection("userAcitivity")
      .doc(review.uploadedBy.id)
      .update({
        activity: firebase.firestore.FieldValue.arrayUnion({
          imageUrl: review.thumbnail,
          time: firebase.firestore.Timestamp.now(),
          to: review.id,
          by: "admin",
          type: "rejected",
        }),
      });
  };

  const loadmore = () => {
    if (!lastpost) {
      firebasefb
        .collection("review")
        .where("type", "==", "experience")
        .startAfter(last)
        .limit(20)
        .get()
        .then((res) => {
          var a = [];
          res.docs.forEach((doc) => {
            a.push(doc.data());
          });
          setreviews([...reviews, ...a]);
          setlast(res.docs[res.docs.length - 1]);
          a.length === 0 ? setlastpost(true) : setlastpost(false);
        });
    }
  };

  const columns = [
    { field: "name", headerName: "Name", width: 190 },
    {
      field: "name",
      headerName: "Uploaded By",
      width: 190,
      renderCell: (row) => row.uploadedBy.name,
    },
    {
      field: "status",
      headerName: "Status",
      width: 190,
      renderCell: (row) =>
        row.rejected ? (
          <p className=" text-red-500 font-bold">Rejected</p>
        ) : row.status ? (
          <p className=" text-green-500 font-bold">Published</p>
        ) : (
          <p className=" text-yellow-500 font-bold">Pending</p>
        ),
    },
    {
      field: "phone",
      headerName: "Join Date",
      renderCell: (row) => {
        return <div className="">{row.dateAdded.toDate().toDateString()}</div>;
      },
    },

    { field: "views", headerName: "Views", width: 100 },
    { field: "parentName", headerName: "Parent", width: 200 },
  ];

  const actionNode = (row) => {
    return (
      <td className="flex h-12">
        <button
          className={data.greenButton}
          onClick={() => {
            dispatch({ type: "SET_ITEM", item: row });
            router.push(`/home/reviews/Viewreview/${row.id}`);
          }}
        >
          View
        </button>
        <button
          className={data.purpleButton}
          onClick={() => {
            navigator.clipboard.writeText("app.myrevue.com/" + row.id);
            new AWN().info("copied to clipboard");
          }}
        >
          <i className="fas fa-link mx-1"></i> Link
        </button>
        <button
          className={data.yellowButton}
          onClick={() => router.push(`/home/updateReview/${row.id}`)}
        >
          Update
        </button>
        <button
          onClick={() => playReview(row.reviewUrl)}
          className={data.playButton}
        >
          Play
        </button>
        <button onClick={() => rejectReview(row)} className={data.redButton}>
          Reject
        </button>
      </td>
    );
  };

  return loading ? (
    <div className="h-screen flex items-center justify-center">
      <span className="fas fa-spinner animate-spin"></span>
    </div>
  ) : (
    <div className=" min-h-screen w-full font-Manrope">
      <div className="flex justify-between items-center">
        <p className="text-3xl font-medium">Total Experiences:</p>
        <p className="text-3xl font-semibold ">{countAdmin.experiences}</p>
      </div>

      <div className="my-4">
        <div className="flex justify-evenly rounded-lg font-medium text-lg tracking-wide">
          <Link
            to={`${url}/allReviews`}
            className={
              location.pathname.includes("allReviews") ||
              location.pathname === url
                ? active
                : passive
            }
          >
            All experiences
          </Link>
          <Link
            to={`${url}/pending`}
            className={location.pathname.includes("pending") ? active : passive}
          >
            Pending
          </Link>
          <Link
            to={`${url}/published`}
            className={
              location.pathname.includes("published") ? active : passive
            }
          >
            Published
          </Link>
          <Link
            to={`${url}/rejected`}
            className={
              location.pathname.includes("rejected") ? active : passive
            }
          >
            Rejected
          </Link>
        </div>
      </div>
      <div className="pb-20 w-full">
        <div className="w-full  bg-white border shadow-lg rounded-md overflow-x-auto">
          <Switch>
            <Route exact path={path}>
              <CustomDataGrid
                rows={reviews}
                columns={columns}
                actionNode={actionNode}
                loadMore={loadmore}
              />
            </Route>

            <Route path={`${path}/allReviews`}>
              <CustomDataGrid
                rows={reviews}
                columns={columns}
                actionNode={actionNode}
                loadMore={loadmore}
              />
            </Route>
            <Route path={`${path}/pending`}>
              <CustomDataGrid
                rows={reviews.filter((a) => !a?.status)}
                columns={columns}
                actionNode={actionNode}
              />
            </Route>
            <Route path={`${path}/published`}>
              <CustomDataGrid
                rows={reviews.filter((a) => a?.status)}
                columns={columns}
                actionNode={actionNode}
              />
            </Route>
            <Route path={`${path}/rejected`}>
              <CustomDataGrid
                rows={reviews.filter((a) => a?.rejected)}
                columns={columns}
                actionNode={actionNode}
              />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default Experience;
