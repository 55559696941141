import React from "react";
import { useState } from "react";
import urls from "../utils/urls";
import axios from "axios";

function HuntSubmissions({ houseId }) {
  const fetchStats = async () => {
    setloading(true);
    const response = await axios(
      urls.brandAdmin + "getHouseContentAll/" + houseId,
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: urls.brandAdminAuth,
        },
      }
    );
    console.log(response);

    if (response.status === 200) {
      let res = await response.data.data;
      setnoData(res.length === 0);
      setStats(res);
      setloading(false);
    }
  };

  const playReview = async (url) => {
    const AWN = (await import("awesome-notifications")).default;

    new AWN().modal(`<video width="320" height="240" controls>
        <source src=${url} type="video/mp4">
        
      Your browser does not support the video tag.
      </video>`);
    return;
  };
  const [nodata, setnoData] = useState(false);
  const [loading, setloading] = useState(false);
  const [stats, setStats] = useState([]);

  if (loading) {
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <i className="animate-pulse">loading...</i>
      </div>
    );
  } else if (stats.length !== 0) {
    return (
      <div className="bg-white rounded-md shadow-md p-4">
        <p className="my-3 text-2xl">{stats.length} Submissions</p>
        <div className="h-96 overflow-y-scroll flex flex-col gap-5">
          {stats.map((prod, index) => (
            <div
              key={index}
              className="grid grid-cols-8  p-1 rounded shadow-md mx-1 items-center justify-start"
            >
              <img
                src={prod.user.imageUrl}
                alt=""
                className="h-14 rounded-full w-14"
              />

              <div className="ml-5 col-span-2">
                <p className="">{prod.user.name}</p>
                <p className="text-xs">{prod.user.address} </p>
                <p className="text-xs">{prod.user.id}</p>
              </div>
              <div className="">
                <p className="text-xs font-semibold">Status</p>
                <button className="">{prod.status} </button>
              </div>
              {prod.shipmentDetails && (
                <div className="">
                  <p className="text-xs">Shipment status</p>
                  <p className="font-bold">
                    {prod?.shipmentDetails?.shipmentStatus ?? ""}
                  </p>
                </div>
              )}
              {prod.changesComment && (
                <div className="">
                  <p className="text-xs">changes</p>
                  <p className="text-sm font-bold">{prod.changesTitle}</p>
                  <textarea className="text-xs h-20">
                    {prod.changesComment}
                  </textarea>
                </div>
              )}
              {prod.submissionDate && (
                <p className="text-xs">
                  {new Date(prod.submissionDate).toDateString()}
                </p>
              )}
              {prod.url && (
                <div className="">
                  <button
                    className="bg-green-500 text-white rounded-sm shadow-md p-1"
                    onClick={() => playReview(prod.url)}
                  >
                    View Submission
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  } else if (nodata) {
    return (
      <div className="h-96 rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <i className="animate-pulse">No Submissions found</i>
      </div>
    );
  } else
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <button
          className="border p-2 shadow-md border-blue-500"
          onClick={fetchStats}
        >
          Reveal Submissions
        </button>
      </div>
    );
}

export default HuntSubmissions;
