import ReviewItem from "./utils/ReviewItem";
import { firebasefb } from "./firebaseconfig";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Goback from "./utils/Goback";

function ViewStore() {
  const router = useHistory();
  const { brandid } = useParams();
  const [store, setstore] = useState({});
  const [review, setreview] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    firebasefb
      .collection("store")
      .doc(brandid)
      .get()
      .then((res) => {
        if (res.data()) setstore(res.data());
        setloading(true);
      });
    firebasefb
      .collection("review")
      .where("bpsId", "==", brandid)
      .get()
      .then((res) => {
        var a = [];
        res.docs.forEach((r) => {
          a.push(r.data());
        });
        setreview(a);
      });
  }, [brandid]);

  return (
    <div className="bg-background min-h-screen ">
      <div className="">
        <div className="bg-white h-24">
          <h1 className="font-medium text-3xl flex flex-grow-0 pt-5 pl-8 ">
            <Goback />
            <span className="pt-2"> &nbsp; View Store</span>
          </h1>
        </div>
      </div>
      <div className="">
        <div className="p-2">
          <button
            onClick={() => {
              router.goBack();
            }}
            className=""
          >
            <span className="fas fa-arrow-left"></span>
          </button>
        </div>
      </div>
      <div className=" flex justify-between">
        {loading ? (
          <div className="w-full p-3">
            <p className="text-2xl ">Store Details</p>
            <table className="table w-full text-left">
              <tbody>
                <tr>
                  <td className=" px-3 h-12">
                    {<img src={store.imageUrl} width="200px" alt="asdfasdf" />}
                  </td>
                </tr>
                <tr>
                  <td className="font-bold bg-gray-300  ">id</td>
                  <td className=" px-3 h-12">{store.id}</td>
                </tr>
                <tr>
                  <td className="font-bold bg-gray-300  ">name</td>
                  <td className=" px-3 h-12">
                    {store.name}
                    {store.isverified ? (
                      <span className="fas fa-check fa-3x"></span>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td className="font-bold bg-gray-300  ">Added By</td>
                  <td className=" px-3 h-12">{store.addedBy.name}</td>
                </tr>
                <tr>
                  <td className="font-bold bg-gray-300  ">about</td>
                  <td className=" px-3 h-12">{store.description}</td>
                </tr>
                <tr>
                  <td className="font-bold bg-gray-300  ">Address</td>
                  <td className=" px-3 h-12">{store.address}</td>
                </tr>
                <tr>
                  <td className="font-bold bg-gray-300  ">store id</td>
                  <td className=" px-3 h-12">{store.addedBy.id}</td>
                </tr>

                <tr>
                  <td className="font-bold bg-gray-300  ">sub Category</td>
                  <td className=" px-3 h-12">{store?.subcategory}</td>
                </tr>

                <tr>
                  <td className="font-bold bg-gray-300  ">Subscribes</td>
                  <td className=" px-3 h-12">{store.subscribes}</td>
                </tr>
                <tr>
                  <td className="font-bold bg-gray-300  ">Reviews Published</td>
                  <td className=" px-3 h-12">{review.length}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          "Loading"
        )}
        <div className="w-full p-3">
          <h1>Reviews </h1>
          <table className="table-fixed w-full text-left">
            <thead>
              <tr className="bg-gray-200 h-12 w-full border-b">
                <th className=" pl-2 w-10">#</th>
                <th className="w-72">name</th>
                <th className="w-32 text-center">Rating</th>
                <th className="w-32 text-center">Views</th>
                <th className="w-72">action</th>
              </tr>
            </thead>
            <tbody>
              {review.map((r, index) => (
                <ReviewItem review={r} index={index} key={index} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className=""></div>
    </div>
  );
}

export default ViewStore;
