import AWN from "awesome-notifications";
import axios from "axios";
import React from "react";
import { useState } from "react";

function MoveToFlicks({ review }) {
  const [move, setmove] = useState(false);
  const [loading, setloading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [subFlickTypes, setFlickTypes] = useState([]);

  const fetchAppAds = async () => {
    setloading(true);
    let chart = sessionStorage.getItem("getFlicksType");
    if (chart) {
      chart = JSON.parse(chart);
      setFlickTypes(chart);
      setloading(false);
      setmove(true);
      return;
    }
    console.log("fetching from server top users");
    const response = await axios(
      "https://userspecificapi-zscu3untuq-el.a.run.app//getFlicksType/user_admin",
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "asdfwp234-0835234-34535FHD568-a26463213234234-llksdfsdlfwSLDF",
        },
      }
    );

    if (response.status === 200) {
      let res = response.data;
      console.log(res);
      sessionStorage.setItem("getFlicksType", JSON.stringify(res.data));
      setFlickTypes(res.data);
      setloading(false);
      setmove(true);
    }
  };
  const moveToFlicks = () => {
    let rev = { ...review };
    delete rev.user;
    delete rev.product;
    delete rev._id;
    delete rev.attributes;
    rev.flickTag = selected.type;
    rev.subFlickTag = selected.name;

    new AWN().confirm(
      "are you sure want to move this review to FLicks",
      () => {
        new AWN().async(
          axios("https://flicksapi-zscu3untuq-el.a.run.app//createFlicks", {
            method: "PUT", // *GET, POST, PUT, DELETE, etc.
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "019271-ALSDFWOER-afasldfwoerew-79adf1a3df-7465AASDsdf",
            },
            data: rev,
          }),
          (response) => {
            if (response.status === 200) {
              if (response.data["success"])
                return new AWN().success(response.data["message"]);
            }
            new AWN().warning(response.data["message"]);
          },
          (error) => {
            new AWN().warning(error);
          }
        );
      },
      () => {
        new AWN().info("pressed cancel");
      }
    );

    console.log(rev);
  };

  if (move)
    return (
      <div className="">
        <div>
          <p className="text-xs">select flickTag (subflickTag)</p> <br />
          <button
            onClick={() => {
              setmove(false);
              setSelected(null);
            }}
          >
            <i className="fas fa fa-trash p-2 bg-white text-red-500 rounded-full hover:text-red-900"></i>
          </button>
          <select
            className="mx-3"
            name=""
            id=""
            onChange={(e) => {
              setSelected(subFlickTypes.find((r) => r._id === e.target.value));
            }}
          >
            <option value="">--select--</option>
            {subFlickTypes?.map((sub) => (
              <option key={sub._id} value={sub._id}>
                {sub.type} -- {sub.name}
              </option>
            ))}
          </select>
          {selected && (
            <button
              className="text-white px-3 py-2 rounded-sm bg-red-500"
              onClick={moveToFlicks}
            >
              Move Now
            </button>
          )}
        </div>
      </div>
    );
  else
    return (
      <button
        className="text-white bg-red-500 rounded-md p-2"
        onClick={fetchAppAds}
      >
        Move to Flicks
      </button>
    );
}

export default MoveToFlicks;
