import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { firebasefb } from "./firebaseconfig";
import Goback from "./utils/Goback";
import { data } from "./utils/Res";

function ViewCoupan() {
  const [coupan, setcoupan] = useState({});
  const [loading, setloading] = useState(true);
  const [tags, settags] = useState([]);
  const { coupanId } = useParams();

  useEffect(() => {
    firebasefb
      .collection("rewardCoupan")
      .doc(coupanId)
      .get()
      .then((res) => {
        setcoupan(res.data());
        settags(res.data()?.rules);
        setloading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  console.log(coupan);
  if (loading)
    return (
      <div className="h-screen flex items-center  justify-center">
        <span className="fas fa-spinner animate-spin"></span>
      </div>
    );
  else
    return (
      <div className=" bg-background  min-h-screen">
        <div className="bg-white  rounded-lg px-3 py-4">
          <h1 className="font-medium text-3xl flex flex-grow-0  ">
            <Goback />
            <span className="pt-2"> &nbsp; {coupan?.code}</span>
          </h1>
        </div>
        <div className="">
          <div className="bg-white m-5 p-5">
            <div className="flex ">
              <div className=" w-64 h-64 overflow-hidden bg-red-200 rounded-lg ">
                <img src={coupan?.imageUrl} alt="" className="h-64" />
              </div>
              <div className=" mx-4 w-64 h-64 overflow-hidden bg-red-200 rounded-lg ">
                <img src={coupan?.bannerUrl} alt="" className="h-64" />
              </div>
              <div className="">
                {coupan.type ?? "coupon type not defined"}

                <p className="">
                  <span className="text-bold">Item name : </span> :{" "}
                  {coupan.item}
                </p>
                <p className="">
                  <span className="text-bold">Item value in Rupees : </span> :{" "}
                  {coupan.worth}
                </p>
                <p className="">
                  <span className="text-bold">
                    cash back Rupees (cashback) :{" "}
                  </span>{" "}
                  : {coupan.cash}om
                </p>
                <p className="">
                  <span className="text-bold">Code (discount) : </span> :{" "}
                  {coupan.code}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="bg-white m-5">
            <div className="pl-12 pt-8 pr-12">
              <p className=" pb-2 text-lg font-medium">
                title
                <span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                disabled="true"
                defaultValue={coupan?.title}
                placeholder="coupan code"
                className={data.textInputStyleDark}
              />
            </div>

            <div className="pl-12 pt-8 pr-12 ">
              <p className=" pb-2 text-lg font-medium">
                Coins required
                <span className="text-red-500">*</span>
              </p>

              <input
                disabled
                defaultValue={coupan?.quantity}
                className={data.textInputStyleDark}
                type="number"
                placeholder="quantity   "
              />
            </div>

            <div className=" pl-12 pr-12 pb-8">
              <h1 className=" font-semibold text-xl">
                Terms and Condition (in list)
              </h1>
              <div className=" pb-4">
                {tags?.map((e, index) => (
                  <p
                    key={index}
                    className="bg-blue-800 text-white my-2 rounded p-2 cursor-pointer"
                  >
                    {e}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default ViewCoupan;
