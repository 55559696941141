import React, { useEffect, useState } from "react";

function CustomDataGrid({
  columns = [],
  rows = [],
  actionNode,
  loadMore,
  setindex,
  darkMode = false,
}) {
  const [page, setpage] = useState(1);
  const [current, setcurrent] = useState([]);
  const [upRows, setupRows] = useState(rows);
  const [asc, setasc] = useState(1);
  const [tindex, settindex] = useState(null);
  const range = [page - 2, page - 1, page, page + 1, page + 2].filter(
    (i) => i > 1 && i < Math.ceil(rows.length / 20)
  );

  useEffect(() => {
    const p = page - 1;
    const end = p * 20 + 20 > upRows.length ? upRows.length : p * 20 + 20;
    var arr = upRows.slice(p * 20, end);

    setcurrent(arr);
  }, [page, rows, upRows]);

  useEffect(() => {
    setupRows(rows);
  }, [rows]);

  const sortthisRows = (basis) => {
    console.log(basis);
    const newrows = upRows.sort((a, b) =>
      a[basis] > b[basis] ? asc * 1 : asc * -1
    );
    setupRows([...newrows]);
    setasc(-1 * asc);
  };

  const dot_verticle = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6 text-blue-600 cursor-pointer"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
      />
    </svg>
  );

  if (rows.length === 0) {
    return (
      <div
        className={
          darkMode
            ? "h-56 background text-white flex items-center justify-center"
            : "h-56 flex items-center justify-center"
        }
      >
        <p className="">No rows present</p>
      </div>
    );
  } else
    return (
      <div className="flex flex-col  overflow-x-auto font-Manrope">
        <table
          className={
            darkMode
              ? "background rounded-md  overflow-hidden m-1  text-white table-auto table-hover "
              : "bg-gray-100  rounded-md overflow-hidden m-1  table-auto table-hover "
          }
        >
          <thead
            className={
              darkMode
                ? "bg-gray-700  rounded-t-md h-12  text-left"
                : "bg-gray-50   h-12  text-left"
            }
          >
            <th className="  font-normal cursor-pointer">#</th>
            {columns.map((head, index) => (
              <th
                key={"tableD" + index}
                className="cursor-pointer hover:bg-gray-200 hover:bg-opacity-50 "
                onClick={() => sortthisRows(head.field)}
              >
                <div className="flex">
                  <p className="font-normal uppercase tracking-wide text-sm">
                    {head.headerName}
                  </p>
                  <span className="w-10">
                    {asc === 1 ? (
                      <img
                        src={require("../images/icons/arrow-down.svg").default}
                        alt="arrow"
                        className="transform scale-50"
                      />
                    ) : (
                      <img
                        src={require("../images/icons/arrow-down.svg").default}
                        alt="arrow"
                        className="transform rotate-180 scale-50 ml-2 -mt-0.5"
                      />
                    )}
                  </span>
                </div>
              </th>
            ))}
            <th className="font-normal cursor-pointer uppercase text-sm tracking-wide ">
              Actions
            </th>
          </thead>
          <tbody className="">
            {current.map((br, index) => (
              <tr className={darkMode ? " h-16" : " border-b h-16"} key={br.id}>
                <td className="px-5  text-center cell font-semibold">
                  {(page - 1) * 20 + 1 + index}.
                </td>
                {columns.map((cl, index) => (
                  <td key={index + "sfsdf"} className="">
                    {cl.renderCell
                      ? cl.renderCell(br)
                      : br[cl.field]?.length <= 100
                      ? br[cl.field]
                      : br[cl.field]?.toString().slice(0, 100)}
                  </td>
                ))}
                <td className="relative">
                  <button
                    onClick={() =>
                      tindex != null ? settindex(null) : settindex(index)
                    }
                  >
                    {dot_verticle}
                  </button>
                  {tindex === index ? actionNode(br, settindex) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div
          className={
            darkMode
              ? "h-12 text-white bg-black     shadow-sm flex justify-around items-center"
              : "h-12 shadow-sm flex justify-around items-center"
          }
        >
          <p className="">
            currently Showing : {(page - 1) * 20 + 1} -
            {(page - 1) * 20 + current.length}
          </p>
          <div className="flex">
            <button
              onClick={() => setpage(1)}
              className="bg-purple-100 border mx-1 border-purple-600 text-purple-500 py-1 px-2 rounded-md"
            >
              1
            </button>
            {Array.from({ length: rows.length / 20 }, (_, index) => {
              if (range.includes(index + 1))
                return (
                  <button
                    key={index}
                    onClick={() => setpage(index + 1)}
                    className=" border mx-1 border-purple-600 text-purple-500 py-1 px-2 rounded-md"
                  >
                    {index + 1}
                  </button>
                );
              else return <p className="px-1">.</p>;
            })}
            <button
              onClick={() => setpage(Math.ceil(rows.length / 20))}
              className="border mx-1 border-purple-600 text-purple-500 py-1 px-2 rounded-md"
            >
              {Math.ceil(rows.length / 20)}
            </button>
          </div>
          <div
            className={
              darkMode
                ? "text-white flex items-center gap-6 "
                : "text-black flex items-center gap-6"
            }
          >
            <i
              className="fas fa-arrow-left cursor-pointer"
              onClick={() => {
                if (page === 1) return;
                setpage(page - 1);
              }}
            ></i>
            <p className="">{page}</p>
            <i
              className="fas fa-arrow-right cursor-pointer"
              onClick={() => {
                if (page === Math.ceil(rows.length / 20)) return;
                setpage(page + 1);
              }}
            ></i>
            <button
              className={darkMode ? "text-white" : "text-black"}
              onClick={() => loadMore()}
            >
              Load More..
            </button>
          </div>
        </div>
      </div>
    );
}

export default CustomDataGrid;
