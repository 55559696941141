import AWN from "awesome-notifications";
import { Placeholder } from "phosphor-react";
import React from "react";
import { useState } from "react";
import { useRef } from "react";

function AffiliateLinkUpdate({ link, updateFun }) {
  let inputRef = useRef();
  const [editMode, setEditMode] = useState(false);

  const checkFun = () => {
    if (!inputRef.current.value || !inputRef.current.value.includes("https")) {
      return new AWN().warning("insert affiliate link");
    }

    let link = inputRef.current.value.trim();
    updateFun({ affiliateLink: link });
    setEditMode(false);
  };
  return (
    <div className="py-2">
      <div className="flex">
        <p className="">Affiliate link</p>
        {editMode ? (
          <div className="">
            <i
              onClick={() => setEditMode(false)}
              className="cursor-pointer mx-3 fas fa fa-close text-red-500 right-1 pt-1"
            ></i>
            <i
              onClick={checkFun}
              className="cursor-pointer mx-3 fas fa fa-check text-green-500 right-1 pt-1"
            ></i>
          </div>
        ) : (
          <i
            onClick={() => setEditMode(true)}
            className="cursor-pointer mx-3 fas fa fa-pencil right-1 pt-1"
          ></i>
        )}
      </div>
      {editMode ? (
        <input
          type="text"
          className="border h-12 p-2 w-full"
          ref={inputRef}
          placeholder="affiliate Link"
        />
      ) : (
        <p className="text-purple-500 text-sm">{link ?? "Not present"}</p>
      )}
    </div>
  );
}

export default AffiliateLinkUpdate;
