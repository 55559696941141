import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Users from "./Users";
import ViewUser from "./ViewUser";
import Dashboard from "./DashBorad";
import GuardedRoute from "./components/GuardedRoute";
import { auth, firebasefb } from "./firebaseconfig";
import CoinHistory from "./CoinHistory";
import Viewreview from "./Viewreview";
import Assets from "./Assets";
import ViewCategory from "./ViewCategory";
import AddNewCategory from "./AddNewCategory";
import UpdateCategory from "./UpdateCategory";
import ViewBrands from "./ViewBrands";
import ViewStore from "./ViewStore";
import ViewProduct from "./ViewProduct";
import Engagement from "./Engagement";
import ViewRequest from "./ViewRequest";
import Rewards from "./Reward";
import CreateBrands from "./utils/CreateBrands";
import Createproducts from "./CreateProduct";
import Notifications from "./Notification";
import CreateNewNotification from "./CreateNewNotification";
import UpdateNotification from "./UpdateNotification";
import CreateUser from "./CreateUser";
import UploadReview from "./UploadReview";
import UpdateReviewvideo from "./UpdateReviewvideo";
import AddnewCoupan from "./AddnewCoupan";
import UpdateCoupan from "./UpdateCoupan";
import ViewCoupan from "./ViewCoupan";
import ViewFlicks from "./screens/ViewFlicks";
import BrandAdmin from "./BrandAdmin";
import CreateBrandAdmin from "./CreateBrandAdmin";
import Testing from "./Testing";
import ViewRedeemDetail from "./ViewRedeemDetail";
import Videoes from "./Videoes";
import SideNav from "./SideNav";
import HashTag from "./screens/HashTag";
import TrendingHashtagManage from "./screens/TrendingHashtagManage";
import ViewWebReview from "./cards/ViewWebReview";
import MergeProduct from "./merge/MergeProduct";
import { useGetRequest } from "./helpers/apicall";
import { useStateValue } from "./StateProvider";
import urls from "./utils/urls";
import EditAppAds from "./screens/EditAppAds";
import BrandReviewRequest from "./screens/BrandReviewRequest";
import ViewBrandRequest from "./screens/ViewBrandRequest";
import BrandTools from "./screens/BrandTools";
import FilterShopifyProduct from "./components/FilterProductShopify";
import BulkProductInsert from "./components/BulkProductInsert";
import UpdateVarientsProduct from "./components/UpdateVarientsProduct";
function Home() {
  const [authuser, setauthuser] = useState(null);
  const [getData, cancelRequests] = useGetRequest();
  const [{ countAdmin }, dispatch] = useStateValue();
  const [loading, setLoading] = useState(true);

  auth.onAuthStateChanged((user) => {
    if (user) {
      setauthuser(user);
    } else {
      setauthuser(null);
    }
  });
  //https://adminapi-zscu3untuq-el.a.run.app
  useEffect(() => {
    console.log("----------inside home-------------");
    getData(
      urls.admin + "dashboardData",
      async (data) => {
        let fbData = await firebasefb
          .doc("top/countAdmin")
          .get()
          .then((r) => r.data());
        dispatch({
          type: "SET_COUNT_ADMIN",
          countAdmin: { ...fbData, ...countAdmin, ...data },
        });
        setLoading(false);
      },
      (err) => {
        console.log("error fetching dashboard data");
        console.log(err);
      },
      { headers: { Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25" } }
    );
  }, []);

  if (loading)
    return (
      <div className="h-screen flex items-center justify-center">
        <i className="fas fa fa-spinner animate-spin text-3xl"></i>
      </div>
    );
  else
    return (
      <div className="flex flex-row h-screen">
        <Router>
          <div className="admin_panel_sidenav_width">
            <SideNav />
          </div>
          <div className="admin_panel_main_width  overflow-y-auto bg-panel_bg">
            <Switch>
              <GuardedRoute
                auth={authuser}
                path="/home/users/:userid"
                component={ViewUser}
              />

              <GuardedRoute
                auth={authuser}
                path="/home/coinHistory/:userid"
                component={CoinHistory}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/uploadReview/:userid"
                component={UploadReview}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/brandRequests"
                component={BrandReviewRequest}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/viewReviewRequest"
                component={ViewBrandRequest}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/review/updateReview/video/:reviewid"
                component={UpdateReviewvideo}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/users"
                component={Users}
              />

              <GuardedRoute
                auth={authuser}
                path="/home/reviews/Viewreview/:reviewid"
                component={Viewreview}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/flicks/ViewFlicks/:reviewid"
                component={ViewFlicks}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/reviews/viewWebReview/:reviewid"
                component={ViewWebReview}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/request/:requestid"
                component={ViewRequest}
              />

              <GuardedRoute
                auth={authuser}
                path="/home/assets"
                component={Assets}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/viewCategory/:catid"
                component={ViewCategory}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/viewBrand/:brandid"
                component={ViewBrands}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/brandTools"
                component={BrandTools}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/FilterShopifyProduct"
                component={FilterShopifyProduct}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/BulkProductInsert"
                component={BulkProductInsert}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/UpdateVarientsProduct"
                component={UpdateVarientsProduct}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/createBrandAdmin"
                component={CreateBrandAdmin}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/viewstore/:brandid"
                component={ViewStore}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/user/create"
                component={CreateUser}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/CreateBrand"
                component={CreateBrands}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/createProduct"
                component={Createproducts}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/mergeProduct"
                component={MergeProduct}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/viewProduct/:productid"
                component={ViewProduct}
              />

              <GuardedRoute
                auth={authuser}
                path="/home/updateCategory/:catid"
                component={UpdateCategory}
              />

              <GuardedRoute
                auth={authuser}
                path="/home/updatenotification/:notid"
                component={UpdateNotification}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/addNewCategory"
                component={AddNewCategory}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/hashTagCategory"
                component={HashTag}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/addNewCoupan"
                component={AddnewCoupan}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/viewcoupan/:coupanId"
                component={ViewCoupan}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/updateCoupan"
                component={UpdateCoupan}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/viewRedeemDetail/:coupanId"
                component={ViewRedeemDetail}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/createNotification"
                component={CreateNewNotification}
              />

              <GuardedRoute
                auth={authuser}
                path="/home/vidoes"
                component={Videoes}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/rewards"
                component={Rewards}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/engagement"
                component={Engagement}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/manageTrendingHashtags"
                component={TrendingHashtagManage}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/notification"
                component={Notifications}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/brandAdmin"
                component={BrandAdmin}
              />
              <GuardedRoute
                auth={authuser}
                path="/home/editAppAds"
                component={EditAppAds}
              />

              <GuardedRoute
                auth={authuser}
                path="/testing"
                component={Testing}
              />
              <GuardedRoute
                auth={authuser}
                path="/home"
                component={Dashboard}
              />

              <Route exact path="/">
                {authuser != null ? (
                  <Redirect to="/home" />
                ) : (
                  <Redirect to="/" />
                )}
              </Route>
            </Switch>
          </div>
        </Router>
      </div>
    );
}

export default Home;
