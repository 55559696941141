import React from "react";
import { useState } from "react";
import urls from "../utils/urls";

function UserReviewStats({ userid }) {
  const fetchStats = async () => {
    setloading(true);
    console.log("fetching from server all users");
    const response = await fetch(urls.admin + "getReviewStats/" + userid, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });

    if (response.status === 200) {
      let res = await response.json();
      setStats(res);
      setloading(false);
    }
  };

  const [loading, setloading] = useState(false);
  const [stats, setStats] = useState([]);

  if (loading) {
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <i className="animate-pulse">loading...</i>
      </div>
    );
  } else if (stats.length !== 0) {
    return (
      <div
        className={
          stats.length === 2
            ? " col-span-2 bg-green-400 rounded shadow-md text-white "
            : " bg-green-400 rounded shadow-md text-white "
        }
      >
        <p className="m-3 text-2xl">Review Stats</p>
        <div className="flex">
          {stats.map((stat, index) => (
            <div key={index} className="text-white p-3 flex-1">
              <p className=""> {stat._id ? "Published" : "Pending"} </p>
              <div className="text-xs">
                <p className="">
                  <span className="text-5xl px-3">{stat.reviews}</span>Reviews
                </p>
                <p className="">
                  <span className="text-5xl px-3">{stat.views}</span>Views
                </p>
                <p className="">
                  <span className="text-5xl px-3">
                    {stat.ratingAvg.toFixed(2)}
                  </span>
                  Avg Ratin
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  } else
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <button
          className="border p-2 shadow-md border-blue-500"
          onClick={fetchStats}
        >
          Reveal Review Stats
        </button>
      </div>
    );
}

export default UserReviewStats;
