import AWN from "awesome-notifications";
import axios from "axios";
import React, { useRef } from "react";
import { useState } from "react";
import urls from "../utils/urls";

function FilterShopifyProduct() {
  const [loading, setLoading] = useState(false);
  const [products, setproducts] = useState(null);
  const [filtered, setFiltered] = useState(false);

  const textArea = useRef();
  const siteUrlRef = useRef();

  // name,parent,description,affiliateLink,imageUrl,sku,category,subcategory
  const filterProduct = () => {
    var prods = textArea.current.value;
    setLoading(true);

    prods = JSON.parse(prods);
    prods = prods.products;
    var newProds = [];
    prods.forEach((el) => {
      var div = document.createElement("div");
      div.innerHTML = el.body_html;
      var textDesc = div.textContent;
      textDesc = textDesc.replaceAll("\n", " ");
      textDesc = textDesc.replaceAll("\r", " ");
      textDesc = textDesc.replaceAll("\t", " ");
      if (!el.images) return;
      else if (!el.images[0]?.src) return;
      var newProd = {
        id: el.id,
        name: el.title,
        imageUrl: el.images[0].src,
        description: textDesc,
        sku: el.variants[0].sku,
        affiliateLink: "products/" + el.handle,
      };
      div.remove();
      newProds.push(newProd);
    });
    console.log(newProds);
    setproducts(newProds);
    setLoading(false);
    setFiltered(true);
  };

  const exportTsSKU = async (sku = false) => {
    if (!siteUrlRef.current.value) {
      new AWN().warning("Please enter site url to create affiliatelink");
      return;
    }
    if (!siteUrlRef.current.value.includes("https://")) {
      new AWN().warning("Please enter valid site url");
      return;
    }
    setLoading(true);
    var prods = [];

    prods = products;

    const brandId = prods[0].parent;

    let csvContent = "data:text/csv;charset=utf-8,";
    const headers =
      "id,name,parent,description,affiliateLink,imageUrl,sku,category,subcategory";
    csvContent += headers.replaceAll(",", "\t") + "\n";
    const fields = headers.split(",");
    let content = prods.reduce((total, current) => {
      let row = fields.map((f) => {
        if (f === "affiliateLink") {
          if (siteUrlRef.current.value.endsWith("/"))
            return siteUrlRef.current.value + current[f];
          else return siteUrlRef.current.value + "/" + current[f];
        }
        return current[f] ? current[f].toString() : "null";
      });
      row = row.join("\t") + "\n";
      row = row.replaceAll("#", "");
      return total + row;
    }, "");

    csvContent += content;

    const filename = sku ? "SkuProduct.tsv" : "nonSkuProduct.csv";

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link); // Required for FF
    updateBrand(brandId);
    link.click();
  };

  const updateBrand = (brandId) => {
    var siteUrl = siteUrlRef.current.value;
    siteUrl = siteUrl.trim();
    siteUrl = siteUrl.endsWith("/")
      ? siteUrl.subString(0, siteUrl.length - 1)
      : siteUrl;

    const data = { site: { type: "shopify", url: siteUrl } };

    axios(urls.admin + "updateBrand/" + brandId, {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
        Authorization: urls.adminAuth,
      },
      data: JSON.stringify(data),
    });
  };

  return (
    <div className="p-3">
      <h3 className="my-2 py-4 px-3 text-3xl">Filter Shopify Product</h3>

      {filtered ? (
        <>
          <div className=" py-3 my-3 shadow-lg px-2 flex gap-5">
            <p className="text-sm">
              Total :<span className="text-2xl"> {products?.length}</span>
            </p>
            <p className="text-sm">
              with SKU :
              <span className="text-2xl">
                {products?.filter((e) => e.compatible === "Yes").length}
              </span>
            </p>
            <p className="text-sm">
              No SKU :
              <span className="text-2xl">
                {products?.filter((e) => e.compatible === "No").length}
              </span>
            </p>

            <div className="">
              <input
                ref={siteUrlRef}
                type="text"
                placeholder="Site url please"
                className="h-12 border p-1 w-72"
              />
              <button
                onClick={() => {
                  exportTsSKU("full");
                }}
                className="p-2 cursor-pointer bg-yellow-400 text-white rounded-sm shadow-md"
              >
                Export
              </button>
            </div>
          </div>
          <table className="w-full h-96 overflow-auto  border p-2">
            <thead>
              <tr className="bg-gray-200">
                <th>id</th>
                <th>title</th>
                <th>image</th>
                <th>Sku</th>
                <th>AffiliateLink</th>
              </tr>
            </thead>
            <tbody>
              {products.map((el, index) => (
                <tr key={el.id} className="h-12 px-2">
                  {Object.entries(el).map(([k, v]) => {
                    if (k === "description") return null;
                    if (k === "imageUrl") {
                      return (
                        <td key={k}>
                          <img src={v} className="h-20" alt="noimage" />
                        </td>
                      );
                    } else return <td key={k}> {v} </td>;
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <div className="flex flex-col items-center">
          <textarea
            className=" my-4 h-96 w-full rounded border border-black p-3 bg-gray-100"
            placeholder="paste products.json text here..."
            ref={textArea}
          ></textarea>
          <button
            onClick={filterProduct}
            className=" px-4 py-3 bg-green-400 text-white"
          >
            {loading ? "Processing..." : "Filter Now"}
          </button>
        </div>
      )}
    </div>
  );
}

export default FilterShopifyProduct;
