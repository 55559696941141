import AWN from "awesome-notifications";
import axios from "axios";
import { nanoid } from "nanoid";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { firebasefb, storage } from "./firebaseconfig";
import { useStateValue } from "./StateProvider";
import Goback from "./utils/Goback";
import urls from "./utils/urls";

function Createproducts() {
  const [productimage, setimage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [loading, setloading] = useState(false);
  const [categoryList, setcategory] = useState([]);
  const [selected, setselected] = useState(0);
  const [id, setid] = useState(nanoid(8));
  const [brandlist, setbrandlist] = useState([]);
  const [{ user }, dispatch] = useStateValue();

  console.log(user);

  let descref = useRef();
  let skuRef = useRef();
  let productname = useRef();
  let category = useRef();
  let subcategory = useRef();
  let brandname = useRef();
  let afflinkRef = useRef();

  const fetchBrandByCategory = async (catId) => {
    const response = await axios(urls.admin + "getBrandsByCategory/" + catId, {
      method: "GET",
      headers: { Authorization: urls.adminAuth },
    });

    console.log(response);
    if (response.status === 200) {
      setbrandlist(response.data);
    }
  };

  useEffect(() => {
    firebasefb
      .collection("category")
      .get()
      .then((res) => {
        var a = [];
        res.docs.forEach((e) => {
          a.push(e.data());
        });
        setcategory(a);
      });
  }, []);

  const imageupload = (e) => {
    if (loading) return;
    setloading(true);
    var image = e.target.files[0];
    if (!image) {
      new AWN().alert("no image file selected", {
        position: "bottom-right",
      });
      setloading(false);
      return;
    }
    var storageRef = storage.ref();
    storageRef
      .child(`product/${id}`)
      .put(image)
      .then((snapshot) => {
        snapshot.ref
          .getDownloadURL()
          .then(function (downloadURL) {
            setimage(downloadURL);
            setloading(false);
          })
          .catch((err) => {
            new AWN().alert(e.message, {
              position: "bottom-right",
            });
            setloading(false);
          });
      });
  };
  const coverImageUpload = (e) => {
    if (loading) return;
    setloading(true);
    var image = e.target.files[0];
    if (!image) {
      new AWN().alert("no image file selected", {
        position: "bottom-right",
      });
      setloading(false);
      return;
    }
    var storageRef = storage.ref();
    storageRef
      .child(`product/${id}coverimage`)
      .put(image)
      .then((snapshot) => {
        snapshot.ref
          .getDownloadURL()
          .then(function (downloadURL) {
            setCoverImage(downloadURL);
            setloading(false);
          })
          .catch((err) => {
            new AWN().alert(e.message, {
              position: "bottom-right",
            });
            setloading(false);
          });
      });
  };

  const finalSubmit = () => {
    let attr;
    try {
      attr = categoryList.find((cat) => cat.id === selected).images;
      attr = attr.find((sc) => sc.name === subcategory.current.value);
      if (!attr) throw new Error("No attributes found for this subcategory.");
    } catch (error) {
      new AWN().alert(error);
      return;
    }
    attr = attr.attributes;
    console.log(attr);
    if (!attr) {
      new AWN().warning("No attributes found for this subcategory.");
      return;
    }

    var filterName = productname.current.value.toLowerCase().split(" ");

    if (productname.current.value.length < 1) {
      new AWN().alert("product name should be more than 1 letter");
      return;
    }
    if (descref.current.value.length < 100) {
      new AWN().alert("description should be more than 100 letter");
      return;
    }
    if (!selected) {
      new AWN().alert("Please select category.");
      return;
    }

    if (!subcategory.current.value) {
      new AWN().alert("Please select sub - category.");
      return;
    }

    if (!coverImage) {
      new AWN().alert("Please upload cover Image");
      return;
    }

    if (!productimage) {
      new AWN().alert("Please upload Product Image");
      return;
    }

    let bName;
    let brandId;
    if (user.role === "brandAdmin") {
      bName = user.brandName;
      brandId = user.brandId;
    } else {
      bName = brandlist.find((ele) => ele.id === brandname.current.value).name;
      brandId = brandname.current.value.trim();
    }

    if (!bName) {
      new AWN().alert("No brand found please deselect and select again.");
      return;
    }

    if (!brandId) {
      new AWN().alert("No brand Id found please deselect and select again.");
      return;
    }

    var brnd = {
      id: `pro_${id}`,
      status: false,
      flicks: 0,
      reviews: 0,
      attributes: attr,
      rating: 3,
      name: productname.current.value,
      description: descref.current.value,
      catId: selected,
      parentName: bName,
      affiliateLink: afflinkRef.current.value ?? null,
      subcategory: subcategory.current.value,
      coverImage: coverImage,
      parent: brandId,
      tag: `@pro_${id}`,
      createdAt: Date.now(),
      addedBy: user.role === "brandAdmin" ? user.brandName : "admin",
      imageUrl: productimage,
      sku: skuRef.current.value ?? null,
    };

    let notifier = new AWN();
    let onOk = () => {
      new AWN().async(
        axios(urls.admin + "createProduct", {
          method: "PUT", // *GET, POST, PUT, DELETE, etc.
          headers: {
            "Content-Type": "application/json",
            Authorization: urls.adminAuth,
          },
          data: brnd,
        }),
        (resp) => {
          let d = resp.data;
          if (d.success) {
            new AWN().success("product created SuccessFully");
          } else {
            new AWN().warning(d.err);
          }
        },
        (err) => {
          new AWN().warning(err.toString());
        }
      );
    };

    let onCancel = () => {
      notifier.info("You pressed Cancel");
    };
    notifier.confirm("Are you sure?", onOk, onCancel, {
      labels: {
        confirm: "Dangerous action",
      },
    });
  };

  if (loading)
    return (
      <div className="h-screen flex items-center justify-center">
        <span className="fas fa-spinner animate-spin"></span>
      </div>
    );
  else
    return (
      <div className=" min-h-screen p-10 bg-gray-100">
        <div className="bg-white shadow-md h-24">
          <h1 className="font-medium text-3xl flex flex-grow-0 pt-5 pl-8 ">
            <Goback />
            <span className="pt-2"> &nbsp; Create New product</span>
          </h1>
        </div>

        <div className="mt-10">
          <div className="  p-8 flex flex-col gap-y-8">
            <div className="grid grid-flow-col grid-cols-2 grid-rows-1 gap-5 ">
              <div className="grid grid-flow-col m-2">
                {productimage ? (
                  <div className=" w-64 h-64 overflow-hidden bg-red-200">
                    <img src={productimage} alt="" className="h-64" />
                  </div>
                ) : (
                  <div className="flex items-start justify-center">
                    <label htmlFor="productimage">
                      <div className="border  rounded text-center w-64 h-64  justify-center items-center cursor-pointer bg-white ">
                        <i className="fas fa-plus mt-24 fa-blue  text-lg "></i>

                        <p className="text-sm text-center  py-24">
                          Upload product image here
                        </p>
                      </div>
                    </label>

                    <input
                      type="file"
                      accept="image/*"
                      id="productimage"
                      className="hidden"
                      onChange={(e) => imageupload(e)}
                    />
                  </div>
                )}
              </div>
              <div className="grid grid-flow-col m-2">
                {coverImage ? (
                  <div className=" w-64 h-64 overflow-hiddfen bg-red-200">
                    <img src={coverImage} alt="" className="h-64" />
                  </div>
                ) : (
                  <div className="flex items-start justify-center">
                    <label htmlFor="coverimage">
                      <div className="border  rounded text-center w-64 h-64  justify-center items-center cursor-pointer bg-white">
                        <i className="fas fa-plus mt-24 fa-blue  text-lg "></i>

                        <p className="text-sm text-center  py-24">
                          Upload Cover Image here
                        </p>
                      </div>
                    </label>

                    <input
                      type="file"
                      accept="image/*"
                      id="coverimage"
                      className="hidden"
                      onChange={(e) => coverImageUpload(e)}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="">
              <p className=" pb-2 text-lg font-medium">
                Name <span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                ref={productname}
                placeholder="Name of product"
                className="h-12 w-full px-5  text-sm tracking-wide appearance-none  border  focus:border-opacity-50"
              />
            </div>

            <div className="">
              <p className=" pb-2 text-lg font-medium">
                Description <span className="text-red-500">*</span>
              </p>

              <textarea
                ref={descref}
                className="h-40 w-full p-5  text-sm tracking-wide appearance-none focus:outline-none outline-none border  focus:border-opacity-50"
                type="text"
                placeholder="Description of product"
              />
            </div>
            <div className="grid grid-flow-col grid-cols-2 grid-rows-1 gap-5 ">
              <div className="">
                <p className=" pb-2 text-lg font-medium">
                  Category <span className="text-red-500">*</span>
                </p>
                <select
                  name=""
                  id=""
                  className="h-12 w-full px-5  text-sm tracking-wide appearance-none focus:outline-none outline-none border  focus:border-opacity-50"
                  ref={category}
                  onChange={(e) => {
                    setselected(e.target.value);
                    if (user.role === "brandAdmin") return;
                    fetchBrandByCategory(e.target.value);
                  }}
                >
                  {categoryList.map((e, index) => (
                    <option value={e.id} key={index}>
                      {e.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="">
                <p className=" pb-2 text-lg font-medium">
                  Sub-Category
                  <span className="text-red-500">*</span>
                </p>

                <select
                  name=""
                  id=""
                  ref={subcategory}
                  className="h-12 w-full px-5  text-sm tracking-wide appearance-none focus:outline-none outline-none border  focus:border-opacity-50"
                >
                  {categoryList
                    .find((ele) => ele.id === selected)
                    ?.images?.map((e) => (
                      <option value={e.name}>{e.name}</option>
                    ))}
                </select>
              </div>
            </div>
            <div className={user.role === "brandAdmin" ? "hidden" : "block"}>
              <p className=" pb-2 text-lg font-medium">
                Parent (Brand)
                <span className="text-red-500">*</span>
              </p>
              <select
                type="text"
                ref={brandname}
                placeholder="Name of product"
                className="h-12 w-full px-5  text-sm tracking-wide appearance-none focus:outline-none outline-none border  focus:border-opacity-50"
              >
                {brandlist?.map((brand, index) => (
                  <option key={index} value={brand.id}>
                    {brand.name}
                  </option>
                ))}
              </select>
            </div>
            <div className={user?.role === "brandAdmin" ? "hidden" : "block"}>
              <p className=" pb-2 text-lg font-medium">
                AffiliateLink <span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                ref={afflinkRef}
                placeholder="Affiliate Link"
                className="h-12 w-full px-5  text-sm tracking-wide appearance-none  border  focus:border-opacity-50"
              />
            </div>
            <div className={user?.role === "brandAdmin" ? "hidden" : "block"}>
              <p className=" pb-2 text-lg font-medium">
                SKU <span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                ref={skuRef}
                placeholder="SKU"
                className="h-12 w-full px-5  text-sm tracking-wide appearance-none  border  focus:border-opacity-50"
              />
            </div>
            <div className="">
              <button
                className="w-48 h-12 text-white bg-pink-500 hover:bg-pink-700  "
                onClick={() => finalSubmit()}
              >
                Create Product
              </button>
            </div>
          </div>
        </div>
        <div className="h-1"></div>
      </div>
    );
}

export default Createproducts;
