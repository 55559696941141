import { DataGrid } from "@material-ui/data-grid";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { firebasefb } from "./firebaseconfig";
import { data } from "./utils/Res";
import AWN from "awesome-notifications";
function LeaderBoard() {
    const [brands, setbrands] = useState([]);
    const history = useHistory();
    const [loading, setloading] = useState(true);
    const [searchResult, setsearchResult] = useState([]);
    const [query, setquery] = useState("");

    useEffect(() => {
        firebasefb
            .collection("brand")
            .where("brandFace", "!=", null)
            .get()
            .then((res) => {
                var a = [];
                res.docs.forEach((d) => {
                    a.push(d.data());
                });
                setbrands(a);
                setloading(false);
            });
    }, []);

    const columns = [
        { field: "name", headerName: "name", width: 190 },
        { field: "id", headerName: "Brand Id", width: 150 },
        { field: "brandFace", headerName: "Brand Face ", width: 300 },
        {
            field: "actions",
            headerName: "Actions",
            width: 200,
            // eslint-disable-next-line react/display-name
            renderCell: ({ row }) => (
                <div>
                    <button
                        className={data.greenButton}
                        onClick={() =>
                            history.push(`/home/viewBrand/${row.id}`)
                        }
                    >
                        View
                    </button>
                    <button
                        className={data.yellowButton}
                        onClick={() =>
                            history.push(`/home/updateBrand/${row.id}`)
                        }
                    >
                        Update
                    </button>
                </div>
            ),
        },
    ];

    if (loading)
        return (
            <div className="h-screen flex items-center justify-center">
                <span className="fas fa-spinner animate-spin fa-3x"></span>
            </div>
        );
    return (
        <div className="bg-background min-h-screen py-6">
            <div className=" bg-white flex border rounded justify-between items-end m-10 h-auto  shadow-lg">
                <div className="">
                    <p className="text-3xl font-medium p-4">Total Brands</p>
                </div>
                <div className="pr-4">
                    <p className="text-md font-Regular text-sidetext text-right ">
                        Brands
                    </p>

                    <p className="text-3xl font-medium flex flex-grow-0 ">
                        {brands.length}
                    </p>
                </div>
            </div>

            <div className="bg-white m-10">
                <div className="flex h-14 mt-8 items-center flex-wrap">
                    <div className="flex-grow flex items-center">
                        <i className="fas fa-search p-2 text-gray-700 mt-2"></i>
                        <input
                            className="flex-grow h-7 outline-none text-gray-700"
                            type="text"
                            placeholder="Search by Name, Category, Brands"
                            onChange={(e) => {
                                setquery(e.target.value);
                                setsearchResult(
                                    brands.filter((row) =>
                                        row.name
                                            .toLowerCase()
                                            .includes(
                                                e.target.value.toLowerCase()
                                            )
                                    )
                                );
                            }}
                        />
                    </div>
                    <div className="duration-200">
                        <button
                            onClick={() => {
                                history.push("/home/CreateBrand");
                            }}
                            className=" bg-green-500 hover:bg-green-700 w-64 h-14  focus:outline-none text-white"
                        >
                            <span className="text-center">
                                <i className="fas fa-plus "></i> Create New
                                Brand
                            </span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="m-10 ">
                <h1 className="text-3xl font-semibold py-3 border-b">Brands</h1>
                <div className=" bg-white">
                    {query.length === 0 ? (
                        <DataGrid
                            autoHeight={true}
                            rows={brands}
                            columns={columns}
                            pageSize={20}
                        />
                    ) : (
                        <DataGrid
                            autoHeight={true}
                            rows={searchResult}
                            columns={columns}
                            pageSize={20}
                        />
                    )}
                </div>
            </div>
            <div className="h-2"></div>
        </div>
    );
}

export default LeaderBoard;
