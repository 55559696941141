import { useState, useEffect } from "react";
import CustomDataGrid from "./utils/CustomDataGrid";

import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import AWN from "awesome-notifications";
import { useStateValue } from "./StateProvider";
import urls from "./utils/urls";
import axios from "axios";

function Reviews() {
  const router = useHistory();
  const [{ countAdmin }, dispatch] = useStateValue();
  const [reviews, setreviews] = useState([]);
  const [loading, setloading] = useState(false);
  let { path, url } = useRouteMatch();
  const location = useLocation();

  // console.log(path);
  const active =
    " font-medium bg-tab_active_bg text-tab_active_color  py-3 w-full text-center";
  const passive =
    " bg-tab_passive_bg text-tab_passive_color  py-3  w-full text-center border-r border-black border-opacity-5";

  const fetchReviews = async (page = 0, refresh = false) => {
    if (page === 0 && !refresh) {
      let chart = sessionStorage.getItem("reviews");

      if (chart) {
        chart = JSON.parse(chart);
        setreviews(chart);
        setloading(false);
        return;
      }
    }

    console.log("fetching from server all users");
    const response = await axios(urls.admin + "getReviews/" + page, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
        Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
      },
    });

    if (response.status === 200) {
      let res = response.data;
      console.log(res);
      if (page !== 0) {
        let eres = sessionStorage.getItem("reviews");
        eres = JSON.parse(eres);
        res = eres.concat(res);
      }
      sessionStorage.setItem("reviews", JSON.stringify(res));
      setreviews(res);
      setloading(false);
    }
  };

  useEffect(() => {
    setloading(true);
    fetchReviews();
  }, []);

  const playReview = async (url) => {
    const AWN = (await import("awesome-notifications")).default;

    new AWN().modal(`<video width="320" height="240" controls>
        <source src=${url} type="video/mp4">
        
      Your browser does not support the video tag.
      </video>`);
    return;
  };

  const loadmore = () => {
    fetchReviews(Math.floor(reviews.length / 50));
    new AWN().success("loaded More");
  };

  const refreshPage = async () => {
    await fetchReviews(0, true);
    new AWN().success("page refreshed");
  };

  const columns = [
    { field: "name", headerName: "Title", width: 100 },
    { field: "user", headerName: "UploadedBy", width: 100 },
    { field: "product", headerName: "Product", width: 100 },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (row) =>
        row.rejected ? (
          <p className=" text-red-500 font-bold">Rejected</p>
        ) : row.status ? (
          <p className=" text-green-500 font-bold">Published</p>
        ) : (
          <p className=" text-yellow-500 font-bold">Pending</p>
        ),
    },
    {
      field: "dateAdded",
      headerName: "Date",
      renderCell: (row) => {
        return <div className="">{new Date(row.dateAdded).toDateString()}</div>;
      },
    },
    // { field: "description", headerName: "Description", width: 250 },

    { field: "views", headerName: "Views", width: 100 },
    { field: "rating", headerName: "Rating", width: 200 },
  ];

  const actionNode = (row, close) => {
    return (
      <div className=" bg-white shadow-sm z-50 w-48 absolute border right-24 rounded-md -mt-8">
        <button
          onClick={() => {
            if (close) close(null);
          }}
          className="h-5 w-5  bg-white shadow-sm rounded-full border flex items-center justify-center right-0 absolute -m-2 cursor-pointer"
        >
          <img
            src={require("./images/icons/Close.svg").default}
            alt=""
            className="transform"
          />
        </button>
        <div className="w-full">
          <div
            className="h-10 w-full font-medium flex items-center px-4 bg-white hover:bg-purple-100 cursor-pointer rounded select-none"
            onClick={() => {
              dispatch({ type: "SET_ITEM", item: row });
              router.push(`/home/reviews/Viewreview/${row.id}`);
            }}
          >
            View
          </div>
          <div
            className="h-10 w-full font-medium flex items-center px-4 bg-white hover:bg-purple-100 cursor-pointer rounded"
            onClick={() => {
              navigator.clipboard.writeText("app.myrevue.com/" + row.id);
              new AWN().info("copied to clipboard");
            }}
          >
            Copy Link
          </div>
          <div
            className="h-10 w-full font-medium flex items-center px-4 bg-white hover:bg-purple-100 cursor-pointer rounded text-green-500"
            onClick={() => router.push(`/home/reviews/Viewreview/${row.id}`)}
          >
            Update
          </div>
          <div
            className="h-10 w-full font-medium flex items-center px-4 bg-white hover:bg-purple-100 cursor-pointer rounded text-blue-500"
            onClick={() => playReview(row.reviewUrl)}
          >
            Play Media
          </div>

          <div
            className="h-10 w-full font-medium flex items-center px-4 bg-white hover:bg-purple-100 cursor-pointer rounded text-red-500"
            onClick={() => {
              if (close) close(null);
            }}
          >
            Close
          </div>
        </div>
      </div>
    );
  };

  return loading ? (
    <div className="h-screen flex items-center justify-center">
      <span className="fas fa-spinner animate-spin"></span>
    </div>
  ) : (
    <div className=" min-h-screen bg-panel_bg w-full font-Manrope">
      <div className="flex justify-between items-center">
        <p className="text-3xl font-medium">Total Reviews:</p>
        <p className="text-3xl font-semibold">
          {countAdmin.reviews}
          <button
            className="bg-purple-500 text-white h-12 w-24 rounded-md shadow-md text-sm ml-5"
            onClick={refreshPage}
          >
            <i className="fas fa fa-refresh"></i>
          </button>
        </p>
      </div>

      <div className="">
        <div className="py-4 flex justify-evenly ">
          <Link
            to={`${url}/allReviews`}
            className={
              location.pathname.includes("allReviews") ||
              location.pathname === url
                ? active
                : passive
            }
          >
            All Reviews
          </Link>
          <Link
            to={`${url}/pending`}
            className={location.pathname.includes("pending") ? active : passive}
          >
            Pending
          </Link>
          <Link
            to={`${url}/published`}
            className={
              location.pathname.includes("published") ? active : passive
            }
          >
            Published
          </Link>
          <Link
            to={`${url}/rejected`}
            className={
              location.pathname.includes("rejected") ? active : passive
            }
          >
            Rejected
          </Link>
        </div>
      </div>
      <div className="pb-20 w-full">
        <div className="w-full  bg-white border shadow-lg rounded-md overflow-x-auto">
          <Switch>
            <Route exact path={path}>
              <CustomDataGrid
                rows={reviews}
                columns={columns}
                actionNode={actionNode}
                loadMore={loadmore}
              />
            </Route>

            <Route path={`${path}/allReviews`}>
              <CustomDataGrid
                rows={reviews}
                columns={columns}
                actionNode={actionNode}
                loadMore={loadmore}
              />
            </Route>
            <Route path={`${path}/pending`}>
              <CustomDataGrid
                rows={reviews.filter((a) => !a?.status)}
                columns={columns}
                actionNode={actionNode}
              />
            </Route>
            <Route path={`${path}/published`}>
              <CustomDataGrid
                rows={reviews.filter((a) => a?.status)}
                columns={columns}
                actionNode={actionNode}
              />
            </Route>
            <Route path={`${path}/rejected`}>
              <CustomDataGrid
                rows={reviews.filter((a) => a?.rejected)}
                columns={columns}
                actionNode={actionNode}
              />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default Reviews;
