import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { firebasefb } from "../firebaseconfig";
import firebase from "firebase";
import AWN from "awesome-notifications";
import axios from "axios";
import { toBeEmpty } from "@testing-library/jest-dom/dist/matchers";
function ViewWebReview() {
  const { reviewid } = useParams();
  const [tags, setTags] = useState([]);
  const router = useHistory();
  const [review, setreview] = useState(null);
  const [play, setplay] = useState(false);
  const [loading, setloading] = useState(true);
  const [disabled, setdisabled] = useState(false);
  const [editField, setEditField] = useState(null);
  const [selectedTags, setselectedTags] = useState([]);

  useEffect(() => {
    setloading(true);
    firebasefb
      .collection("schedulerTasks")
      .doc(reviewid)
      .get()
      .then((res) => {
        let temp = res.data();
        let rev = temp.review;
        rev.processed = temp.processed;
        setreview(rev);
        setloading(false);
      });

    setdisabled(true);
  }, [reviewid]);

  useEffect(() => {
    if (tags.length === 0 && review?.subcategory) {
      firebasefb
        .collection("hashtags")
        .where("subcategory", "==", review.subcategory)
        .get()
        .then((res) => {
          setTags(res.docs.map((r) => r.data()));
          setselectedTags(review.tags || []);
        });
    }
  }, [review, tags.length]);

  const deletethis = () => {
    let notifier = new AWN();
    let onOk = () => {
      firebasefb
        .collection("review")
        .doc(reviewid)
        .delete()
        .then((res) => {
          new AWN().success("successfully Deleted");
          firebasefb
            .collection("deleteItem")
            .doc(reviewid)
            .set(review)
            .then(() => {
              new AWN().success("deleted and added in recycle bin");
              router.goBack();
              return;
            });
        })
        .catch((e) => {
          new AWN().error("something went wrong");
        });
    };
    let onCancel = () => {
      notifier.info("You pressed Cancel");
    };
    notifier.confirm("Are you sure?", onOk, onCancel, {
      labels: {
        confirm: "Dangerous action",
      },
    });
  };

  const publish = () => {
    firebasefb
      .collection("review")
      .add(review)
      .then(() => {
        new AWN().success("this web review has been published.");
      });
    firebasefb
      .collection("userActivity")
      .doc(review.uploadedBy.id)
      .update({
        activity: firebase.firestore.FieldValue.arrayUnion({
          by: "admin",
          to: `${review.name} verified and posted successfully. Thank you!! `,
          time: firebase.firestore.Timestamp.now(),
          type: "notification",
        }),
      });
  };

  const rejectReview = async (review) => {
    var user = await firebasefb
      .collection("user")
      .doc(review.uploadedBy.id)
      .get();
    if (!user) return alert("no user found something went wrong try again");
    if (!user.token) {
      alert(
        "user have no message token associated so no notification will be sent"
      );
    } else {
      const token = user.token;

      const message = {
        notification: {
          title: "uploaded review rejected",
          body: `review named ${review.name} rejected due to `,
        },
      };
      const params = JSON.stringify({ message: message, token: token });
      const headers = {
        "content-type": "application/json",
      };
      var response = await axios.post(
        "https://restapi-zscu3untuq-em.a.run.app/notification",
        params,
        { headers: headers }
      );
      console.log(response);
      if (response.status === 200 && response.data.success) {
        new AWN().success("notification sent");
      } else {
        new AWN().alert("something went wrong  try again");
        return;
      }
    }
    await firebasefb
      .collection("review")
      .doc(review.id)
      .update({ rejected: true })
      .then(() => {
        setreview({ ...review, rejected: true });
        new AWN().success("review rejected");
      });
    await firebasefb
      .collection("userActivity")
      .doc(review.uploadedBy.id)
      .update({
        activity: firebase.firestore.FieldValue.arrayUnion({
          time: firebase.firestore.Timestamp.now(),
          to: review.name,
          by: "admin",
          type: "rejected",
        }),
      });
  };

  const addTags = async (tag) => {
    let a = [...selectedTags, tag];
    await firebasefb.doc("review/" + reviewid).update({ tags: a });
    setselectedTags(a);
  };
  const inputfield =
    "appearance-none outline-none border-b text-nav_active_bg font-medium text-sm tracking-wide pr-10 w-full h-10";

  if (loading)
    return (
      <div className="flex justify-center items-center h-screen">
        <span className="fas fa-spinner animate-spin"></span>
      </div>
    );
  else
    return (
      <>
        <div className="bg-panel_bg_secondary min-h-screen py-10 px-6 font-Manrope ">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-3">
              <i
                className="fas fa-arrow-left cursor-pointer px-2"
                onClick={() => router.goBack()}
              ></i>
              <p className="text-3x">View Web Review</p>
            </div>
          </div>
          <div className="bg-white shadow-sm p-5 rounded mt-5">
            <div className="w-full flex justify-between items-center">
              <div className="flex items-center gap-x-4">
                <div className="">
                  {review?.productImage ? (
                    <img
                      src={review.productImage}
                      alt=""
                      className="h-14 w-14 rounded-full"
                      draggable={false}
                    />
                  ) : (
                    <div className="bg-inputbg h-14 w-14 rounded-full text-black flex items-center justify-center text-lg">
                      {review?.parentName
                        ?.split(" ")[0]
                        .substring(0, 2)
                        .toUpperCase()}
                    </div>
                  )}
                </div>
                <div className="">
                  <p className="text-lg font-bold">{review?.parentName}</p>
                  <p className="text-sm font-medium">
                    {review?.brand || "not present"}
                  </p>
                </div>
                <div className="ml-10 space-x-5">
                  <button
                    className={
                      review?.status
                        ? "bg-green-500 px-6 py-2 text-white text-sm appearance-none outline-none rounded"
                        : "bg-red-500 px-6 py-2 text-white text-sm appearance-none outline-none rounded"
                    }
                    onClick={() => publish()}
                  >
                    {review?.status ? "Unpublish" : "Publish"}
                  </button>
                  <button className="border border-red-500 px-6 py-2 text-red-500 text-sm appearance-none outline-none rounded">
                    Reject Review
                  </button>
                </div>
              </div>

              <div className="ml-10  justify-self-end  space-x-3">
                <p className="shadow-md p-2 rounded-md bg-green-400 text-white">
                  {review.processed ? "Ready to Publish" : "Not yet procesed"}
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 grid-flow-col mt-10">
              <div className="">
                <div className="bg-white rounded-lg w-full pb-5 shadow-sm">
                  <div className="border-b  px-5">
                    <p className="border-b border-nav_active_bg w-20 py-3">
                      &nbsp; &nbsp; Details
                    </p>
                  </div>

                  <div className="py-5 grid grid-flow-row grid-cols-2 px-5 gap-x-5">
                    <div className="py-2">
                      <p className="">Title</p>
                      <div className="relative ">
                        <input
                          type="text"
                          className={inputfield}
                          placeholder="Title"
                          defaultValue={review?.name}
                          disabled={disabled}
                        />
                      </div>
                    </div>
                    <div className="py-2">
                      <p className="">Description</p>
                      <div className="relative ">
                        <input
                          type="text"
                          className={inputfield}
                          placeholder="Description"
                          defaultValue={review?.description}
                          disabled={disabled}
                        />
                      </div>
                    </div>
                    <div className="py-2">
                      <p className="">Parent</p>
                      <div className="relative ">
                        <input
                          type="text"
                          className={inputfield}
                          placeholder="Parent Name"
                          defaultValue={review?.parentName}
                          disabled={disabled}
                        />
                      </div>
                    </div>
                    <div className="py-2">
                      <p className="">Uploaded by</p>
                      <div className="relative ">
                        <input
                          type="text"
                          className={inputfield}
                          placeholder="Uploaded by"
                          defaultValue={review?.uploadedBy?.name}
                          disabled={disabled}
                        />
                      </div>
                    </div>
                    <div className="py-2">
                      <p className="">Uploaded by (ID)</p>
                      <div className="relative ">
                        <input
                          type="text"
                          className={inputfield}
                          placeholder="Belongs to id"
                          defaultValue={review?.uploadedBy?.id}
                          disabled={disabled}
                        />
                      </div>
                    </div>
                    <div className="py-2">
                      <p className="">Belongs to ID</p>
                      <div className="relative ">
                        <input
                          type="text"
                          className={inputfield}
                          placeholder="Belongs to id"
                          defaultValue={review?.uploadedBy?.name}
                          disabled={disabled}
                        />
                      </div>
                    </div>

                    <div className="py-2">
                      <p className="">Category</p>
                      <div className="relative ">
                        <p className="">{review.catId || "not present"}</p>
                      </div>
                    </div>
                    <div className="py-2">
                      <p className="">sub-Category</p>
                      <div className="relative ">
                        <p className="">
                          {review.subcategory || "not present"}
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <div className="flex flex-wrap">
                        {selectedTags.map((r, index) => (
                          <p
                            className="p-1 px-2 m-1 rounded-full bg-green-500 text-white cursor-pointer"
                            key={"selected" + index}
                          >
                            {r}
                          </p>
                        ))}
                      </div>
                      <p className="">select From here</p>
                      <div className="flex flex-wrap">
                        {tags.map((r, index) => (
                          <p
                            className="p-1 px-2 m-1 rounded-full bg-gray-200 text-gray-400 cursor-pointer"
                            key={"tag" + index}
                            onClick={() => addTags(r.hash)}
                          >
                            {r.hash}
                          </p>
                        ))}
                      </div>
                    </div>
                    <div className="py-2 col-span-2">
                      <div className="grid grid-cols-2 grid-flow-col rounded border bg-panel_bg_secondary">
                        <div className="py-3 px-5">
                          <div className="flex gap-x-3 items-center">
                            <p className="">View Count</p>
                          </div>
                          <div className="py-5">
                            <p className="text-left text-4xl font-medium text-nav_active_bg">
                              {review.views || 0}
                            </p>
                          </div>
                        </div>
                        <div className="border-l py-3 px-5">
                          <div className="flex gap-x-3 items-center">
                            <p className="">Rating</p>
                          </div>
                          <div className="py-5">
                            <p className="text-right text-4xl font-medium text-nav_active_bg">
                              {review.rating || 3}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center">
                {play ? (
                  <video
                    src={review.reviewUrl}
                    className="rounded w-64 aspect_video object-cover"
                    controls
                  ></video>
                ) : (
                  <div
                    className="w-64 aspect_video relative flex items-center justify-center cursor-pointer"
                    onClick={() => setplay(true)}
                  >
                    <img
                      src={require("../images/icons/play.png").default}
                      alt=""
                      className=" z-10 absolute"
                    />
                    <img
                      src={review.thumbnail}
                      className="w-64 rounded"
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default ViewWebReview;
