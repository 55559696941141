import { DataGrid } from "@material-ui/data-grid";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { firebasefb } from "./firebaseconfig";
import { data } from "./utils/Res";
import CustomDataGrid from "./utils/CustomDataGrid";

function FeedBack() {
  const [report, setreport] = useState([]);
  const [loading, setloading] = useState(true);
  useEffect(() => {
    firebasefb
      .collection("feedback")
      .get()
      .then((res) => {
        var a = [];
        res.docs.forEach((r, index) => a.push({ ...r.data() }));
        a = a.sort((a, b) => b.date - a.date);
        setreport(a);
        setloading(false);
      });
  }, []);

  const columns = [
    {
      field: "date",
      headerName: "date",
      renderCell: (row) => (
        <p className="">{new Date(row?.date).toLocaleDateString()}</p>
      ),
    },
    { field: "name", headerName: "Name" },
    { field: "id", headerName: "User ID" },
    {
      field: "feedback",
      headerName: "FeedBack",
      renderCell: (row) => <p className="text-black">{row?.feedback}</p>,
    },
  ];

  if (loading)
    return (
      <div className="flex h-screen justify-center items-center ">
        <span className="fas fa-spinner animate-spin"></span>
      </div>
    );
  else
    return (
      <div className="font-Manrope">
        <div className="flex justify-between items-center m-10 ">
          <p className="text-3xl font-medium">Total Feedback:</p>
          <p className="text-3xl font-semibold "> {report.length}</p>
        </div>

        <div className="m-10 ">
          <div className="h-screen bg-white shadow-lg">
            <CustomDataGrid
              rows={report}
              columns={columns}
              actionNode={() => {}}
              loadMore={() => {}}
            />
          </div>
        </div>
        <div className="h-2"></div>
      </div>
    );
}

export default FeedBack;
