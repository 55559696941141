import axios from "axios";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import urls from "../utils/urls";

function FlicksTagCount() {
  const [ads, setAds] = useState([]);
  const [current, setcurrent] = useState(0);

  const fetchFlicksTagCount = async () => {
    let chart = sessionStorage.getItem("FlicksTagCount");
    if (chart) {
      chart = JSON.parse(chart);
      setAds(chart);
      return;
    }
    console.log("fetching from server top users");
    const response = await axios(urls.admin + "getFlicksCountByTag", {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
        Authorization: urls.adminAuth,
      },
    });

    if (response.status === 200) {
      let res = response.data;
      sessionStorage.setItem("FlicksTagCount", JSON.stringify(res));
      setAds(res);
    }
  };

  if (ads.length === 0) {
    return (
      <div className="bg-white rounded border p-3 w-full my-5 h-80 shadow-md flex items-center justify-center">
        <button
          className="py-3 px-4 border rounded-md"
          onClick={fetchFlicksTagCount}
        >
          Reveal Flicks Tag Count
        </button>
      </div>
    );
  } else
    return (
      <div className="bg-white rounded border p-3 w-full shadow-md">
        <div className="flex justify-between items-center py-1 border-b">
          <p className="text-xl">Flicks Tag Count</p>
        </div>
        <div className="overflow-auto h-56">
          <ul className="">
            {ads?.map((ad, index) => (
              <li className="flex  justify-between p-2 m-1 bg-pink-300 items-center">
                <p className="">{ad._id} :</p>
                <p className="">{ad.count}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
}

export default FlicksTagCount;
