import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AreaChart, Area, XAxis, Tooltip, ResponsiveContainer } from "recharts";
import { useStateValue } from "../StateProvider";
import urls from "../utils/urls";
function ViewsChart() {
  const [{ countAdmin }] = useStateValue();
  const [viewsData, setviewsData] = useState([]);

  const months = [
    "jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const makeChart = (chart) => {
    chart = chart.map((r) => {
      let d = new Date(r.date);
      d = d.getDate() + "-" + months[d.getMonth()] + "-" + d.getFullYear();
      return { ...r, date: d, id: r.date };
    });
    chart = chart.reduce((accu, curr) => {
      let found = accu.find((r) => r.name === curr.date);
      if (found) {
        found.uv += 1;
      } else {
        accu.push({
          name: curr.date,
          id: curr.date,
          uv: 1,
          pv: 0,
          rank: curr.id,
        });
      }
      return accu;
    }, []);
    chart = chart.sort((a, b) => a.rank - b.rank);
    setviewsData(chart);
  };
  const fetchData = async () => {
    let chart = sessionStorage.getItem("viewsChart");
    if (chart) {
      chart = JSON.parse(chart);
      makeChart(chart);
      return;
    }
    console.log("fetching from server");
    const response = await fetch(urls.admin + "viewsGraphData", {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });

    if (response.status === 200) {
      let res = await response.json();
      sessionStorage.setItem("viewsChart", JSON.stringify(res));
      makeChart(res);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  console.log(viewsData);

  return (
    <div className=" h-96 flex-1 m-1 rounded-md">
      <div className="">
        <p className="text-sm font-semibold px-2 ">Total Views</p>
        <p className="text-7xl font-semibold px-2 text-green-500">
          {countAdmin?.views}
        </p>
      </div>
      <ResponsiveContainer width="98%" height="80%">
        <AreaChart width={500} height={400} data={viewsData}>
          <XAxis dataKey="name" />
          <Tooltip />
          <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ViewsChart;
