import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import AddhashTags from "../cards/AddhashTags";
import { firebasefb } from "../firebaseconfig";
import { useStateValue } from "../StateProvider";
import Goback from "../utils/Goback";

function HashTag() {
  const [tags, settags] = useState([]);
  const [deletable, setdeletable] = useState(false);
  const [save, setsave] = useState(2); // 0 - not saved ,1 - saving , 2 - saved
  const [categorized, setCategorized] = useState([]);
  const [category, setcategory] = useState([]);

  const [{hashtags},dispatch] = useStateValue();
  const router = useHistory();  

  useEffect(() => {
    let listenTags = firebasefb.collection("hashtags").onSnapshot((res) => {
      let b = [];
      let a = [];
      res.docs.forEach((r) => {
        // if (r.type === "added") {
        let d = r.data();
        if (d.category) {
          b.push(d);
        }
        if (d.trending) {
          a.push(d);
        }
        // }
      });
      console.log(b);
      setCategorized([...categorized, ...b]);
      settags([...tags, ...a]);

      dispatch({
        type: "SET_HASHTAGS",
        hashtags: a.concat(b),
      });
    });

    if (category.length === 0) {
      firebasefb
        .collection("category")
        .get()
        .then((res) => {
          let a = res.docs.map((r) => r.data());
          setcategory(a);
        });
    }

    // if(working)return;
    // if(!category ||!tags) return;
    return () => listenTags();
  }, []);

  let draggedItem;

  const saveCategories = async () => {
    setsave(1);
    for (let tag of categorized) {
      if (tag.new) {
        await firebasefb.doc("hashtags/" + tag.hash).update({
          category: tag.category,
          subcategory: tag.subcategory,
        });
      }
    }
    setsave(2);
  };

  return (
    <div className="bg-background ">
      <div className="bg-white h-24 flex justify-between">
        <h1 className="font-medium text-3xl flex flex-grow-0 pt-5 pl-8 ">
          <Goback />
          <span className="pt-2"> &nbsp; HashTags </span>
        </h1>
        <div className=" flex items-center">
          {" "}
          <button
            className="mx-2 bg-red-500 text-white p-2 hover:bg-red-700 flex  items-center "
            onClick={() => saveCategories()}
          >
            {save === 2 ? "Saved" : save === 1 ? "Saving" : "Save Before Leave"}
            {save === 1 ? <i className="mx-2" id="loading"></i> : null}
          </button>
        </div>
      </div>
      <div className="grid grid-cols-3">
        <div className="p-2 col-span-2 h-screen overflow-scroll">
          {category.map((r, index) => (
            <div
              key={"category" + index}
              className="b-2 rounded-md bg-gray-50 p-2 mx -1 my-3"
            >
              <p className="text-2xl py-3">{r.name}</p>
              <div className="grid grid-cols-2">
                {r.images.map((sub, ind) => (
                  <div
                    key={"subcategory" + sub.name}
                    className=" bg-purple-100 m-1 border border-purple-200 rounded-md"
                  >
                    <p className="border-b px-2 py-1  border-purple-300">
                      {sub.name}
                    </p>
                    <div className="h-96 overflow-scroll">
                      <div className="flex flex-wrap">
                        {categorized
                          .filter((r) => r.subcategory === sub.name)
                          .map((t, index) => (
                            <p
                              className="rounded-full px-2 py-1 m-1 cursor-pointer bg-indigo-500 text-white"
                              key={"cattag" + index}
                              draggable
                              onDragStart={() => {
                                draggedItem = t;
                              }}
                            >
                              {t.hash}
                            </p>
                          ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="">
          <div className="">
            <AddhashTags />
          </div>
          <div className="flex justify-between items-center">
          <p className="text-2xl p-2 font-semibold">Trending HashTags</p>

          <button
          className="px-2"
          onClick={() => {
            router.push("/home/manageTrendingHashtags");
          }}
        >
          <span className="fas fa-arrow-right"></span>
        </button>
          </div>
          <div className="">
            <div className=" p-1  h-screen overflow-scroll">
              <div
                className="flex flex-wrap "
                onDragEnter={() => {
                  if (!draggedItem) return;

                  draggedItem.trending = true;
                }}
                onDragLeave={async () => {
                  if (!draggedItem) return;
                  if (!draggedItem.trending) return;
                  // await firebasefb.doc("hashtags/" + draggedItem.hash).update({
                  //   trending: true,
                  // });
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                }}
                onDrop={async () => {
                  if (!draggedItem) return;
                  let exist = tags.some((a) => a.hash === draggedItem.hash);
                  if (exist) return;
                  await firebasefb.doc("hashtags/" + draggedItem.hash).update({
                    trending: true,
                  });
                  settags([...tags, draggedItem]);

                  draggedItem = null;
                }}
              >
                {tags.map((r, index) => (
                  <p
                    className="rounded-full px-2 py-1 m-1 cursor-pointer bg-green-500 text-white"
                    key={"tag" + index}
                    draggable
                    onDragStart={() => {
                      setdeletable(r);

                      console.log("drag started");
                    }}
                    onDragEnd={() => {
                      setdeletable(false);
                    }}
                  >
                    {r.hash}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {deletable ? (
        <div
          className="  p-5 rounded-md absolute bottom-10 right-10 bg-red-600"
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDrop={async () => {
            console.log("drag end", deletable);

            if (!deletable) return;
            await firebasefb.doc("hashtags/" + deletable.hash).update({
              trending: false,
            });
            settags(tags.filter((e) => e !== deletable));
          }}
        >
          <span className="fas fa-trash fa-5x text-white"></span>
        </div>
      ) : null}
    </div>
  );
}

export default HashTag;
