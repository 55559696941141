import axios from "axios";
import React from "react";
import { useState } from "react";
import urls from "../utils/urls";
import AWN from "awesome-notifications";
import { useRef } from "react";

function UpdateProductFlicks({ product, updateFun }) {
  const [edit, setEdit] = useState(false);
  const [result, setResult] = useState([]);
  const [searching, setSearching] = useState(false);

  const upateProd = (prod) => {
    new AWN().confirm(
      "Update flicks with this parent Id: " + prod.id,
      () => {
        updateFun(prod.id);
      },
      () => {
        new AWN().warning("updation cancelled");
      }
    );
  };

  let queryRef = useRef();

  const searchProd = async () => {
    let query = queryRef.current.value?.trim();
    if (!query) {
      new AWN().alert("something went wrong. Try again later");
      return;
    }
    setSearching(true);
    let response = await axios(urls.admin + "searchProducts", {
      method: "PUT",
      headers: {
        Authorization: urls.adminAuth,
        "Content-Type": "application/json",
      },
      data: { query: query },
    });

    if (response.status === 200) {
      setResult(response.data);
      setSearching(false);
    } else {
      new AWN().alert("something went wrong. Try again later");
    }
  };
  const searchProdById = async () => {
    let query = queryRef.current.value?.trim();
    if (!query) {
      new AWN().alert("something went wrong. Try again later");
      return;
    }
    setSearching(true);
    let response = await axios(urls.admin + "searchProductsById", {
      method: "PUT",
      headers: {
        Authorization: urls.adminAuth,
        "Content-Type": "application/json",
      },
      data: { query: query },
    });

    if (response.status === 200) {
      console.log(response.data);
      setResult(response.data);
      setSearching(false);
    } else {
      new AWN().alert("something went wrong. Try again later");
    }
  };
  const searchProdBySKU = async () => {
    let query = queryRef.current.value?.trim();
    if (!query) {
      new AWN().alert("something went wrong. Try again later");
      return;
    }
    setSearching(true);
    let response = await axios(urls.admin + "searchProductsBySKU", {
      method: "PUT",
      headers: {
        Authorization: urls.adminAuth,
        "Content-Type": "application/json",
      },
      data: { query: query },
    });

    if (response.status === 200) {
      console.log(response.data);
      setResult(response.data);
      setSearching(false);
    } else {
      new AWN().alert("something went wrong. Try again later");
    }
  };

  if (edit)
    return (
      <div className="relative">
        <div
          style={{ width: "700px" }}
          className="absolute z-20 h-96 rounded shadow-md bg-white p-2"
        >
          <p className=" border-b py-1 w-full text-right flex justify-between items-center">
            <span> Update Product</span>
            <i
              className="fas fa fa-close cursor-pointer hover:scale-110 transform duration-75"
              onClick={() => setEdit(false)}
            ></i>
          </p>
          <div className="flex gap-1 text-white">
            <input
              type="text"
              ref={queryRef}
              placeholder="search by text, id, SKU here............."
              className="text-black appearance-none h-10 border flex-1 rounded px-2"
            />
            <button className="bg-green-500 rounded p-1" onClick={searchProd}>
              Search
            </button>
            <button
              className="bg-blue-500 rounded p-1"
              onClick={searchProdById}
            >
              By Id
            </button>
            <button
              className="bg-purple-500 rounded p-1"
              onClick={searchProdBySKU}
            >
              By SKU
            </button>
          </div>
          <div className="overflow-y-scroll h-72 ">
            {searching ? "searching......." : null}
            {result.length === 0 ? (
              "Empty Result"
            ) : (
              <ul className="">
                {result.map((r) => (
                  <li
                    key={r.id}
                    className="hover:bg-purple-100 cursor-pointer transform duration-105 flex gap-3 items-center h-14 border m-1 rounded-sm px-1 shadow-sm "
                  >
                    <img
                      src={r.imageUrl}
                      alt=""
                      className="h-10 w-10 object-cover rounded-full"
                    />
                    <div
                      className="flex items-start flex-col justify-center"
                      onClick={() => upateProd(r)}
                    >
                      <span className=""> {r.name}</span>
                      <span className="text-xs text-gray-500">{r.id} </span>
                      <span className="text-xs text-gray-500">{r.sku}</span>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    );
  else
    return (
      <div className="flex items-center gap-3 relative">
        <img
          src={product.imageUrl}
          className="h-20 w-20 rounded-full"
          alt="noimage"
        />
        <p className="flex-1 flex flex-col items-start justify-center">
          <span className="text-xl font-semibold">{product.name}</span>
          <span>{product.id}</span>
          <span>{product.sku}</span>
        </p>
        <i
          onClick={() => {
            setEdit(true);
          }}
          className="fas fa fa-pencil hover:scale-110 transform duration-75 cursor-pointer"
        ></i>
      </div>
    );
}

export default UpdateProductFlicks;
