import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import PushNotification from "./PushNotification";
import CustomDataGrid from "./utils/CustomDataGrid";
import { useStateValue } from "./StateProvider";
import urls from "./utils/urls";
import AWN from "awesome-notifications";
import ExportCollection from "./components/ExportCollection";

function Users() {
  const history = useHistory();
  const [{ countAdmin }, dispatch] = useStateValue();
  const [loading, setloading] = useState(false);
  const [user, setuser] = useState([]);
  const [showPush, setshowPush] = useState(false);
  const [curentRow, setcurentRow] = useState(null);
  const [searchResult, setsearchResult] = useState([]);
  const [searchMode, setSearcMode] = useState(false);

  let queryref = useRef();

  const fetchUsers = async (page = 0, refresh = false) => {
    if (page === 0 && !refresh) {
      let chart = sessionStorage.getItem("users");
      if (chart) {
        chart = JSON.parse(chart);
        setuser(chart);
        setloading(false);
        return;
      }
    }
    console.log("fetching from server all users");
    const response = await fetch(urls.admin + "getUsers/" + page, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });

    if (response.status === 200) {
      let res = await response.json();
      if (page !== 0) {
        let eres = sessionStorage.getItem("users");
        eres = JSON.parse(eres);
        res = eres.concat(res);
      }
      sessionStorage.setItem("users", JSON.stringify(res));
      setuser(res);
      setloading(false);
    }
  };

  useEffect(() => {
    setloading(true);
    fetchUsers();
  }, []);

  const searchNow = async (e) => {
    e.preventDefault();
    setSearcMode(true);
    const response = await fetch(urls.admin + "searchUsers", {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
      },
      body: JSON.stringify({ query: queryref.current.value }),
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });

    if (response.status === 200) {
      let res = await response.json();
      setsearchResult(res);
    }
  };
  console.log(searchResult);

  const columns = [
    { field: "name", headerName: "Name" },
    { field: "email", headerName: "Email ID" },
    {
      field: "name",
      headerName: "Profile Photo",
      renderCell: (row) => (
        <img
          src={
            row.images
              ? row.images.xs
              : row.imageUrl ?? "https://ui-avatars.com/api/?name=" + row.name
          }
          className="h-10 w-10 rounded-full transform hover:scale-150 duration-75 "
          alt=""
        />
      ),
    },
    { field: "id", headerName: "User Id" },
    { field: "phone", headerName: "Phone number" },
    {
      field: "joinDate",
      headerName: "Join Date",
      renderCell: (row) => {
        const d = new Date(row.joinDate);
        return (
          <div className="">
            <p className="">{d.toLocaleString().split(" ")[0]}</p>
            <p className="">{d.toLocaleString().split(" ")[1]}</p>
          </div>
        );
      },
    },
    { field: "status", headerName: "Status" },
    { field: "reviews", headerName: "Reviews" },
    { field: "flicks", headerName: "Flicks" },
  ];

  const actionNode = (row) => (
    <div className=" bg-white shadow-sm z-50 w-48 absolute border right-24 rounded-md -mt-8">
      <div className="h-5 w-5  bg-white shadow-sm rounded-full border flex items-center justify-center right-0 absolute -m-2 cursor-pointer">
        <i className="fas fa fa-close"></i>
      </div>
      <div className="w-full">
        <button
          className="h-10 w-full font-medium flex items-center px-4 bg-white hover:bg-purple-100 cursor-pointer rounded select-none"
          onClick={() => history.push("/home/users/" + row.id)}
        >
          View
        </button>
        <button className="h-10 w-full font-medium flex items-center px-4 bg-white hover:bg-purple-100 cursor-pointer rounded">
          Make Top Reviewer
        </button>
        <button
          className="h-10 w-full font-medium flex items-center px-4 bg-white hover:bg-purple-100 cursor-pointer rounded text-green-500"
          onClick={() => {
            setcurentRow(row);
            setshowPush(true);
          }}
        >
          Push Notification
        </button>
      </div>
    </div>
  );

  const refreshPage = async () => {
    await fetchUsers(0, true);
    new AWN().success("page Refreshed");
  };

  const loadMore = () => {
    fetchUsers(Math.floor(user.length / 50) + 1);
  };

  if (loading)
    return (
      <div className="bg-panel_bg">
        <div className=" h-screen flex items-center justify-center">
          <span className="fas fa-spinner animate-spin"></span>
        </div>
      </div>
    );
  else
    return (
      <div className="bg-panel_bg min-h-screen py-6 font-Manrope">
        <div className="mt-5 px-10 border-b  border-dashed pb-5 flex items-center justify-between">
          <div className="bg-nav_bg h-10 w-96 rounded-md border flex items-center justify-between px-3">
            <p className="flex items-center ">
              Total Users:
              <span className="text-lg font-Manrope font-bold ml-3 text-nav_active_bg">
                {countAdmin.users}
              </span>
            </p>
            <p className="flex items-center gap-x-2 text-sm ml-2 text-black text-opacity-50 ">
              (&nbsp;
              <div className="bg-nav_active_bg h-3 w-3 rounded-full"></div>42
              active &nbsp;)
            </p>
          </div>
          <div className="flex ">
            <ExportCollection collection="users" />
            <button
              className="ml-3 w-52 py-1 px-8 bg-blue-500 rounded text-white flex items-center"
              onClick={() => history.push("/home/user/create")}
            >
              <span className="text-2xl">+</span>&nbsp; Create User
            </button>
          </div>
        </div>
        <div className="flex justify-between divide-x-2 divide-black divide-opacity-50 my-6  px-10">
          <div className="flex w-full">
            <form
              action=""
              className="flex items-center relative w-full"
              onSubmit={(e) => searchNow(e)}
            >
              {searchMode ? (
                <i
                  className="fas fa-times-circle fa-lg p-1 cursor-pointer absolute right-3"
                  onClick={() => {
                    setSearcMode(false);
                  }}
                ></i>
              ) : null}{" "}
              <input
                type="text"
                ref={queryref}
                className="pr-8 py-2 px-3 w-full appearance-none outline-none border"
                placeholder="Search by Email, Name, Username, User ID"
              />
            </form>
          </div>
          <div className="flex items-center">
            <button
              className="bg-purple-400 text-white h-12 rounded-md w-24 "
              onClick={refreshPage}
            >
              <i className="fas fa fa-refresh"></i>
            </button>
            {/* <button className="ml-3 w-52 py-2 px-8 bg-filterby rounded text-white">
              Filter By:
            </button>
            <button className="ml-3 w-56 py-2 px-8  rounded bg-nav_bg text-black border">
              Sort by: &nbsp;<i class="fas fa-solid fa-sort"></i>{" "}
            </button> */}
          </div>
        </div>

        <div className="mx-10  bg-white border shadow-lg rounded-md overflow-x-auto">
          {!searchMode ? (
            <CustomDataGrid
              rows={user}
              columns={columns}
              actionNode={actionNode}
              loadMore={loadMore}
            />
          ) : (
            <div className="">
              <p className="py-3 text-xl font-semibold px-2">
                Showing search result for : {queryref?.current?.value}
              </p>
              <CustomDataGrid
                rows={searchResult}
                columns={columns}
                actionNode={actionNode}
                loadMore={loadMore}
              />
            </div>
          )}
        </div>
        {showPush ? (
          <PushNotification setshow={setshowPush} row={curentRow} />
        ) : null}
      </div>
    );
}

export default Users;
