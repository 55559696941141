import axios from "axios";
import React, { useEffect, useState } from "react";
import urls from "../utils/urls";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import CustomDataGrid from "../utils/CustomDataGrid";

function BrandReviewRequest() {
  const [req, SetReq] = useState([]);
  const [loading, setLoading] = useState(true);
  const router = useHistory();
  const location = useLocation();
  let { path, url } = useRouteMatch();

  const active =
    " font-medium bg-tab_active_bg text-tab_active_color  py-3 w-full text-center";
  const passive =
    " bg-tab_passive_bg text-tab_passive_color  py-3  w-full text-center border-r border-black border-opacity-5";

  const fetchUsers = async (refresh = false) => {
    if (!refresh) {
      let chart = sessionStorage.getItem("brandReviewRequests");
      if (chart) {
        chart = JSON.parse(chart);
        SetReq(chart);
        setLoading(false);
        return;
      }
    }
    console.log("fetching from server all users");
    const response = await axios(
      urls.brandAdmin + "getAllHouseRequestsForAdmin",
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: urls.brandAdminAuth,
        },
      }
    );
    if (response.status === 200) {
      let res = response.data.data;
      sessionStorage.setItem("brandReviewRequests", JSON.stringify(res));
      SetReq(res);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  console.log(req);

  const loadMore = () => {};

  const columns = [
    {
      field: "name",
      headerName: "Name",
      className: "w-54",
      renderCell: (row) => <span>{row.brand?.name}</span>,
    },
    {
      field: "status",
      headerName: "Status",
      className: "w-54",
      renderCell: (row) => <span>{row.status}</span>,
    },
    { field: "videoType", headerName: "video Type", className: "w-54" },
    { field: "videoQuality", headerName: "Quality", className: "w-54" },
    {
      field: "dateAdded",
      headerName: "Date Added",
      className: "w-54",
      renderCell: (row) => (
        <span>{new Date(row.dateAdded).toLocaleDateString()}</span>
      ),
    },
    { field: "brandId", headerName: "Brand Id", className: "w-54" },
    { field: "videos", headerName: "Videos ", className: "w-54" },
    {
      field: "totalAmount",
      headerName: "Amount",
      className: "w-54",
      renderCell: (row) => (
        <span>
          {row.totalAmount.toLocaleString("en-US", {
            style: "currency",
            currency: "INR",
          })}
        </span>
      ),
    },
  ];
  const actionNode = (row) => (
    <div className=" bg-white shadow-sm z-50 w-48 absolute border right-24 rounded-md -mt-8">
      <button
        className="h-10 w-full font-medium flex items-center px-4 bg-white hover:bg-purple-100 cursor-pointer rounded select-none"
        onClick={() => {
          sessionStorage.setItem("temp", JSON.stringify(row));
          router.push(`/home/viewReviewRequest/${row._id}`);
        }}
      >
        View
      </button>
    </div>
  );

  if (loading)
    return (
      <div className="h-screen bg-panel_bg font-Manrope flex items-center justify-center">
        <span className="fas fa-spinner animate-spin fa-3x"></span>
      </div>
    );

  return (
    <div className="bg-background min-h-screen p-3">
      <p className="text-3xl bg-white p-3  rounded-sm shadow-md">
        Brand Review Request
      </p>
      <div className="py-4 flex justify-evenly ">
        <Link
          to={`${url}/HuntRequests`}
          className={
            location.pathname.includes("HuntRequests") ||
            location.pathname === url
              ? active
              : passive
          }
        >
          Hunt Requests
        </Link>
        <Link
          to={`${url}/huntSubmission`}
          className={
            location.pathname.includes("huntSubmission") ? active : passive
          }
        >
          hunt Submission
        </Link>
      </div>
      <div className="pb-20 w-full">
        <div className="w-full  bg-white border shadow-lg rounded-md overflow-x-auto">
          <Switch>
            <Route exact path={path}>
              <CustomDataGrid
                key="normal"
                rows={req}
                columns={columns}
                actionNode={actionNode}
                loadMore={loadMore}
              />
            </Route>

            <Route path={`${path}/HuntRequests`}>
              <CustomDataGrid
                key="normal"
                rows={req}
                columns={columns}
                actionNode={actionNode}
                loadMore={loadMore}
              />
            </Route>
            <Route path={`${path}/huntSubmission`}>
              <CustomDataGrid
                key="normal"
                rows={req}
                columns={columns}
                actionNode={actionNode}
                loadMore={loadMore}
              />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default BrandReviewRequest;
