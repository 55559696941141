import React, { useState } from "react";
import { firebasefb } from "../firebaseconfig";
import firebase from "firebase";
import AWN from "awesome-notifications";

function UpdateParentReviewPopup({ close, review }) {
  const [pros, setpros] = useState([]);
  const [selected, setselected] = useState(null);
  const [updating, setUpdating] = useState(false);

  const search = async (query) => {
    if (query.length < 2) return;
    if (selected) setselected(null);

    query = query.trim().split(" ");
    console.log(query);
    let prods = await firebasefb
      .collection("product")
      .where("filter", "array-contains-any", query)
      .limit(20)
      .get()
      .then((res) => res.docs.map((r) => r.data()));

    setpros(prods);
  };

  const updateReview = async () => {
    let prod = selected;
    if (updating) return;
    setUpdating(true);
    let collec = review.type === "flicks" ? "flicks" : "review";
    await firebasefb.doc(collec + "/" + review.id).update({
      bpsId: prod.id,
      brand: prod.parent,
      catId: prod.catId,
      parentName: prod.name,
      productImage: prod.imageUrl,
      subcategory: prod.subcategory,
    });

    await firebasefb.doc("product/" + prod.id).update({
      reviews: firebase.firestore.FieldValue.increment(1),
    });
    await firebasefb.doc("product/" + review.bpsId).update({
      reviews: firebase.firestore.FieldValue.increment(-1),
    });

    new AWN().success("action succeeded");
    setUpdating(false);
  };
  console.log(pros);

  return (
    <div>
      <div className="flex items-center justify-between">
        <p className="text-xl">Update Parent</p>
        <button>
          <i className="fa fas fa-close" onClick={() => close(null)}></i>
        </button>
      </div>
      <div className="p-1">
        <input
          type="text"
          className="h-12 appearance-none border w-full  border-indigo-300"
          placeholder="enter product name"
          onChange={(e) => search(e.target.value)}
        />
        <div className=" overflow-scroll h-96  bg-gray-200 px-1  my-1">
          {selected ? (
            <div className=" mb-2">
              <p className="">{selected.name}</p>
              <p className="">id -- {selected.id}</p>
              <p className=""> brand id --{selected.parent}</p>
              <p className="">
                {selected.parentName || "not preseent(brand Name)"}
              </p>
              <p className="">
                {selected.subcategory || "not preseent(brand Name)"}
              </p>

              <div className="flex my-3 items-center justify-center">
                <button
                  className="p-2 bg-green-500 text-white rounded-md mx-1"
                  onClick={() => updateReview()}
                >
                  {updating ? <div className="mx-2" id="loading"></div> : null}
                  Update
                </button>
                <button
                  className="p-2 bg-red-500 text-white rounded-md mx-1"
                  onClick={() => setselected(null)}
                >
                  Discard
                </button>
              </div>
            </div>
          ) : (
            pros.map((pro, index) => (
              <p
                key={"pro" + index}
                onClick={() => setselected(pro)}
                className="h-12 my-1 px-2 border-b border-blue-900 cursor-pointer"
              >
                {pro.name}
              </p>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default UpdateParentReviewPopup;
