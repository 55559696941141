import AWN from "awesome-notifications";
import React from "react";
import { useState } from "react";
import axios from "axios";
import urls from "../utils/urls";
import { useRef } from "react";

function UpdateBrandofProduct({ brandId, updateFun }) {
  const [editMode, setEditMode] = useState(false);
  const [brands, setbrands] = useState([]);

  let inputRef = useRef();

  const searchNow = async () => {
    let response;

    let query = inputRef.current.value;
    query = query.trim();
    if (!query.includes(" ") && query.startsWith("brand")) {
      response = await axios(urls.admin + "getBrand/" + query, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
        },
      });

      if (response.status === 200) {
        let eres = response.data;
        setbrands([eres]);
      }
    } else {
      response = await axios(urls.admin + "searchBrands", {
        method: "PUT", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
        },
        data: JSON.stringify({ query: query }),
      });

      if (response.status === 200) {
        let eres = response.data;
        setbrands(eres);
      }
    }
  };

  if (editMode)
    return (
      <div className="py-2 text-black relative z-10 popup">
        <p className="Brand">Brand</p>
        <div
          className="absolute bg-white z-10 h-96 top-0 rounded-md shadow-md p-2"
          style={{ width: "700px" }}
        >
          <div className="flex justify-between items-center py-2  border-b">
            <p className="">Update Brand</p>
            <i
              className="fas fa fa-close text-red fa-lg cursor-pointer  "
              onClick={() => setEditMode(false)}
            ></i>
          </div>
          <div className="flex gap-3 my-1">
            <input
              type="text"
              ref={inputRef}
              placeholder="search brand here......."
              className="border rounded appearance-none h-10 px-2 flex-1"
            />
            <button
              onClick={searchNow}
              className="bg-green-500 p-2 text-white rounded-md shadow-md"
            >
              search
            </button>
          </div>
          <div className="">
            {brands.length === 0 ? (
              <p className="text-center">empty result...........</p>
            ) : (
              <ul>
                {brands.map((brand, index) => (
                  <li
                    key={brand.id}
                    onClick={() => {
                      updateFun({ parent: brand.id });
                      setEditMode(false);
                    }}
                    className="flex p-1 cursor-pointer transform duration-100  gap-3 items-center m-1 border rounded shadow-sm hover:bg-purple-200"
                  >
                    <img
                      src={brand.imageUrl}
                      alt="noimage"
                      className="h-20 w-20 rounded-md object-cover"
                    />
                    <p className="">
                      <span className="font-semibold text-xl">
                        {brand.name}
                      </span>
                      <br /> <span className="">{brand.id}</span>
                    </p>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    );
  else
    return (
      <div>
        <p className="Brand">Brand</p>
        <p className="text-purple-500">
          {brandId ?? "Unknown"}
          <i
            onClick={() => setEditMode(true)}
            className=" transform duration-75 hover:font-bold fas fa fa-pencil mx-3 text-black cursor-pointer hover:scale-110"
          ></i>
        </p>
      </div>
    );
}

export default UpdateBrandofProduct;
