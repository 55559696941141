import AWN from "awesome-notifications";
import { nanoid } from "nanoid";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { firebasefb, storage } from "../firebaseconfig";
import Goback from "./Goback";
import axios from "axios";
import urls from "./urls";

function CreateBrands() {
  const [brandimage, setimage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [loading, setloading] = useState(false);
  const [tags, settags] = useState([]);
  const [categoryList, setcategory] = useState([]);
  const [selected, setselected] = useState(0);
  const [id, setid] = useState(nanoid(8));

  let descref = useRef();
  let tagref = useRef();
  let brandname = useRef();
  let category = useRef();
  let subcategory = useRef();

  useEffect(() => {
    firebasefb
      .collection("category")
      .get()
      .then((res) => {
        var a = [];
        res.docs.forEach((e) => {
          a.push(e.data());
        });
        setcategory(a);
      });
  }, []);

  const imageupload = (e) => {
    if (loading) return;
    setloading(true);
    var image = e.target.files[0];
    if (!image) {
      new AWN().alert("no image file selected", {
        position: "bottom-right",
      });
      setloading(false);
      return;
    }
    var storageRef = storage.ref();
    storageRef
      .child(`brand/${id}`)
      .put(image)
      .then((snapshot) => {
        snapshot.ref
          .getDownloadURL()
          .then(function (downloadURL) {
            setimage(downloadURL);
            setloading(false);
          })
          .catch((err) => {
            new AWN().alert(e.message, {
              position: "bottom-right",
            });
            setloading(false);
          });
      });
  };
  const coverImageUpload = (e) => {
    if (loading) return;
    setloading(true);
    var image = e.target.files[0];
    if (!image) {
      new AWN().alert("no image file selected", {
        position: "bottom-right",
      });
      setloading(false);
      return;
    }
    var storageRef = storage.ref();
    storageRef
      .child(`brand/${id}coverimage`)
      .put(image)
      .then((snapshot) => {
        snapshot.ref
          .getDownloadURL()
          .then(function (downloadURL) {
            setCoverImage(downloadURL);
            setloading(false);
          })
          .catch((err) => {
            new AWN().alert(e.message, {
              position: "bottom-right",
            });
            setloading(false);
          });
      });
  };

  const finalSubmit = () => {
    var nameFilter = brandname.current.value.toLowerCase().split(" ");
    var brnd = {
      id: `brand_${id}`,
      subscribes: 0,
      isverified: false,
      rating: 3,
      name: brandname.current.value,
      description: descref.current.value,
      catId: categoryList[category.current.value].id,
      catName: categoryList[category.current.value].name,
      subcategory: subcategory.current.value,
      reviews: 0,
      flicks: 0,
      status: false,
      experiences: 0,
      coverImage: coverImage,
      tag: `@brand_${id}`,
      dateAdded: Date.now(),
      brandFace: null,
      addedBy: "admin",
      filter: [...tags, ...nameFilter],
      imageUrl: brandimage,
    };

    if (descref.current.value.length < 10) {
      return alert("fill more than 10 words for description");
    }

    let notifier = new AWN();
    let onOk = () => {
      new AWN().async(
        axios(urls.brands + "createBrand", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: urls.brandAuth,
          },
          data: brnd,
        }),
        (response) => {
          console.log(response);
          if (response.status === 200) {
            if (response.data.success) {
              new AWN().success(response.data.message);
            } else {
              new AWN().alert(response.data.errors.toString());
            }
          } else {
            new AWN().alert(response.data);
          }
        },
        (err) => {
          new AWN().alert(err.toString());
        },
        "Creating Brand"
      );
    };
    let onCancel = () => {
      notifier.info("You pressed Cancel");
    };
    notifier.confirm("Are you sure?", onOk, onCancel, {
      labels: {
        confirm: "Dangerous action",
      },
    });
  };

  const removeTag = (e) => {
    console.log(e);
    settags(tags.filter((word) => e !== word));
  };

  const addtags = (e) => {
    e.preventDefault();

    if (tags?.includes(tagref.current.value)) {
      return;
    }
    settags([...tags, tagref.current.value.trim()]);
    tagref.current.value = "";
  };

  if (loading)
    return (
      <div className="h-screen flex items-center justify-center">
        <span className="fas fa-spinner animate-spin"></span>
      </div>
    );
  else
    return (
      <div className="bg-background min-h-screen">
        <div className="bg-white h-24">
          <h1 className="font-medium text-3xl flex flex-grow-0 pt-5 pl-8 ">
            <Goback />
            <span className="pt-2"> &nbsp; Create New Brand</span>
          </h1>
        </div>
        <div className="">
          <div className="bg-white m-20 ">
            <div className="pl-12 pt-8 pr-12">
              <div className=" pb-8 grid grid-flow-col grid-cols-2 grid-rows-1 gap-5 ">
                <div className="grid grid-flow-col m-2">
                  <div className=" w-64 h-64 overflow-hidden bg-red-200">
                    <img src={brandimage} alt="" className="h-64" />
                  </div>

                  <div className="flex items-start justify-center">
                    <label htmlFor="brandimage">
                      <div className="border rounded text-center w-64 h-64  justify-center items-center cursor-pointer bg-gray-50">
                        <i className="fas fa-plus mt-24 fa-blue  text-lg"></i>

                        <p className="text-sm text-center text-gray-800 py-24">
                          Upload Brand image here
                        </p>
                      </div>
                    </label>

                    <input
                      type="file"
                      accept="image/*"
                      id="brandimage"
                      className="hidden"
                      onChange={(e) => imageupload(e)}
                    />
                  </div>
                </div>
                <div className="grid grid-flow-col m-2">
                  <div className=" w-64 h-64 overflow-hiddfen bg-red-200">
                    <img src={coverImage} alt="" className="h-64" />
                  </div>
                  <div className="flex items-start justify-center">
                    <label htmlFor="coverimage">
                      <div className="border rounded text-center w-64 h-64  justify-center items-center cursor-pointer bg-gray-50">
                        <i className="fas fa-plus mt-24 fa-blue  text-lg"></i>

                        <p className="text-sm text-center text-gray-800 py-24">
                          Upload Cover Image here
                        </p>
                      </div>
                    </label>

                    <input
                      type="file"
                      accept="image/*"
                      id="coverimage"
                      className="hidden"
                      onChange={(e) => coverImageUpload(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="bg-white m-20 ">
            <div className="pl-12 pt-8 pr-12">
              <p className=" pb-2 text-lg font-medium">
                Name <span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                ref={brandname}
                placeholder="Name of Brand"
                className="h-12 px-5 w-full bg-box appearance-none outline-none border border-box focus:border-purple-700"
              />
            </div>
            <div className="pl-12 pt-8 pr-12">
              <p className=" pb-2 text-lg font-medium">
                Description <span className="text-red-500">*</span>
              </p>

              <textarea
                ref={descref}
                className="h-44 pt-2 px-5 w-full bg-box appearance-none outline-none border border-box focus:border-purple-700"
                type="text"
                placeholder="Description of brand"
              />
            </div>
            <div className=" pb-8 grid grid-flow-col grid-cols-2 grid-rows-1 gap-5 p-10 ">
              <div className="mx-2">
                <p className=" pb-2 text-lg font-medium">
                  Category <span className="text-red-500">*</span>
                </p>
                <select
                  name=""
                  id=""
                  className="h-12 px-5 w-full bg-box appearance-none outline-none border border-box focus:border-purple-700"
                  ref={category}
                  onChange={(e) => {
                    setselected(e.target.value);
                  }}
                >
                  {categoryList.map((e, index) => (
                    <option value={index}>{e.name}</option>
                  ))}
                </select>
              </div>
              <div className="mx-2">
                <p className=" pb-2 text-lg font-medium">
                  Sub-Category <span className="text-red-500">*</span>
                </p>

                <select
                  name=""
                  id=""
                  ref={subcategory}
                  className="h-12 px-5 w-full bg-box appearance-none outline-none border border-box focus:border-purple-700"
                >
                  {categoryList[selected]?.subcategory.map((e) => (
                    <option value={e}>{e}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className=" pl-12 pr-12 pb-8">
              <h1 className=" font-semibold text-xl">Tags</h1>
              <div className="flex flex-wrap pb-4">
                {tags?.map((e) => (
                  <span
                    className="bg-blue-400 text-white m-1 rounded p-2 cursor-pointer"
                    onClick={() => removeTag(e)}
                  >
                    {e}
                  </span>
                ))}
              </div>
              <form onSubmit={(e) => addtags(e)}>
                <input
                  type="text"
                  ref={tagref}
                  className="h-12 px-5 w-full bg-box appearance-none outline-none border border-box focus:border-purple-700"
                  placeholder="tags"
                />
              </form>
            </div>
          </div>

          <div className="text-left m-20">
            <button
              className="w-48 h-14 bg-pink-500 hover:bg-pink-700 text-white "
              onClick={() => finalSubmit()}
            >
              Creata Brand
            </button>
          </div>
        </div>
        <div className="h-1"></div>
      </div>
    );
}

export default CreateBrands;
