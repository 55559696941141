import AWN from "awesome-notifications";
import axios from "axios";
import React, { useState } from "react";
import urls from "../utils/urls";

function FlicksBonusCoin({ flicks, setFlicks }) {
  const [selected, setSelected] = useState([]);
  const [sum, setSum] = useState(0);
  const checks = [
    {
      check: "Video Quality",
      coin: 350,
      rank: 1,
    },
    {
      check: "Reviewer Speaking",
      coin: 100,
      rank: 2,
    },
    {
      check: "Product Overview",
      coin: 100,
      rank: 3,
    },
    {
      check: "Video Length",
      coin: 200,
      rank: 4,
    },
    {
      check: "User Looks",
      coin: 200,
      rank: 5,
    },
    {
      check: "Title & Description",
      coin: 100,
      rank: 6,
    },
    {
      check: "Correct Product",
      coin: 200,
      rank: 7,
    },
    {
      check: "List of Brand Product",
      coin: 1000,
      rank: 8,
    },
    {
      check: "Posting Coins",
      coin: 500,
      rank: 9,
    },
    {
      check: "Language",
      coin: 1000,
      rank: 10,
    },
    {
      check: "Selected By Brand",
      coin: 3750,
      rank: 11,
    },
    {
      check: "Incorrect Flick Upload on product",
      coin: -500,
      rank: 12,
    },
  ];

  const addOrRemove = (rank, dec) => {
    let data;
    if (dec) {
      data = [...selected, rank];
    } else {
      data = selected.filter((ran) => ran !== rank);
    }
    let s = data.reduce((accu, curr) => {
      let sel = checks.find((res) => res.rank === curr);
      if (sel) return accu + sel.coin;
      else return accu;
    }, 0);

    setSum(s);
    setSelected(data);
  };

  console.log("reloading bonusflicks");

  const initilizeApplyCoins = () => {
    if (!sum) {
      new AWN().alert("apply some check conditions first.");
      return;
    }
    new AWN().confirm(
      "you are about give bonus coins to this User. Are you sure.",
      () => {
        new AWN().async(
          axios(urls.admin + "updateBonusCoin", {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: urls.adminAuth,
            },
            data: JSON.stringify({
              subId: flicks.id,
              userId: flicks.uploadedBy,
              flicksName: flicks.name,
              coins: sum,
            }),
          }),
          async (resp) => {
            console.log(resp.data);
            console.log(resp);
            setFlicks({
              ...flicks,
              bonusCoinProvided: resp.data.success,
              bonusCoins: sum,
            });
            new AWN().success("bonus coin updated");
          },
          (message) => {
            new AWN().warning(message);
          },
          "Making changes to database"
        );
      },
      () => {
        new AWN().info("operation cancelled.");
      }
    );
  };

  if (flicks?.bonusCoinProvided) {
    return (
      <div className="h-80 shadow-lg bg-white rounded-md flex items-center justify-center">
        <p className="">
          Bonus Coin already Updated: {flicks.bonusCoins} coins
        </p>
      </div>
    );
  } else
    return (
      <div className=" bg-white p-4 rounded-sm my-4 shadow-md">
        <div className="">
          {checks.map((check, index) => (
            <div
              className="flex bg-blue-100 items-center px-3 justify-between m-1 my-2"
              key={"checks" + index}
            >
              {check.check} <br />
              <div className="flex w-28 items-center justify-between">
                <input
                  type="checkbox"
                  className="h-7 w-7"
                  onChange={(e) => addOrRemove(check.rank, e.target.checked)}
                />
                <p className="">{check.coin} coins</p>
              </div>
            </div>
          ))}

          <div className="flex items-center justify-end text-3xl">
            <span>Total Coins: {sum} coins</span>
            <button
              onClick={initilizeApplyCoins}
              className="px-3 py-2 text-white bg-green-500 ml-3 text-sm"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    );
}

export default FlicksBonusCoin;
