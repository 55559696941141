import { nanoid } from "nanoid";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { firebasefb, storage } from "./firebaseconfig";
import ArrayInputer from "./utils/ArrayInputer";
import AWN from "awesome-notifications";
import Goback from "./utils/Goback";
function AddNewCategory() {
    const [category, setcategory] = useState({ imageUrl: null, id: nanoid(8) });
    const [subcat, setsubcat] = useState([]);
    const [loading, setloading] = useState(false);
    const [subimage, setsubimage] = useState([]);
    let nameref = useRef();

    const imageupload = (e) => {
        if (loading) return;
        setloading(true);
        var image = e.target.files[0];
        if (!image) {
            new AWN().alert("no image file selected", {
                position: "bottom-right",
            });
            setloading(false);
            return;
        }
        var storageRef = storage.ref();
        storageRef
            .child(`utils/${category.id}`)
            .put(image)
            .then((snapshot) => {
                snapshot.ref
                    .getDownloadURL()
                    .then(function (downloadURL) {
                        setcategory({ ...category, imageUrl: downloadURL });
                        setloading(false);
                    })
                    .catch((err) => {
                        new AWN().alert(e.message, {
                            position: "bottom-right",
                        });
                        setloading(false);
                    });
            });
    };

    const subimages = (e, name) => {
        if (loading) return;
        setloading(true);
        var image = e.target.files[0];
        if (!image) {
            new AWN().alert("no image file selected", {
                position: "bottom-right",
            });
            setloading(false);
            return;
        }
        console.log(category.id, name);
        var storageRef = storage.ref();
        storageRef
            .child(`category/${category.id + name}`)
            .put(image)
            .then((snapshot) => {
                snapshot.ref
                    .getDownloadURL()
                    .then(function (downloadURL) {
                        setsubimage([
                            ...subimage,
                            { icon: downloadURL, name: name },
                        ]);
                        setloading(false);
                    })
                    .catch((err) => {
                        new AWN().alert(e.message, {
                            position: "bottom-right",
                        });
                        setloading(false);
                    });
            });
    };
    const finalSubmit = () => {
        if (nameref.current.value.length < 2)
            return alert("enter valid category name");
        if (!category.imageUrl) return alert("enter valid image");
        if (subcat.length === 0) return alert("empty subcategories enter some");

        const newcat = {
            ...category,
            images: subimage,
            name: nameref.current.value,
            subcategory: subcat,
        };

        firebasefb
            .collection("category")
            .doc(category.id)
            .set(newcat)
            .then((res) => {
                new AWN().success("successfully added");
                nameref.current.value = "";
                setsubcat([]);
                setsubimage([]);
                setcategory({ imageUrl: null, id: nanoid(8) });
                return;
            })
            .catch((e) => {
                new AWN().error("successfully added");
            });
    };

    return (
        <div className="bg-background min-h-screen">
            <div className="bg-white h-24">
                <h1 className="font-medium text-3xl flex flex-grow-0 pt-5 pl-8 ">
                    <Goback />
                    <span className="pt-2"> &nbsp; Create New Category</span>
                </h1>
            </div>

            <div className="">
                <div className="bg-white m-20">
                    <div className="grid grid-cols-2 p-10">
                        <div className="h-80 w-80 pl-10">
                            {!category.imageUrl ? (
                                <div className="flex items-start justify-center">
                                    <label htmlFor="file-for">
                                        <div className="border rounded text-center w-80 h-80  justify-center items-center cursor-pointer bg-box">
                                            <i className="fas fa-plus mt-24 fa-blue  text-lg"></i>

                                            <p className="text-sm text-center text-gray-800 py-24">
                                                Upload your category image here
                                            </p>
                                        </div>
                                    </label>

                                    <input
                                        type="file"
                                        accept="image/*"
                                        id="file-for"
                                        className="hidden "
                                        onChange={(e) => imageupload(e)}
                                    />
                                    {loading ? (
                                        <div className="text-center">
                                            <span className="fas fa-spinner animate-spin"></span>
                                        </div>
                                    ) : null}
                                </div>
                            ) : (
                                <div className=" w-96 h-96 overflow-hidden">
                                    <img
                                        src={category.imageUrl}
                                        alt=""
                                        className="h-64"
                                    />
                                </div>
                            )}
                        </div>
                        <div>
                            <div className="text-black">
                                <div className="">
                                    <p className="font-medium text-lg pb-2">
                                        Category Name
                                    </p>
                                    <input
                                        ref={nameref}
                                        type="text"
                                        placeholder="Enter Category Name"
                                        className="bg-box h-14 pl-4 w-full"
                                    />
                                </div>
                            </div>
                            <div className="text-black pt-4">
                                <p className="font-medium text-lg ">
                                    Sub-Category
                                </p>
                                <ArrayInputer
                                    arr={subcat}
                                    setarr={setsubcat}
                                    placeholder="enter a new subcategory to add"
                                />
                            </div>
                            <div className="pt-4">
                                <p className="text-xl font-semibold">
                                    Sub Categories
                                </p>
                                {subcat.length !== 0 ? (
                                    <ul className="list-none">
                                        {subcat.map((s, index) => {
                                            return (
                                                <li
                                                    className="h-44 w-full pl-4 pt-4 mt-2 flex items-center bg-box"
                                                    key={index}
                                                >
                                                    {!subimage.find(
                                                        (sc) => sc.name === s
                                                    ) ? (
                                                        <div className="flex items-start justify-center">
                                                            <label htmlFor="subimages">
                                                                <div className="border rounded text-center w-32 h-32  justify-center items-center cursor-pointer bg-box">
                                                                    <i className="fas fa-plus fa-blue mt-12 text-lg"></i>
                                                                </div>
                                                            </label>

                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                id="subimages"
                                                                className="hidden "
                                                                onChange={(e) =>
                                                                    subimages(
                                                                        e,
                                                                        s
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    ) : (
                                                        <img
                                                            src={
                                                                subimage.find(
                                                                    (sc) =>
                                                                        sc.name ===
                                                                        s
                                                                )?.icon
                                                            }
                                                            alt=""
                                                            height="100px"
                                                            width="100px"
                                                        />
                                                    )}
                                                    <p className="px-4">{s}</p>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                ) : (
                                    <div className="h-14 w-full bg-box pt-4 pl-4">
                                        No Sub-categories available
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className=" pl-20" onClick={() => finalSubmit()}>
                <button className="w-48 h-14 bg-pink-500 hover:bg-pink-700 text-white font-medium tracking-wide">
                    Submit
                </button>
            </div>
        </div>
    );
}

export default AddNewCategory;
