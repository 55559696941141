import { useStateValue } from "./StateProvider";
import TopRankings from "./utils/TopRankings";
import ViewsChart from "./cards/ViewsChart";
import TotaluserChart from "./cards/TotaluserChart";
import AppAds from "./components/AppAds";
import FlicksTagCount from "./components/FlicksTagCount";
import ServerStatus from "./components/ServerStatus";
function Dashboard() {
  const [{ countAdmin, user }] = useStateValue();

  return (
    <div className="bg-panel_bg_secondary min-h-screen py-10 px-6 font-Manrope ">
      <div className="flex justify-between">
        <div className="">
          <p className="text-blue-700 text-sm">Welcome Back,</p>
          <p className="text-blue-600">{user.name || "Admin"}</p>
        </div>
        <div className="">
          <p className="text-purple-800">{Date()}</p>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 lg:grid-flow-col gap-5 mt-5">
        <div className="bg-purple-600 h-48 w-full rounded grid grid-cols-2 grid-flow-col">
          <div className=" flex flex-col justify-between px-5 py-3">
            <p className="text-white">Total Reviews:</p>
            <div className="flex items-end gap-x-3  py-3">
              <p className="text-5xl text-white font-medium">
                {countAdmin?.reviews}
              </p>
            </div>
            <p className="text-white">
              Timeline:
              <span className="opacity-70 font-light text-sm ml-6">
                30 Mar - Present
              </span>
            </p>
          </div>
          <div className="justify-self-end p-5">
            <img
              src={require("./images/icons/chart.png").default}
              alt=""
              className="h-40"
            />
          </div>
        </div>
        <div className="bg-green-400 h-48 w-full rounded grid grid-cols-2 grid-flow-col">
          <div className=" flex flex-col justify-between px-5 py-3">
            <p className="text-white">Total Flicks :</p>
            <div className="flex items-end gap-x-3  py-3">
              <p className="text-5xl text-white font-medium">
                {countAdmin?.flicks}
              </p>
            </div>
            <p className="text-white">
              Timeline:{" "}
              <span className="opacity-70 font-light text-sm ml-6">
                30 Mar - Present
              </span>
            </p>
          </div>
          <div className="justify-self-end p-5">
            <img
              src={require("./images/icons/chart.png").default}
              alt=""
              className="h-40"
            />
          </div>
        </div>
      </div>
      <ServerStatus />
      <div className="grid lg:grid-cols-3 lg:grid-flow-row gap-5 mt-5 ">
        <div className="bg-white p-5 h-52 w-full border rounded-md shadow-md">
          <div className="bg-panel_bg_secondary w-full h-full rounded p-2">
            <div className="flex justify-between">
              <p className="">Total Users</p>
            </div>
            <div className="text-5xl mt-10 text-indigo-800">
              {countAdmin?.users}
            </div>
          </div>
        </div>
        <div className="bg-white p-5 h-52 w-full border rounded-md shadow-md">
          <div className="bg-panel_bg_secondary w-full h-full rounded p-2">
            <div className="flex justify-between">
              <p className="">Total Brands</p>
            </div>
            <div className="text-5xl mt-10 text-indigo-800">
              {countAdmin?.brands}
            </div>
          </div>
        </div>
        <div className="bg-white p-5 h-52 w-full border rounded-md shadow-md">
          <div className="bg-panel_bg_secondary w-full h-full rounded p-2">
            <div className="flex justify-between">
              <p className="">Total Products</p>
            </div>
            <div className="text-5xl mt-10 text-indigo-800">
              {countAdmin?.products}
            </div>
          </div>
        </div>
        <div className="bg-white p-5 h-52 w-full border rounded-md shadow-md">
          <div className="bg-panel_bg_secondary w-full h-full rounded p-2">
            <div className="flex justify-between">
              <p className="">Total Views</p>
            </div>
            <div className="text-5xl mt-10 text-indigo-800">
              {countAdmin?.views}
            </div>
          </div>
        </div>
        <div className="bg-white p-5 h-52 w-full border rounded-md shadow-md">
          <div className="bg-panel_bg_secondary w-full h-full rounded p-2">
            <div className="flex justify-between">
              <p className="">Total Hearts</p>
            </div>
            <div className="text-5xl mt-10 text-indigo-800">
              {countAdmin?.hearts}
            </div>
          </div>
        </div>
        <div className="bg-white p-5 h-52 w-full border rounded-md shadow-md">
          <div className="bg-panel_bg_secondary w-full h-full rounded p-2">
            <div className="flex justify-between">
              <p className="">Total Ratings</p>
            </div>
            <div className="text-5xl mt-10 text-indigo-800">
              {countAdmin?.hearts}
            </div>
          </div>
        </div>
        <div className="bg-white p-5 h-52 w-full border rounded-md shadow-md">
          <div className="bg-panel_bg_secondary w-full h-full rounded p-2">
            <div className="flex justify-between items-center">
              <div className="flex gap-x-3">
                <p className="">New Brands in</p>
                <img
                  src={require("./images/elite.png").default}
                  alt=""
                  className=""
                />
              </div>
              <div className="bg-gray-700 py-1 px-3 rounded text-white tracking-wide">
                Weekly
              </div>
            </div>
            <div className="text-5xl mt-10 text-indigo-800">
              {countAdmin?.brandAdmins}
            </div>
          </div>
        </div>
        <div className="bg-white p-5 h-52 w-full border rounded-md shadow-md">
          <div className="bg-panel_bg_secondary w-full h-full rounded p-2">
            <div className="flex justify-between">
              <p className="">Brands Onboarded revenue in </p>
            </div>
            <div className="text-5xl mt-10 text-indigo-800">
              {countAdmin?.revenueAdmin}
            </div>
          </div>
        </div>
        <FlicksTagCount />
      </div>
      <div className="m-1 p-2 rounded-sm shadow-md bg-white flex">
        <ViewsChart />
        <TotaluserChart users={countAdmin?.users || 0} />
      </div>

      <TopRankings />
      <AppAds />
      <div className="grid lg:grid-cols-4 lg:grid-flow-col mt-5 gap-5">
        <div className="bg-white p-5 h-52 w-full border rounded-md shadow-md">
          <div className="bg-panel_bg_secondary w-full h-full rounded p-2">
            <div className="flex gap-x-3 items-center">
              <p className="">Complaint Issues on App </p>
              <div className="bg-blue-500 h-3 w-3 rounded-full"></div>
            </div>
            <div className="text-5xl mt-10 text-blue-500">
              {countAdmin?.complaints}
            </div>
          </div>
        </div>
        <div className="bg-white p-5 h-52 w-full border rounded-md shadow-md">
          <div className="bg-panel_bg_secondary w-full h-full rounded p-2">
            <div className="flex gap-x-3 items-center">
              <p className="">Total Flags raised By Brands </p>
              <div className="bg-blue-500 h-3 w-3 rounded-full"></div>
            </div>
            <div className="text-5xl mt-10 text-yellow-500">
              {countAdmin?.flags}
            </div>
          </div>
        </div>
        <div className="bg-white p-5 h-52 w-full border rounded-md shadow-md">
          <div className="bg-panel_bg_secondary w-full h-full rounded p-2">
            <div className="flex gap-x-3 items-center">
              <p className="">Total Flags Solved By Brands</p>
            </div>
            <div className="text-5xl mt-10 text-purple-500">1,200</div>
          </div>
        </div>
        <div className="bg-white p-5 h-52 w-full border rounded-md shadow-md">
          <div className="bg-panel_bg_secondary w-full h-full rounded p-2">
            <div className="flex gap-x-3 items-center">
              <p className="">Total Pending Flags For Brands</p>
            </div>
            <div className="text-5xl mt-10 text-purple-500">15</div>
          </div>
        </div>
      </div>
      <div className="grid lg:grid-cols-3 lg:grid-flow-col mt-5 gap-5">
        <div className="bg-white p-5 h-52 w-full border rounded-md shadow-md">
          <div className="bg-panel_bg_secondary w-full h-full rounded p-2">
            <div className="flex gap-x-3 items-center">
              <p className="">User Reports </p>
              <div className="bg-blue-500 h-3 w-3 rounded-full"></div>
            </div>
            <div className="text-5xl mt-10 text-blue-500">
              {countAdmin?.reports}
            </div>
          </div>
        </div>
        <div className="bg-white p-5 h-52 w-full border rounded-md shadow-md">
          <div className="bg-panel_bg_secondary w-full h-full rounded p-2">
            <div className="flex gap-x-3 items-center">
              <p className="">In Message Reports </p>
              <div className="bg-blue-500 h-3 w-3 rounded-full"></div>
            </div>
            <div className="text-5xl mt-10 text-yellow-500">
              {countAdmin?.inmessage}
            </div>
          </div>
        </div>
        <div className="bg-white p-5 h-52 w-full border rounded-md shadow-md">
          <div className="bg-panel_bg_secondary w-full h-full rounded p-2">
            <div className="flex gap-x-3 items-center">
              <p className="">Total Product Review Request </p>
            </div>
            <div className="text-5xl mt-10 text-purple-500">
              {countAdmin?.requests}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
