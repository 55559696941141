import { DataGrid } from "@material-ui/data-grid";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { firebasefb } from "./firebaseconfig";
import { data } from "./utils/Res";
function Stores() {
  const [stores, setbrands] = useState([]);
  const history = useHistory();
  const [loading, setloading] = useState(true);

  const [searchResult, setsearchResult] = useState([]);
  const [query, setquery] = useState("");

  useEffect(() => {
    firebasefb
      .collection("store")
      .get()
      .then((res) => {
        var a = [];
        res.docs.forEach((d) => {
          a.push(d.data());
        });
        setbrands(a);
        setloading(false);
      });
  }, []);

  const columns = [
    { field: "name", headerName: "name", width: 190 },
    { field: "catId", headerName: "Category Id", width: 150 },
    { field: "description", headerName: "Description", width: 150 },
    { field: "dateAdded", headerName: "Date Added", width: 150 },
    { field: "id", headerName: "Brand Id", width: 150 },
    { field: "rating", headerName: "Rating ", width: 120 },
    { field: "subscribes", headerName: "Subscribes ", width: 120 },
    {
      field: "actions",
      headerName: "Actions",
      width: 320,
      // eslint-disable-next-line react/display-name
      renderCell: ({ row }) => (
        <div>
          <button
            className={data.greenButton}
            onClick={() => history.push(`/home/viewstore/${row.id}`)}
          >
            View
          </button>
          <button
            className={data.redButton}
            onClick={() => history.push(`/home/updateStore/${row.id}`)}
          >
            Update
          </button>
        </div>
      ),
    },
  ];

  if (loading)
    return (
      <div className="h-screen flex items-center justify-center">
        <span className="fas fa-spinner animate-spin fa-3x"></span>
      </div>
    );
  return (
    <div className="font-Manrope py-10">
      <div className="flex justify-between items-center px-10">
        <p className="text-3xl font-medium">Total Stores:</p>
        <p className="text-3xl font-semibold text-nav_active_color">
          {stores.length}
        </p>
      </div>

      <div className="mx-10 py-10 focus:outline-none">
        <input
          type="text"
          className="pr-8 py-3 px-3 w-full appearance-none outline-none border focus:border-nav_active_color"
          placeholder="Search by Name, Categoty, Description, Brand"
          onChange={(e) => {
            setquery(e.target.value);
            setsearchResult(
              stores.filter((row) => row.name?.includes(e.target.value))
            );
          }}
        />
      </div>

      <div className="pb-20 px-10 w-full">
        <div className="w-full  bg-white border shadow-lg rounded-md overflow-x-auto">
          {query.length === 0 ? (
            <DataGrid
              autoHeight={true}
              rows={stores}
              columns={columns}
              pageSize={20}
            />
          ) : (
            <DataGrid
              autoHeight={true}
              rows={searchResult}
              columns={columns}
              pageSize={20}
            />
          )}
        </div>
      </div>
      <div className="h-2"></div>
    </div>
  );
}

export default Stores;
