import axios from "axios";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import urls from "../utils/urls";
import AWN from "awesome-notifications";
import HuntSubmissions from "./HuntSubmissions";
import { storage } from "../firebaseconfig";

function ViewBrandRequest() {
  const [req, setReq] = useState(null);
  const [loading, setLoading] = useState(true);

  let titleRef = useRef();
  let givewayRef = useRef();
  let rewardCoinsRef = useRef();

  useEffect(() => {
    setReq(JSON.parse(sessionStorage.getItem("temp")));
    setLoading(false);
  }, []);

  const coverImageUpload = (e) => {
    if (req.status === "Ready") return;
    var image = e.target.files[0];
    if (!image) {
      new AWN().alert("no image file selected", {
        position: "bottom-right",
      });
      return;
    }

    var extension = image.type.split("/").pop();

    var storageRef = storage.ref();
    storageRef
      .child(`houseRequestCoverImage/${req._id}.${extension}`)
      .put(image)
      .then((snapshot) => {
        snapshot.ref
          .getDownloadURL()
          .then(function (downloadURL) {
            setReq({ ...req, coverImage: downloadURL });
          })
          .catch((e) => {
            new AWN().alert(e.message, {
              position: "bottom-right",
            });
          });
      });
  };
  const productImageUpload = (e) => {
    var image = e.target.files[0];
    if (!image) {
      new AWN().alert("no image file selected", {
        position: "bottom-right",
      });
      return;
    }

    var extension = image.type.split("/").pop();

    var storageRef = storage.ref();
    storageRef
      .child(`houseRequestCoverImage/prodImage${req._id}.${extension}`)
      .put(image)
      .then((snapshot) => {
        snapshot.ref
          .getDownloadURL()
          .then(function (downloadURL) {
            setReq({ ...req, productImage: downloadURL });
          })
          .catch((e) => {
            new AWN().alert(e.message, {
              position: "bottom-right",
            });
          });
      });
  };

  const updateStatus = async (status) => {
    if (req.status === "Ready") return;

    if (status === "Ready") {
      if (titleRef.current.value.length < 5) {
        new AWN().warning("please add more letters than 5 title");
        return;
      }
      if (!rewardCoinsRef.current.value) {
        new AWN().warning("please add reward coins");
        return;
      }
      if (!req.coverImage) {
        new AWN().warning("please add coverImage");
        return;
      }
      if (!req.productImage) {
        new AWN().warning("please add product image");
        return;
      }
    }
    new AWN().async(
      axios(urls.brandAdmin + "updateHouseStatus/" + req._id, {
        method: "PUT", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: urls.brandAdminAuth,
        },
        data: JSON.stringify({
          status: status,
          title: titleRef.current.value,
          coverImage: req.coverImage,
          productImage: req.productImage,
          rewardCoin: Number.parseInt(rewardCoinsRef.current.value),
          giveway: givewayRef.current.value,
        }),
      }),
      (response) => {
        if (response.status === 200) {
          console.log(response);
          if (!response.data.success) {
            new AWN().alert(response.data.message);
            return;
          }
          new AWN().success("Status updated");
          let items = sessionStorage.getItem("brandReviewRequests");
          items = JSON.parse(items);
          let item = items.find((r) => r._id === req._id);
          item.status = status;
          sessionStorage.setItem("brandReviewRequests", JSON.stringify(items));
          setReq({ ...req, status: status });
        } else {
          new AWN().alert(response.data.message);
        }
      },
      (err) => {
        new AWN().alert(err.toString());
      },
      "Updating Status"
    );
  };

  if (loading)
    return (
      <div className="h-screen bg-panel_bg flex items-center justify-center">
        <span className="fas fa-spinner animate-spin fa-3x"></span>
      </div>
    );
  return (
    <div className="p-3 bg-background min-h-screen">
      <div className="bg-white shadow-lg  p-3 flex justify-between rounded mb-5">
        <p className="text-3xl ">View Brand Request</p>
        <div className="relative">
          <p className="text-xs text-gray-400">status</p>

          <div className="relative">
            <button className="bg-green-500 p-2 rounded shadow-md text-white dropdown-button">
              {req.status}
            </button>
          </div>
        </div>
      </div>

      <div className="grid  grid-cols-8 gap-4">
        <div className=" col-span-2 rounded-md shadow-lg bg-white p-2">
          <p className=" border-b">Video Requirements</p>
          <div className="flex justify-between items-center my-2">
            <p className="">Video Quality</p>
            <button className="bg-yellow-500 shadow-md rounded-sm p-2 text-white">
              {req?.videoQuality}
            </button>
          </div>
          <div className="flex justify-between items-center my-2">
            <p className="">Video Type</p>
            <button className="bg-blue-500 shadow-md rounded-sm p-2 text-white">
              {req?.videoType}
            </button>
          </div>
          <div className="flex justify-between items-center my-2">
            <p className="">Video Duration</p>
            <button className="bg-green-500 shadow-md rounded-sm p-2 text-white">
              {req?.videoDuration}s
            </button>
          </div>
        </div>
        <div className=" col-span-3 rounded-md shadow-lg bg-white p-2">
          <p className=" border-b">Creator Criteria</p>
          <div className="flex justify-between items-center my-2">
            <p className="">Gender</p>
            <button className="bg-yellow-500 shadow-md rounded-sm p-2 text-white">
              {req?.creatorCriteria.gender}
            </button>
          </div>
          <div className="flex justify-between items-center my-2">
            <p className="">Language</p>
            <button className="bg-blue-500 shadow-md rounded-sm p-2 text-white">
              {req?.creatorCriteria.language}
            </button>
          </div>
          <div className="flex justify-between items-center my-2">
            <p className="">Age Group</p>
          </div>
        </div>
        <div className=" col-span-3 row-span-3 rounded-md shadow-lg bg-white p-2">
          <p className=" border-b">Product Details</p>
          <button className="bg-green-500 shadow-md rounded-sm mt-4 p-2 text-white">
            {req?.productDetails.productType}
          </button>
          <div className="flex items-center justify-center">
            <img
              src={req.productDetails.productImage}
              alt=""
              className=""
              width="300px"
            />
          </div>
          <div className="flex justify-between items-center my-2">
            <p className="">Type</p>
            <button className="bg-yellow-500 shadow-md rounded-sm p-2 text-white">
              {req?.productType}
            </button>
          </div>
          <div className="my-2">
            <p className="text-xs text-gray-400">product name:</p>
            <p className="">{req.productDetails.productName}</p>
          </div>
          <div className="my-2">
            <p className="text-xs text-gray-400">product value:</p>
            <p className="">
              {Number.parseInt(req.productDetails.productValue).toLocaleString(
                "en-US",
                { style: "currency", currency: "INR" }
              )}
            </p>
          </div>
          <div className="my-2">
            <p className="text-xs text-gray-400">product link:</p>
            <a
              href={req.productDetails.productLink}
              className="text-blue-500"
              rel="noreferrer"
              target="_blank"
            >
              {req.productDetails.productLink}
            </a>
          </div>
        </div>

        <div className=" col-span-5 rounded-md shadow-lg bg-white p-2 font-semibold">
          <p className=" border-b mb-8">PayOut Details</p>
          <div className="flex justify-between items-center">
            <p className="">Total Vidoes requested</p>
            <p className=""> {req.videos}</p>
          </div>
          <div className="flex justify-between items-center border-b my-2 ">
            <p className="">Vider per cost</p>
            <p className="">
              {req.perVideoAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INr",
              })}
            </p>
          </div>
          <div className="flex justify-between items-center">
            <p className="">Total Payable amount</p>
            <p className="">
              {req.totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INr",
              })}
            </p>
          </div>
        </div>
        <div className=" col-span-5 rounded-md shadow-lg bg-white p-2">
          <p className=" border-b mb-8">Thumbnail Instructions</p>
          <p className="">
            {req.photos &&
              req.photosDescription.split("\n")?.map((r, index) => (
                <p className="" key={"notes" + index}>
                  {r}
                </p>
              ))}
          </p>
        </div>
        <div className=" col-span-4 rounded-md shadow-lg bg-white p-2">
          <p className=" border-b mb-8">Video Instructions</p>
          <p className="">
            {req.notes?.map((r, index) => (
              <p className="" key={"notes" + index}>
                {index + 1}. {r}
              </p>
            ))}
          </p>
        </div>
        <div className=" col-span-4 rounded-md shadow-lg bg-white p-2">
          <p className=" border-b mb-8 font-bold">Update</p>
          <p className="">
            This will be shown on application as title and subtitle
          </p>
          <div className="flex flex-col">
            <p className="text-xs text-gray-400">title*</p>
            <input
              type="text"
              placeholder="title"
              ref={titleRef}
              defaultValue={req.title}
              className="h-10 px-3 border"
            />
            <br />
            <p className="text-xs text-gray-400">
              Giveway (extra things other than coins)
            </p>
            <input
              type="text"
              ref={givewayRef}
              className="h-10 px-3 border"
              defaultValue={req.giveway}
              placeholder="Giveway"
            />
            <br />
            <p className="text-xs text-gray-400">Reward Coins*</p>
            <input
              type="number"
              ref={rewardCoinsRef}
              className="h-10 px-3 border"
              defaultValue={req.rewardCoin}
              placeholder="reward coins"
            />
            <div className="flex items-center justify-center h-96">
              <div className="flex items-start justify-center">
                <label htmlFor="coverimage">
                  {req.coverImage ? (
                    <img src={req.coverImage} alt="" className="h-32 w-54" />
                  ) : (
                    <div className="border  rounded text-center w-54 h-32   flex flex-col justify-center items-center cursor-pointer">
                      <i className="fas fa-plus  text-lg "></i>

                      <p className="text-sm text-center">
                        Upload Cover Image here
                      </p>
                    </div>
                  )}
                </label>

                <input
                  type="file"
                  accept="image/*"
                  id="coverimage"
                  className="hidden"
                  onChange={(e) => coverImageUpload(e)}
                />
              </div>
              <div className="flex items-start justify-center">
                <label htmlFor="productImage">
                  {req.productImage ? (
                    <img src={req.productImage} alt="" className="h-32 w-54" />
                  ) : (
                    <div className="border  rounded text-center w-54 h-32 flex flex-col  justify-center items-center cursor-pointer bg-white">
                      <i className="fas fa-plus fa-blue  text-lg "></i>

                      <p className="text-sm text-center">
                        Upload productImage here
                      </p>
                    </div>
                  )}
                </label>

                <input
                  type="file"
                  accept="image/*"
                  id="productImage"
                  className="hidden"
                  onChange={(e) => productImageUpload(e)}
                />
              </div>
            </div>
            {req.status === "Ready" ? null : (
              <div className="flex items-center">
                <button
                  className="h-10 flex-1 rounded shadow-md bg-purple-500 text-white"
                  onClick={() => updateStatus("Ready")}
                >
                  Update and Push to Ready
                </button>
                <span className="mx-3">Or</span>
                <button
                  className="h-10 rounded shadow-md bg-red-500 text-white px-3"
                  onClick={() => updateStatus("Rejected")}
                >
                  Reject
                </button>
              </div>
            )}
          </div>
        </div>
        <div className=" col-span-8">
          <HuntSubmissions houseId={req._id} />
        </div>
      </div>
    </div>
  );
}

export default ViewBrandRequest;
