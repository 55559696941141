import { DataGrid } from "@material-ui/data-grid";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { firebasefb } from "./firebaseconfig";
import DataCard from "./utils/DataCard";
import { data } from "./utils/Res";

function Request() {
  const history = useHistory();
  const [report, setreport] = useState([]);
  const [loading, setloading] = useState(true);
  useEffect(() => {
    firebasefb
      .collection("requests")
      .get()
      .then((res) => {
        var a = [];
        res.docs.forEach((r) => a.push({ ...r.data(), id: r.id }));
        setreport(a);
        setloading(false);
      });
  }, []);

  const columns = [
    { field: "name", headerName: "Name", width: 190 },
    { field: "desc", headerName: "Descrition", width: 190 },
    { field: "category", headerName: "Category", width: 190 },
    { field: "type", headerName: "Type", width: 190 },
    { field: "fulfilled", headerName: "Fulfilled", width: 190 },

    {
      field: "actions",
      headerName: "Actions",
      width: 320,
      // eslint-disable-next-line react/display-name
      renderCell: ({ row }) => (
        <div>
          <button
            className={data.greenButton}
            onClick={() => history.push(`/home/request/${row.id}`)}
          >
            View
          </button>
        </div>
      ),
    },
  ];

  if (loading)
    return (
      <div className="flex h-screen justify-center items-center ">
        <span className="fas fa-spinner animate-spin"></span>
      </div>
    );
  else
    return (
      <div className="font-Manrope">
        <div className="flex justify-between items-center m-10 ">
          <p className="text-3xl font-medium">Total Requests:</p>
          <p className="text-3xl font-semibold "> {report.length}</p>
        </div>

        <div className="m-10 ">
          <div className="h-screen bg-white shadow-lg">
            <DataGrid rows={report} columns={columns} pageSize={20} />
          </div>
        </div>
        <div className="h-2"></div>
      </div>
    );
}

export default Request;
