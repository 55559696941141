import AWN from "awesome-notifications";

import { nanoid } from "nanoid";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { firebasefb, storage } from "./firebaseconfig";
import firebase from "firebase";
import SearchgetSingle from "./utils/SearchgetSingle";

function UploadReview() {
  const history = useHistory();
  const router = useHistory();
  const { userid } = useParams();
  const [user, setuser] = useState({});
  const [parent, setparent] = useState(null);
  const [loading, setloading] = useState(true);
  const [reviewurl, setReviewUrl] = useState(null);
  const [thumbnail, setthumbnail] = useState(null);
  const [progress, setprogress] = useState(0);

  let brandIdRef = useRef();
  let brandNameRef = useRef();
  let affLinkRef = useRef();
  let catRef = useRef();
  let subcatRef = useRef();
  let titleRef = useRef();
  let descRef = useRef();

  var uploadedBy = {
    id: userid,
    image: user.imageUrl ?? "",
    tag: user.tag ?? "",
    name: user.name ?? "",
  };

  const reviewid = "review_" + nanoid(16);

  useEffect(() => {
    firebasefb
      .collection("user")
      .doc(userid)
      .get()
      .then((res) => {
        setuser(res.data());
        setloading(false);
      });
  }, []);

  const savereview = () => {
    if (!parent) {
      return new AWN().warning("select parent product.");
    }
    if (!reviewurl) {
      return new AWN().warning("upload review please.");
    }
    if (titleRef.current.value.length < 3) {
      return new AWN().warning("more than 3 letters required for review title");
    }
    if (descRef.current.value.length < 50) {
      return new AWN().warning(
        "more than 50 letters required for review description"
      );
    }
    try {
      if (brandIdRef.current.value.includes(" ")) {
        return new AWN().warning("Please enter valid brand id");
      }
    } catch (e) {
      console.log(e);
    }

    const review = {
      thumbnail: thumbnail,
      reviewUrl: reviewurl,
      name: titleRef.current.value,
      description: descRef.current.value,
      bpsId: parent.id ?? null,
      catid: parent.catId ?? catRef.current.value,
      subcategory: parent.subcategory ?? subcatRef.current.value,
      productImage: parent.image ?? null,
      affiliateLink: affLinkRef.current.value ?? null,
      brand: parent.parent ?? brandIdRef.current.value,
      brandName: parent.parentName ?? brandNameRef?.current?.value ?? null,
      dateAdded: firebase.firestore.Timestamp.now(),
      id: reviewid,
      type: "review",
      parentName: parent.name ?? null,
      attributes: parent.attributes ?? null,
      rating: 3,
      uploadedBy: uploadedBy,
      views: 0,
      status: false,
    };

    console.log(review);

    firebasefb
      .collection("review")
      .doc(reviewid)
      .set(review)
      .then((res) => {
        new AWN().success("success");
        history.goBack();
      })
      .catch((e) => {
        new AWN().warning(e);
      });
  };

  const uploadreview = (e) => {
    if (loading) return;

    var image = e.target.files[0];
    if (!image) {
      new AWN().alert("no review file selected", {
        position: "bottom-right",
      });
      setloading(false);
      return;
    }
    var storageRef = storage.ref();
    var uploadTask = storageRef.child(`reviews2/${reviewid}.mp4`).put(image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        setprogress(progress);
      },
      (error) => {
        // Handle unsuccessful uploads
        alert(error);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          setReviewUrl(downloadURL);
        });
      }
    );
  };

  const uploadthumbnail = (e) => {
    if (loading) return;

    var image = e.target.files[0];
    if (!image) {
      new AWN().alert("no image file selected", {
        position: "bottom-right",
      });
      setloading(false);
      return;
    }
    var storageRef = storage.ref();
    var uploadTask = storageRef.child(`thumbnails/${reviewid}`).put(image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        setprogress(progress);
      },
      (error) => {
        // Handle unsuccessful uploads
        alert(error);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          setthumbnail(downloadURL);
        });
      }
    );
  };

  console.log(parent);

  if (loading) {
    return (
      <div className="min-h-screen bg-panel_bg_secondary flex items-center justify-center">
        <i className="fas fa-spinner animate-spin"></i>
      </div>
    );
  } else
    return (
      <div className="bg-panel_bg_secondary min-h-screen py-10 px-6 font-Manrope ">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-3">
            <img
              src={require("./images/icons/leftarrow.png").default}
              alt="back"
              className="transform scale-75 cursor-pointer"
              onClick={() => router.goBack()}
            />
            <p className="">Upload a Review</p>
          </div>
        </div>
        <div className="bg-white p-5 mt-5 rounded-md shadow-sm relative ">
          <div className="border-b border-dashed pb-5">
            <p className="font-bold text-lg">Product Title</p>
            {parent?.name ?? "Please select Product"}
            <SearchgetSingle collection="product" setselect={setparent} />

            <div className="py-2 mt-10">
              <p className="">Review Title</p>
              <input
                type="text"
                ref={titleRef}
                className="appearance-none outline-none border-b text-nav_active_bg font-medium text-sm tracking-wide pr-10 w-96 h-10"
                placeholder="Title"
              />
            </div>
            <div className="py-2 pb-6">
              <p className="">Review Description</p>
              <textarea
                type="text"
                ref={descRef}
                className="mt-1 appearance-none outline-none border-b text-nav_active_bg font-medium text-sm tracking-wide pr-10 w-96 h-10"
                placeholder="Description"
              ></textarea>
            </div>
          </div>
          {parent != null && !parent.parent ? (
            <div className="">
              <p className="text-red-500 py-2">Brand not present *(Optional)</p>
              <p className="">Please Enter brand Id </p>
              <input
                type="text"
                ref={brandIdRef}
                className="my-1 px-3 border appearance-none border-green-500 w-1/2 h-12"
                placeholder="Brand Id"
              />
              <br />
              <p className="mt-3">Please Enter brand Name </p>
              <input
                type="text"
                ref={brandNameRef}
                className="px-3 border appearance-none border-green-500 w-1/2 h-12"
                placeholder="Brand name"
              />
            </div>
          ) : null}
          {parent != null && !parent.parentName ? (
            <div className="">
              <p className="mt-3">Please Enter brand Name </p>
              <input
                type="text"
                ref={brandNameRef}
                className="px-3 border appearance-none border-green-500 w-1/2 h-12"
                placeholder="Brand name"
              />
            </div>
          ) : null}
          <p className="mt-3">Affiliate Link (optional) </p>
          <input
            type="text"
            ref={affLinkRef}
            className="px-3 border appearance-none border-green-500 w-1/2 h-12"
            placeholder="Brand name"
          />

          {parent == null ? (
            <div className="">
              <p className="">
                Please Category Id (copy and paste category id)
              </p>
              <input
                type="text"
                ref={catRef}
                className="my-1 px-3 border appearance-none border-green-500 w-1/2 h-12"
                placeholder="category Id"
              />
              <p className="">
                Please sub category Id (copy and paste sub-category id)
              </p>
              <input
                type="text"
                ref={subcatRef}
                className="my-1 px-3 border appearance-none border-green-500 w-1/2 h-12"
                placeholder="sub-category"
              />
            </div>
          ) : null}

          {parent != null ? (
            <div className="">
              <p className="my-3">Please select rating for this product</p>
              <div className="grid grid-rows-2 grid-flow-col">
                {Object.entries(parent.attributes).map(([key, value]) => (
                  <div className="" key={key + "attributess"}>
                    <p className="">{key}</p>
                    <div className="flex gap-2">
                      {Array.from({ length: 5 }).map((_, index) =>
                        index + 1 <= value ? (
                          <i
                            className="fas fa fa-star text-3xl cursor-pointer text-purple-700"
                            key={"star" + index}
                            onClick={() => {
                              let attr = parent.attributes;

                              attr[key] = index + 1;
                              setparent({ ...parent, attributes: attr });
                            }}
                          ></i>
                        ) : (
                          <i
                            className="fas fa fa-star text-3xl cursor-pointer text-gray-300"
                            key={"star" + index}
                            onClick={() => {
                              let attr = parent.attributes;
                              attr[key] = index + 1;
                              setparent({ ...parent, attributes: attr });
                            }}
                          ></i>
                        )
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          <div className="py-5 grid grid-cols-2">
            <div className="">
              <p className="font-medium">Rules to follow before upload :</p>
              <ul className="text-sm font-medium list-disc px-5">
                <li>
                  Make Sure review fits the guidelines of recording review.
                </li>
                <li>review should not exceed more than 60 seconds</li>
                <li>review should not be less than 20 seconds</li>
                <li>review quality should be medium and in 25 fps</li>
                <li>review size do not exceed more than 20mb file</li>
              </ul>
            </div>
            <div className="relative flex border border-red-400">
              <input
                type="file"
                accept="video/mp4"
                className="hidden"
                onChange={(e) => uploadreview(e)}
                id="review"
              />
              <input
                type="file"
                id="thumbnail"
                onChange={(e) => uploadthumbnail(e)}
                accept="image/png, image/jpeg, image/jpg"
                className="hidden"
              />

              <label htmlFor="review">
                {reviewurl ? (
                  <div className="flex m-2 flex-col items-center justify-center">
                    <video src={reviewurl} controls className="w-52"></video>
                    <p className="">Review</p>
                  </div>
                ) : (
                  <div className="m-2 bg-red-200 p-2 rounded-md h-60 w-44 flex flex-col items-center justify-center">
                    <i className="fas fa fa-upload fa-lg"></i>
                    <p className="my-2">Upload Review</p>
                  </div>
                )}
              </label>
              <label htmlFor="thumbnail">
                {thumbnail ? (
                  <div className="flex m-2 flex-col items-center justify-center">
                    <img src={thumbnail} alt="" className="" />
                    <p className="">thumbnail</p>
                  </div>
                ) : (
                  <div className="m-2 bg-red-200 p-2 rounded-md h-60 w-44 flex flex-col items-center justify-center">
                    <i className="fas fa fa-upload fa-lg"></i>
                    <p className="my-2">Upload Thumbnail</p>
                  </div>
                )}
              </label>
            </div>
          </div>
          <button
            className="rounded shadow-md h-12 px-4 bg-green-500 text-white "
            onClick={savereview}
          >
            Upload Review
          </button>
        </div>
      </div>
    );
}

export default UploadReview;
