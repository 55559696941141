import React from "react";
import { Cell, PieChart, Pie } from "recharts";

function UserCoinStats({ coins, totalCoins }) {
  const data = [
    { name: "coins", value: coins },
    { name: "Coins Burned", value: totalCoins - coins },
  ];
  const COLORS = ["#FFBB28", "#FF8042"];

  return (
    <div className="text-white text-3xl shadow-lg border bg-blue-500 rounded-md  relative flex flex-col items-center justify-start ">
      <PieChart width={250} height={240}>
        <Pie
          data={data}
          cx={120}
          cy={120}
          innerRadius={60}
          outerRadius={80}
          fill="#FFBB28"
          stroke="none"
          paddingAngle={6}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
      <div className="grid grid-cols-2 items-center gap-3">
        <p className="text-3xl">{coins}</p>
        <p className="text-xs">coins left</p>
        <p className="text-3xl">{totalCoins - coins}</p>
        <p className="text-xs">coins burned</p>
        <p className="text-3xl">{totalCoins}</p>
        <p className="text-xs">coins earned</p>
      </div>
    </div>
  );
}

export default UserCoinStats;
