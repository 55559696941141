import AWN from 'awesome-notifications';
import React, { useState } from 'react'
import { firebasefb, storage } from '../firebaseconfig';
import { data } from "../utils/Res"

function SubcategoryUpdateCard({subcat,catid,images }) {


  const [editMode,setEditMode] = useState();


  const deleteSubcat=(name)=>{
    let newimages = images.filter((f)=>f.name!==name);
    let notifier = new AWN();
    let onOk = () => {
      firebasefb
        .doc("category/" + catid)
        .update({
          images:newimages
        })
        .then((res) => {
          new AWN().success("successfully updated");
        
        })
        .catch((e) => {
          console.log(e);
          new AWN().alert("something went wrong");
        });
    };
    let onCancel = () => {
      notifier.info("You pressed Cancel");
    };
    notifier.confirm("Are you sure?", onOk, onCancel, {
      labels: {
        confirm: "Dangerous action",
      },
    });

  }
  
  const updateSubCatImage = (e, name) => {
    var image = e.target.files[0];
    if (!image) {
      new AWN().alert("no image file selected", {
        position: "bottom-right",
      });
      return;
    }
    var storageRef = storage.ref();
    storageRef
      .child(`category/${catid + name}`)
      .put(image)
      .then((snapshot) => {
        snapshot.ref
          .getDownloadURL()
          .then( function (downloadURL) {
            let newimages = images.filter((f)=>f.name!==name);
            newimages.push({icon:downloadURL,name:name});
             firebasefb.doc("category/"+catid).update({images:newimages})
            console.log("updated");
            new AWN().success("successfull");
          })
          .catch((err) => {
            new AWN().alert(e.message, {
              position: "bottom-right",
            });
            new AWN().alert(e);
           
          });
      })
      .catch((e) => {
        new AWN().alert(e);
        
      });
  };


  return (
    <div className="bg-white mt-4 ">
             <div className="flex items-center">
                  {subcat?.icon ? (
                    <img
                      src={
                        subcat?.icon
                      }
                      alt=""
                      className={"h-32 w-32 rounded "}
                    />
                  ) : (
                    <div>
                      <label htmlFor="subimages">
                        <div className=" text-center w-24 h-24 flex  justify-center items-center cursor-pointer bg-box">
                          <i className="fas fa-plus  fa-blue  text-lg"></i>
                        </div>
                      </label>

                      <input
                        type="file"
                        accept="image/*"
                        id="subimages"
                        className="hidden"
                        onChange={(img) => updateSubCatImage(img, subcat.name)}
                      />
                    </div>
                  )}
                  <div className="flex justify-between px-4 w-full">
                    <p>{subcat.name.toString()}</p>
                   <div className="">
                   <button
                     onClick={()=>setEditMode(!editMode)}
                     className={data.purpleButton}
                    >
                     {editMode?"Cancel" :"Update"}
                    </button>
                    <button
                     onClick={()=>deleteSubcat()}
                     className={data.redButton}
                    >
                      Delete
                    </button>
                   </div>
                  </div>
                  </div>  

                  {editMode? <div className="mx-4 my-2 w-24">
                      <label htmlFor="subimages" className='' >
                        <div className=" text-center  h-24 flex  justify-center items-center cursor-pointer bg-box">
                          <i className="fas fa-plus  fa-blue  text-lg"></i>
                        </div>
                      </label>

                      <input
                        type="file"
                        accept="image/*"
                        id="subimages"
                        className="hidden"
                        onChange={(img) => updateSubCatImage(img, subcat.name)}
                      />
                  </div> :null}
              
            </div>
  )
}

export default SubcategoryUpdateCard