import AWN from "awesome-notifications";
import React, { useRef } from "react";
import { useState } from "react";
import axios from "axios";
import urls from "../utils/urls";

function MergeProduct() {
  let queryRef = useRef();
  const [result, setResult] = useState([]);
  const [container, SetContainer] = useState([]);
  const [primary, setPrimary] = useState(null);

  const saerchNow = async (e) => {
    e.preventDefault();
    let q = queryRef.current.value;
    q = q.trim();
    const response = await axios(urls.admin + "searchProducts", {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
        Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
      },
      data: JSON.stringify({ query: q }),
    });

    if (response.status === 200) {
      let res = await response.data;
      setResult(res);
    }
  };

  const addToContainer = (row) => {
    let index = container.findIndex((r) => r.id === row.id);
    if (index === -1) {
      SetContainer([...container, row]);
    } else {
      SetContainer(container.filter((r) => r.id !== row.id));
    }
  };
  const removeFromContainer = (row) => {
    SetContainer(container.filter((r) => r.id !== row.id));
  };

  const checkFirst = () => {
    if (container.length <= 1) {
      return new AWN().warning("Please select some or more product first");
    }
    if (!primary) {
      return new AWN().warning("Please choose primary product.");
    }
    let index = container.findIndex((row) => row.id === primary.id);
    if (index === -1) {
      return new AWN().warning("Please choose primary product.");
    }
    const prime = primary.id;
    let prods = container.filter((r) => r.id !== prime);
    prods = prods.map((r) => r.id);

    new AWN().async(
      axios(urls.admin + "mergeProducts", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: urls.adminAuth,
        },
        data: JSON.stringify({ primary: prime, prods: prods }),
      }),
      (resp) => {
        if (resp.data.success) {
          new AWN().success("SuccessFully merged");
        } else {
          new AWN().warning(resp.data.message);
        }
      },
      (err) => {
        new AWN().warning(err);
      },
      "Operation ongoing."
    );
  };

  return (
    <div>
      <p className="font-semibold text-3xl py-4 px-3 border-b">Merge Product</p>

      <div className="flex gap-5">
        <div className="flex-1 p-4">
          <div className="w-full">
            <form onSubmit={saerchNow}>
              <input
                type="text"
                ref={queryRef}
                className="w-full h-12 px-2 border"
                placeholder="search product here..."
              />
            </form>
            <ul className="h-screen my-5 overflow-scroll">
              {result.map((res, index) => (
                <div key={res.id} className="h-10 flex justify-between">
                  <p className="">
                    {res.name} <br />
                    <span className="text-xs">
                      {res.parent ?? "Not Present"}, Reviews:
                      {res.totalReviews ?? 0}
                    </span>
                  </p>
                  <button
                    className="m-2 text-white bg-green-500 rounded px-2"
                    onClick={() => addToContainer(res)}
                  >
                    Select
                  </button>
                </div>
              ))}
            </ul>
          </div>
        </div>
        <div className="flex-1">
          <p className=" mx-3 text-3xl">Product Selected</p>
          <ul className="h-96 overflow-scroll my-5">
            {container.map((res, index) => (
              <div className=" flex justify-between">
                <p className="text-black">
                  {res.name}
                  {primary?.id === res?.id ? (
                    <span className="text-green-500 p-2 rounded text-xs">
                      Primary
                    </span>
                  ) : null}{" "}
                  <br />
                  <span className="text-xs">
                    {res.parent ?? "Not Present"}, Reviews:
                    {res.totalReviews ?? 0}
                  </span>
                </p>
                <div className="flex">
                  <button
                    className="m-2 text-white bg-red-500 rounded px-2"
                    onClick={() => removeFromContainer(res)}
                  >
                    DeSelect
                  </button>
                  <button
                    className="m-2 text-white bg-yellow-500 rounded px-2"
                    onClick={() => setPrimary(res)}
                  >
                    Primary
                  </button>
                </div>
              </div>
            ))}
          </ul>
          <div className="flex">
            <button
              className="bg-green-500 text-white py-2 px-3 rounded"
              onClick={checkFirst}
            >
              Start Merge Process
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MergeProduct;
