import { useEffect, useState } from "react";
import { firebasefb } from "./firebaseconfig";
import { useHistory } from "react-router-dom";
import CustomDataGrid from "./utils/CustomDataGrid";

function BrandAdmin() {
  const history = useHistory();
  const [loading, setloading] = useState(false);
  const [user, setuser] = useState([]);
  const [searchResult, setsearchResult] = useState([]);
  const [query, setquery] = useState("");
  useEffect(() => {
    setloading(true);
    firebasefb
      .collection("brandAdmin")
      .get()
      .then((res) => {
        var a = [];
        res.docs.forEach((doc) => {
          var b = doc.data();
          if (typeof b.dateJoined == "string") console.log(b);
          a.push({ ...b, id: b.brandId, dateJoined: b.dateJoined ?? 0 });
        });
        setuser(a);
        setloading(false);
      })
      .catch((e) => {
        console.log(e);
        setloading(false);
        alert(e.message);
      });
  }, []);
  const columns = [
    { field: "email", headerName: "Email", width: 230 },

    { field: "name", headerName: "Name", width: 300 },
    { field: "brandId", headerName: "Brand ID", width: 100 },
    { field: "pass", headerName: "First Password", width: 100 },
    {
      field: "dateJoined",
      headerName: "Join Date",
      renderCell: (row) => {
        return (
          <div className="">
            {new Date(row.dateJoined).toLocaleDateString()}
          </div>
        );
      },
      width: 300,
    },
  ];

  if (loading)
    return (
      <div className="bg-panel_bg">
        <div className=" h-screen flex items-center justify-center">
          <span className="fas fa-spinner animate-spin"></span>
        </div>
      </div>
    );
  else
    return (
      <div className="bg-panel_bg min-h-screen py-6 font-Manrope">
        <div className="flex justify-between items-center m-10 ">
          <p className="text-3xl font-medium">Total Brand Admins:</p>
          <p className="text-3xl font-semibold text-nav_active_color">
            {" "}
            {user.length}
          </p>
        </div>
        <div className="flex w-full px-10 my-6">
          <form action="" className="flex-1 items-center relative w-full">
            <input
              type="text"
              onChange={(e) => {
                setquery(e.target.value);
                setsearchResult(
                  user.filter((row) =>
                    row.email
                      .toLowerCase()
                      ?.includes(e.target.value?.toLowerCase())
                  )
                );
              }}
              className="pr-8 py-2 px-3 w-full appearance-none outline-none border focus:border-nav_active_color"
              placeholder="Search by Email, Name, Username, User ID"
            />
          </form>
          <button
            className="ml-3 w-64 py-2 px-8 bg-green-400 rounded text-white"
            onClick={() => history.push("/home/brandTools")}
          >
            Brand Onboarding Tools
          </button>
          <button
            className="ml-3 w-64 py-2 px-8 bg-blue-400 rounded text-white"
            onClick={() => history.push("/home/createBrandAdmin")}
          >
            Create Brand Admin
          </button>
        </div>

        <div className=" max-h-full m-10 bg-white rounded">
          {query.length === 0 ? (
            <CustomDataGrid
              rows={user}
              columns={columns}
              pageSize={20}
              loadMore={() => {}}
            />
          ) : (
            <CustomDataGrid
              rows={searchResult}
              columns={columns}
              pageSize={20}
              loadMore={() => {}}
            />
          )}
        </div>
      </div>
    );
}

export default BrandAdmin;
