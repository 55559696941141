import React, { useState } from "react";
import { useEffect } from "react";
import IdeasFromCreators from "./IdeasFromCreators";
import ProductTopCards from "./ProductTopCards";
import ReviewersTopCards from "./ReviewerTopCards";
import TopBrandCards from "./TopBrandCards";

function TopRankings({ top }) {
  const [rangeValue, setrangvalue] = useState("0");
  const [topUsers, setTopUsers] = useState([]);
  const [topProducts, settopProducts] = useState([]);
  const [topbrands, settopBrands] = useState([]);
  const rangeactive =
    "w-32 bg-blue-500 h-10 my-2 rounded flex items-center px-2 text-white text-sm tracking-wide";
  const rangepassive =
    "w-32 h-10 my-2 rounded flex items-center px-2 text-black text-sm tracking-wide";

  const listTop = () => {
    if (rangeValue === "0") {
      return <TopBrandCards brands={topbrands} />;
    } else if (rangeValue === "1")
      return <ProductTopCards products={topProducts} />;
    else if (rangeValue === "2")
      return <ReviewersTopCards reviewers={topUsers} />;
    else if (rangeValue === "3") return <IdeasFromCreators />;
  };

  const fetchUser = async () => {
    let chart = sessionStorage.getItem("topUsers");
    if (chart) {
      chart = JSON.parse(chart);
      setTopUsers(chart.data);
      return;
    }
    console.log("fetching from server top users");
    const response = await fetch(
      "https://userspecificapi-zscu3untuq-el.a.run.app/getTopUser/user_admin",
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "asdfwp234-0835234-34535FHD568-a26463213234234-llksdfsdlfwSLDF",
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }
    );

    if (response.status === 200) {
      let res = await response.json();
      sessionStorage.setItem("topUsers", JSON.stringify(res));
      setTopUsers(res.data);
    }
  };
  const fetchBrands = async () => {
    let chart = sessionStorage.getItem("topBrands");
    if (chart) {
      chart = JSON.parse(chart);
      settopBrands(chart.data);
      return;
    }
    console.log("fetching from server top users");
    const response = await fetch(
      "https://userspecificapi-zscu3untuq-el.a.run.app/getTopBrand/user_admin",
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "asdfwp234-0835234-34535FHD568-a26463213234234-llksdfsdlfwSLDF",
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }
    );

    if (response.status === 200) {
      let res = await response.json();
      sessionStorage.setItem("topBrands", JSON.stringify(res));
      settopBrands(res.data);
    }
  };
  const fetchProducts = async () => {
    let chart = sessionStorage.getItem("topProducts");
    if (chart) {
      chart = JSON.parse(chart);
      settopProducts(chart.data);
      return;
    }
    console.log("fetching from server top users");
    const response = await fetch(
      "https://userspecificapi-zscu3untuq-el.a.run.app/getTopProduct/user_admin",
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "asdfwp234-0835234-34535FHD568-a26463213234234-llksdfsdlfwSLDF",
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }
    );

    if (response.status === 200) {
      let res = await response.json();
      sessionStorage.setItem("topProducts", JSON.stringify(res));
      settopProducts(res.data);
    } else {
      console.log(response);
    }
  };

  useEffect(() => {
    fetchBrands();
    fetchProducts();
    fetchUser();
  }, []);
  return (
    <div className="bg-white rounded border p-3 w-full my-5 h-80 shadow-md">
      <div className="border-b">
        <p className="border-b-2 border-purple-500 w-max">Top Rankings:</p>
      </div>
      <div className="flex justify-between py-5">
        <div className="p-2 flex relative">
          <div className="select-none">
            <div
              className={rangeValue === "0" ? rangeactive : rangepassive}
              onClick={() => setrangvalue("0")}
            >
              Top Brands
            </div>
            <div
              className={rangeValue === "1" ? rangeactive : rangepassive}
              onClick={() => setrangvalue("1")}
            >
              Top Products
            </div>
            <div
              className={rangeValue === "2" ? rangeactive : rangepassive}
              onClick={() => setrangvalue("2")}
            >
              Top Reviewers
            </div>
            <div
              className={rangeValue === "3" ? rangeactive : rangepassive}
              onClick={() => setrangvalue("3")}
            >
              Ideas From creators
            </div>
          </div>
        </div>

        {listTop()}
      </div>
    </div>
  );
}

export default TopRankings;
