import AWN from "awesome-notifications";
import axios from "axios";
import moment from "moment";
import React, { useRef, useState } from "react";
import { firebasefb } from "./firebaseconfig";
import { data } from "./utils/Res";
import firebase from "firebase";
function PushNotification({ setshow, row, message }) {
  const [loading, setloading] = useState(false);
  let messageref = useRef();
  let titleref = useRef();
  let topicref = useRef();

  const sendSubscribedNotification = async () => {
    if (loading) return;
    if (titleref.current?.value?.length < 10)
      return new AWN().warning("add more words in headline");
    if (messageref.current.value.length < 10)
      return new AWN().warning("add more words in message");
    setloading(true);
    const message = {
      header: titleref.current.value,
      message: messageref.current.value,
      time: moment(Date.now()).toString(),
      type: "publish",
      token: row.token,
      multiple: true,
      topic: topicref.current.value,
    };

    const notification = {
      message: {
        notification: {
          title: message.header,
          body: message.message,
        },
        topic: message.topic,
      },
    };
    const result = await axios.post(
      "https://asia-south1-revueapp-4eeed.cloudfunctions.net/queueNotice/api/topicNotification",
      notification
    );

    if (result.status === 200 && result.data.success) {
      await firebasefb
        .collection("notifications")
        .doc(row.id)
        .update({ type: "publish", topic: message.topic })
        .then((res) => {
          new AWN().success("notification queued");
          setshow(false);
          setloading(false);
        })
        .catch((e) => {
          new AWN().alert(e);
          setloading(false);
          return;
        });
    } else {
      console.log(result);
      setloading(false);
      new AWN().alert("something went wrong");
    }
    return;
  };

  const sendnow = async () => {
    if (loading) return;
    if (titleref.current?.value?.length < 10)
      return new AWN().warning("add more words in headline");
    if (messageref.current.value.length < 10)
      return new AWN().warning("add more words in message");
    setloading(true);
    const message = {
      header: titleref.current.value,
      message: messageref.current.value,
      time: moment(Date.now()).toString(),
      type: "publish",
      token: row.token,
      multiple: false,
      user: {
        name: row.name,
        imageUrl: row.imageUrl,
        id: row.id,
      },
    };

    const notification = {
      token: row.token,
      message: {
        notification: {
          title: message.header,
          body: message.message,
        },
      },
    };
    console.log(notification);

    const result = await axios.post(
      "https://restapi-zscu3untuq-em.a.run.app/notification",
      notification
    );

    if (result.status === 200 && result.data.success) {
      await firebasefb
        .collection("userActivity")
        .doc(row.id)
        .update({
          activity: firebase.firestore.FieldValue.arrayUnion({
            by: "admin",
            imageUrl:
              "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/FCMImages%2Flogowhite.png?alt=media&token=3230798d-cd33-47d5-9b41-d1cba0faa229",
            time: firebase.firestore.Timestamp.now(),
            to: message.message,
            type: "notification",
          }),
        });
      await firebasefb
        .collection("notifications")
        .add(message)
        .then((res) => {
          new AWN().success("notification queued");
          setshow(false);
          setloading(false);
        })
        .catch((e) => {
          new AWN().alert(e);
          setloading(false);
          return;
        });
    } else {
      console.log(result);
      setloading(false);
      new AWN().alert("something went wrong");
    }
    return;
  };
  if (row.multiple) {
    return (
      <div className="border h-screen fixed  w-full top-0 left-0 bg-black flex items-center justify-center">
        <div className="w-1/2  bg-gray-100  border-2 shadow-2xl rounded flex flex-col">
          <div className=" px-5 border-b border-pink-500 flex items-center justify-between">
            <p className=" font-bold ">Push Notification</p>
            <i
              className="fas fa-times w-10 text-center text-red-700 p-3 m-3 rounded-full cursor-pointer border-red-600 border "
              onClick={() => {
                setshow(false);
              }}
            ></i>
          </div>
          <div className="flex items-center justify-between h-12 px-5 my-2">
            <p className="">Select subscription</p>
            <select className={data.textInput} ref={topicref}>
              <option value="appUser">App Users</option>
            </select>
          </div>
          <div className="flex-1 p-5">
            <input
              type="text"
              ref={titleref}
              defaultValue={message?.header}
              className={data.textInputStyle}
              placeholder="notification title"
            />
            <input
              type="text"
              ref={messageref}
              defaultValue={message?.message}
              className={data.textInputStyle}
              placeholder="notification message"
            />
          </div>
          <div className="text-right p-5">
            <button
              onClick={() => sendSubscribedNotification()}
              className={data.greenButton}
            >
              Push Now
            </button>
          </div>
        </div>
      </div>
    );
  } else
    return (
      <div className="h-screen fixed  w-full top-0 left-0 flex items-center justify-center">
        <div className="w-1/2  bg-gray-100 opacity-100 border-2 shadow-2xl rounded flex flex-col">
          <div className=" px-5 border-b border-pink-500 flex items-center justify-between">
            <p className=" font-bold    ">Push Notification</p>
            <i
              className="fas fa-times w-10 text-center text-red-700 p-3 m-3 rounded-full cursor-pointer border-red-600 border "
              onClick={() => {
                setshow(false);
              }}
            ></i>
          </div>
          <div className="flex items-center justify-between h-12 px-5 my-2">
            <h1 className="">
              {row.name} (
              <span className=" font-light">
                {row.token
                  ? "notification will be recieved"
                  : "will not recieve any notification"}
                )
              </span>
            </h1>
            {row.token ? (
              <i className="fas fa-check-circle m-2 text-green-500 fa-3x"></i>
            ) : (
              <i className="fas fa-times-circle m-2 text-red-500 fa-3x"></i>
            )}
          </div>
          <div className="flex-1 p-5">
            <input
              type="text"
              ref={titleref}
              className={data.textInputStyle}
              placeholder="notification title"
            />
            <input
              type="text"
              ref={messageref}
              className={data.textInputStyle}
              placeholder="notification message"
            />
          </div>
          <div className="text-right p-5">
            <button onClick={() => sendnow()} className={data.greenButton}>
              Push Now
              {loading ? (
                <i className="fa fa-spinner animate-spin mx-2 "></i>
              ) : null}
            </button>
          </div>
        </div>
      </div>
    );
}

export default PushNotification;
