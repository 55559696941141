import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AWN from "awesome-notifications";
import PopUp from "./helpers/PopUp";
import UpdateParentReviewPopup from "./cards/UpdateParentReviewPopup";
import CustomRating from "./helpers/Rating";
import { deleteReviewRequest } from "./helpers/apicall";
import urls from "./utils/urls";
import MoveToFlicks from "./components/MoveToFlicks";
function Viewreview() {
  const { reviewid } = useParams();
  const [tags, setTags] = useState([]);
  const router = useHistory();
  const [review, setreview] = useState(null);
  const [play, setplay] = useState(false);
  const [loading, setloading] = useState(true);
  const [disabled, setdisabled] = useState(false);
  const [editField, setEditField] = useState(null);
  const [selectedTags, setselectedTags] = useState([]);

  useEffect(() => {
    setloading(true);
    fetchReview();
  }, [reviewid]);

  const fetchReview = async () => {
    console.log("fetching from server all users");
    const response = await fetch(urls.admin + "getReview/" + reviewid, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });

    if (response.status === 200) {
      let res = await response.json();
      setreview(res);
      setloading(false);
    }
  };

  const editMode = () => {
    if (!editField) return null;
    if (editField === "parent")
      return (
        <PopUp>
          <UpdateParentReviewPopup close={setEditField} review={review} />
        </PopUp>
      );
  };

  const deletethis = async () => {
    let data = {
      reviewId: review.id,
      brandId: review.brand,
      productId: review.bpsId,
      uploadedBy: review.uploadedBy,
      status: review.status,
    };

    new AWN().confirm(
      "Sure, you want to delete this review",
      () => {
        new AWN().async(
          deleteReviewRequest(data),
          (resp) => {
            console.log(resp);
            new AWN().success(resp.message);
            if (resp.success) {
              let sessionReviews = sessionStorage.getItem("reviews");
              if (sessionReviews) {
                sessionReviews = JSON.parse(sessionReviews);
                sessionReviews = sessionReviews.filter(
                  (r) => r.id !== review.id
                );
                sessionStorage.setItem(
                  "reviews",
                  JSON.stringify(sessionReviews)
                );
              }
              router.goBack();
            }
          },
          (err) => {
            new AWN().alert(err);
          }
        );
      },
      () => {
        new AWN().info("operation cancelled");
      }
    );
  };

  const publish = () => {
    new AWN().async(
      fetch(urls.admin + "publishReview/" + reviewid, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }),
      async (response) => {
        console.log(response);
        if (response.status === 200) {
          let data = await response.json();
          let message = "operation not succeded";
          console.log(data);
          if (data.success) {
            message = data.status ? "published" : "pending";
            new AWN().success(message);
            setreview({ ...review, status: data.status });
            //set in the list also

            let reviewList = sessionStorage.getItem("reviews");
            reviewList = JSON.parse(reviewList);
            let index = reviewList.findIndex((r) => r.id === reviewid);
            if (index !== -1) {
              reviewList[index].status = data.status;
              sessionStorage.setItem("reviews", JSON.stringify(reviewList));
            }
            //set in the list also

            return;
          }
          new AWN().warning(message);
          return;
        }
      },
      (err) => {
        console.log(err);
        new AWN().warning(err.toString());
        return;
      }
    );
  };

  const rejectReview = async () => {};

  const inputfield =
    "appearance-none outline-none border-b text-nav_active_bg font-medium text-sm tracking-wide pr-10 w-full h-10";

  if (loading)
    return (
      <div className="flex justify-center items-center h-screen">
        <span className="fas fa-spinner animate-spin"></span>
      </div>
    );
  else
    return (
      <>
        <div className="bg-panel_bg_secondary min-h-screen py-10 px-6 font-Manrope ">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-3">
              <i
                className="fas fa fa-arrow-left transform scale-75 cursor-pointer"
                onClick={() => router.goBack()}
              ></i>
              <p className="">View review</p>
            </div>
            <div
              className="flex items-center gap-x-3 cursor-pointer"
              onClick={() => setdisabled(!disabled)}
            >
              <i className="fas fa fa-eye transform scale-75 cursor-pointer"></i>
              <p className="">{!disabled ? "Edit Mode" : "View Mode"}</p>
            </div>
          </div>
          <div className="bg-white shadow-sm p-5 rounded mt-5">
            <div className="w-full flex justify-between items-center">
              <div className="flex items-center gap-x-4">
                <div className="">
                  {review.product.imageUrl ? (
                    <img
                      src={review.product.imageUrl}
                      alt=""
                      className="h-14 w-14 rounded-full"
                      draggable={false}
                    />
                  ) : (
                    <div className="bg-inputbg h-14 w-14 rounded-full text-black flex items-center justify-center text-lg">
                      {review?.product.name
                        ?.split(" ")[0]
                        .substring(0, 2)
                        .toUpperCase()}
                    </div>
                  )}
                </div>
                <div className="">
                  <p className="text-lg font-bold">{review?.product.name}</p>
                  <p className="text-sm font-medium">
                    {review?.brand || "not present"}
                  </p>
                </div>
                <div className="ml-10 space-x-5 flex items-center">
                  <p className="">{review?.status ? "Published" : "Pending"}</p>
                  <button
                    className={
                      review?.status
                        ? "bg-green-500 px-6 py-2 text-white text-sm appearance-none outline-none rounded"
                        : "bg-red-500 px-6 py-2 text-white text-sm appearance-none outline-none rounded"
                    }
                    onClick={() => publish()}
                  >
                    {review?.status ? "Unpublish" : "Publish"}
                  </button>
                  <button
                    className="border border-red-500 px-6 py-2 text-red-500 text-sm appearance-none outline-none rounded"
                    onClick={rejectReview}
                  >
                    Reject Review
                  </button>
                </div>
              </div>

              <div className="ml-10  justify-self-end  space-x-3">
                <button
                  className="bg-white hover:bg-gray-200 border px-6 py-2 text-blue-500 text-sm appearance-none outline-none rounded"
                  onClick={() => setdisabled(!disabled)}
                >
                  {disabled ? "Update" : "View"} Review
                </button>
              </div>
            </div>
            <div className="grid grid-cols-2 grid-flow-col mt-10">
              <div className="">
                <div className="bg-white rounded-lg w-full pb-5 shadow-sm">
                  <div className="border-b  px-5">
                    <p className="border-b border-nav_active_bg w-20 py-3">
                      &nbsp; &nbsp; Details
                    </p>
                  </div>

                  <div className="py-5 grid grid-flow-row grid-cols-2 px-5 gap-x-5">
                    <div className="py-2">
                      <p className="">Title</p>
                      <div className="relative ">
                        {!disabled ? (
                          <i className="fas fa fa-pencil transform scale-75 absolute right-1 pt-1"></i>
                        ) : null}
                        <input
                          type="text"
                          className={inputfield}
                          placeholder="Title"
                          defaultValue={review?.name}
                          disabled={disabled}
                        />
                      </div>
                    </div>
                    <div className="py-2">
                      <p className="">Description</p>
                      <div className="relative ">
                        {!disabled ? (
                          <i className="fas fa fa-pencil transform scale-75 absolute right-1 pt-1"></i>
                        ) : null}
                        <input
                          type="text"
                          className={inputfield}
                          placeholder="Description"
                          defaultValue={review?.description}
                          disabled={disabled}
                        />
                      </div>
                    </div>
                    <div className="py-2">
                      <p className="">Parent</p>
                      <div className="relative ">
                        {!disabled ? (
                          <div
                            className="cursor-pointer bg-red-400"
                            onClick={() => setEditField("parent")}
                          >
                            <i className="fas fa fa-pencil transform scale-75 absolute right-1 pt-1"></i>
                          </div>
                        ) : null}
                        <input
                          type="text"
                          className={inputfield}
                          placeholder="Parent Name"
                          defaultValue={review?.product.name}
                          disabled={disabled}
                        />
                      </div>
                    </div>
                    <div className="py-2">
                      <p className="">Uploaded by</p>
                      <div className="relative ">
                        <input
                          type="text"
                          className={inputfield}
                          placeholder="Uploaded by"
                          defaultValue={review?.user?.name}
                          disabled={disabled}
                        />
                      </div>
                    </div>
                    <div className="py-2">
                      <p className="">Uploaded by (ID)</p>
                      <div className="relative ">
                        <input
                          type="text"
                          className={inputfield}
                          placeholder="Belongs to id"
                          defaultValue={review?.user?.id}
                          disabled={disabled}
                        />
                      </div>
                    </div>
                    <div className="py-2">
                      <p className="">Belongs to ID</p>
                      <div className="relative ">
                        <input
                          type="text"
                          className={inputfield}
                          placeholder="Belongs to id"
                          defaultValue={review?.user?.name}
                          disabled={disabled}
                        />
                      </div>
                    </div>
                    <div className="py-2">
                      <p className="">Uploaded Date Time</p>
                      <p className={inputfield}>
                        {new Date(review?.dateAdded).toLocaleString()}
                      </p>
                    </div>

                    <div className="py-2">
                      <p className="">Category</p>
                      <div className="relative ">
                        <p className="">{review?.catId || "not present"}</p>
                      </div>
                    </div>
                    <div className="py-2">
                      <p className="">Category</p>
                      <div className="relative ">
                        <p className="">{review?.catId || "not present"}</p>
                      </div>
                    </div>
                    <div className="py-2">
                      <p className="">sub-Category</p>
                      <div className="relative ">
                        <p className="">
                          {review.subcategory || "not present"}
                        </p>
                      </div>
                      <div className="relative ">
                        <p className="">
                          {new Date(review?.dateAdded).toLocaleString()}
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <div className="flex flex-wrap">
                        {selectedTags.map((r, index) => (
                          <p
                            className="p-1 px-2 m-1 rounded-full bg-green-500 text-white cursor-pointer"
                            key={"selected" + index}
                          >
                            {r}
                          </p>
                        ))}
                      </div>
                    </div>
                    <div className="py-2 col-span-2">
                      <div className="grid grid-cols-2 grid-flow-col rounded border bg-panel_bg_secondary">
                        <div className="py-3 px-5">
                          <div className="flex gap-x-3 items-center">
                            <p className="">View Count</p>
                          </div>
                          <div className="py-5">
                            <p className="text-left text-4xl font-medium text-nav_active_bg">
                              {review.views || 0}
                            </p>
                          </div>
                        </div>
                        <div className="border-l py-3 px-5">
                          <div className="flex gap-x-3 items-center">
                            <p className="">Rating</p>
                          </div>
                          <div className="py-5">
                            <p className="text-right text-4xl font-medium text-nav_active_bg">
                              {review.rating || 3}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" border w-full mx-4 p-2">
                    <p className="text-lg">Attributes</p>
                    <div className="grid grid-cols-2 grid-flow-row text-black gap-5">
                      {Object.entries(review?.attributes ?? {}).map(
                        ([key, value]) => (
                          <div key={key} className="">
                            <p className="">
                              {key} - ({value})
                            </p>
                            <CustomRating rating={value} />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center">
                {play ? (
                  <video
                    src={review.reviewUrl}
                    className="rounded w-64 aspect_video object-cover"
                    controls
                  ></video>
                ) : (
                  <div
                    className="w-64 aspect_video relative flex items-center justify-center cursor-pointer"
                    onClick={() => setplay(true)}
                  >
                    <i className="fas fa-play text-white transform"></i>
                    <img
                      src={review.thumbnail}
                      className="w-64 rounded"
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>

            {disabled ? null : (
              <div className="flex items-center justify-end">
                <button className="py-2 px-8 bg-pink-500 appearance-none outline-none text-white rounded-md text-sm tracking-wide">
                  Update Review
                </button>
              </div>
            )}
          </div>

          {editMode()}
          <div className="bg-red-300 rounded-md border border-red-500 my-3 p-2">
            <h1 className="text-white text-3xl">Danger Section</h1>
            <div className="flex items-center justify-between my-1">
              <p className="">
                By clicking on this button you are going to delete review. Now
                this will go in recycle Bin collection.
              </p>
              <button
                className="text-white bg-red-500 rounded-md p-2"
                onClick={deletethis}
              >
                Delete Review
              </button>
            </div>
            <div className="flex items-center justify-between my-1">
              <p className="">Move this review to Flicks collection</p>
              <MoveToFlicks review={review} />
            </div>
          </div>
        </div>
      </>
    );
}

export default Viewreview;
