import { DataGrid } from "@material-ui/data-grid";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { firebasefb } from "./firebaseconfig";
import { data } from "./utils/Res";
function Category() {
  const [brands, setbrands] = useState([]);
  const history = useHistory();
  const [loading, setloading] = useState(true);

  useEffect(() => {
    firebasefb
      .collection("category")
      .get()
      .then((res) => {
        var a = [];
        res.docs.forEach((d) => {
          a.push({ ...d.data(), cid: d.id });
        });
        setbrands(a);
        setloading(false);
      });
  }, []);

  console.log(brands);
  const columns = [
    { field: "name", headerName: "name", width: 190 },
    { field: "id", headerName: "Category Id", width: 180 },
    {
      field: "subcategory",
      headerName: "sub categories",
      width: 500,

      renderCell: ({ row }) => {
        <div>
          {row?.subcategory?.map((sc, index) => (
            <span key={index}>{sc},</span>
          ))}
        </div>;
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 320,
      // eslint-disable-next-line react/display-name
      renderCell: ({ row }) => (
        <div>
          <button
            className={data.greenButton}
            onClick={() => history.push(`/home/viewCategory/${row.id}`)}
          >
            View
          </button>
          <button
            className={data.redButton}
            onClick={() => history.push(`/home/updateCategory/${row.cid}`)}
          >
            Update
          </button>
        </div>
      ),
    },
  ];

  if (loading)
    return (
      <div className="h-screen flex items-center justify-center">
        <span className="fas fa-spinner animate-spin fa-3x"></span>
      </div>
    );
  else
    return (
      <div className="font-Manrope py-10">
        <div className="flex justify-between items-center px-10">
          <p className="text-3xl font-medium">Total Categories:</p>
          <p className="text-3xl font-semibold text-nav_active_color">
            {" "}
            {brands.length}
          </p>
        </div>
        <div className="bg-white m-10">
          <div className="flex h-14 mt-8 items-center justify-between flex-wrap">
            <div className="duration-200">
              <button
                onClick={() => {
                  history.push("/home/addNewCategory");
                }}
                className=" bg-green-500 hover:bg-green-700 w-64 h-14  focus:outline-none text-white"
              >
                <span className="text-center">
                  <i class="fas fa-plus "></i> Create New Category
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className=" bg-white m-10 rounded">
          <DataGrid
            autoHeight={true}
            rows={brands}
            columns={columns}
            pageSize={20}
          />
        </div>
        <div className="h-2"></div>
      </div>
    );
}

export default Category;
