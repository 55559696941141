import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import UserBrandInterested from "./components/UserBrandInterested";
import UserBrandReviewed from "./components/UserBrandReviewed";
import UserCoinHistory from "./components/UserCoinHistory";
import UserCoinStats from "./components/UserCoinStats";
import UserFlicksStats from "./components/UserFlicksStats";
import UserProductInterested from "./components/UserProductInterested";
import UserProductReviewed from "./components/UserProductReviewed";
import UserReviewStats from "./components/UserReviewStats";
import UserReviews from "./components/userReviews";
import UserFlicks from "./components/UserFlicks";
import AWN from "awesome-notifications";
import urls from "./utils/urls";
import Shuriken from "./components/Shuriken";
import axios from "axios";

function ViewUser() {
  const router = useHistory();
  const { userid } = useParams();
  const [user, setuser] = useState({});

  const fetchUser = async () => {
    console.log("fetching from server all users");
    const response = await fetch(urls.admin + "getUser/" + userid, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });

    if (response.status === 200) {
      let res = await response.json();
      setuser(res);
    }
  };

  useEffect(() => {
    fetchUser();
  }, [userid]);

  const handletop = async () => {
    new AWN().async(
      fetch(
        "https://userspecificapi-zscu3untuq-el.a.run.app/updateTopUser/" +
          userid,
        {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "asdfwp234-0835234-34535FHD568-a26463213234234-llksdfsdlfwSLDF",
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        }
      ),
      async (response) => {
        if (response.status === 200) {
          let data = await response.json();
          let message = "operation not succeded";
          console.log(data);
          if (data.success) {
            message = data.top
              ? "Added in Top Users List"
              : "Removed from top Users";
            new AWN().success(message);
            setuser({ ...user, top: data.top });
            let topusers = sessionStorage.getItem("topUsers");
            topusers = JSON.parse(topusers);
            if (data.top) {
              topusers.push({
                imageUrl: user.imageUrl,
                name: user.name,
                reviews: user.reviews,
                flicks: user.flicks,
                id: user.id,
              });
            } else {
              topusers = topusers.filter((r) => r.id !== userid);
            }
            sessionStorage.setItem("topUsers", JSON.stringify(topusers));

            return;
          }
          new AWN().warning(message);
          return;
        }
      },
      (err) => {
        console.log(err);
        new AWN().warning(err.toString());
        return;
      }
    );
  };
  const toggleVerifiedUser = async () => {
    new AWN().async(
      axios(urls.admin + "toggleVerifyUser/" + userid, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: urls.adminAuth,
        },
      }),
      async (response) => {
        if (response.status === 200) {
          let data = response.data;
          let message = "operation not succeded";
          console.log(data);
          if (data.success) {
            message = data.top ? "user is verified" : "user removed verified";
            new AWN().success(message);
            setuser({ ...user, verified: data.data });
            return;
          }
          new AWN().warning(message);
          return;
        }
      },
      (err) => {
        console.log(err);
        new AWN().warning(err.toString());
        return;
      }
    );
  };

  const banthisuser = () => {};

  const inputfield =
    "appearance-none outline-none border-b text-nav_active_bg font-medium text-sm tracking-wide pr-10 w-full h-10";

  return (
    <div className="bg-panel_bg_secondary min-h-screen py-10 px-6 font-Manrope ">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-x-3">
          <button
            onClick={() => {
              router.replace("/home/users");
            }}
            className=""
          >
            <span className="fas fa-arrow-left"></span>
          </button>
          <p className="">User's Data</p>
        </div>
      </div>
      <div className="bg-white shadow mt-10 rounded w-full py-5">
        <div className="flex justify-between px-5 ">
          <div className="flex ">
            <div className="">
              <img
                src={user.imageUrl}
                alt="userimage"
                className="w-16 h-16 rounded-full"
              />
            </div>
            <div className="ml-5">
              <p className="flex">
                {user?.name}
                {user?.verified && <Shuriken />}
              </p>
              <p className="">{user?.tag}</p>
            </div>
            <div className="space-x-5 ml-16">
              <button
                className="bg-red-500 h-10 px-8 rounded-lg appearance-none outline-none text-white"
                onClick={() => banthisuser()}
              >
                {user.ban ? "Unban User" : "Ban User"}
              </button>
              <button
                className="bg-green-500 h-10 px-8 rounded-lg appearance-none outline-none text-white"
                onClick={() => {
                  router.push(`/home/uploadReview/${userid}`);
                }}
              >
                Upload a review
              </button>
            </div>
          </div>
          <div className="">
            <div className="border flex items-center justify-between">
              <button className="border-r px-7 h-10 appearance-none outline-none hover:bg-tab_passive_bg text-nav_active_bg">
                Push Notification
              </button>
              <button
                className="px-7 h-10  appearance-none outline-none hover:bg-tab_passive_bg text-nav_active_bg"
                onClick={() => handletop()}
              >
                {user?.top ? "remove" : "Make"} Top Reviewer
              </button>
              <button
                className="px-7 h-10  border appearance-none outline-none hover:bg-tab_passive_bg text-nav_active_bg"
                onClick={() => toggleVerifiedUser()}
              >
                {user?.verified ? "Verified User" : "Normal User"}
              </button>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-end gap-x-5 px-5 mt-8">
          <div className="bg-panel_bg_secondary rounded pl-6 pr-20 py-5 text-xl text-nav_active_bg">
            Reviews:
            <span className="font-bold text-black">
              &nbsp; &nbsp; &nbsp; {user?.reviews}
            </span>
          </div>
          <div className="bg-panel_bg_secondary rounded pl-6 pr-20 py-5 text-xl text-nav_active_bg">
            Flicks:
            <span className="font-bold text-black">
              &nbsp; &nbsp; &nbsp; {user?.flicks ?? "0"}
            </span>
          </div>
          <div className="bg-panel_bg_secondary rounded pl-6 pr-20 py-5 text-xl text-nav_active_bg">
            Following:
            <span className="font-bold text-black">
              &nbsp; &nbsp; &nbsp; {user?.following ?? "0"}
            </span>
          </div>
          <div className="bg-panel_bg_secondary rounded pl-6 pr-20 py-5 text-xl text-nav_active_bg">
            Followers:
            <span className="font-bold text-black">
              &nbsp; &nbsp; &nbsp; {user?.followers ?? "0"}
            </span>
          </div>
          <div className="bg-panel_bg_secondary rounded pl-6 pr-20 py-5 text-xl text-nav_active_bg">
            Join Date:
            <span className="font-bold text-black">
              &nbsp; &nbsp; &nbsp;{" "}
              {new Date(user?.dateAdded).toLocaleDateString()}
            </span>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-4 mt-5 gap-5">
        <div className="col-span-2 row-span-2">
          <div className="bg-white rounded-lg w-full pb-5 shadow-sm">
            <div className="border-b  px-5">
              <p className="border-b border-nav_active_bg w-20 py-3">
                &nbsp; &nbsp; Details
              </p>
            </div>

            <div className="py-5 grid grid-flow-row grid-cols-2 px-5 gap-x-5">
              <div className="py-2">
                <p className="">Name</p>
                <div className="relative ">
                  <input
                    type="text"
                    className={inputfield}
                    placeholder="Name"
                    defaultValue={user?.name}
                    disabled
                  />
                </div>
              </div>
              <div className="py-2">
                <p className="">User Name</p>
                <div className="relative ">
                  <input
                    type="text"
                    className={inputfield}
                    placeholder="User Name"
                    defaultValue={user?.tag}
                    disabled
                  />
                </div>
              </div>
              <div className="py-2">
                <p className="">User ID</p>
                <div className="relative ">
                  <input
                    type="text"
                    className={inputfield}
                    placeholder="User ID"
                    defaultValue={user?.id}
                    disabled
                  />
                </div>
              </div>

              <div className="py-2">
                <p className="">Referred By</p>
                <div className="relative ">
                  <input
                    type="text"
                    className={inputfield}
                    placeholder="Referred By"
                    defaultValue={user?.refBy}
                    disabled
                  />
                </div>
              </div>
              <div className="py-2">
                <p className="">About</p>
                <div className="relative ">
                  <img
                    src={require("./images/icons/pencil.png").default}
                    alt=""
                    className="transform scale-75 absolute right-1 pt-1"
                  />
                  <input
                    type="text"
                    className={inputfield}
                    placeholder="About"
                    defaultValue={user?.about}
                    disabled
                  />
                </div>
              </div>

              <div className="py-2">
                <p className="">Gender</p>
                <div className="relative ">
                  <input
                    type="text"
                    className={inputfield}
                    placeholder="Gender"
                    defaultValue={user?.gender}
                    disabled
                  />
                </div>
              </div>
              <div className="py-2">
                <p className="">Address</p>
                <div className="relative ">
                  <img
                    src={require("./images/icons/pencil.png").default}
                    alt=""
                    className="transform scale-75 absolute right-1 pt-1"
                  />
                  <input
                    type="text"
                    className={inputfield}
                    placeholder="Address"
                    defaultValue={user?.address}
                    disabled
                  />
                </div>
              </div>
              <div className="py-2">
                <p className="">Language</p>
                <div className="relative ">
                  <input
                    type="text"
                    className={inputfield}
                    placeholder="Language"
                    defaultValue={user?.languages}
                    disabled
                  />
                </div>
              </div>
              <div className="py-2">
                <p className="">Category</p>
                <div className="relative ">
                  <img
                    src={require("./images/icons/pencil.png").default}
                    alt=""
                    className="transform scale-75 absolute right-1 pt-1"
                  />
                  <input
                    type="text"
                    className={inputfield}
                    placeholder="Category"
                    defaultValue={user?.category}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="px-5">
              <div className="grid grid-cols-2 grid-flow-col gap-x-5">
                <div className="bg-blue-500 h-32 w-full rounded">
                  <div className="flex items-center justify-between px-5 py-3">
                    <p className="text-white">Total Time Spent:</p>
                    <img
                      src={require("./images/icons/allarrow.png").default}
                      alt=""
                      className=""
                    />
                  </div>
                  <div className="flex items-end gap-x-3 px-5 py-3">
                    <p className="text-4xl text-white font-medium">300</p>
                    <p className="text-white">hours</p>
                  </div>
                </div>
                <div className="bg-green-400 h-32 w-full rounded">
                  <div className="flex items-center justify-between px-5 py-3">
                    <p className="text-white">Daily Time Spent::</p>
                    <img
                      src={require("./images/icons/allarrow.png").default}
                      alt=""
                      className=""
                    />
                  </div>
                  <div className="flex items-end gap-x-3 px-5 py-3">
                    <p className="text-4xl text-white font-medium">300</p>
                    <p className="text-white">minutes</p>
                  </div>
                </div>
              </div>
              <div className="grid mt-5 gap-x-5">
                <div className="bg-purple-600 h-48 w-full rounded grid grid-cols-2 grid-flow-col">
                  <div className=" flex flex-col justify-between px-5 py-3">
                    <p className="text-white">Avg Time Spent:</p>
                    <div className="flex items-end gap-x-3  py-3">
                      <p className="text-5xl text-white font-medium">2000</p>
                      <p className="text-white">minutes</p>
                    </div>
                    <p className="text-white">
                      Time Stats :{" "}
                      <span className="opacity-70 font-light">
                        30 Mar - 06 Apr{" "}
                      </span>
                    </p>
                  </div>
                  <div className="justify-self-end p-5">
                    <img
                      src={require("./images/icons/chart.png").default}
                      alt=""
                      className="h-40"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UserCoinStats coins={user.coins} totalCoins={user.totalCoins} />
        <UserCoinHistory userid={userid} />
        <UserReviewStats userid={userid} />
        <UserFlicksStats userid={userid} />
        <UserBrandInterested userid={userid} />
        <UserProductInterested userid={userid} />
        <UserBrandReviewed userid={userid} />
        <UserProductReviewed userid={userid} />
        <UserReviews userId={userid} />
        <UserFlicks userId={userid} />
      </div>
    </div>
  );
}

export default ViewUser;
