import React from "react";
import Brands from "./Brands";
import Stores from "./Stores";
import Products from "./Products";
import Category from "./Category";
import {
  Link,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import Coupan from "./Coupan";

function Assets() {
  let { path, url } = useRouteMatch();
  const location = useLocation();

  const active = " font-medium bg-tab_active_bg text-tab_active_color  py-3 w-full text-center";
  const passive = " bg-tab_passive_bg text-tab_passive_color  py-3  w-full text-center border-r border-black border-opacity-5";

  return (
    <div className="py-16">
      <div className="flex px-10">
        <Link
          to={`${url}/brand`}
          className={
            location.pathname.includes("brand") || location.pathname === url
              ? active
              : passive
          }
        >
          Brands
        </Link>
        <Link
          to={`${url}/store`}
          className={location.pathname.includes("store") ? active : passive}
        >
          Store
        </Link>
        <Link
          to={`${url}/product`}
          className={location.pathname.includes("product") ? active : passive}
        >
          Product
        </Link>
        <Link
          to={`${url}/category`}
          className={location.pathname.includes("category") ? active : passive}
        >
          Category
        </Link>
        <Link
          to={`${url}/coupan`}
          className={location.pathname.includes("coupan") ? active : passive}
        >
          Coupons
        </Link>
      </div>

      <div className="">
        <Switch>
          <Route exact path={path}>
            <Brands />
          </Route>
          <Route path={`${path}/brand`}>
            <Brands />
          </Route>
          <Route path={`${path}/store`}>
            <Stores />
          </Route>
          <Route path={`${path}/product`}>
            <Products />
          </Route>
          <Route path={`${path}/category`}>
            <Category />
          </Route>
          <Route path={`${path}/coupan`}>
            <Coupan />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default Assets;
