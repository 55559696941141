import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import CustomDataGrid from "./utils/CustomDataGrid";
import { useStateValue } from "./StateProvider";
import urls from "./utils/urls";
import axios from "axios";
import ExportCollection from "./components/ExportCollection";
import AWN from "awesome-notifications";

function Brands() {
  const [brands, setbrands] = useState([]);
  const [{ countAdmin }, dispatch] = useStateValue();

  const history = useHistory();
  const [loading, setloading] = useState(true);
  const [searchResult, setsearchResult] = useState([]);
  const [searchMode, setSearchMode] = useState(false);

  let queryRef = useRef();

  const fetchUsers = async (page = 0, refresh = false) => {
    if (page === 0 && !refresh) {
      let chart = sessionStorage.getItem("brands");
      if (chart) {
        chart = JSON.parse(chart);
        setbrands(chart);
        setloading(false);
        return;
      }
    }
    console.log("fetching from server all users");
    const response = await fetch(urls.admin + "getBrands/" + page, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });

    if (response.status === 200) {
      let res = await response.json();
      if (page !== 0) {
        let brands = sessionStorage.getItem("brands");
        brands = JSON.parse(brands);

        res = brands.concat(res);
      }
      sessionStorage.setItem("brands", JSON.stringify(res));
      setbrands(res);
      setloading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const loadMore = () => {
    fetchUsers(Math.floor(brands.length / 50));
  };

  const searchNow = async (e) => {
    e.preventDefault();
    setSearchMode(true);
    let response;

    let query = queryRef.current.value;
    query = query.trim();
    if (!query.includes(" ") && query.startsWith("brand")) {
      response = await axios(urls.admin + "getBrand/" + query, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
        },
      });

      if (response.status === 200) {
        let eres = response.data;
        setsearchResult([eres]);
      }
    } else {
      response = await axios(urls.admin + "searchBrands", {
        method: "PUT", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
        },
        data: JSON.stringify({ query: queryRef.current.value }),
      });

      if (response.status === 200) {
        let eres = response.data;
        setsearchResult(eres);
      }
    }
  };

  const columns = [
    { field: "name", headerName: "name", clasclassNames: "w-54" },
    { field: "catId", headerName: "Category Id", className: "w-54" },

    {
      field: "dateAdded",
      headerName: "Date Added",
      className: "w-54",
      renderCell: (row) => (
        <span>{new Date(row.dateAdded).toLocaleDateString()}</span>
      ),
    },
    { field: "id", headerName: "Brand Id", className: "w-54" },
    { field: "rating", headerName: "Rating ", className: "w-54" },
    { field: "flicks", headerName: "Flicks ", className: "w-54" },
    { field: "reviews", headerName: "reviews ", className: "w-54" },
  ];
  const actionNode = (row) => (
    <div className=" bg-white shadow-sm z-50 w-48 absolute border right-24 rounded-md -mt-8">
      <button
        className="h-10 w-full font-medium flex items-center px-4 bg-white hover:bg-purple-100 cursor-pointer rounded select-none"
        onClick={() => history.push(`/home/viewBrand/${row.id}`)}
      >
        View
      </button>
    </div>
  );

  const refreshPage = async () => {
    await fetchUsers(0, true);
    new AWN().success("page refreshed.");
  };

  if (loading)
    return (
      <div className="h-screen bg-panel_bg font-Manrope flex items-center justify-center">
        <span className="fas fa-spinner animate-spin fa-3x"></span>
      </div>
    );
  return (
    <>
      <div className="block bg-panel_bg font-Manrope">
        <div className="mt-5 px-10 border-b  border-dashed pb-5 flex items-center justify-between">
          <div className="bg-nav_bg h-10 w-96 rounded-md border flex items-center justify-between px-3">
            <div className="flex items-center justify-between w-full">
              <p className="text-sm text-black text-opacity-50 ">
                Total Brands:
              </p>
              <p className="text-lg font-Manrope font-bold  text-nav_active_bg ">
                {countAdmin.brands}
              </p>
            </div>
          </div>
          <div className="flex ">
            <ExportCollection collection="brands" />
            <button
              className="ml-3 w-52 py-1 px-8 bg-blue-500 rounded text-white flex items-center"
              onClick={() => history.push("/home/CreateBrand")}
            >
              <span className="text-2xl">+</span>&nbsp; Create Brand
            </button>
          </div>
        </div>
        <div className="flex justify-between divide-x-2 divide-black divide-opacity-50 my-6  px-10">
          <div className="flex w-full mr-5">
            <form
              action=""
              className="flex items-center relative w-full"
              onSubmit={(e) => searchNow(e)}
            >
              {searchMode ? (
                <i
                  className="fas fa-times-circle fa-lg p-1 cursor-pointer absolute right-3"
                  onClick={() => {
                    setSearchMode(false);
                  }}
                ></i>
              ) : null}{" "}
              <input
                type="text"
                ref={queryRef}
                className="pr-8 py-2 px-3 w-full appearance-none outline-none border"
                placeholder="Search by Name, Category, Brands"
              />
            </form>
          </div>
          <div className="flex items-center">
            <button className="ml-3 w-52 py-2 px-8 bg-filterby rounded text-white">
              Filter By:
            </button>
            <button className="ml-3 w-56 py-2 px-8  rounded bg-nav_bg text-black border">
              Sort by: &nbsp;<i className="fas fa-solid fa-sort"></i>{" "}
            </button>
            <button
              onClick={refreshPage}
              className="ml-3 bg-purple-500 py-2 px-8  rounded text-white border"
            >
              <i className="fas fa-solid fa-recycle"></i>
            </button>
          </div>
        </div>
        <div className="m-10">
          <div className="w-full  bg-white border shadow-lg rounded-md overflow-x-auto">
            {!searchMode ? (
              <CustomDataGrid
                key="normal"
                rows={brands}
                columns={columns}
                actionNode={actionNode}
                loadMore={loadMore}
              />
            ) : (
              <CustomDataGrid
                key="search"
                rows={searchResult}
                columns={columns}
                actionNode={actionNode}
                loadMore={loadMore}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Brands;
