import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { firebasefb, storage } from "./firebaseconfig";
import { data } from "./utils/Res";
import AWN from "awesome-notifications";
import Goback from "./utils/Goback";
import { useParams } from "react-router-dom";
import SubcategoryUpdateCard from "./utils/SubcategoryUpdateCard";

function UpdateCategory() {
  const [category, setcategory] = useState(null);
  const [loaded, setloaded] = useState(false);
  const [loading, setloading] = useState(false);
  const [show, setshow] = useState(false);
  const [subimage, setsubimage] = useState([]);

  let nameref = useRef();
  let subname = useRef();
  const { catid } = useParams();

  useEffect(() => {
    if (!loaded)
      firebasefb
        .collection("category")
        .doc(catid)
        .get()
        .then((res) => {
          var a = { ...res.data(), docId: res.id };
          setcategory(a);
          setsubimage(a.images ?? []);
          setloaded(true);
        });
  }, [catid]);

  console.log(subimage);

  const imageupload = (e) => {
    if (loading) return;
    setloading(true);
    var image = e.target.files[0];
    if (!image) {
      new AWN().alert("no image file selected", {
        position: "bottom-right",
      });
      setloading(false);
      return;
    }
    var storageRef = storage.ref();
    storageRef
      .child(`category/${catid}`)
      .put(image)
      .then((snapshot) => {
        snapshot.ref
          .getDownloadURL()
          .then(function (downloadURL) {
            setcategory({ ...category, imageUrl: downloadURL });
            setloading(false);
          })
          .catch((err) => {
            new AWN().alert(e.message, {
              position: "bottom-right",
            });
            setloading(false);
          });
      });
  };


  const finalSubmit = () => {
    if (nameref.current.value.length < 2)
      return alert("enter valid category name");
    if (!category.imageUrl) return alert("enter valid image");
    if (category.images.length === 0) return alert("empty subcategories enter some");

    const cat = {
      imageUrl: category.imageUrl,
      images: subimage,
      name: nameref.current.value,
      subcategory: category.images,
    };

    let notifier = new AWN();
    let onOk = () => {
      firebasefb
        .doc("category/" + category.docId)
        .update(cat)
        .then((res) => {
          new AWN().success("successfully updated");
          nameref.current.value = "";
          setcategory({
            ...category,
            name: nameref.current.value,
            subcategory: category.images,
          });
          return;
        })
        .catch((e) => {
          console.log(e);
          new AWN().alert("something went wrong");
        });
    };
    let onCancel = () => {
      notifier.info("You pressed Cancel");
    };
    notifier.confirm("Are you sure?", onOk, onCancel, {
      labels: {
        confirm: "Dangerous action",
      },
    });
  };

  if (!loaded)
    return (
      <div className="h-screen flex justify-center items-center">
        <span className="fas fa-spinner animate-spin fa-3x">{}</span>
      </div>
    );
  else
    return (
      <div className="bg-background min-h-screen">
        <div className="bg-white h-24">
          <h1 className="font-medium text-3xl flex flex-grow-0 pt-5 pl-8 ">
            <Goback />
            <span className="pt-2"> &nbsp; Update Category</span>
          </h1>
        </div>
        <div className=" m-20">
          <div className="flex flex-grow-0">
            <div className="">
              <div className="bg-white h-80 w-80 flex items-center justify-center shadow-md">
                <img src={category.imageUrl} alt="" className="h-72 w-72" />
              </div>
            </div>
            <div className="bg-white w-full ml-20 flex flex-grow">
              <div className="m-8">
                <div className=" border shadow-md">
                  <div className="flex items-start justify-center">
                    <label htmlFor="file-for">
                      <div className=" text-center w-64 h-64  justify-center items-center cursor-pointer bg-box">
                        <i className="fas fa-plus mt-24 fa-blue  text-lg"></i>

                        <p className="text-sm text-center text-gray-800 py-24">
                          Upload your category image here
                        </p>
                      </div>
                    </label>

                    <input
                      type="file"
                      accept="image/*"
                      id="file-for"
                      className="hidden"
                      onChange={(e) => imageupload(e)}
                    />
                    {loading ? (
                      <div className="text-center">
                        <span className="fas fa-spinner animate-spin"></span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="p-8 w-full">
                <p className="font-medium text-lg pb-2">
                  Category Name
                  <span className="text-red-500 px-2">*</span>
                </p>
                <input
                  type="text"
                  ref={nameref}
                  disabled={false}
                  defaultValue={category.name}
                  className="h-14 px-5 w-full bg-box appearance-none outline-none border border-box focus:border-purple-700"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="m-20">
          {category?.images?.map((e, index) => (
            <SubcategoryUpdateCard subcat={e} catid={catid} images={category.images} key={index} />
          ))}
          <div
            className="my-3 p-2 h-20 w-full bg-gray-100 border border-black text-center text-3xl cursor-pointer"
            onClick={() => setshow(true)}
          >
            +
          </div>
        </div>

        <div className="text-right px-5" onClick={() => finalSubmit()}>
          <button className={data.greenButton}>Final Submit</button>
        </div>
        {show ? (
          <div className="fixed top-0 w-full h-screen bg-black bg-opacity-30 flex items-center justify-center">
            <div className="lg:w-1/3 w-full h-96 bg-white drop-shadow-lg rounded">
              <div className="p-2 text-right">
                <i
                  className="fas fa-times bg-gray-300 rounded-full shadow-sm p-3 hover:bg-gray-400 cursor-pointer "
                  onClick={() => setshow(false)}
                ></i>
              </div>
              <form className="w-full p-3">
                <p className=" text-gray-600 p-2">
                  sub category name <span className="px-2 text-red-600">*</span>
                </p>
                <input
                  type="text"
                  ref={subname}
                  placeholder="sub category name"
                  className={data.textInputStyle}
                />
                <button
                  className={data.pinkButton}
                  type="submit"
                  onClick={async (e) => {
                    e.preventDefault();
                    if (category.images.find((a) => a.name === subname.current.value.trim()))
                      return;
                    else {
                      setcategory({...category, images:[...category.images,{icon:null,name:subname.current.value}]})
                      setshow(false);
                      return;
                    }
                  }}
                >
                  Add
                </button>
              </form>
            </div>
          </div>
        ) : null}
      </div>
    );
}

export default UpdateCategory;
