import React from "react";
import { PieChart, Pie, Cell } from "recharts";

function TotaluserChart({ users }) {
  const data = [
    { name: "Group A", value: 400 },
    { name: "Group B", value: 300 },
  ];
  const COLORS = ["#FFBB28", "#FF8042"];

  return (
    <div className=" shadow-sm border bg-blue-500 rounded-md  relative ">
      <p className="text-white text-sm mx-2 ">Total Users</p>
      <p className="text-white mx-2 text-5xl">{users}</p>
      <PieChart width={250} height={240}>
        <Pie
          data={data}
          cx={120}
          cy={120}
          innerRadius={60}
          outerRadius={80}
          fill="#FFBB28"
          stroke="none"
          paddingAngle={6}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </div>
  );
}

export default TotaluserChart;
