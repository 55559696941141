import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {firebasefb} from "./firebaseconfig";
import {data} from "./utils/Res";
import {DataGrid} from "@material-ui/data-grid";
import {Avatar} from "@material-ui/core";

function Invitation() {
  const [invites, setinvites] = useState([]);
  const [loading, setloading] = useState(true);

  const router = useHistory();

  useEffect(() => {
    if (loading)
      firebasefb
        .collection("invitation")
        .get()
        .then((res) => {
          var a = [];
          res.docs.forEach((invite) => {
            a.push(invite.data());
          });

          setinvites(a);
          setloading(false);
        });
  }, []);

  console.log(invites);

  const columns = [
    {
      field: "image",

      renderCell: ({row}) => {
        return <Avatar src={row.imageUrl} alt="sdf" />;
      },
      headerName: "Image",
      width: 120,
    },
    {field: "name", headerName: "Name", width: 190},
    {field: "coin", headerName: "Coin", width: 190},
    {field: "id", headerName: "Refer To", width: 190},
  ];

  if (loading)
    return (
      <div className="h-screen flex items-center justify-center">
        <span className="fas fa-spinner animate-spin"></span>
      </div>
    );
  else
    return (
      <div className="bg-background min-h-screen w-full ">
        <div className=" bg-white flex  justify-between items-end  h-auto  ">
          <div className="">
            <p className="text-3xl font-medium p-4">Total invites</p>
          </div>
          <div className="pr-4">
            <p className="text-md font-Regular text-sidetext text-right ">
              invites
            </p>

            <p className="text-3xl font-medium flex flex-grow-0 text-right">
              {invites.length}
            </p>
          </div>
        </div>
        <div className="h-96">
          <DataGrid rows={invites} columns={columns} pageSize={20} />
        </div>
      </div>
    );
}

export default Invitation;
