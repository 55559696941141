import { useRef, useState } from "react";
import { auth, firebasefb } from "./firebaseconfig";
import { useHistory } from "react-router";
import { useStateValue } from "./StateProvider";

export default function Login() {
  const [loading, setloading] = useState(false);
  const [showpass, setshowpass] = useState(false);
  const router = useHistory();
  let email = useRef();
  let password = useRef();
  const [{ user }, dispatch] = useStateValue();

  const validate = () => {
    if (email.current.value.length < 6) {
      alert("enter valid email");
      return false;
    } else if (password.current.value.length < 8) {
      alert("enter valid password");
      return false;
    } else return true;
  };
  function gotohomepage(e) {
    e.preventDefault();
    if (!validate() || loading) return;
    setloading(true);
    const emailD = email.current.value;
    const pass = password.current.value;
    auth
      .signInWithEmailAndPassword(emailD, pass)
      .then((res) => {
        console.log(res);
        if (res.user) {
          firebasefb
            .doc("admin/" + emailD)
            .get()
            .then((e) => {
              var data = e.data();
              localStorage.setItem("user", JSON.stringify(data));
              if (data.role === "admin") {
                router.replace("/home");
                dispatch({ type: "SET_USER", user: data });

                console.log("wokring login ");
              } else {
                setloading(false);
                alert("not valid credential");
              }
            })
            .catch((e) => console.log(e));
        } else {
          setloading(false);
          alert("bad request");
        }
      })
      .catch((e) => {
        setloading(false);
        alert(e);
      });
  }
  return (
    <div className="h-screen w-full bg-white flex font-Poppins overflow-y-auto">
      <div className="tagline-container">
        <div className="logo">
          <img
            src={require("./images/myrevue_white.svg").default}
            alt=""
            className=""
          />
        </div>
        <div className="tagline">
          <span className="text-8xl text-white font-semibold">Build.</span>
          <span className="text-8xl text-white font-semibold">Run.</span>
          <span className="text-8xl text-white font-semibold">Scale.</span>
        </div>
      </div>
      <div className="login-container">
        <div className="login-box">
          <div className="mb-8">
            <div className="mb-20">
              <div className="block lg:hidden">
                <img
                  src={require("./images/myrevue_black.svg").default}
                  alt=""
                  className="mx-auto mb-10"
                />
              </div>
              <h1 className="my-4 text-3xl text-black text-opacity-90 font-medium">
                Hello There.
              </h1>
              <h2 className="text-2xl text-gray-700">
                Sign In to your account.
              </h2>
            </div>
            <div className="space-y-4">
              <div className="">
                <label htmlFor="email" className="text-sm text-gray-600">
                  Email
                </label>
                <input
                  name="email"
                  id="email"
                  type="text"
                  ref={email}
                  className="h-10 w-full border-b-2 appearance-none outline-none focus:border-purple-500 !text-sm tracking-wide"
                />
              </div>
              <div className="">
                <label htmlFor="password" className="text-sm text-gray-600">
                  Password
                </label>
                <div className="relative w-full flex items-center">
                  <button
                    className="absolute right-2"
                    onClick={() => setshowpass(!showpass)}
                  >
                    {!showpass ? (
                      <img
                        src={require("./images/icons/eyeview.svg").default}
                        alt=""
                        className="select-none"
                      />
                    ) : (
                      <img
                        src={require("./images/icons/eyeviewcross.svg").default}
                        alt=""
                        className="select-none"
                      />
                    )}
                  </button>

                  <input
                    name="password"
                    id="password"
                    type={showpass ? "text" : "password"}
                    ref={password}
                    className={
                      showpass
                        ? "text-sm h-10 w-full pr-10 border-b-2 appearance-none outline-none focus:border-purple-500 tracking-wide"
                        : " text-base h-10 w-full pr-10 border-b-2 appearance-none outline-none focus:border-purple-500 tracking-wide"
                    }
                  />
                </div>
              </div>
              {/* <div className="">
                                <p className="font-semibold text-purple-600 -mt-3 text-right cursor-pointer select-none" onClick={()=forgo}>Forgot password?</p>
                            </div> */}
              <div className="">
                <button
                  className="h-10 w-32 rounded-md bg-blue-700 text-white appearance-none outline-none flex items-center justify-center select-none"
                  onClick={gotohomepage}
                >
                  {loading ? <div className="" id="loading"></div> : "Sign in"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <p className="copyright">
          © 2022 Valuable Digital Experiences Pvt. Ltd. All Rights Reserved.
        </p>
      </div>
    </div>
  );
}
