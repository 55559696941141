import AWN from "awesome-notifications";
import { nanoid } from "nanoid";
import React, { useRef, useState } from "react";
import { useStateValue } from "./StateProvider";
import { firebasefb, storage } from "./firebaseconfig";
import Goback from "./utils/Goback";
import { data } from "./utils/Res";
import firebase from "firebase";

function AddnewCoupan() {
  const [{ user }] = useStateValue();
  const [productimage, setimage] = useState(null);
  const [loading, setloading] = useState(false);
  const [tags, settags] = useState([]);
  const [type, settype] = useState("cashback");
  const [bannerImage, setbannerImage] = useState(null);

  const id = nanoid(8);
  console.log(user);

  let code = useRef();
  let tagref = useRef();
  let cashRef = useRef();
  let itemRef = useRef();
  let worthRef = useRef();
  let quantityRef = useRef();
  let coupanValueRef = useRef();

  const imageupload = (e, banner) => {
    if (loading) return;

    var image = e.target.files[0];
    if (!image) {
      new AWN().alert("no image file selected", {
        position: "bottom-right",
      });
      setloading(false);
      return;
    }
    var storageRef = storage.ref();
    storageRef
      .child(`rewardCoupan/${id}`)
      .put(image)
      .then((snapshot) => {
        snapshot.ref
          .getDownloadURL()
          .then(function (downloadURL) {
            if (banner) setbannerImage(downloadURL);
            else setimage(downloadURL);
          })
          .catch((err) => {
            new AWN().alert(err, {
              position: "bottom-right",
            });
          });
      });
  };

  const finalSubmit = () => {
    var brnd = {
      id: `coupon_${id}`,
      isverified: true,
      title: code.current.value.toUpperCase(),
      cash: cashRef?.current?.value ?? null,
      worth: worthRef?.current?.value ?? null,
      type: type,
      item: itemRef?.current?.value ?? null,
      quantity: Number.parseInt(quantityRef.current.value),
      coupanValue: Number.parseInt(coupanValueRef.current.value),
      dateAdded: firebase.firestore.Timestamp.now(),
      bannerUrl: bannerImage,
      rules: tags,
      imageUrl: productimage,
    };
    console.log(brnd);

    let notifier = new AWN();
    let onOk = async () => {
      if (loading) return;
      setloading(true);
      firebasefb
        .collection("rewardCoupan")
        .doc(brnd.id)
        .set(brnd)
        .then(async (res) => {
          new AWN().success("successfully updated");
          for (let i = 0; i < brnd.quantity; i++) {
            var id = await nanoid(8);
            firebasefb
              .collection("rewardCoupan/" + brnd.id + "/coupanCodes")
              .doc(id)
              .set({
                alloted: null,
                id: id,
              });
          }
          setloading(false);

          return;
        })
        .catch((e) => {
          setloading(false);

          new AWN().error("something went wrong");
        });
    };
    let onCancel = () => {
      notifier.info("You pressed Cancel");
    };
    notifier.confirm("Are you sure?", onOk, onCancel, {
      labels: {
        confirm: "Make sure all fields are filled.",
      },
    });
  };

  const removeTag = (e) => {
    console.log(e);
    settags(tags.filter((word) => e !== word));
  };

  const addtags = (e) => {
    e.preventDefault();

    if (tags?.includes(tagref.current.value)) {
      return;
    }
    settags([...tags, tagref.current.value.trim()]);
    tagref.current.value = "";
  };

  if (loading)
    return (
      <div className="h-screen flex items-center text-white justify-center">
        <span className="fas fa-spinner animate-spin"></span>
      </div>
    );
  else
    return (
      <div className="bg-background  min-h-screen">
        <div className="bg-white m-5 rounded-lg px-3 py-4">
          <h1 className="font-medium text-3xl flex flex-grow-0  ">
            <Goback />
            <span className="pt-2"> &nbsp; Create New Coupon</span>
          </h1>
        </div>
        <div className="">
          <div className="bg-white m-5 p-5">
            <h1>Upload Coupon Image here (inside)</h1>
            <div className="">
              <div className="  grid grid-flow-col grid-cols-2 grid-rows-1 gap-5 ">
                <div className="grid grid-flow-col m-2">
                  <div className=" w-64 h-64 overflow-hidden bg-red-200 rounded-lg ">
                    <img src={productimage} alt="" className="h-64" />
                  </div>

                  <div className="flex items-start  justify-center">
                    <label htmlFor="productimage">
                      <div className="border rounded text-center w-64 h-64  justify-center items-center cursor-pointer bg-gray-300">
                        <i className="fas fa-plus mt-24 fa-blue  text-lg"></i>

                        <p className="text-sm text-center text-gray-800 py-24">
                          Upload coupon image here
                        </p>
                      </div>
                    </label>

                    <input
                      type="file"
                      accept="image/*"
                      id="productimage"
                      className="hidden"
                      onChange={(e) => imageupload(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="bg-white m-5 p-5">
            <h1>Upload Coupon banner here (outside)</h1>
            <div className="">
              <div className="  grid grid-flow-col grid-cols-2 grid-rows-1 gap-5 ">
                <div className="grid grid-flow-col m-2">
                  <div className=" w-64 h-64 overflow-hidden bg-red-200 rounded-lg ">
                    <img src={bannerImage} alt="" className="h-64" />
                  </div>

                  <div className="flex items-start  justify-center">
                    <label htmlFor="bannerimage">
                      <div className="border rounded text-center w-64 h-64  justify-center items-center cursor-pointer bg-gray-300">
                        <i className="fas fa-plus mt-24 fa-blue  text-lg"></i>

                        <p className="text-sm text-center text-gray-800 py-24">
                          Upload coupon banner here
                        </p>
                      </div>
                    </label>

                    <input
                      type="file"
                      accept="image/*"
                      id="bannerimage"
                      className="hidden"
                      onChange={(e) => imageupload(e, true)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="bg-white m-5">
            <div className="pl-12 pt-8 pr-12">
              <p className=" pb-2 text-lg font-medium">
                Heading (Title)
                <span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                ref={code}
                placeholder="heading (Title)"
                className={data.textInputStyle}
              />
            </div>
            <div className="pl-12 pt-8 pr-12">
              <p className=" pb-2 text-lg font-medium">
                Quanity (Number of coupons to be generated)
                <span className="text-red-500">*</span>
              </p>
              <input
                type="number"
                min={5}
                ref={quantityRef}
                placeholder="number of coupons to be generated"
                className={data.textInputStyle}
              />
            </div>

            <div className="pl-12 pt-8 pr-12 ">
              <p className=" pb-2 text-lg font-medium">
                Coins required
                <span className="text-red-500">*</span>
              </p>

              <select
                ref={coupanValueRef}
                className={data.textInputStyle}
                type="number"
                placeholder="coins required "
              >
                <option value="3000">3000</option>
                <option value="5000">5000</option>
                <option value="10000">10000</option>
                <option value="20000">20000</option>
                <option value="25000">25000</option>
              </select>
            </div>
            <div className="px-12 pt-8">
              <p className=" pb-2 text-lg font-medium">
                what type of coupon you are going to generate.
                <span className="text-red-500">*</span>
              </p>

              <select
                className={data.textInputStyle}
                onChange={(e) => settype(e.target.value)}
                type="number"
                placeholder="coins required "
              >
                <option defaultChecked value="cashback">
                  Cashback
                </option>
                <option value="freegift">Free Gift</option>
              </select>
            </div>

            {type === "cashback" ? (
              <div className="px-10 pt-8">
                <p className="">
                  Put the amount to be used for this coupon (Rs.)
                </p>

                <input
                  type="number"
                  min={0}
                  ref={cashRef}
                  className={data.textInputStyle}
                  placeholder="cash in Rs to be redeemed"
                />
              </div>
            ) : (
              <div className="px-10 pt-8">
                <p className="">
                  Put the details of the gift to be used for this coupon.
                </p>
                <input
                  type="text"
                  ref={itemRef}
                  className={data.textInputStyle}
                  placeholder="name of the item to be redeemed."
                />
                <input
                  type="number"
                  ref={worthRef}
                  min={5}
                  className={data.textInputStyle}
                  placeholder="value of the item in rupees."
                />
              </div>
            )}

            <div className=" pl-12 pr-12 pb-8">
              <h1 className=" font-semibold text-xl">
                Terms and Condition (in list){" "}
                <span className="text-xs">
                  *press enter after entering a condition
                </span>
              </h1>
              <div className=" pb-4">
                {tags?.map((e) => (
                  <p
                    className="bg-blue-800 text-white my-2 rounded p-2 cursor-pointer"
                    onClick={() => removeTag(e)}
                  >
                    {e}
                  </p>
                ))}
              </div>
              <form onSubmit={(e) => addtags(e)}>
                <input
                  type="text"
                  ref={tagref}
                  className={data.textInputStyle}
                  placeholder="single terms and condition"
                />
              </form>
            </div>
          </div>

          <div className="text-right m-5">
            <button
              className="w-48 h-14 bg-pink-500 hover:bg-pink-700 text-white "
              onClick={() => finalSubmit()}
            >
              Final Submit
            </button>
          </div>
        </div>
        <div className="h-1"></div>
      </div>
    );
}

export default AddnewCoupan;
