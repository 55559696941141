import React from "react";
import { useState } from "react";
import urls from "../utils/urls";

function UserCoinHistory({ userid }) {
  const fetchHistory = async () => {
    setLoading(true);
    console.log("fetching from server all users");
    const response = await fetch(urls.admin + "getCoinHistory/" + userid, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: urls.adminAuth,
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });

    if (response.status === 200) {
      let res = await response.json();
      setcoinhistory(res);
      setLoading(false);
    }
  };

  const [coinHistory, setcoinhistory] = useState([]);
  const [loading, setLoading] = useState(false);

  if (loading) {
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <i className="animate-pulse">loading...</i>
      </div>
    );
  } else if (coinHistory.length !== 0) {
    return (
      <div className="bg-white shadow-lg rounded-md p-2">
        <p className="text-2xl mb-3">Coin History</p>{" "}
        <div className="overflow-y-scroll text-xs h-96">
          <table className="rounded-md shadow-md table-fixed border-collapse overflow-scroll   text-left">
            <thead>
              <tr>
                <th>coins</th>
                <th>message</th>
                <th>date</th>
              </tr>
            </thead>
            <tbody>
              {coinHistory.map((hist, index) => (
                <tr key={hist.date} className="h-16  odd:bg-red-500">
                  <td
                    className={
                      hist.coin > 0
                        ? "font-bold text-green-500"
                        : "font-bold text-red-500"
                    }
                  >
                    {" "}
                    {hist.coin}{" "}
                  </td>
                  <td className="pl-2"> {hist.message} </td>
                  <td> {new Date(hist.date).toLocaleDateString()} </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  } else
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <button
          className="border p-2 shadow-md border-blue-500"
          onClick={fetchHistory}
        >
          Reveal Coin history
        </button>
      </div>
    );
}

export default UserCoinHistory;
