import AWN from "awesome-notifications";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { firebasefb, storage } from "./firebaseconfig";
import Goback from "./utils/Goback";
import { data } from "./utils/Res";

function UpdateReviewvideo() {
    const { reviewid } = useParams();
    const [review, setreview] = useState({});
    const [loading, setloading] = useState(true);
    const [reviewurl, setreviewurl] = useState(null);
    const [progress, setprogress] = useState(0);
    const [thumbnail, setthumbnail] = useState(null);

    const router = useHistory();

    useEffect(() => {
        firebasefb
            .collection("review")
            .doc(reviewid)
            .get()
            .then((res) => {
                setreview(res.data());
                setloading(false);
            });
    }, []);

    const uploadthumbnail = (e) => {
        if (loading) return;

        var image = e.target.files[0];
        if (!image) {
            new AWN().alert("no image file selected", {
                position: "bottom-right",
            });
            setloading(false);
            return;
        }
        var storageRef = storage.ref();
        var uploadTask = storageRef.child(`thumbnails/${reviewid}`).put(image);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                var progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                setprogress(progress);
            },
            (error) => {
                // Handle unsuccessful uploads
                alert(error);
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    setthumbnail(downloadURL);
                });
            }
        );
    };

    const uploadreview = (e) => {
        if (loading) return;

        var image = e.target.files[0];
        if (!image) {
            new AWN().alert("no review file selected", {
                position: "bottom-right",
            });
            setloading(false);
            return;
        }
        var storageRef = storage.ref();
        var uploadTask = storageRef.child(`reviews2/${reviewid}`).put(image);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                var progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                setprogress(progress);
            },
            (error) => {
                // Handle unsuccessful uploads
                alert(error);
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    setreviewurl(downloadURL);
                });
            }
        );
    };

    const updatereview = () => {
        console.log(reviewurl, thumbnail);

        firebasefb
            .collection("review")
            .doc(reviewid)
            .update({
                reviewUrl: reviewurl,
                thumbnail: thumbnail,
            })
            .then((res) => {
                new AWN().success("successfully updated");
                router.goBack();
            })
            .catch((e) => {
                new AWN().warning(e);
            });
    };

    return (
        <div>
            <div className="flex items-center justify-start">
                <Goback />
                <p className="mx-3 text-lg font-semibold">{review.name}</p>
            </div>
            <div className="p-10 grid lg:grid-cols-2 ">
                {thumbnail ? (
                    <div className="flex flex-col items-center justify-center p-2">
                        <img src={thumbnail} alt="" className="h-72" />
                    </div>
                ) : (
                    <div className="flex flex-col items-center justify-center p-2">
                        <p>Thumbnail</p>
                        <input
                            type="file"
                            id="thumbnail"
                            accept="image/*"
                            onChange={(e) => uploadthumbnail(e)}
                            className=" hidden"
                        />
                        <label htmlFor="thumbnail">
                            <div className="w-64 h-64 border border-black rounded bg-pink-100 flex items-center justify-center">
                                <i className="fas fa-plus"></i>
                            </div>
                        </label>
                        <div className="flex-1 w-full text-center">
                            <p>upload Progress ({progress}%) </p>
                        </div>
                    </div>
                )}
                <div className="flex flex-col items-center justify-center p-2">
                    {reviewurl ? (
                        <iframe
                            src={reviewurl}
                            title="review"
                            frameborder="0"
                        ></iframe>
                    ) : (
                        <div className="">
                            <p className="text-center">Review</p>
                            <input
                                type="file"
                                id="videofile"
                                accept=".mp4"
                                onChange={(e) => uploadreview(e)}
                                className=" hidden"
                            />
                            <label htmlFor="videofile">
                                <div className="w-64 h-64 border border-black rounded bg-pink-100 flex items-center justify-center">
                                    <i className="fas fa-plus"></i>
                                </div>
                            </label>

                            <p>upload Progress ({progress}%) </p>
                        </div>
                    )}
                    <div className="">
                        <p className=" font-medium">
                            Rules to follow before upload :
                        </p>
                        <ul className="list-disc">
                            <li>
                                Make Sure review fits the guidelines of
                                recording review.
                            </li>
                            <li>
                                review should not exceed more than 60 seconds
                            </li>
                            <li>review should not be less than 20 seconds</li>
                            <li>
                                review quality should be medium and in 25 fps{" "}
                            </li>
                            <li>
                                review size do not exceed more than 20mb file
                            </li>
                        </ul>
                        <div className="text-center p-3">
                            <button
                                className={data.pinkButton + "h-12"}
                                onClick={() => updatereview()}
                            >
                                update Review
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpdateReviewvideo;
