import React from "react";
import { useLocation, useRouteMatch } from "react-router";
import { Link, Switch, Route } from "react-router-dom";
import FeedBack from "./FeedBack";
import Inmessage from "./Inmessage";
import Report from "./Report";
import Request from "./Request";

function Engagement() {
    let { path, url } = useRouteMatch();
    const location = useLocation();

    const active = " font-medium bg-tab_active_bg text-tab_active_color  py-3 w-full text-center";
    const passive = " bg-tab_passive_bg text-tab_passive_color  py-3  w-full text-center border-r border-black border-opacity-5";

    return (
        <div className="pt-16 ">
            <div className="">
                <div className=" px-10 flex justify-evenly ">
                    <Link
                        to={`${url}/report`}
                        className={
                            location.pathname.includes("report") ||
                                location.pathname === url
                                ? active
                                : passive
                        }
                    >
                        Report
                    </Link>
                    <Link
                        to={`${url}/request`}
                        className={
                            location.pathname.includes("request")
                                ? active
                                : passive
                        }
                    >
                        Reqeust
                    </Link>
                    <Link
                        to={`${url}/feedback`}
                        className={
                            location.pathname.includes("feedback")
                                ? active
                                : passive
                        }
                    >
                        Feedback
                    </Link>
                    <Link
                        to={`${url}/inmessage`}
                        className={
                            location.pathname.includes("inmessage")
                                ? active
                                : passive
                        }
                    >
                        In Message Report
                    </Link>
                </div>
                <div className="">
                    <Switch>
                        <Route exact path={path}>
                            <Report />
                        </Route>
                        <Route path={`${path}/report`}>
                            <Report />
                        </Route>
                        <Route path={`${path}/request`}>
                            <Request />
                        </Route>
                        <Route path={`${path}/feedback`}>
                            <FeedBack />
                        </Route>
                        <Route path={`${path}/inmessage`}>
                            <Inmessage />
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    );
}

export default Engagement;
