import axios from "axios";
import urls from "../utils/urls";

const useGetRequest = () => {
  const cancelToken = axios.CancelToken.source();
  const getData = (path, onComplete, onError, reqParams) => {
    return new Promise((resolve, reject) => {
      axios
        .get(path, { cancelToken: cancelToken.token, ...reqParams })
        .then((res) => {
          if (onComplete) onComplete(res.data);
          resolve(res.data);
        })
        .catch((err) => {
          if (onError) onError(err);
          reject(err);
        });
    });
  };

  const cancelRequests = () => {
    if (cancelToken) cancelToken.cancel();
  };

  return [getData, cancelRequests];
};

async function deleteReviewRequest(data) {
  let response = await axios(urls.admin + "deleteReview", {
    method: "put",
    headers: {
      Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });

  if (response.status === 200) {
    return response.data;
  }
  console.log(response);
  return false;
}
async function deleteFlicksRequest(data) {
  let response = await axios(urls.admin + "deleteFlicks", {
    method: "put",
    headers: {
      Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });

  if (response.status === 200) {
    return response.data;
  }
  console.log(response);
  return false;
}

export { useGetRequest, deleteReviewRequest, deleteFlicksRequest };
