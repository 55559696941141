import React from "react";
import { useState } from "react";
import urls from "../utils/urls";

function BrandProducts({ brandId }) {
  const fetchStats = async () => {
    setloading(true);
    console.log("fetching from server all users");
    const response = await fetch(urls.admin + "getBrandProducts/" + brandId, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });

    if (response.status === 200) {
      let res = await response.json();
      setnoData(res.length === 0);
      setStats(res);
      setloading(false);
    }
  };

  const [nodata, setnoData] = useState(false);
  const [loading, setloading] = useState(false);
  const [stats, setStats] = useState([]);
  const [query, setSearch] = useState("");

  if (loading) {
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <i className="animate-pulse">loading...</i>
      </div>
    );
  } else if (stats.length !== 0) {
    return (
      <div className="bg-white rounded-md shadow-md p-4">
        <p className="my-3 text-2xl">{stats.length} Products</p>
        <input
          type="text"
          className="border h-10 px-2 py-1 w-full"
          placeholder="search by name"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="h-96 overflow-y-scroll grid grid-cols-2 gap-2">
          {(query ? stats.filter((r) => r.name.includes(query)) : stats).map(
            (prod) => (
              <div className="flex items-center justify-start">
                <img src={prod.imageUrl} alt="" className="h-32 w-32" />
                <div className="">
                  <p className="">
                    {prod.name.length > 30
                      ? prod.name.substring(0, 30)
                      : prod.name}
                  </p>
                  <p className="text-xs font-bold">{prod.id}</p>
                  <p className="text-xs">{prod.reviews} Reviews </p>
                  <p className="text-xs">{prod.flicks} Flicks</p>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    );
  } else if (nodata) {
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <i className="animate-pulse">No Brand Products Found</i>
      </div>
    );
  } else
    return (
      <div className="rounded-md shadow-md flex items-center justify-center border bg-blue-100">
        <button
          className="border p-2 shadow-md border-blue-500"
          onClick={fetchStats}
        >
          Reveal Brand Products
        </button>
      </div>
    );
}

export default BrandProducts;
