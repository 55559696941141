import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AWN from "awesome-notifications";
import FlicksBonusCoin from "../utils/FlicksBonusCoin";
import { deleteFlicksRequest } from "../helpers/apicall";
import urls from "../utils/urls";
import axios from "axios";
import UpdateProductFlicks from "../utils/UpdateProductFlicks";
function ViewFlicks() {
  const { reviewid } = useParams();
  const [tags, setTags] = useState([]);
  const router = useHistory();
  const [review, setreview] = useState(null);
  const [play, setplay] = useState(false);
  const [loading, setloading] = useState(true);

  const fetchFlick = async () => {
    console.log("fetching from server all users");
    const response = await fetch(urls.admin + "getFlick/" + reviewid, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });

    if (response.status === 200) {
      let res = await response.json();
      console.log(res);
      setreview(res);
      setloading(false);
    }
  };

  useEffect(() => {
    setloading(true);
    fetchFlick();
  }, [reviewid]);

  useEffect(() => {}, [review, tags.length]);

  const deletethis = () => {
    let data = {
      reviewId: review.id,
      uploadedBy: review.uploadedBy,
    };

    new AWN().confirm(
      "Sure, you want to delete this review",
      () => {
        new AWN().async(
          deleteFlicksRequest(data),
          (resp) => {
            console.log(resp);
            new AWN().success(resp.message);
            if (resp.success) {
              let sessionReviews = sessionStorage.getItem("flicks");
              if (sessionReviews) {
                sessionReviews = JSON.parse(sessionReviews);
                sessionReviews = sessionReviews.filter(
                  (r) => r.id !== review.id
                );
                sessionStorage.setItem(
                  "flicks",
                  JSON.stringify(sessionReviews)
                );
              }
              router.goBack();
            }
          },
          (err) => {
            new AWN().alert(err);
          }
        );
      },
      () => {
        new AWN().info("operation cancelled");
      }
    );
  };

  const handleCreatorList = async () => {
    new AWN().async(
      axios(
        "https://userspecificapi-zscu3untuq-el.a.run.app/updateTopFlicks/" +
          reviewid,
        {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "asdfwp234-0835234-34535FHD568-a26463213234234-llksdfsdlfwSLDF",
          },
        }
      ),
      (response) => {
        if (response.status === 200) {
          let data = response.data;
          let message = "operation not succeded";
          console.log(data);
          if (data.success) {
            message = data.top
              ? "Added in Ideas by Creator List"
              : "Removed from top users";
            new AWN().success(message);
            setreview({ ...review, top: data.top });
            return;
          }
          new AWN().warning(message);
          return;
        }
      },
      (err) => {
        console.log(err);
        new AWN().warning(err.toString());
        return;
      }
    );
  };

  const moveToFlicks = async () => {};

  const updateFlicks = (productId) => {
    new AWN().async(
      axios(urls.admin + "updateFlicksParent/" + review.id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: urls.adminAuth,
        },
        data: { productId: productId },
      }),
      (response) => {
        if (response.status === 200) {
          console.log(response.data.value);
          if (response.data.ok) {
            new AWN().success("updated successFully");
          }
        } else {
          new AWN().alert(response.statusText.toString());
        }
      },
      (error) => {
        new AWN().alert(error.toString());
      }
    );
  };

  if (loading)
    return (
      <div className="flex justify-center items-center h-screen">
        <span className="fas fa-spinner animate-spin"></span>
      </div>
    );
  else
    return (
      <>
        <div className="bg-panel_bg_secondary min-h-screen py-10 px-6 font-Manrope ">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-3">
              <i
                className="fas fa fa-arrow-left transform scale-75 cursor-pointer"
                onClick={() => router.goBack()}
              ></i>
              <p className="">View Flicks</p>
            </div>
          </div>
          <div className="bg-white shadow-sm p-5 rounded mt-5">
            <div className="w-full flex justify-between items-center">
              <div className="flex items-center gap-x-4">
                <div className="">
                  {review.product.imageUrl ? (
                    <img
                      src={review.product.imageUrl}
                      alt=""
                      className="h-14 w-14 rounded-full"
                      draggable={false}
                    />
                  ) : (
                    <div className="bg-inputbg h-14 w-14 rounded-full text-black flex items-center justify-center text-lg">
                      {review?.product.name
                        ?.split(" ")[0]
                        .substring(0, 2)
                        .toUpperCase()}
                    </div>
                  )}
                </div>
                <div className="">
                  <p className="text-lg font-bold">{review?.product.name}</p>
                  <p className="text-sm font-medium">
                    {review?.brand || "not present"}
                  </p>
                </div>
              </div>

              <div className="ml-10  justify-self-end  space-x-3">
                <button
                  className="bg-white hover:bg-gray-200 border px-6 py-2 text-blue-500 text-sm appearance-none outline-none rounded"
                  onClick={handleCreatorList}
                >
                  {review.top ? "remove from" : "Add in"} Ideas from Creator
                  List
                </button>
              </div>
            </div>
            <div className="grid grid-cols-2 grid-flow-col mt-10">
              <div className="">
                <div className="bg-white rounded-lg w-full pb-5 shadow-sm">
                  <div className="border-b  px-5">
                    <p className="border-b border-nav_active_bg w-20 py-3">
                      &nbsp; &nbsp; Details
                    </p>
                  </div>

                  <div className="py-5 grid grid-flow-row grid-cols-2 px-5 gap-x-5">
                    <div className="py-2">
                      <p className="">Title</p>
                      <p className="text-blue-500">{review?.name}</p>
                    </div>
                    <div className="py-2">
                      <p className="">Description</p>
                      <p className="text-blue-500">{review?.description}</p>
                    </div>

                    <div className="py-2">
                      <p className="">Flick Tag</p>
                      <p className="text-blue-500">
                        {review?.flickTag || "not present"}
                      </p>
                    </div>
                    <div className="py-2">
                      <p className="">sub-Flick Tag</p>
                      <p className="text-blue-500">
                        {review.subFlicksTag || "not present"}
                      </p>
                    </div>
                    <div className="flex items-center gap-3">
                      <img
                        src={review.user.imageUrl}
                        className="h-20 w-20 rounded-full"
                        alt="noimage"
                      />
                      <p className="flex-1">
                        <span className="text-xl font-semibold">
                          {review.user.name}
                        </span>
                        <br /> <span>{review.user.id}</span>
                      </p>
                    </div>
                    <UpdateProductFlicks
                      product={review.product}
                      updateFun={updateFlicks}
                    />
                    <div className="py-2 col-span-2">
                      <div className="grid grid-cols-2 grid-flow-col rounded border bg-panel_bg_secondary">
                        <div className="border-l py-3 px-5">
                          <div className="flex gap-x-3 items-center">
                            <p className="">Views</p>
                          </div>
                          <div className="py-5 flex items-center justify-around">
                            <i className="fas fa fa-eye fa-3x text-blue-400"></i>
                            <p className=" text-4xl font-medium text-nav_active_bg">
                              {review.views || 0}
                            </p>
                          </div>
                        </div>
                        <div className="border-l py-3 px-5">
                          <div className="flex gap-x-3 items-center">
                            <p className="">Hearts</p>
                          </div>
                          <div className="py-5 flex items-center justify-around">
                            <i className="fas fa fa-heart fa-3x text-red-400"></i>
                            <p className=" text-4xl font-medium text-nav_active_bg">
                              {review.hearts || 0}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center">
                {play ? (
                  <video
                    src={review.reviewUrl}
                    className="rounded w-64 aspect_video object-cover"
                    controls
                  ></video>
                ) : (
                  <div
                    className="w-64 aspect_video relative flex items-center justify-center cursor-pointer"
                    onClick={() => setplay(true)}
                  >
                    <i className="fas fa-play text-white  z-10 absolute"></i>
                    <img
                      src={review.thumbnail}
                      className="w-64 rounded"
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <FlicksBonusCoin flicks={review} setFlicks={setreview} />
          <div className="bg-red-300 rounded-md border border-red-500 my-3 p-2">
            <h1 className="text-white text-3xl">Danger Section</h1>
            <div className="flex items-center justify-between my-1">
              <p className="">
                By clicking on this button you are going to delete review. Now
                this will go in recycle Bin collection.
              </p>
              <button
                className="text-white bg-red-500 rounded-md p-2"
                onClick={deletethis}
              >
                Delete Flicks
              </button>
            </div>
            <div className="flex items-center justify-between my-1">
              <p className="">Move this Flicks to Review collection</p>
              <button
                className="text-white bg-red-500 rounded-md p-2"
                onClick={moveToFlicks}
              >
                Move to Review
              </button>
            </div>
          </div>
        </div>
      </>
    );
}

export default ViewFlicks;
