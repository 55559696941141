import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Goback from "./utils/Goback";
import { data } from "./utils/Res";
import AWN from "awesome-notifications";
import { firebasefb } from "./firebaseconfig";
import { useHistory, useParams } from "react-router";

function UpdateNotification() {
    const [not, setnot] = useState({});
    const [loading, setloading] = useState(true);
    const { notid } = useParams();

    const router = useHistory();

    useEffect(() => {
        firebasefb
            .collection("notifications")
            .doc(notid)
            .get()
            .then((res) => {
                setnot(res.data());
                setloading(false);
            });
    }, []);

    let messageref = useRef();
    let headref = useRef();
    let timeref = useRef();

    const addnewnot = () => {
        if (headref.current?.value?.length < 10)
            return new AWN().warning("add more words in headline");
        if (messageref.current.value.length < 10)
            return new AWN().warning("add more words in message");

        if (timeref.current.value.length === "")
            return new AWN().warning("add time when this will be published");
        if (!not.type) return new AWN().warning("select type");

        const message = {
            header: headref.current.value,
            message: messageref.current.value,
            time: timeref.current.value,
            type: not.type,
        };

        firebasefb
            .collection("notifications")
            .doc(notid)
            .update(message)
            .then((res) => {
                new AWN().success("success");
                router.goBack();
            })
            .catch((e) => {
                new AWN().alert(e);
                router.goBack();
            });
    };
    if (loading)
        return (
            <div className="h-screen flex items-center justify-center">
                <span className="fas fa-spinner animate-spin fa-3x"></span>
            </div>
        );
    else
        return (
            <div className="bg-gray-100 min-h-screen">
                <div className="bg-white h-24 mb-10">
                    <h1 className="font-medium text-3xl flex flex-grow-0 pt-5 pl-8 ">
                        <Goback />
                        <span className="pt-2">
                            &nbsp; Update {not?.header}
                        </span>
                    </h1>
                </div>
                <div className="">
                    <div className="bg-gray-100 m-10 grid grid-cols-2">
                        <div className="m-5">
                            <p className="font-medium text-lg pb-2">HeadLine</p>
                            <input
                                type="text"
                                ref={headref}
                                defaultValue={not?.header}
                                placeholder="Notification HeadLine"
                                className={data.textInputStyle}
                            />
                        </div>
                        <div className="m-5">
                            <p className="font-medium text-lg pb-2">Message</p>
                            <input
                                type="text"
                                ref={messageref}
                                defaultValue={not?.message}
                                placeholder="Notification Message"
                                className={data.textInputStyle}
                            />
                        </div>

                        <div className="m-5">
                            <p className="font-medium text-lg pb-2">Time</p>
                            <input
                                type="datetime-local"
                                ref={timeref}
                                defaultValue={not?.time}
                                placeholder="Enter Category Name"
                                min={
                                    moment(new Date())
                                        .format("YYYY-MM-DD")
                                        .toString() + "T00:00"
                                }
                                className={data.textInputStyle}
                            />
                        </div>
                        <div className="m-5">
                            <p className="font-medium text-lg pb-2">type</p>
                            <select
                                onChange={(e) => {
                                    setnot({ ...not, type: e.target.value });
                                }}
                                name="status"
                                id=""
                                className={data.textInputStyle}
                            >
                                <option
                                    defaultChecked={"draft" === not?.type}
                                    value="draft"
                                >
                                    Draft
                                </option>
                                <option
                                    defaultChecked={"schedule" === not?.type}
                                    value="schedule"
                                >
                                    Schedule
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="text-right mx-10 px-5">
                    <button
                        className="w-48 h-14 bg-pink-500 hover:bg-pink-700 text-white font-medium tracking-wide"
                        onClick={() => addnewnot()}
                    >
                        Update
                    </button>
                </div>
            </div>
        );
}

export default UpdateNotification;
