import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { firebasefb } from "../firebaseconfig";
function TrendingHashtagManage() {
  const [hashtags, setHashtags] = useState([]);
  const [selected, setSelected] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [loading, setloading] = useState(false);

  let arrowRef = useRef();
  let expandedAreaRef = useRef();

  useEffect(() => {
    let cancelSub = firebasefb.collection("hashtags").onSnapshot((snapshot) => {
      let b = snapshot.docs.map((snap) => snap.data());
      setHashtags(b);
    });

    return () => cancelSub();
  }, []);

  const loadReviews = async () => {
    if (!selected?.hash) return;
    let revs = await firebasefb
      .collection("review")
      .where("tags", "array-contains", selected.hash)
      .get()
      .then((res) =>
        res.docs.map((r) => {
          let rev = r.data();
          let data = {
            id: rev.id,
            thumbnail: rev.thumbnail,
          };
          return data;
        })
      );
    setReviews(revs);
    console.log(revs);
  };

  const makeThubmnail = () => {
    if (loading) return;
    let revs = reviews.filter((r) => r.selected);
    if (revs.length <= 1) {
      alert("Please select atleast one review to generate thumbnail");
      return;
    }
    revs = revs.slice(0, 5);
    console.log(revs);
    revs = revs.map((r) => r.thumbnail);
    setloading(true);

    axios
      .post(
        "http://35.207.209.77:3000/mergeImage",
        { images: revs, tag: selected.hash },
        {
          headers: {
            Authorization: "asldfhapweroalkxnv#%#^2-43js",
            "Content-Type": "application/json",
          },
        }
      )
      .catch((e) => {
        console.log(e);
        setloading(false);
      })
      .then((e) => {
        console.log(e);
        setloading(false);
      });
  };

  return (
    <div className="">
      <h1 className="h-min text-3xl p-3 mx-2 rounded-md shadow-md">
        Manage Trending HashTags
      </h1>
      <div className="flex h-almost">
        <div className="w-96 rounded-md shadow-md mp-3 m-2 overflow-scroll">
          {hashtags.map((tag, index) => (
            <p
              key={"tagm" + index}
              className={
                selected === tag
                  ? "h-8 my-1 cursor-pointer shadow-sm px-2 bg-blue-700 py-1 text-white"
                  : "h-8 my-1 cursor-pointer shadow-sm px-2 py-1"
              }
              onClick={() => {
                setSelected(tag);
                setReviews([]);
              }}
            >
              {tag.hash}
            </p>
          ))}
        </div>
        <div className="rounded-md shadow-md mp-3 m-2 flex-1 flex flex-col">
          <div className="h-min grid grid-cols-2 gap-5 shadow-md bg-white p-3 rounded-md mx-1">
            <div className="">
              <p className="text-xs text-gray-700">Hash</p>
              <p className="">{selected?.hash ?? "not selected"}</p>
            </div>
            <div className="">
              <p className="text-xs text-gray-700">sub Category</p>
              <p className="">{selected?.subcategory ?? "not selected"}</p>
            </div>
            <div className="">
              <p className="text-xs text-gray-700"> Category</p>
              <p className="">{selected?.category ?? "not selected"}</p>
            </div>
            <div className="">
              <p className="text-xs text-gray-700">Trending</p>
              <p className="">
                {selected?.trending ? (
                  <button className="bg-green-500 text-white px-3 py-2 rounded-md shadow-md">
                    Trending
                  </button>
                ) : (
                  <button className="bg-red-500 text-white px-3 py-2 rounded-md shadow-md">
                    Not Trending
                  </button>
                )}
              </p>
            </div>
            <div className="">
              <p className="text-xs text-gray-700">Product</p>
              <p className="">{selected?.featuredProduct ?? "Not selected"}</p>
            </div>
            <div className="">
              <p className="text-xs text-gray-700">Thumbnail</p>
              {selected?.imageUrl ? (
                <img src={selected?.imageUrl} alt="" />
              ) : (
                <p className="">Not generated</p>
              )}
            </div>
          </div>
          <div className="m-2 shadow-md rounded-md flex flex-col flex-1">
            <div
              onClick={() => {
                arrowRef.current.classList.toggle("rotate-full");
                expandedAreaRef.current.classList.toggle("flex-1");
                if (expandedAreaRef.current.classList.contains("flex-1"))
                  loadReviews();
                setReviews([]);
              }}
              className="cursor-pointer transform duration-200 w-full flex items-center justify-between rounded-md hover:bg-gray-300"
            >
              <p className="text-3xl my-3 px-3">Generate Clip</p>
              <p className=" ">
                <i
                  ref={arrowRef}
                  className="fas fa-arrow-up p-3 transform duration-200"
                ></i>
              </p>
            </div>
            <div
              ref={expandedAreaRef}
              className=" transform relative duration-200 h-0  overflow-hidden ease-in-out bg-white"
            >
              <div className="flex flex-wrap gap-2 ">
                {reviews.map((rev) =>
                  rev.selected ? (
                    <div
                      key={rev.id}
                      className="bg-black rounded h-64 cursor-pointer"
                      onClick={() => {
                        rev.selected = false;
                        let temp = Array.from(reviews);
                        setReviews(temp);
                      }}
                    >
                      <img
                        src={rev?.thumbnail}
                        alt="url not found"
                        className="h-64 rounded -mb-10 opacity-50"
                      />
                      <i className="fas fa-check text-white shadow-md bg-blue-500 ml-3 p-2 rounded-full"></i>
                    </div>
                  ) : (
                    <div
                      key={rev.id}
                      className="bg-black rounded h-64 cursor-pointer"
                      onClick={() => {
                        rev.selected = true;
                        let temp = Array.from(reviews);
                        setReviews(temp);
                      }}
                    >
                      <img
                        src={rev?.thumbnail}
                        alt="url not found"
                        className="h-64 rounded  -mb-10"
                      />
                    </div>
                  )
                )}
              </div>
              <button
                className="text-white bg-green-500 rounded h-12 px-4  my-5 hover:bg-green-400"
                onClick={makeThubmnail}
              >
                {loading ? (
                  <i className="fas fa-spinner px-3 animate-spin"></i>
                ) : (
                  "Make thumbnail"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrendingHashtagManage;
