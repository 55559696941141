import { DataGrid } from "@material-ui/data-grid";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { firebasefb } from "./firebaseconfig";
import { data } from "./utils/Res";

function Inmessage() {
  const [report, setreport] = useState([]);
  const [loading, setloading] = useState(true);
  useEffect(() => {
    firebasefb
      .collection("inmessage")
      .get()
      .then((res) => {
        var a = [];
        res.docs.forEach((r, index) =>
          a.push({ ...r.data(), index: index + 1, id: r.id })
        );
        setreport(a);
        setloading(false);
      });
  }, []);

  const columns = [
    { field: "index", headerName: "", width: 60 },
    { field: "id", headerName: "id", width: 190 },
    {
      field: "message",
      headerName: "Message",
      renderCell: ({ row }) => (
        <div>
          <p className="">{`${row.message.message}`}</p>
        </div>
      ),
      width: 190,
    },
    {
      field: "message.senderId",
      headerName: "Report to",
      renderCell: ({ row }) => (
        <div>
          <p className="">{`${row.message.senderId}`}</p>
        </div>
      ),
      width: 190,
    },
    { field: "report_by", headerName: "Report By", width: 190 },
  ];

  if (loading)
    return (
      <div className="flex h-screen justify-center items-center ">
        <span className="fas fa-spinner animate-spin"></span>
      </div>
    );
  else
    return (
      <div className="font-Manrope">
        <div className="flex justify-between items-center m-10 ">
          <p className="text-3xl font-medium">Total Message Report:</p>
          <p className="text-3xl font-semibold "> {report.length}</p>
        </div>

        <div className="m-10 ">
          <div className="h-screen bg-white shadow-lg">
            <DataGrid rows={report} columns={columns} pageSize={20} />
          </div>
        </div>
        <div className="h-2"></div>
      </div>
      // <div>
      //   <div className=" bg-white flex justify-between items-end m-10 h-auto  shadow-lg">
      //     <div className="">
      //       <p className="text-3xl font-medium p-4">Total Message Report</p>
      //     </div>
      //     <div className="pr-4">
      //       <p className="text-md font-Regular text-sidetext text-right ">
      //         Message reports
      //       </p>

      //       <p className="text-3xl font-medium flex flex-grow-0 text-right ">
      //         {report.length}
      //       </p>
      //     </div>
      //   </div>
      //   <div className="m-10 ">
      //     <h1 className="text-3xl font-semibold py-3 border-b">
      //       inMessage Reports
      //     </h1>
      //     <div className="h-screen bg-white">
      //       <DataGrid rows={report} columns={columns} pageSize={20} />
      //     </div>
      //   </div>
      // </div>
    );
}

export default Inmessage;
