import AWN from "awesome-notifications";
import React from "react";
import { useState } from "react";
import { firebasefb } from "../firebaseconfig";
import axios from "axios";
import urls from "../utils/urls";

function BulkProductInsert() {
  const [prods, setprods] = useState(null);
  const [failed, setFailed] = useState([]);
  const [step, setStep] = useState(1);

  let tsvheaders = {
    products:
      "id,name,parent,description,affiliateLink,imageUrl,sku,category,subcategory",
  };

  const checkAllProducts = () => {
    const heads =
      "id,name,parent,description,affiliateLink,imageUrl,sku,catId,subcategory";
    for (const prod of prods) {
      for (const head of heads.split(",")) {
        if (!prod[head] || prod[head] === "null") {
          new AWN().alert(
            "Please fix this row before uploading Product Name => " + prod.name
          );
          return false;
        }
      }
    }
    return true;
  };

  const uploadCsv = async () => {
    if (!checkAllProducts()) return;
    console.log(prods);
    const selectedFile = new File([JSON.stringify(prods)], "product.json", {
      type: "text/plain",
    });

    let formData = new FormData();
    formData.append("file", selectedFile);

    new AWN().async(
      axios.post(urls.admin + "insertProducts", formData, {
        headers: {
          Authorization: urls.adminAuth,
        },
      }),
      function (response) {
        if (response.data.success) {
          setFailed(response.data.failedDoc);
          new AWN().success("File uploaded successfully.");
          setStep(3);
        } else new AWN().alert(response.data.message);
      },
      function (error) {
        new AWN().alert("Error uploading file.");
      }
    );
  };

  const getReadData = (text) =>
    new Promise((resolve, reject) => {
      let tsvReader = new FileReader();

      tsvReader.onload = (event) => {
        var csvdata = event.target.result;
        csvdata = csvdata.split("\n");
        let headers = csvdata.shift();
        headers = headers.trim();
        headers = headers.split("\t");

        for (const head of headers) {
          if (!tsvheaders.products.includes(head.trim()))
            reject(
              head +
                "<-- this is not suitable header recommended header --> " +
                tsvheaders.products
            );
        }
        csvdata = csvdata.map((row) => {
          row = row.replace("\r", "");
          row = row.split("\t");
          let cols = {};
          row.forEach((col, index) => {
            cols[headers[index]] = col;
          });
          return cols;
        });

        resolve(csvdata);
      };

      tsvReader.readAsText(text);
    });

  const importTsv = async (file) => {
    try {
      console.log(file);
      if (!file) {
        new AWN().warning("not tsv file selected");
        return;
      }
      let ext = file.name.split(".").pop();
      if (ext !== "tsv") {
        new AWN().warning("this is not tsv file");
        return;
      }
      console.log("------------------reading data-----------");
      let data = await getReadData(file).catch((e) => {
        new AWN().alert(e.toString());
        return null;
      });

      if (!data) return;
      let categories = await firebasefb
        .collection("category")
        .get()
        .then((res) => res.docs.map((r) => r.data()));
      console.log(data);

      data.forEach((d, index) => {
        let cat = categories.find((cat) => cat.id === d.category);
        if (!cat) {
          throw new Error(
            "some row has no category or bad cat id Index => " +
              index +
              "  " +
              d.name
          );
        }
        let attr = cat.images.find((sub) => d.subcategory === sub.name);
        console.log(attr);
        if (!attr) {
          throw new Error("some row has no subcategory at index => " + index);
        }
        d.shopify = { id: Number.parseInt(d.id) };
        d.name = d.name.toLowerCase();
        d.attributes = attr.attributes;
        d.createdAt = Date.now();
        d.id = "pro_" + d.sku?.replaceAll(" ", "");
        d.flicks = 0;
        d.reviews = 0;
        d.rating = 3;
        d.catId = d.category;
        delete d.category;
        d.tag = d.sku;
        d.subscribes = 0;
        d.status = true;
      });

      setprods(data);
      setStep(2);
    } catch (error) {
      new AWN().alert(error.toString());
    }
  };

  const markRed = (prod, index) => {
    return (
      <tr key={prod.id}>
        <td>{index + 1}</td>
        {tsvheaders.products.split(",").map((keys) => {
          if (keys === "attributes") return null;
          if (keys === "coverImage") return null;
          if (keys === "imageUrl")
            return (
              <td key={prod.id + keys}>
                <img
                  src={prod[keys]}
                  alt="noimage"
                  className={
                    !prod[keys] || prod[keys] === "null"
                      ? "bg-red-200 h-20"
                      : "h-20"
                  }
                />
              </td>
            );
          if (keys === "category")
            return <td key={prod.id + keys}>{prod.catId}</td>;
          else
            return (
              <td
                className={
                  !prod[keys] || prod[keys] === "null"
                    ? "bg-red-200 h-20"
                    : "h-20"
                }
                key={prod.id + keys}
              >
                <p className="h-20 overflow-auto"> {prod[keys]}</p>
              </td>
            );
        })}
      </tr>
    );
  };

  if (step === 1)
    return (
      <div className="p-3">
        <h3 className="my-2 py-4 px-3 text-3xl">Insert Bulk Product</h3>

        <div className="h-96 w-full border border-black rounded-md shadow-md bg-gray-100 flex flex-col items-center justify-center">
          <p className="">
            Upload product TSV file to process or check compatiblity
          </p>
          <input
            type="file"
            id="tsvfile"
            className=" hidden"
            onChange={(e) => importTsv(e.target.files?.item(0))}
          />
          <label htmlFor="tsvfile">
            <p className="py-2 px-3 rounded bg-green-500 text-white shadow-md">
              Upload TSV
            </p>
          </label>
        </div>
      </div>
    );
  else if (step === 2)
    return (
      <div className="p-3">
        <h3 className="my-2 py-4 px-3 text-3xl">Insert Bulk Product</h3>

        <div>
          <p className="text-3xl py-3 px-3 my-2 shadow-md rounded">
            Bulk Upload Product
          </p>
        </div>
        <div className="w-full">
          <button
            onClick={uploadCsv}
            className="px-4 py-2 rounded bg-green-500 text-white shadow-md"
          >
            Upload All
          </button>
        </div>
        <div className="bg-red-200 rounded-md p-3 my-4 ">
          *product marked red will not be uploaded.
        </div>
        <div className="w-full max-h-screen overflow-auto">
          <table className="w-full">
            <thead>
              <tr>
                <th>SL. NO.</th>
                {tsvheaders.products.split(",").map((head, index) => (
                  <th key={head}>{head}</th>
                ))}
              </tr>
            </thead>
            <tbody>{prods?.map((prod, index) => markRed(prod, index))}</tbody>
          </table>
        </div>
      </div>
    );
  else if (step === 3)
    return (
      <div className="">
        <h3 className="my-2 py-4 px-3 text-3xl">Insert Bulk Product</h3>
        <div className="h-96 flex flex-col items-center justify-center bg-green-600">
          <p className="text-3xl text-white">Product Uploaded SuccessFully</p>
        </div>
      </div>
    );
}

export default BulkProductInsert;
