import React, { useEffect, useState } from "react";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import { firebasefb } from "./firebaseconfig";
import { data } from "./utils/Res";
import { Avatar } from "@material-ui/core";
import PushNotification from "./PushNotification";

function Notifications() {
  let { path, url } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const [showPush, setshowPush] = useState(false);
  const [currentrow, setcurrentrow] = useState(null);
  const [notification, setnotification] = useState([]);

  useEffect(() => {
    firebasefb
      .collection("notifications")
      .get()
      .then((res) => {
        var a = [];
        res.docs.forEach((ele) => {
          a.push({ ...ele.data(), id: ele.id });
        });
        setnotification(a);
      });
  }, []);

  const active =
    " font-medium bg-tab_active_bg text-tab_active_color  py-3 w-full text-center";
  const passive =
    " bg-tab_passive_bg text-tab_passive_color  py-3  w-full text-center border-r border-black border-opacity-5";

  const columns = [
    { field: "header", headerName: "Header", width: 250 },
    { field: "message", headerName: "Description", width: 300 },
    {
      field: "image",

      renderCell: ({ row }) => {
        return <Avatar src={row.user?.ImageUrl} alt="sdf" />;
      },
      headerName: "Image",
      width: 80,
    },
    {
      field: "name",

      renderCell: ({ row }) => {
        return <p className="">{row.user?.name}</p>;
      },
      headerName: "name",
      width: 150,
    },
    { field: "time", headerName: "Date Added", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 500,
      // eslint-disable-next-line react/display-name
      renderCell: ({ row }) => (
        <div>
          {row.type !== "publish" ? (
            <select
              onChange={(e) => {
                row.type = e.target.value;
                firebasefb
                  .collection("notifications")
                  .doc(row.id)
                  .update({ type: e.target.value });
              }}
              className=" h-12 w-44"
              name=""
              id=""
            >
              <option value="draft">Draft</option>
              <option value="schedule">Schedule</option>
            </select>
          ) : null}

          {row.type === "publish" ? (
            <button className={data.greenButton}>published</button>
          ) : (
            <>
              <button
                onClick={() =>
                  history.push(`/home/updatenotification/${row.id}`)
                }
                className={data.redButton}
              >
                Update
              </button>
              <button
                className={data.yellowButton}
                onClick={() => {
                  setcurrentrow(row);
                  setshowPush(true);
                }}
              >
                Push Now
              </button>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="bg-panel_bg min-h-screen py-6 font-Manrope">
      <div className="flex justify-between items-center m-10 ">
        <p className="text-3xl font-medium">Total Notifications:</p>
        <p className="text-3xl font-semibold text-nav_active_color">
          {" "}
          {notification.length}
        </p>
      </div>
      {/* <div className="flex m-10 h-18 bg-white p-1 border-2 items-center flex-wrap">
                <div className="flex-grow flex items-center">
                    <i className="fas fa-search p-2 text-gray-700 mt-2"></i>
                    <input
                        className="flex-grow h-full outline-none text-gray-700"
                        type="text"
                        placeholder="Search notification"
                        onChange={(e) => {
                            setquery(e.target.value);
                            setsearchResult(
                                notification.filter((row) =>
                                    row.name
                                        .toLowerCase()
                                        .includes(e.target.value)
                                )
                            );
                        }}
                    />
                </div>
                <div className="duration-200">
                    <button
                        onClick={() => {
                            history.push("/home/createNotification");
                        }}
                        className=" bg-green-500 hover:bg-green-700 w-64 h-14  focus:outline-none text-white"
                    >
                        <span className="text-center"><i className="fas fa-plus "></i> Create New Notification</span>
                    </button>
                </div>
            </div> */}
      <div className="flex justify-between border mx-10">
        <Link
          to={`${url}`}
          className={location.pathname === url ? active : passive}
        >
          All
        </Link>
        <Link
          to={`${url}/draft`}
          className={location.pathname.includes("draft") ? active : passive}
        >
          Draft
        </Link>
        <Link
          to={`${url}/scheduled`}
          className={location.pathname.includes("scheduled") ? active : passive}
        >
          Scheduled
        </Link>
        <Link
          to={`${url}/published`}
          className={location.pathname.includes("published") ? active : passive}
        >
          Published
        </Link>
      </div>

      <div className="   bg-white rounded  w-full p-10">
        <Switch>
          <Route exact path={path}>
            <DataGrid
              autoHeight={true}
              rows={notification}
              columns={columns}
              pageSize={20}
            />
          </Route>
          <Route path={`${path}/draft`}>
            <DataGrid
              autoHeight={true}
              rows={notification.filter((a) => a.type === "draft")}
              columns={columns}
              pageSize={20}
            />
          </Route>
          <Route path={`${path}/scheduled`}>
            <DataGrid
              autoHeight={true}
              rows={notification.filter((a) => a.type === "schedule")}
              columns={columns}
              pageSize={20}
            />
          </Route>
          <Route path={`${path}/published`}>
            <DataGrid
              autoHeight={true}
              rows={notification.filter((a) => a.type === "publish")}
              columns={columns}
              pageSize={20}
            />
          </Route>
        </Switch>
      </div>
      {showPush ? (
        <PushNotification
          setshow={setshowPush}
          row={currentrow}
          message={currentrow}
        />
      ) : null}
    </div>
  );
}

export default Notifications;
