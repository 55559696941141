import { useState } from "react";
import Redemption from "./Redemption";
import Invitation from "./Invitation";
import LeaderBoard from "./LeaderBoard";
import {
    Link,
    Route,
    Switch,
    useLocation,
    useRouteMatch,
} from "react-router-dom";

function Reward() {
    const [loading, setloading] = useState(false);
    let { path, url } = useRouteMatch();
    const location = useLocation();
    const active =
        " pt-5 font-medium bg-pink-400 text-white h-16 w-full text-center";
    const passive = "pt-5 bg-white text-black h-16 w-full text-center";

    console.log(url, path);

    return loading ? (
        <div className="h-screen flex items-center justify-center">
            <span className="fas fa-spinner animate-spin"></span>
        </div>
    ) : (
        <div className="bg-background min-h-screen w-full py-6">
            <div className="m-6 ">
                <div className="p-4 flex justify-evenly ">
                    <Link
                        to={`${url}/redemption`}
                        className={
                            location.pathname.includes("redemption") ||
                            location.pathname === url
                                ? active
                                : passive
                        }
                    >
                        Redemption
                    </Link>
                    <Link
                        to={`${url}/invitation`}
                        className={
                            location.pathname.includes("invitation")
                                ? active
                                : passive
                        }
                    >
                        Invitation
                    </Link>
                    <Link
                        to={`${url}/leaderboard`}
                        className={
                            location.pathname.includes("leaderboard")
                                ? active
                                : passive
                        }
                    >
                        LeaderBoard
                    </Link>
                </div>
            </div>
            <div className="m-10">
                <div className=" border  bg-white rounded h-screen w-full">
                    <Switch>
                        <Route exact path={path}>
                            <Redemption />
                        </Route>
                        <Route path={`${path}/redemption`}>
                            <Redemption />
                        </Route>
                        <Route path={`${path}/invitation`}>
                            <Invitation />
                        </Route>
                        <Route path={`${path}/leaderboard`}>
                            <LeaderBoard />
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    );
}

export default Reward;
