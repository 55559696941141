// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyByseu9xYgJzhDX3qLrjnWcOGw_keL0qgg",
  authDomain: "revueapp-4eeed.firebaseapp.com",
  databaseURL:
    "https://revueapp-4eeed-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "revueapp-4eeed",
  storageBucket: "revueapp-4eeed.appspot.com",
  messagingSenderId: "383429322359",
  appId: "1:383429322359:web:2fa03d2e185e4b710c0872",
  measurementId: "G-PB72CS3XGE",
};

var fb;
if (!firebase.apps.length) {
  fb = firebase.initializeApp(firebaseConfig);
} else {
  fb = firebase.app(); // if already initialized, use that one
}
let auth = fb.auth();
let firebasefb = fb.firestore();
let fcm = fb.messaging();
let storage = fb.storage();

export { auth, firebasefb, storage, fcm };
