import userEvent from "@testing-library/user-event";
import React, { useRef, useState } from "react";
import { firebasefb } from "../firebaseconfig";

function SearchgetSingle({ collection, setselect }) {
  const queryref = useRef();
  const [result, setresult] = useState([]);
  const [select, setselected] = useState({});

  let searchResultDivRef = useRef();
  let pingRef = useRef();

  const findnow = async (e) => {
    e.preventDefault();
    searchResultDivRef.current.classList.toggle("hidden");
    await pingRef.current.classList.toggle("hidden");
    const query = queryref.current.value;
    var result = await firebasefb
      .collection(collection)
      .where("name", ">=", query)
      .get();

    var a = [];
    result.docs.forEach((doc) => {
      a.push(doc.data());
    });

    await pingRef.current.classList.toggle("hidden");

    setresult(a);
  };

  console.log(select);

  return (
    <div className="bg-white my-3">
      <div className="mt-10  relative">
        <form
          action=""
          className="w-1/2 flex justify-between "
          onSubmit={(e) => findnow(e)}
        >
          <input
            type="text"
            className="bg-panel_bg_secondary py-2 px-2 w-full rounded-md appearance-none outline-none focus:border focus:border-purple-500"
            placeholder="Search product...."
            ref={queryref}
          />
          <button
            type="submit"
            className="bg-green-400 px-8 py-2 text-white rounded-md ml-5"
          >
            Search
          </button>
        </form>
        <div
          ref={searchResultDivRef}
          className="hidden text-center z-10 p-3 w-full bg-panel_bg_secondary mt-5 absolute shadow-md rounded-md"
        >
          <i ref={pingRef} className="fas fa-circle animate-ping"></i>
          {result.length !== 0 ? (
            <div className="h-96 overflow-y-auto">
              <ul className="text-left">
                {result.map((item, index) => (
                  <li
                    className="p-2 border-b hover:bg-green-100 cursor-pointer flex items-center justify-between"
                    onClick={() => {
                      setselected(item);
                      setselect(item);
                      setresult([]);
                      searchResultDivRef.current.classList.toggle("hidden");
                    }}
                    key={index}
                  >
                    {item.name}
                    <button
                      onClick={() => {
                        setselected(item);
                        setselect(item);
                        setresult([]);
                      }}
                      className="bg-green-300 px-5 py-2 mx-3 rounded text-white"
                    >
                      select
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      </div>

      {/* <div className="flex items-center justify-between p-1 border">
                <i className="fas fa-search p-2 "></i>
                <form className="flex-1 flex" >
                    <input
                        type="text"
                        ref={queryref}
                        defaultValue={select?.name}
                        className=" flex-1 h-12 p-1 outline-none appearance-none"
                        placeholder="search product here "
                    />
                    <button
                        type="submit"
                        className="w-36 bg-green-500 h-12 text-white"
                    >
                        search
                    </button>
                </form>
            </div> */}
    </div>
  );
}

export default SearchgetSingle;
