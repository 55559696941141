import axios from "axios";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

function AppAds() {
  const [ads, setAds] = useState([]);
  const [current, setcurrent] = useState(0);

  const fetchAppAds = async () => {
    let chart = sessionStorage.getItem("appAds");
    if (chart) {
      chart = JSON.parse(chart);
      setAds(chart);
      return;
    }
    console.log("fetching from server top users");
    const response = await axios(
      "https://userspecificapi-zscu3untuq-el.a.run.app/getAppAds",
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "asdfwp234-0835234-34535FHD568-a26463213234234-llksdfsdlfwSLDF",
        },
      }
    );
    console.log(response);
    if (response.status === 200) {
      let res = response.data;
      sessionStorage.setItem("appAds", JSON.stringify(res.data));
      setAds(res.data);
    }
  };

  const playFlicks = async (url) => {
    const AWN = (await import("awesome-notifications")).default;

    new AWN().modal(`<video width="320" height="240" controls>
        <source src=${url} type="video/mp4">
        
      Your browser does not support the video tag.
      </video>`);
    return;
  };

  if (ads.length === 0) {
    return (
      <div className="bg-white rounded border p-3 w-full my-5 h-80 shadow-md flex items-center justify-center">
        <button className="py-3 px-4 border rounded-md" onClick={fetchAppAds}>
          Reveal App Story
        </button>
      </div>
    );
  } else
    return (
      <div className="bg-white rounded border p-3 w-full my-5 h-80 shadow-md">
        <div className="flex justify-between items-center py-1 border-b">
          <p className="text-xl">App Story</p>
          <Link
            to="/home/editAppAds"
            className="bg-green-500 py-2 px-3 m-1 shadow-md text-white rounded-sm"
          >
            Edit
          </Link>
        </div>
        <div className="flex gap-2">
          {ads?.map((ad, index) => (
            <div
              className={
                index === current
                  ? " w-28 h-42 rounded-md bg-blue-300 p-1"
                  : " w-28 h-42"
              }
            >
              <img
                src={ad.thumbnail}
                onClick={() => setcurrent(index)}
                className="w-28 h-40  object-cover rounded-md"
                alt=""
              />
            </div>
          ))}
        </div>
        <div className="flex items-end flex-col">
          <div className="divide-x-2 border rounded-md mt-5">
            <button
              className="bg-purple-500 text-white py-2 px-8 "
              onClick={() => playFlicks(ads[current].videoUrl)}
            >
              Play
            </button>
          </div>
        </div>
      </div>
    );
}

export default AppAds;
