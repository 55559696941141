import React from "react";

function Shuriken() {
  return (
    <svg
      className="h-7 w-7 mx-2"
      width="800px"
      height="800px"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#6C0BA9"
        d="M395.13 313l89.869-100.857H388.08c-45.748 0-82.824-37.063-82.824-82.824l-.297-33.208-100.857-77.147v96.917c0 45.751-37.06 82.824-82.822 82.824l-9.844.298L27.001 299.86h96.92c45.75 0 82.822 37.062 82.822 82.824l.298 20.484 100.854 89.868v-96.913c0-45.749 37.062-82.823 82.824-82.823zM256 306.669c-27.99 0-50.666-22.685-50.666-50.665 0-27.99 22.679-50.671 50.665-50.671 27.99 0 50.67 22.682 50.67 50.671 0 27.98-22.683 50.665-50.67 50.665z"
      />
    </svg>
  );
}

export default Shuriken;
