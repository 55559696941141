import React, { Component } from "react";
import Resizer from "react-image-file-resizer";

class imageResizer extends Component {
  constructor(props) {
    super(props);
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
    this.state = {
      newImage: "",
    };
  }

  fileChangedHandler(event) {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          100,
          100,
          "JPEG",
          70,
          0,
          (uri) => {
            console.log(typeof uri);

            var blob = new Blob([uri], { type: "image/jpeg" });
            var file = new File([blob], "foo.jpeg", { type: "image/jpeg" });
            console.log(file);
            this.setState({ newImage: uri });
          },
          "base64",
          20,
          20
        );
      } catch (err) {
        console.log(err);
      }
    }
  }

  render() {
    return (
      <div className="App">
        <input type="file" onChange={this.fileChangedHandler} />
        <img src={this.state.newImage} alt="" />
      </div>
    );
  }
}

export default imageResizer;
