const urls = {
  // admin: window.location.origin.includes("localhost")
  //   ? "http://localhost:8080/"
  //   : "https://adminapi-zscu3untuq-el.a.run.app/",
  admin: "https://adminapi-zscu3untuq-el.a.run.app/",
  brands: "https://brandapi-zscu3untuq-el.a.run.app/",
  brandAuth: "KLAHSDJHOWR-a234u20jf-23049usldfs-20klafdg1g",
  brandAdmin: "https://brandadminapi-zscu3untuq-el.a.run.app/",
  adminAuth: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
  brandAdminAuth:
    "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN",
};

export default urls;
