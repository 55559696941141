import axios from "axios";
import React from "react";
import { useState } from "react";
import urls from "../utils/urls";
function ExportCollection({ collection }) {
  const [loading, setLoading] = useState(false);

  let collections = {
    products: "getAllProducts/",
    users: "getAllUsers/",
    brands: "getAllBrands/",
  };
  let csvheaders = {
    products:
      "id,name,parent,description,reviews,flicks,createdAt,rating,affiliateLink,status",
    users: "id,name,phone,email,reviews,flicks,joinDate,rating,address,pincode",
    brands: "id,name,reviews,flicks,dateAdded,rating,status,products",
  };

  const fetchUsers = async () => {
    const response = await axios(urls.admin + collections[collection], {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
        Authorization: urls.adminAuth,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else return [];
  };

  const exportCsv = async () => {
    setLoading(true);
    let allProds = await fetchUsers();
    let csvContent = "data:text/csv;charset=utf-8,";
    const headers = csvheaders[collection];
    csvContent += headers + "\r\n";
    const fields = headers.split(",");
    let content = allProds.reduce((total, current) => {
      let row = fields.map((f) =>
        current[f] ? current[f].toString() : "null"
      );
      row = row.join(",") + "\r\n";
      return total + row;
    }, "");

    csvContent += content;
    console.log(csvContent);

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", collection + ".csv");
    document.body.appendChild(link); // Required for FF
    link.click();
    setLoading(false);
  };

  if (loading)
    return (
      <button className="w-44 bg-red-500 text-white rounded-md shadow-md">
        <i className="fas fa fa-spinner animate-spin"></i>
      </button>
    );
  else
    return (
      <button
        onClick={exportCsv}
        className="w-44 bg-red-500 text-white rounded-md shadow-md"
      >
        Export {collection}
      </button>
    );
}

export default ExportCollection;
