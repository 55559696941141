import AWN from "awesome-notifications";
import React, { useRef } from "react";
import { useState } from "react";
import urls from "../utils/urls";
import axios from "axios";

function UpdateVarientsProduct() {
  const [loading, setLoading] = useState(false);
  const [products, setproducts] = useState(null);
  const [filtered, setFiltered] = useState(false);

  const textArea = useRef();

  // name,parent,description,affiliateLink,coverImage,imageUrl,sku,category,subcategory
  const filterProduct = () => {
    var prods = textArea.current.value;
    setLoading(true);

    prods = JSON.parse(prods);
    prods = prods.products;
    console.log(prods);
    prods = prods.map((d) => ({
      sku: d.variants[0].sku,
      variants: d.variants.map((va) => ({
        id: va.id,
        title: va.title,
      })),
      options:
        d.options[0].name === "Title"
          ? null
          : d.options.map((va) => ({ name: va.name, values: va.values })),
    }));

    prods = prods.filter((p) => p.sku && p.sku.length > 2);
    console.log(prods);
    prods = prods.map((d) => {
      if (d.sku.includes("-")) d.sku = d.sku.substring(0, d.sku.indexOf("-"));
      return d;
    });
    console.log(prods);
    setproducts(prods);
    setLoading(false);
    setFiltered(true);
  };

  const exportTsSKU = async () => {
    setLoading(true);
    const selectedFile = new File([JSON.stringify(products)], "product.json", {
      type: "text/plain",
    });

    let formData = new FormData();
    formData.append("file", selectedFile);

    new AWN().async(
      axios.post(urls.admin + "updateProductVariants", formData, {
        headers: {
          Authorization: urls.adminAuth,
        },
      }),
      function (response) {
        if (response.data.success)
          new AWN().success("File uploaded successfully.");
        else new AWN().alert(response.data.message);
      },
      function (error) {
        new AWN().alert("Error uploading file.");
      }
    );
  };

  return (
    <div className="p-3">
      <h3 className="my-2 py-4 px-3 text-3xl">Update Variants</h3>
      {filtered ? (
        <>
          <div className=" py-3 my-3 shadow-lg px-2 flex gap-5">
            <p className="text-sm">
              Total :<span className="text-2xl"> {products?.length}</span>
            </p>

            <button
              onClick={exportTsSKU}
              className="p-2 cursor-pointer bg-green-400 text-white rounded-sm shadow-md"
            >
              Update Product Variants
            </button>
          </div>
          <table className="w-full h-96 overflow-auto  border p-2">
            <thead>
              <tr className="bg-gray-200">
                <th>sku</th>
                <th>variants</th>
                <th>options</th>
              </tr>
            </thead>
            <tbody>
              {products.map((el, index) => (
                <tr key={el.sku} className="h-12 px-2">
                  {Object.entries(el).map(([k, v]) => {
                    if (k === "variants")
                      return (
                        <td key={el.sku + k}>
                          {v.map((e) => e.title).join(", ")}
                        </td>
                      );
                    if (k === "options") {
                      return (
                        <td key={el.sku + k}>
                          {v
                            ? v
                                .map(
                                  (e) => e.name + " => " + e.values.join(" / ")
                                )
                                .join(", ")
                            : 0}
                        </td>
                      );
                    } else return <td key={k}> {v} </td>;
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <div className="flex flex-col items-center">
          <textarea
            className=" my-4 h-96 w-full rounded border border-black p-3 bg-gray-100"
            placeholder="paste products.json text here..."
            ref={textArea}
          ></textarea>
          <button
            onClick={filterProduct}
            className=" px-4 py-3 bg-green-400 text-white"
          >
            {loading ? "Processing..." : "Update"}
          </button>
        </div>
      )}
    </div>
  );
}

export default UpdateVarientsProduct;
